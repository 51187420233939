import { createRequest } from './create-request';
import {
  CreateDeviceUserRequestDto,
  DeviceUserSimpleDto,
  GetDeviceUserListRequestDto,
  GetDeviceUserListResponseDto,
  GetDeviceUsersCriteriaResponseDto,
  GetDeviceUsersCriteriaValuesRequestDto,
  GetDeviceUsersCriteriaValuesResponseDto,
  UpdateDeviceUserRequestDto,
  UploadSpreadsheetRequestDto
} from '../types/api';

export async function getDeviceUserList(
  params?: GetDeviceUserListRequestDto
): Promise<GetDeviceUserListResponseDto> {
  return createRequest({ url: 'device-users', params });
}

export async function getDeviceUser(id: string): Promise<DeviceUserSimpleDto> {
  return createRequest<DeviceUserSimpleDto>({ url: `device-users/${id}` });
}

export async function createDeviceUser(
  data: CreateDeviceUserRequestDto
): Promise<DeviceUserSimpleDto> {
  return createRequest({ method: 'POST', url: `device-users`, data });
}

export async function updateDeviceUser(
  id: string,
  data: UpdateDeviceUserRequestDto
): Promise<DeviceUserSimpleDto> {
  return createRequest({ method: 'PATCH', url: `device-users/${id}`, data });
}

export async function deleteDeviceUser(id: string): Promise<DeviceUserSimpleDto> {
  return createRequest({ method: 'DELETE', url: `device-users/${id}` });
}

export async function uploadDeviceUsers(
  data: UploadSpreadsheetRequestDto
): Promise<{ users: DeviceUserSimpleDto[]; count: number }> {
  return createRequest({
    url: 'device-users/upload',
    method: 'POST',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}

export async function getDeviceUsersCriteriaOptions(): Promise<GetDeviceUsersCriteriaResponseDto> {
  return createRequest({ url: `device-users/criteria/options` });
}

export async function getDeviceUsersCriteriaValues(
  params: GetDeviceUsersCriteriaValuesRequestDto
): Promise<GetDeviceUsersCriteriaValuesResponseDto> {
  return createRequest({ url: `device-users/criteria/values`, params });
}

export async function syncDeviceUsersViaLDAP(): Promise<void> {
  return createRequest({ url: `device-users/sync/ldap`, method: 'POST' });
}
