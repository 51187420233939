import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Permission } from '../../../types/api';
import { usePermission } from '../../contexts/permission.context';
import { Paths } from '../../constants';
import { Spin } from '@gravity-ui/uikit';

type IProps = {
  to: Permission[];
};

export const RestrictedRoute: FC<IProps> = (props) => {
  const { to } = props;

  const { loading, isAllowedTo } = usePermission();

  if (loading) {
    return <Spin />;
  }

  return isAllowedTo(...to) ? <Outlet /> : <Navigate to={Paths.SIGN_IN} replace />;
};
