import { FC, FormEventHandler, useEffect, useMemo, useState } from 'react';
import {
  DeviceCriteriaDto,
  DeviceOsType,
  SearchDeviceByConditionDto
} from '../../../../../types/api';
import { useFieldArray, useForm } from 'react-hook-form';
import { Column, Table } from '../../../../components/table/table.component';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  RadioButton,
  RadioButtonOption,
  Select,
  TextInput
} from '@gravity-ui/uikit';
import css from './filter-popup.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { searchDeviceFormSchema, SearchDeviceFormValues } from './filter-popup.schema';
import useDeviceSection, { DeviceType } from '../../../../contexts/device-section.context';
import { deviceCriteriaTitles } from '../../../../../const/criteria-titles.const';
import { getCriteria, getCriterionValues } from '../../../../../api/device';
import { SelectOption } from '@gravity-ui/uikit/build/esm/components/Select/types';
import { Ellipsis } from '@gravity-ui/icons';
import { AsyncSelect } from '../../../../components/async-select/async-select.component';

interface IProps {
  conditions: SearchDeviceByConditionDto[];
  onSearch: (conditions: SearchDeviceByConditionDto[]) => void;
  onCancel: () => void;
}

export const FilterPopup: FC<IProps> = (props) => {
  const { onSearch, onCancel, conditions } = props;

  const [criteriaNumber, setCriteriaNumber] = useState<number>(1);
  const [searchDeviceCriteria, setSearchDeviceCriteria] = useState<DeviceCriteriaDto[]>([]);
  const criteriaValues: Record<string, SelectOption[]> = useMemo(() => ({}), []);

  const { control, register, setValue, watch, getValues, reset } = useForm<SearchDeviceFormValues>({
    resolver: yupResolver(searchDeviceFormSchema),
    defaultValues: { conditions }
  });
  const { fields, append, remove } = useFieldArray({ name: 'conditions', control });

  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const conjunctiveOptions: RadioButtonOption[] = [
    { content: t('inventory.filter.and_or.and'), value: 'and' },
    { content: t('inventory.filter.and_or.or'), value: 'or' }
  ];
  const operatorOptions: SelectOption[] = [
    { content: t('inventory.filter.operator.is'), value: '=' },
    { content: t('inventory.filter.operator.is_not'), value: '!=' },
    { content: t('inventory.filter.operator.like'), value: 'like' },
    { content: t('inventory.filter.operator.not_like'), value: 'not like' },
    { content: '>', value: '>' },
    { content: '>=', value: '>=' },
    { content: '<', value: '<' },
    { content: '<=', value: '<=' }
  ];
  const criteriaDropdownValues: SelectOption[] = searchDeviceCriteria.map((criterion) => ({
    content: deviceCriteriaTitles[criterion.name] || criterion.name,
    value: criterion.name
  }));

  const handleAddCriterionClick = () => {
    setCriteriaNumber(criteriaNumber + 1);
    append({
      conjunctive: criteriaNumber === 1 ? undefined : 'and',
      criteria: criteriaDropdownValues.length > 0 ? criteriaDropdownValues[0].value : '',
      operator: '=',
      value: ''
    });
  };

  const handleRemoveCriterionClick = (index: number) => () => {
    setCriteriaNumber(criteriaNumber - 1);
    remove(index);
  };

  const handleClickStartBracket = (index: number) => () => {
    const prev = getValues().conditions[index].start_bracket;
    setValue(`conditions.${index}.start_bracket`, !prev);
  };

  const handleClickEndBracket = (index: number) => () => {
    const prev = getValues().conditions[index].end_bracket;
    setValue(`conditions.${index}.end_bracket`, !prev);
  };

  const handleChangeConjunctive = (index: number) => (value: string) =>
    setValue(`conditions.${index}.conjunctive`, value as SearchDeviceByConditionDto['conjunctive']);

  const handleChangeOperator = (index: number) => (value: string[]) => {
    setValue(`conditions.${index}.operator`, value[0] as SearchDeviceByConditionDto['operator']);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
  };

  const fetchCriteriaValue = (criterion: string) => async () => {
    if (criteriaValues[criterion]) return criteriaValues[criterion];

    const values = await getCriterionValues({
      criterion,
      os_type: deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS
    });

    criteriaValues[criterion] = Object.values(values)[0].map((item) => ({
      value: item.toString(),
      content: item.toString()
    }));
    return criteriaValues[criterion];
  };

  const handleApply = async () => {
    onSearch(getValues().conditions);
  };

  const handleCancel = () => {
    reset({ conditions });
    onCancel();
  };

  const columns: Column<SearchDeviceByConditionDto>[] = [
    {
      id: 'conjunctive',
      name: t('inventory.filter.table.heading.and_or'),
      selector: (_, index) => {
        if (index == 0) {
          setValue(`conditions.${index}.conjunctive`, undefined);
          return <></>;
        }

        const selected = watch(`conditions.${index}.conjunctive`);
        return (
          <RadioButton
            key={fields[index].id}
            className={css.RadioButton}
            options={conjunctiveOptions}
            defaultValue={selected || undefined}
            onUpdate={handleChangeConjunctive(index)}
            {...register(`conditions.${index}.conjunctive`)}
          />
        );
      },
      width: 120
    },
    {
      id: 'start_bracket',
      name: '',
      selector: (_, index) => {
        const checked = watch(`conditions.${index}.start_bracket`);
        return (
          <Button
            view="outlined"
            className={checked ? undefined : css.UncheckedBracketCheckbox}
            onClick={handleClickStartBracket(index)}
          >
            (
          </Button>
        );
      },
      width: 0
    },
    {
      id: 'criteria',
      name: t('inventory.filter.table.heading.criteria'),
      selector: (_, index) => {
        const criteria = watch(`conditions.${index}.criteria`);
        return (
          <Select
            key={fields[index].id}
            options={criteriaDropdownValues}
            defaultValue={
              criteriaDropdownValues.length > 0 ? [criteriaDropdownValues[0].value] : []
            }
            width="max"
            value={[criteria]}
            onUpdate={(value) => setValue(`conditions.${index}.criteria`, value[0])}
            {...register(`conditions.${index}.criteria`)}
          />
        );
      },
      width: 300
    },
    {
      id: 'operator',
      name: t('inventory.filter.table.heading.operator'),
      selector: (_, index) => {
        const operator = watch(`conditions.${index}.operator`);

        return (
          <Select
            key={fields[index].id}
            options={operatorOptions}
            defaultValue={[operatorOptions[0].value]}
            width="max"
            value={[operator]}
            onUpdate={handleChangeOperator(index)}
            {...register(`conditions.${index}.operator`)}
          />
        );
      },
      width: 150
    },
    {
      id: 'value',
      name: t('inventory.filter.table.heading.value'),
      selector: (_, index) => (
        <TextInput key={fields[index].id} {...register(`conditions.${index}.value`)} />
      )
    },
    {
      id: 'available_value',
      name: '',
      selector: (_, index) => {
        const selectedCriteria =
          getValues().conditions?.[index].criteria || criteriaDropdownValues[0].value;
        const options: SelectOption[] = criteriaValues[selectedCriteria];

        return (
          <AsyncSelect
            defaultOptions={options}
            onUpdate={(value) => setValue(`conditions.${index}.value`, value)}
            onLoad={fetchCriteriaValue(selectedCriteria)}
            icon={Ellipsis}
          />
        );
      },
      width: 40
    },
    {
      id: 'end_bracket',
      name: '',
      selector: (_, index) => {
        const checked = watch(`conditions.${index}.end_bracket`);
        return (
          <Button
            key={fields[index].id}
            view="outlined"
            className={checked ? undefined : css.UncheckedBracketCheckbox}
            onClick={handleClickEndBracket(index)}
          >
            )
          </Button>
        );
      },
      width: 0
    },
    {
      id: 'delete',
      name: '',
      selector: (_, index) => (
        <Button view="outlined-danger" selected onClick={handleRemoveCriterionClick(index)}>
          {t('inventory.filter.delete_criterion_btn')}
        </Button>
      ),
      align: 'end',
      width: 0
    }
  ];

  useEffect(() => {
    const fetchCriteria = async () => {
      if (searchDeviceCriteria.length === 0) {
        const result = await getCriteria(osType, { forSearch: true });
        setSearchDeviceCriteria(result.criteria);
      }
    };

    void fetchCriteria();
  }, []);

  return (
    <form className={css.Root} onSubmit={handleSubmit}>
      {fields.length > 0 && <Table columns={columns} data={fields} />}
      <Button view="outlined-info" onClick={handleAddCriterionClick}>
        {t('inventory.filter.add_criterion_btn')}
      </Button>
      <Divider style={{ width: '100%' }} />
      <div className={css.ButtonGroup}>
        <Button view="outlined" onClick={handleCancel}>
          {t('inventory.filter.cancel_btn')}
        </Button>
        <Button view="action" onClick={handleApply}>
          {t('inventory.filter.apply_btn')}
        </Button>
      </div>
    </form>
  );
};
