import React, { FC, useState } from 'react';
import css from './activation-tab.module.scss';
import {
  DeviceActivationInfoDto,
  DeviceFullDto,
  Permission
} from '../../../../../../../../../types/api';
import { getActivationInfo } from '../../../../../../../../../api/device';
import useRequest from '../../../../../../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../../../../../../contexts/permission.context';
import { BlankPage } from '../../../../../../../../components/blank-page/blank-page.component';
import { Button, Text, useToaster } from '@gravity-ui/uikit';
import { getLocalizedErrorString } from '../../../../../../../../../utils/localize-error';

interface IProps {
  device?: DeviceFullDto;
}

export const ActivationTab: FC<IProps> = (props: IProps) => {
  const { device } = props;

  const getActivationInfoRequest = useRequest<DeviceActivationInfoDto | null>();

  const [activationInfo, setActivationInfo] = useState('');
  const [isCodeShown, setIsCodeShown] = useState(false);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();

  const handleShowCodeClick = async () => {
    try {
      if (device) {
        const activationInfoResponse = await getActivationInfoRequest.send(
          getActivationInfo(device.id),
          1000
        );
        if (activationInfoResponse) {
          if (activationInfoResponse.activation_lock_bypass_code) {
            setActivationInfo(activationInfoResponse.activation_lock_bypass_code);
            setIsCodeShown(true);
          }
          if (activationInfoResponse.error) {
            setActivationInfo('');
            setIsCodeShown(false);

            toaster.add({
              name: 'error',
              title: t('inventory.device_page.management_tab.activation_tab.activation_form.error'),
              content: activationInfoResponse.error,
              theme: 'danger',
              autoHiding: 5000
            });
          }
        }
      }
    } catch (error) {
      setActivationInfo('');
      setIsCodeShown(false);

      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  return device?.mdm_enrollment_status ? (
    <div className={css.Root}>
      <Button
        view="action"
        className={`${css.ActivationButton} ${isCodeShown ? css.Hidden : ''}`}
        onClick={handleShowCodeClick}
        loading={getActivationInfoRequest.loading}
        disabled={!isAllowedTo(Permission.EditCommands)}
      >
        {t('inventory.device_page.management_tab.activation_tab.activation_form.update_btn')}
      </Button>
      <Text className={`${isCodeShown ? '' : css.Hidden}`} variant="subheader-2">
        {t('inventory.device_page.management_tab.activation_tab.activation_form.title')}
      </Text>
      <Text className={`${isCodeShown ? '' : css.Hidden}`}>{activationInfo}</Text>
    </div>
  ) : (
    <BlankPage text={t('inventory.device_page.management_tab.commands_tab.device_offline')} />
  );
};
