import { FC, FormEvent, useEffect, useState } from 'react';
import css from './user-local-form.module.scss';
import { Permission, UserOriginType } from '../../../../../../types/api';
import { usePermission } from '../../../../../contexts/permission.context';
import { useTranslation } from 'react-i18next';
import { UserLocalFormValues } from './user-local-form.schema';
import { UseFormReturn } from 'react-hook-form';
import { IRole } from '../../user.component';
import { userOriginTypeTitles } from '../../../../../../const/users.const';
import { Button, Icon, Select, SelectOption, Text, TextInput } from '@gravity-ui/uikit';
import { Eye, EyeSlash } from '@gravity-ui/icons';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  roles?: IRole[];
  form: UseFormReturn<UserLocalFormValues>;
}

interface IEyeButtonProps {
  opened: boolean;
  onClick: () => void;
}

const EyeButton = (props: IEyeButtonProps) => {
  const { opened, onClick } = props;
  return (
    <Button view="flat" size="xs" onClick={onClick}>
      <Icon data={opened ? Eye : EyeSlash} />
    </Button>
  );
};

export const UserLocalFormComponent: FC<IProps> = (props) => {
  const { mode, roles = [], form } = props;
  const {
    register,
    watch,
    setValue,
    formState: { errors }
  } = form;

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);

  const rolesOptions: SelectOption[] = roles.map((role) => ({
    content: role.title,
    value: role.id
  }));

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handlePasswordEyeButtonClick = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordConfirmEyeButtonClick = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  useEffect(() => {
    if (roles) {
      if (watch().role.id) {
        setValue('role', watch().role);
      } else {
        setValue('role', roles[0]);
      }
    }
  }, [roles]);

  return (
    <form onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditAdministration)}>
        <div>
          <Text>{t('settings.tiles.users.page.user_page.username')}</Text>
          <TextInput
            error={errors.username?.message}
            disabled={mode === 'view'}
            {...register('username')}
          />
        </div>
        <div>
          <Text>{t('settings.tiles.users.page.user_page.origin_type')}</Text>
          <TextInput value={userOriginTypeTitles[UserOriginType.Local]} disabled />
        </div>
        <div>
          <Text>{t('settings.tiles.users.page.user_page.role')}</Text>
          <Select
            options={rolesOptions}
            disabled={mode === 'view'}
            width="max"
            value={[watch().role.id]}
            onUpdate={(value) => setValue('role.id', value[0])}
          />
        </div>
        {mode !== 'view' && (
          <>
            <div>
              <Text>{t('settings.tiles.users.page.user_page.password')}</Text>
              <TextInput
                type={showPassword ? 'text' : 'password'}
                error={errors.password?.message}
                endContent={
                  <EyeButton opened={showPassword} onClick={handlePasswordEyeButtonClick} />
                }
                placeholder={t('sign_in.password')}
                {...register('password')}
              />
            </div>
            <div>
              <Text>{t('settings.tiles.users.page.user_page.confirm_password')}</Text>
              <TextInput
                type={showPasswordConfirm ? 'text' : 'password'}
                error={errors.confirmPassword?.message}
                endContent={
                  <EyeButton
                    opened={showPasswordConfirm}
                    onClick={handlePasswordConfirmEyeButtonClick}
                  />
                }
                placeholder={t('sign_in.password')}
                {...register('confirmPassword')}
              />
            </div>
          </>
        )}
      </fieldset>
    </form>
  );
};
