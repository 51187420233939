import cn from 'classnames';
import css from './policy-runs-tab.module.scss';
import { FC, ReactNode, useEffect, useState } from 'react';
import { GetPolicyRunsResponseDto, PolicyRunSimpleDto } from '../../../../../../../types/api';
import { WithClassname } from '../../../../../../../types/common';
import {
  acknowledgeAllPolicyRuns,
  getPolicyRuns,
  rerunPolicy
} from '../../../../../../../api/policies';
import { PolicyRun } from './components/policy-run/policy-run.component';
import useRequest from '../../../../../../../hooks/useRequest';
import { formatDate } from '../../../../../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../../../../../const/pagination.const';
import { Column, PaginationState, Table } from '../../../../../../components/table/table.component';
import { Button, Dialog, Label } from '@gravity-ui/uikit';
import { TableSkeleton } from './components/skeleton/table-skeleton.component';

interface IProps {
  policyId?: string;
}

export const PolicyRunsTab: FC<IProps & WithClassname> = (props) => {
  const { className, policyId } = props;
  const acknowledgeAllRequest = useRequest();
  const rerunPolicyRequest = useRequest();
  const getPolicyRunsRequest = useRequest<GetPolicyRunsResponseDto>();
  const [policyRuns, setPolicyRuns] = useState<PolicyRunSimpleDto[]>();
  const [policyRunsCount, setPolicyRunsCount] = useState(0);
  const [selectedPolicyRun, setSelectedPolicyRun] = useState<PolicyRunSimpleDto | null>(null);
  const [isRerunModalOpen, setIsRerunModalOpen] = useState(false);

  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: 10
  });

  const { t } = useTranslation();

  const handlePaginationChange = (state: PaginationState) => setPaginationState(state);

  const handlePolicyRunDeselect = () => setSelectedPolicyRun(null);
  const handleRunSelect = (policyRun: PolicyRunSimpleDto) => setSelectedPolicyRun(policyRun);

  const handleAcknowledgeAll = async () => {
    if (!policyId || acknowledgeAllRequest.loading) return;
    await acknowledgeAllRequest.send(acknowledgeAllPolicyRuns(policyId));
    setPolicyRuns(policyRuns?.map((run) => ({ ...run, acknowledged: true })));
  };

  const handleRerunPolicyClick = () => {
    if (!policyId || rerunPolicyRequest.loading) return;
    setIsRerunModalOpen(true);
  };
  const handleRerunPolicyCancel = () => {
    setIsRerunModalOpen(false);
  };
  const handleRerunPolicyConfirm = async () => {
    if (!policyId || rerunPolicyRequest.loading) return;
    await rerunPolicyRequest.send(rerunPolicy(policyId));
    setIsRerunModalOpen(false);
    const response = await getPolicyRunsRequest.send(
      getPolicyRuns({
        policy_id: policyId,
        page: paginationState.page,
        limit: paginationState.rowsPerPage
      })
    );
    setPolicyRuns(response.policy_runs);
  };

  useEffect(() => {
    const init = async () => {
      if (!policyId || getPolicyRunsRequest.loading) return;
      const response = await getPolicyRunsRequest.send(
        getPolicyRuns({
          policy_id: policyId,
          page: paginationState.page,
          limit: paginationState.rowsPerPage
        })
      );
      setPolicyRuns(response.policy_runs);
      setPolicyRunsCount(response.count);
    };
    init().then();
  }, [paginationState]);

  const getRunStatusLabel = (run: PolicyRunSimpleDto): ReactNode => {
    if (run.canceled)
      return <Label theme="warning">{t('policies.policy_run_list.statuses.canceled')}</Label>;
    if (run.success)
      return <Label theme="success">{t('policies.policy_run_list.statuses.success')}</Label>;
    if (run.success === false && run.acknowledged)
      return <Label theme="normal">{t('policies.policy_run_list.statuses.failed')}</Label>;
    if (run.success === false)
      return <Label theme="danger">{t('policies.policy_run_list.statuses.failed')}</Label>;
    if (run.cancel_pending)
      return <Label theme="warning">{t('policies.policy_run_list.statuses.cancel_pending')}</Label>;
    if (run.in_progress)
      return <Label theme="info">{t('policies.policy_run_list.statuses.in_progress')}</Label>;
    return <Label theme="normal">{t('policies.policy_run_list.statuses.scheduled')}</Label>;
  };
  const policiesColumns: Column<PolicyRunSimpleDto>[] = [
    {
      id: 'device',
      name: t('policies.policy_run_list.column_device'),
      selector: (policyRun) =>
        policyRun.device
          ? `${policyRun.device.model_name} (${policyRun.device.serial_number})`
          : t('policies.policy_run_list.device_deleted')
    },
    {
      id: 'status',
      name: t('policies.policy_run_list.column_status'),
      selector: (policyRun) => getRunStatusLabel(policyRun)
    },
    {
      id: 'started_at',
      name: t('policies.policy_run_list.column_started_at'),
      selector: (policyRun) => (policyRun.started_at ? formatDate(policyRun.started_at) : undefined)
    },
    {
      id: 'finished_at',
      name: t('policies.policy_run_list.column_finished_at'),
      selector: (policyRun) =>
        policyRun.finished_at ? formatDate(policyRun.finished_at) : undefined
    }
  ];
  const tableLeftContent = (
    <div className={css.BtnGroup}>
      <Button
        onClick={handleAcknowledgeAll}
        loading={acknowledgeAllRequest.loading}
        view="action"
        disabled={!policyRuns?.length}
      >
        {t('policies.policy_run_list.button_acknowledge_all')}
      </Button>
      <Button
        className={css.TopSectionBtn}
        onClick={handleRerunPolicyClick}
        loading={rerunPolicyRequest.loading}
        view="action"
      >
        {t('policies.policy_run_list.button_rerun_policy')}
      </Button>
    </div>
  );

  return (
    <div className={cn(css.Root, className)}>
      {!selectedPolicyRun && getPolicyRunsRequest.loading && <TableSkeleton />}
      {!selectedPolicyRun && !getPolicyRunsRequest.loading && (
        <Table
          columns={policiesColumns}
          data={policyRuns || []}
          onRowClick={handleRunSelect}
          pagination
          paginationState={paginationState}
          onChangePagination={handlePaginationChange}
          paginationTotalRows={policyRunsCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          leftContent={tableLeftContent}
        />
      )}
      {selectedPolicyRun && (
        <PolicyRun policyRun={selectedPolicyRun} onBack={handlePolicyRunDeselect} />
      )}
      <Dialog onClose={handleRerunPolicyCancel} open={isRerunModalOpen}>
        <Dialog.Header caption={t('policies.policy_run_list.modal_rerun_policy_title')} />
        <Dialog.Body>{t('policies.policy_run_list.modal_rerun_policy_text')}</Dialog.Body>
        <Dialog.Footer
          onClickButtonApply={handleRerunPolicyConfirm}
          onClickButtonCancel={handleRerunPolicyCancel}
          textButtonApply={t('policies.policy_run_list.modal_rerun_policy_submit')}
          textButtonCancel={t('common.modal.cancel_btn')}
          loading={rerunPolicyRequest.loading}
        />
      </Dialog>
    </div>
  );
};
