import React, { FC, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Icon, Tabs, TabsItemProps } from '@gravity-ui/uikit';
import css from './navbar.module.scss';
import { WithClassname } from '../../../types/common';
import cn from 'classnames';
import { ChevronLeft, ChevronRight } from '@gravity-ui/icons';

export interface NavbarItemProps {
  id: string;
  icon?: ReactNode;
  title: string;
  disabled?: boolean;
}

interface IProps {
  activeTab: string;
  onSelectTab: (tabId: string) => void;
  items: NavbarItemProps[];
  size?: 'l' | 'm';
}

export const Navbar: FC<IProps & WithClassname> = (props) => {
  const { className, activeTab, onSelectTab, items, size = 'l' } = props;

  const [scrollLeft, setScrollLeft] = useState(0);
  const [leftButtonVisible, setLeftButtonVisible] = useState(false);
  const [rightButtonVisible, setRightButtonVisible] = useState(true);

  const ref = useRef<HTMLDivElement>(null);

  const wrapTo = (item: TabsItemProps) => (
    <Button
      className={css?.[`TabButton_size_${size}`]}
      key={item.id}
      disabled={item.disabled}
      view="flat-secondary"
      size={size}
      selected={item.id === activeTab}
      onClick={() => onSelectTab(item.id)}
    >
      {item.icon}
      {item.title}
    </Button>
  );

  const handleScrollLeft = () => {
    if (!ref.current) return;

    ref.current.scrollLeft -= 100;
    setScrollLeft((prev) => prev - 100);
  };

  const handleScrollRight = () => {
    if (!ref.current) return;

    ref.current.scrollLeft += 100;
    setScrollLeft((prev) => prev + 100);
  };

  useLayoutEffect(() => {
    if (!ref.current) return;

    ref.current.addEventListener('scroll', (event) => {
      if (event.target) setScrollLeft((event.target as unknown as HTMLDivElement).scrollLeft);
    });

    setRightButtonVisible(
      ref.current.scrollLeft < ref.current.scrollWidth - ref.current.clientWidth - 1
    );
    setLeftButtonVisible(ref.current.scrollLeft > 0);
  }, []);

  useEffect(() => {
    if (!ref.current) return;

    setRightButtonVisible(scrollLeft < ref.current.scrollWidth - ref.current.clientWidth - 1);
    setLeftButtonVisible(scrollLeft > 0);
  }, [scrollLeft]);

  return (
    <div className={css.Root}>
      <Tabs
        ref={ref}
        className={cn(className, css.Navbar)}
        activeTab={activeTab}
        items={items}
        onSelectTab={onSelectTab}
        wrapTo={wrapTo}
      />

      {leftButtonVisible && (
        <Button
          className={css?.[`LeftButton_size_${size === 'l' ? 'm' : 's'}`]}
          size={size === 'l' ? 'm' : 's'}
          onClick={handleScrollLeft}
          view="outlined"
        >
          <Icon data={ChevronLeft} />
        </Button>
      )}
      {rightButtonVisible && (
        <Button
          className={css?.[`RightButton_size_${size === 'l' ? 'm' : 's'}`]}
          size={size === 'l' ? 'm' : 's'}
          onClick={handleScrollRight}
          view="outlined"
        >
          <Icon data={ChevronRight} />
        </Button>
      )}
    </div>
  );
};
