import { FC, FormEvent, useEffect } from 'react';
import css from './settings-scep.module.scss';
import { GetSettingsResponseDto, Permission, UpdateSettingsDto } from '../../../../types/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { getSettings, updateSettings } from '../../../../api/settings';
import { usePermission } from '../../../contexts/permission.context';
import { ScepForm, scepFormSchema } from './settings-scep.schema';
import { Button, Checkbox, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { Header } from '../../../components/header/header.component';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';

export const SettingsScep: FC = () => {
  const initRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const updateRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const { register, setValue, trigger, getValues, watch, formState } = useForm<ScepForm>({
    mode: 'onChange',
    resolver: yupResolver(scepFormSchema),
    defaultValues: scepFormSchema.getDefault()
  });
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleClickSave = async () => {
    if (initRequest.loading || updateRequest.loading) return;
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    try {
      const requestBody: Partial<UpdateSettingsDto> = {
        use_server_as_scep_proxy: Boolean(values.use_server_as_scep_proxy),
        scep_certificate_url: String(values.scep_certificate_url)
      };
      await updateRequest.send(updateSettings(requestBody), 1000);
      toaster.add({
        name: 'save-success',
        content: t('settings.tiles.scep_config.page.settings_successfully_saved'),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error: unknown) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'save-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      const settings = await initRequest.send(
        getSettings(['use_server_as_scep_proxy', 'scep_certificate_url']),
        1000
      );
      if (settings.use_server_as_scep_proxy) {
        setValue('use_server_as_scep_proxy', settings.use_server_as_scep_proxy);
      }
      if (settings.scep_certificate_url) {
        setValue('scep_certificate_url', settings.scep_certificate_url);
      }
    };
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Header
        rightContent={
          <Button
            view="action"
            loading={updateRequest.loading}
            disabled={
              !isAllowedTo(Permission.EditPolicies) ||
              !isAllowedTo(Permission.EditAdministration) ||
              initRequest.loading
            }
            onClick={handleClickSave}
          >
            {t('settings.tiles.scep_config.page.update_btn')}
          </Button>
        }
      />
      <div className={css.Content}>
        <div className={css.Title}>
          <Text variant="display-2">{t('settings.tiles.scep_config.name')}</Text>
        </div>
        {initRequest.loading || updateRequest.loading ? (
          <FormSkeleton />
        ) : (
          <form className={css.Form} onSubmit={handleFormSubmit}>
            <Checkbox
              className={css.Checkbox}
              content={<Text>{t('settings.tiles.scep_config.page.use_server_as_scep_proxy')}</Text>}
              checked={watch().use_server_as_scep_proxy}
              disabled={
                initRequest.loading ||
                !isAllowedTo(Permission.EditPolicies) ||
                !isAllowedTo(Permission.EditAdministration)
              }
              {...register('use_server_as_scep_proxy')}
            />
            <div>
              <Text>{t('settings.tiles.scep_config.page.scep_certificate_url')}</Text>
              <TextInput
                error={formState.errors.scep_certificate_url?.message}
                {...register('scep_certificate_url')}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
