import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import css from './user-list.module.scss';
import { usePermission } from '../../contexts/permission.context';
import {
  DeviceUserSimpleDto,
  DeviceUserSortField,
  GetDeviceUserListRequestDto,
  GetDeviceUserListResponseDto,
  PaginationSortOrder,
  Permission,
  SearchDeviceUserByConditionDto
} from '../../../types/api';
import { getDeviceUserList } from '../../../api/device-users';
import { PaginationRowsPerPageOptionsDeviceUsers } from '../../../const/pagination.const';
import { DeviceUsersUploadModal } from './components/device-users-upload-modal/device-users-upload-modal.component';
import { useDebounce } from 'use-debounce';
import { Column, PaginationState, SortState, Table } from '../../components/table/table.component';
import { id } from 'date-fns/locale';
import { Button, Text, TextInput } from '@gravity-ui/uikit';
import { Header } from '../../components/header/header.component';
import useRequest from '../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';
import noDataImage from '../../../assets/images/no-data.png';

export const DeviceUserListComponent: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [users, setUsers] = useState<DeviceUserSimpleDto[]>([]);
  const [usersCount, setUsersCount] = useState(0);

  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: PaginationRowsPerPageOptionsDeviceUsers[0]
  });
  const [sortState, setSortState] = useState<SortState>();

  const fetchRequest = useRequest<GetDeviceUserListResponseDto>();
  const [filterText, setFilterText] = useState<string>('');
  const [debouncedFilterText] = useDebounce(filterText, 1000);
  const [condition, setCondition] = useState<SearchDeviceUserByConditionDto[]>([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);

  const updateData = async (request: Partial<GetDeviceUserListRequestDto> = {}) => {
    const response = await fetchRequest.send(
      getDeviceUserList({
        conditions: request.conditions ?? condition,
        page: request.page ?? paginationState.page,
        limit: request.limit ?? paginationState.rowsPerPage,
        sort_order: request.sort_order ?? (sortState?.order as PaginationSortOrder),
        sort_field: request.sort_field ?? (sortState?.column as DeviceUserSortField)
      }),
      1000
    );
    setUsers(response.users);
    setUsersCount(response.count);
  };

  const handleRowClick = (row: DeviceUserSimpleDto) => {
    navigate(row.id);
  };

  const handleChangePagination = (state: PaginationState) => {
    setPaginationState(state);
  };

  const handleChangeSort = (state?: SortState) => {
    setSortState(state);
  };

  const handleUploadModalCancel = () => {
    setIsUploadModalOpen(false);
  };

  const handleUploadModalSuccess = () => {
    void updateData();
    setIsUploadModalOpen(false);
  };

  const handleAddClick = () => {
    navigate('new');
  };

  useEffect(() => {
    setPaginationState((prevState) => {
      prevState.page = 1;
      return prevState;
    });
    let conditions: SearchDeviceUserByConditionDto[] = [];
    if (debouncedFilterText) {
      conditions = [
        {
          criteria: 'username',
          value: debouncedFilterText
        },
        {
          criteria: 'email',
          value: debouncedFilterText
        },
        {
          criteria: 'employee_name',
          value: debouncedFilterText
        },
        {
          criteria: 'position',
          value: debouncedFilterText
        },
        {
          criteria: 'phone_number',
          value: debouncedFilterText
        },
        {
          criteria: 'computers_count',
          value: debouncedFilterText
        },
        {
          criteria: 'mobile_devices_count',
          value: Number.isNaN(Number(debouncedFilterText))
            ? ''
            : Number(debouncedFilterText).toString()
        }
      ];
    }
    setCondition(conditions);
    setPaginationState((prev) => {
      prev.page = 1;
      return prev;
    });
    void updateData({
      page: 1,
      conditions
    });
  }, [debouncedFilterText]);

  const deviceUsersColumns: Column<DeviceUserSimpleDto>[] = [
    {
      name: t('device_users.user_list.column_username'),
      selector: (row: DeviceUserSimpleDto) => row.username,
      sortable: true,
      id: DeviceUserSortField.Username
    },
    {
      name: t('device_users.user_list.column_email'),
      selector: (row: DeviceUserSimpleDto) => row?.email,
      sortable: true,
      id: DeviceUserSortField.Email
    },
    {
      name: t('device_users.user_list.column_employee_name'),
      selector: (row: DeviceUserSimpleDto) => row?.employee_name,
      sortable: true,
      id: DeviceUserSortField.EmployeeName
    },
    {
      name: t('device_users.user_list.column_phone_number'),
      selector: (row: DeviceUserSimpleDto) => row?.phone_number,
      id: DeviceUserSortField.PhoneNumber
    },
    {
      name: t('device_users.user_list.column_position'),
      selector: (row: DeviceUserSimpleDto) => row?.position,
      sortable: true,
      id: DeviceUserSortField.Position
    },
    {
      name: t('device_users.user_list.column_computers_count'),
      selector: (row: DeviceUserSimpleDto) => row?.computers_count,
      sortable: true,
      id: DeviceUserSortField.ComputersCount
    },
    {
      name: t('device_users.user_list.column_mobile_devices_count'),
      selector: (row: DeviceUserSimpleDto) => row?.mobile_devices_count,
      sortable: true,
      id: DeviceUserSortField.MobileDevicesCount
    }
  ];

  useEffect(() => {
    void updateData();
  }, [paginationState, sortState, id]);

  const headerButtons = (
    <div className={css.BtnGroup}>
      <Button
        view="action"
        disabled={fetchRequest.loading || !isAllowedTo(Permission.EditDeviceUsers)}
        onClick={handleAddClick}
      >
        {t('profiles.new_btn')}
      </Button>
      <Button
        view="action"
        disabled={fetchRequest.loading || !isAllowedTo(Permission.EditDeviceUsers)}
        onClick={() => setIsUploadModalOpen(true)}
      >
        {t('profiles.upload_btn')}
      </Button>
    </div>
  );

  const tableLeftContent = useMemo(() => {
    const handleClear = () => {
      setFilterText('');
    };

    return (
      <div className={css.Filter}>
        <TextInput
          className={css.FilterInput}
          value={filterText}
          onUpdate={setFilterText}
          placeholder={t('device_users.user_list.filter.placeholder')}
        />
        <Button view="action" onClick={handleClear}>
          {t('device_users.user_list.filter.clear_btn')}
        </Button>
      </div>
    );
  }, [filterText]);

  return (
    <div className={css.Root}>
      <Header rightContent={headerButtons} />
      <div className={css.Content}>
        <Text variant="display-2">{t('breadcrumbs.device_users')}</Text>
        {fetchRequest.loading ? (
          <TableSkeleton />
        ) : usersCount === 0 && debouncedFilterText === '' ? (
          <div className={css.NoDataContainer}>
            <img alt="no-data" src={noDataImage} />
            <Text variant="subheader-3">{t('device_users.user_list.no_data')}</Text>
            <Button
              view="action"
              disabled={fetchRequest.loading || !isAllowedTo(Permission.EditDeviceUsers)}
              onClick={handleAddClick}
            >
              {t('profiles.new_btn')}
            </Button>
          </div>
        ) : (
          <div className={css.DataTableContainer}>
            <Table
              onRowClick={handleRowClick}
              columns={deviceUsersColumns}
              data={users}
              pagination
              paginationState={paginationState}
              onChangePagination={handleChangePagination}
              paginationTotalRows={usersCount}
              paginationRowsPerPageOptions={PaginationRowsPerPageOptionsDeviceUsers}
              sortState={sortState}
              onChangeSort={handleChangeSort}
              leftContent={tableLeftContent}
            />
          </div>
        )}
      </div>
      <DeviceUsersUploadModal
        isOpen={isUploadModalOpen}
        onCancel={handleUploadModalCancel}
        onSubmit={handleUploadModalSuccess}
      />
    </div>
  );
};
