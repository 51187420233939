import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import css from './device-application-assistant.module.scss';
import { AppStoreApplicationList } from './components/app-store-application-list/app-store-application-list.component';
import { DeviceApplicationSearchFormComponent } from './components/device-application-search-form/device-application-search-form.component';
import { DeviceApplicationTypeComponent } from './components/device-application-type/device-application-type.component';
import {
  CountryCode,
  SearchDeviceApplication,
  SearchDeviceApplicationsResponseDto
} from '../../../../../types/api';
import { getAppStoreApplications } from '../../../../../api/device-applications';
import { StepSidebar } from './components/step-sidebar/step-sidebar.component';
import { FormProvider, useForm } from 'react-hook-form';
import {
  DeviceApplicationSearchFormValues,
  deviceApplicationSearchSchema
} from './components/device-application-search-form/device-application-search-form.schema';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useRequest from '../../../../../hooks/useRequest';

export enum InstallationSteps {
  NEW,
  SEARCH,
  CREATE
}

interface IProps {
  onAddApp: (app: SearchDeviceApplication) => void;
  onCreateAppManually: () => void;
}

export const MobileDeviceAppAssistant: FC<IProps> = (props: IProps) => {
  const { onAddApp, onCreateAppManually } = props;
  const navigate = useNavigate();
  const searchRequest = useRequest<SearchDeviceApplicationsResponseDto>();

  const appSearchForm = useForm<DeviceApplicationSearchFormValues>({
    mode: 'onChange',
    resolver: yupResolver(deviceApplicationSearchSchema),
    defaultValues: deviceApplicationSearchSchema.getDefault()
  });

  const [step, setStep] = useState<InstallationSteps>(InstallationSteps.NEW);
  const [apps, setApps] = useState<SearchDeviceApplication[]>([]);

  const searchApps = async () => {
    const isValid = await appSearchForm.trigger();
    if (!isValid) return;

    const request = appSearchForm.getValues();
    const response = await searchRequest.send(
      getAppStoreApplications({
        term: request.term,
        country_code: request.country_code as CountryCode
      })
    );
    setApps(response.ios_apps_list.filter((i) => i.price === 0));
  };

  const handleNextStep = async () => {
    if (step + 1 === InstallationSteps.CREATE) {
      await searchApps();
    }
    setStep(step + 1);
  };
  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleCancel = () => navigate('./..');

  const handleCreateManually = () => {
    onCreateAppManually?.();
  };

  const handleAddApp = (app: SearchDeviceApplication) => {
    onAddApp?.(app);
  };

  return (
    <div className={css.Root}>
      <StepSidebar step={step} />
      <div className={css.Content}>
        {step === InstallationSteps.NEW && (
          <DeviceApplicationTypeComponent
            disabled={searchRequest.loading}
            onCancel={handleCancel}
            onNextStep={handleNextStep}
          />
        )}
        {step === InstallationSteps.SEARCH && (
          <FormProvider {...appSearchForm}>
            <DeviceApplicationSearchFormComponent
              loading={searchRequest.loading}
              onCancel={handleCancel}
              onPreviousStep={handlePreviousStep}
              onNextStep={handleNextStep}
              onCreateManually={handleCreateManually}
            />
          </FormProvider>
        )}
        {step === InstallationSteps.CREATE && (
          <AppStoreApplicationList
            apps={apps}
            disabled={searchRequest.loading}
            onAddApp={handleAddApp}
            onCancel={handleCancel}
            onPreviousStep={handlePreviousStep}
          />
        )}
      </div>
    </div>
  );
};
