import { FC } from 'react';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../types/api';
import { isBoolean } from '../../../../../../../../../utils/assert.utils';
import { useTranslation } from 'react-i18next';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import css from './security-table.module.scss';
import { Text } from '@gravity-ui/uikit';

interface IProps {
  device?: DeviceFullDto;
}

interface IKeyValue {
  key: string;
  value: string | null | undefined;
}

export const SecurityTable: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const di = device?.device_information;

  const commonData: IKeyValue[] = [
    {
      key: t(
        'inventory.device_page.inventory_tab.security_tab.table.heading.system_integrity_protection'
      ),
      value: isBoolean(di?.sip_enabled)
        ? di?.sip_enabled
          ? t('inventory.device_page.inventory_tab.security_tab.table.enabled')
          : t('inventory.device_page.inventory_tab.security_tab.table.disabled')
        : undefined
    },
    {
      key: t('inventory.device_page.inventory_tab.security_tab.table.heading.gatekeeper_status'),
      value: isBoolean(di?.gatekeeper_enabled)
        ? di?.gatekeeper_enabled
          ? t('inventory.device_page.inventory_tab.security_tab.table.enabled')
          : t('inventory.device_page.inventory_tab.security_tab.table.disabled')
        : undefined
    },
    {
      key: t(
        'inventory.device_page.inventory_tab.security_tab.table.heading.xprotect_definitions_version'
      ),
      value: di?.x_protect_definitions_version
    },
    {
      key: t('inventory.device_page.inventory_tab.security_tab.table.heading.automatic_login'),
      value: isBoolean(di?.autologin_enabled)
        ? di?.autologin_enabled
          ? t('inventory.device_page.inventory_tab.security_tab.table.enabled')
          : t('inventory.device_page.inventory_tab.security_tab.table.disabled')
        : undefined
    },
    {
      key: t(
        'inventory.device_page.inventory_tab.security_tab.table.heading.remote_desktop_enabled'
      ),
      value: isBoolean(di?.ard_enabled)
        ? di?.ard_enabled
          ? t('inventory.device_page.inventory_tab.security_tab.table.yes')
          : t('inventory.device_page.inventory_tab.security_tab.table.no')
        : undefined
    },
    {
      key: t(
        'inventory.device_page.inventory_tab.security_tab.table.heading.activation_lock_enabled'
      ),
      value: isBoolean(di?.activation_lock_enabled)
        ? di?.activation_lock_enabled
          ? t('inventory.device_page.inventory_tab.security_tab.table.yes')
          : t('inventory.device_page.inventory_tab.security_tab.table.no')
        : undefined
    },
    {
      key: t(
        'inventory.device_page.inventory_tab.security_tab.table.heading.activation_lock_bypass'
      ),
      value: di?.activation_lock
    },
    {
      key: t('inventory.device_page.inventory_tab.security_tab.table.heading.firewall_enabled'),
      value: isBoolean(di?.firewall_enabled)
        ? di?.firewall_enabled
          ? t('inventory.device_page.inventory_tab.security_tab.table.yes')
          : t('inventory.device_page.inventory_tab.security_tab.table.no')
        : undefined
    },
    {
      key: t('inventory.device_page.inventory_tab.security_tab.table.heading.lock_pin'),
      value: di?.lock_pin
    }
  ];
  const appleSiliconData: IKeyValue[] = [
    {
      key: t(
        'inventory.device_page.inventory_tab.security_tab.table.heading.recovery_lock_enabled'
      ),
      value: isBoolean(di?.recovery_lock_enabled)
        ? di?.recovery_lock_enabled
          ? t('inventory.device_page.inventory_tab.security_tab.table.yes')
          : t('inventory.device_page.inventory_tab.security_tab.table.no')
        : undefined
    },
    {
      key: t('inventory.device_page.inventory_tab.security_tab.table.heading.recovery_lock'),
      value: di?.recovery_lock
    }
  ];
  const otherData: IKeyValue[] = [
    {
      key: t(
        'inventory.device_page.inventory_tab.security_tab.table.heading.firmware_password_enabled'
      ),
      value: isBoolean(di?.firmware_password_enabled)
        ? di?.firmware_password_enabled
          ? t('inventory.device_page.inventory_tab.security_tab.table.yes')
          : t('inventory.device_page.inventory_tab.security_tab.table.no')
        : undefined
    },
    {
      key: t('inventory.device_page.inventory_tab.security_tab.table.heading.firmware_password'),
      value: di?.firmware_password
    }
  ];

  const data = [...commonData, ...(di?.is_apple_silicon ? appleSiliconData : otherData)];

  const columns: Column<IKeyValue>[] = [
    {
      id: 'key',
      selector: (row) => <Text variant="subheader-1">{row.key}</Text>,
      width: 250
    },
    {
      id: 'value',
      selector: (row) => row.value
    }
  ];

  return (
    <>
      <Table data={data} columns={columns} className={css.Table} />
      <DeviceExtensionAttributes
        className={css.ExtensionAttributes}
        device={device}
        inventorySection={ExtensionAttributeInventorySection.Security}
      />
    </>
  );
};
