import {
  PolicyFrequency,
  PolicyPayloadPackageModifyType,
  PolicyRunStepSimpleDto,
  PolicyTrigger
} from '../types/api';
import i18next from '../i18n/i18n';

export const policyFrequencyTitles: Record<PolicyFrequency, string> = {
  [PolicyFrequency.Ongoing]: i18next.t('policies.options_general.policy_frequency.ongoing'),
  [PolicyFrequency.OnceEveryDay]: i18next.t(
    'policies.options_general.policy_frequency.once_every_day'
  ),
  [PolicyFrequency.OnceEveryWeek]: i18next.t(
    'policies.options_general.policy_frequency.once_every_week'
  ),
  [PolicyFrequency.OnceEveryMonth]: i18next.t(
    'policies.options_general.policy_frequency.once_every_month'
  ),
  [PolicyFrequency.OncePerComputer]: i18next.t(
    'policies.options_general.policy_frequency.once_per_computer'
  )
};

export const policyTriggerTitles: Record<PolicyTrigger, string> = {
  [PolicyTrigger.Immediate]: i18next.t('policies.options_general.policy_triggers.immediate'),
  [PolicyTrigger.Interval]: i18next.t('policies.options_general.policy_triggers.interval'),
  [PolicyTrigger.CustomEvent]: i18next.t('policies.options_general.policy_triggers.custom_event')
};

// TODO: Replace with enum from Swagger
export const policyRunStepCommandTypeTitles: Record<
  PolicyRunStepSimpleDto['command_type'],
  string
> = {
  RunCustomScript: 'Run custom script',
  GetDeviceInformation: 'Get device information',
  RestartComputer: 'Restart computer',
  ModifyPackage: 'Modify package'
};

export const policyPayloadPackageModifyTypes: Record<PolicyPayloadPackageModifyType, string> = {
  [PolicyPayloadPackageModifyType.Cache]: i18next.t(
    'policies.policy_payload_package_modify_types.cache'
  ),
  [PolicyPayloadPackageModifyType.Install]: i18next.t(
    'policies.policy_payload_package_modify_types.install'
  ),
  [PolicyPayloadPackageModifyType.InstallCached]: i18next.t(
    'policies.policy_payload_package_modify_types.install_cached'
  )
};
