import { FC } from 'react';
import { Skeleton } from '@gravity-ui/uikit';
import css from './history-table-skeleton.module.scss';

export const HistoryTableSkeleton: FC = () => {
  return (
    <>
      <div className={css.TableHeader}>
        <Skeleton className={css.FilterButtonSkeleton} />
        <Skeleton className={css.PaginationSkeleton} />
      </div>
      <Skeleton className={css.TableSkeleton} />
    </>
  );
};
