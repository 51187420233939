import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import css from './device-user.module.scss';
import {
  CreateDeviceUserRequestDto,
  DeviceFullDto,
  DeviceUserSimpleDto,
  DeviceUserSortField,
  GetDeviceUserListResponseDto,
  PaginationSortOrder,
  SearchDeviceUserByConditionDto
} from '../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { createDeviceUser, getDeviceUserList } from '../../../../../../../../../api/device-users';
import {
  getDeviceUserByDeviceId,
  linkDeviceToUser,
  unlinkDeviceUser
} from '../../../../../../../../../api/device';
import { DeviceUserList } from './components/device-user-list/device-user-list.component';
import { getLocalizedErrorString } from '../../../../../../../../../utils/localize-error';
import useRequest from '../../../../../../../../../hooks/useRequest';
import { DeviceUserFormValues } from './components/device-user-form/device-user-form.schema';
import { DeviceUsersForm } from './components/device-user-form/device-user-form.component';
import { Text, Button, TextInput, Dialog, useToaster } from '@gravity-ui/uikit';
import {
  PaginationState,
  SortState
} from '../../../../../../../../components/table/table.component';
import { useDebounce } from 'use-debounce';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';
import { UserSkeleton } from './components/user-skeleton/user-skeleton.component';

interface IProps {
  device?: DeviceFullDto;
}

export const DeviceUser: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();
  const crudRequest = useRequest<DeviceUserSimpleDto>();
  const fetchUsersRequest = useRequest<GetDeviceUserListResponseDto>();
  const userRef = useRef<DeviceUserSimpleDto>();
  const toaster = useToaster();

  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>('view');
  const [userFormValues, setUserFormValues] = useState<DeviceUserFormValues>();

  const [inputUsername, setInputUsername] = useState('');
  const [debouncedInputUsername] = useDebounce(inputUsername, 1000);

  const [foundUsers, setFoundUsers] = useState<DeviceUserSimpleDto[]>([]);
  const [foundUsersCount, setFoundUsersCount] = useState(0);

  const [sortState, setSortState] = useState<SortState>();
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: 15
  });

  const [isUnlinkModalShown, setIsUnlinkModalShown] = useState(false);

  const userToForm = (input: DeviceUserSimpleDto): DeviceUserFormValues => {
    return {
      username: input.username,
      ...(input?.email && { email: input?.email }),
      ...(input?.employee_name && { employee_name: input?.employee_name }),
      ...(input?.phone_number && { phone_number: input?.phone_number }),
      ...(input?.position && { position: input?.position }),
      computers_count: input.computers_count,
      mobile_devices_count: input.mobile_devices_count
    };
  };
  const handleSearch = async () => {
    const conditions: SearchDeviceUserByConditionDto[] = debouncedInputUsername
      ? [
          {
            criteria: 'username',
            value: debouncedInputUsername
          }
        ]
      : [];
    const response = await fetchUsersRequest.send(
      getDeviceUserList({
        conditions,
        page: paginationState.page,
        limit: paginationState.rowsPerPage,
        sort_order: sortState?.order as PaginationSortOrder,
        sort_field: sortState?.column as DeviceUserSortField
      }),
      1000
    );
    setFoundUsers(response.users);
    setFoundUsersCount(response.count);
  };

  const handleShowForm = () => {
    setFormMode('edit');
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => setInputUsername(e.target.value);
  const handleCancel = () => setFormMode('view');
  const handleUserUnlinkRequest = () => setIsUnlinkModalShown(true);
  const handleUserUnlinkCancel = () => setIsUnlinkModalShown(false);
  const handleUserUnlink = async () => {
    if (device?.udid) {
      await crudRequest.send(unlinkDeviceUser(device.id), 1000);
      setIsUnlinkModalShown(false);
      setUserFormValues(undefined);
      setFormMode('view');

      toaster.add({
        name: 'successfully_unlinked',
        content: t(
          'inventory.device_page.inventory_tab.device_user_tab.form.responses.successfully_unlinked'
        ),
        theme: 'success',
        autoHiding: 5000
      });
    }
  };
  const handleUserLink = (row: DeviceUserSimpleDto) => async () => {
    if (device?.udid) {
      const response = await crudRequest.send(
        linkDeviceToUser(device.id, { device_user_id: row.id }),
        1000
      );
      userRef.current = response;
      setUserFormValues(userToForm(response));
      setFoundUsers([]);
      setFormMode('view');

      toaster.add({
        name: 'successfully_linked',
        content: t(
          'inventory.device_page.inventory_tab.device_user_tab.form.responses.successfully_linked',
          { username: response.username }
        ),
        theme: 'success',
        autoHiding: 5000
      });
    }
  };
  const handleUserCreate = async (values: DeviceUserFormValues) => {
    if (crudRequest.loading) return;

    const request: CreateDeviceUserRequestDto = {
      username: values.username,
      ...(values.email && { email: values.email }),
      ...(values.employee_name && { employee_name: values.employee_name }),
      ...(values.phone_number && { phone_number: values.phone_number }),
      ...(values.position && { position: values.position })
    };
    try {
      const response = await crudRequest.send(createDeviceUser(request), 1000);
      if (device?.udid) {
        await crudRequest.send(linkDeviceToUser(device.id, { device_user_id: response.id }), 1000);
      }
      setFoundUsers([]);
      setFormMode('view');
      setUserFormValues(userToForm(response));

      toaster.add({
        name: 'successfully_created',
        content: t(
          'inventory.device_page.inventory_tab.device_user_tab.form.responses.successfully_created',
          { username: response.username }
        ),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (device?.udid) {
        try {
          const response = await crudRequest.send(getDeviceUserByDeviceId(device.id), 1000);
          userRef.current = response;
          if (response) {
            setUserFormValues(userToForm(response));
          }
        } catch (error) {
          const localizedErrorString = getLocalizedErrorString(error as Error);
          console.log(localizedErrorString);
        }
      }
    };
    void getData();
  }, [device?.udid]);

  const searchBar = (
    <div className={css.SearchBar}>
      <TextInput
        placeholder={t('inventory.device_page.inventory_tab.device_user_tab.form.search_label')}
        onChange={handleInputChange}
      />
    </div>
  );

  useEffect(() => {
    setPaginationState({
      page: 1,
      rowsPerPage: 15
    });
    setSortState(undefined);
  }, [debouncedInputUsername]);

  useEffect(() => {
    if (formMode !== 'edit') return;
    void handleSearch();
  }, [paginationState, sortState, debouncedInputUsername, formMode]);

  if (crudRequest.loading) {
    return <UserSkeleton />;
  }

  return (
    <>
      {formMode === 'edit' && (
        <>
          <Button view="action" onClick={() => setFormMode('create')} className={css.ActionButton}>
            {t('inventory.device_page.inventory_tab.device_user_tab.buttons.new_user')}
          </Button>
          {fetchUsersRequest.loading ? (
            <TableSkeleton />
          ) : (
            <DeviceUserList
              deviceUsers={foundUsers}
              deviceUsersCount={foundUsersCount}
              handleLinkUser={handleUserLink}
              paginationState={paginationState}
              sortState={sortState}
              onChangePaginationState={setPaginationState}
              onChangeSortState={setSortState}
              leftContent={searchBar}
              className={css.DeviceUserList}
            />
          )}
        </>
      )}
      {!userFormValues && formMode === 'view' && (
        <div className={css.Root}>
          <Text variant="body-1">
            {t('inventory.device_page.inventory_tab.device_user_tab.no_linked_user_text')}
          </Text>
          <Button view="action" onClick={handleShowForm} className={css.ActionButton}>
            {t('inventory.device_page.inventory_tab.device_user_tab.no_linked_user_btn')}
          </Button>
        </div>
      )}
      {((userFormValues && formMode === 'view') || formMode === 'create') && (
        <DeviceUsersForm
          mode={formMode}
          values={userFormValues}
          onCreate={handleUserCreate}
          onCreateCancel={handleCancel}
          onUnlink={handleUserUnlinkRequest}
        />
      )}
      <Dialog onClose={handleUserUnlinkCancel} open={isUnlinkModalShown}>
        <Dialog.Header
          caption={t('inventory.device_page.inventory_tab.device_user_tab.unlink_modal_title', {
            username: userRef.current?.username
          })}
        />
        <Dialog.Body>
          <Text>
            {t('inventory.device_page.inventory_tab.device_user_tab.unlink_modal_text', {
              username: userRef.current?.username
            })}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          onClickButtonCancel={handleUserUnlinkCancel}
          onClickButtonApply={handleUserUnlink}
          textButtonApply={t(
            'inventory.device_page.inventory_tab.device_user_tab.unlink_modal_submit_btn'
          )}
          textButtonCancel={t('common.modal.cancel_btn')}
        />
      </Dialog>
    </>
  );
};
