import { FC, FormEvent } from 'react';
import css from './script-form.module.scss';
import { UseFormReturn } from 'react-hook-form';
import { ScriptFormValues } from './script-form.schema';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../../../contexts/permission.context';
import { Permission } from '../../../../../../types/api';
import { Text, TextArea } from '@gravity-ui/uikit';

interface IProps {
  mode: 'view' | 'create' | 'update' | 'edit';
  form: UseFormReturn<ScriptFormValues>;
}

export const ScriptForm: FC<IProps> = (props) => {
  const { form, mode = 'view' } = props;
  const { register, formState } = form;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditPolicies)}>
        <div>
          <Text>{t('settings.tiles.scripts.page.script_page.description')}</Text>
          <TextArea
            minRows={10}
            error={formState.errors.description?.message}
            disabled={mode === 'view'}
            controlProps={{ style: { resize: 'vertical' } }}
            {...register('description')}
          />
        </div>
        <div>
          <Text>{t('settings.tiles.scripts.page.script_page.script_content')}</Text>
          <TextArea
            className={css.Code}
            minRows={10}
            error={formState.errors.script?.message}
            disabled={mode === 'view'}
            controlProps={{ style: { resize: 'vertical' } }}
            {...register('script')}
          />
        </div>
      </fieldset>
    </form>
  );
};
