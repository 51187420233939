import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import css from './action-menu.module.scss';
import { t } from 'i18next';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  disabled: boolean;
  onUserCreate: () => void;
  onUserCancel: () => void;
  onUserDelete: () => void;
  onUserEditStart: () => void;
  onUserEdit: () => void;
}

export const ActionMenu: FC<IProps> = (props) => {
  const { mode, disabled, onUserCreate, onUserCancel, onUserDelete, onUserEditStart, onUserEdit } =
    props;

  return (
    <div className={css.ButtonGroup}>
      {mode === 'create' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onUserCancel}>
            {t('device_users.buttons.cancel')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onUserCreate}>
            {t('device_users.buttons.create')}
          </Button>
        </>
      )}
      {mode === 'view' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onUserDelete}>
            {t('device_users.buttons.delete')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onUserEditStart}>
            {t('device_users.buttons.edit')}
          </Button>
        </>
      )}
      {mode === 'edit' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onUserCancel}>
            {t('device_users.buttons.cancel')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onUserEdit}>
            {t('device_users.buttons.save')}
          </Button>
        </>
      )}
    </div>
  );
};
