import { array, boolean, mixed, object, string } from 'yup';
import { SearchDeviceByConditionDto } from '../../../../../types/api';

export type SearchDeviceCriteriaValues = SearchDeviceByConditionDto[];

export interface SearchDeviceFormValues {
  conditions: SearchDeviceCriteriaValues;
}

export const searchDeviceFormSchema = object({
  conditions: array()
    .of(
      object({
        start_bracket: boolean().required().default(false),
        end_bracket: boolean().required().default(false),
        criteria: string().required('Criteria is required!'),
        operator: mixed<'=' | '!=' | 'like' | 'not like' | '>' | '>=' | '<' | '<='>()
          .required('Operator is required!')
          .oneOf(['=', '!=', 'like', 'not like', '>', '>=', '<', '<=']),
        value: string().required('Criteria value is required!'),
        conjunctive: mixed<'and' | 'or' | null>().oneOf(['and', 'or', null])
      })
    )
    .default({ criteria: 'General.ComputerName', operator: '=', value: '' })
});
