import { FC, useEffect, useState } from 'react';
import css from './device-users-tab.module.scss';
import {
  DeviceUserSimpleDto,
  DeviceUserSortField,
  GetDeviceUserListResponseDto,
  PaginationSortOrder,
  SearchDeviceUserByConditionDto
} from '../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../../../../../const/pagination.const';
import { useDebounce } from 'use-debounce';
import { getDeviceUserList } from '../../../../../../../api/device-users';
import { getDeviceUsersForStaticGroup } from '../../../../../../../api/static-groups-device-users';
import {
  Column,
  PaginationState,
  SortState,
  Table
} from '../../../../../../components/table/table.component';
import { useParams } from 'react-router-dom';
import { Button, TextInput } from '@gravity-ui/uikit';
import useRequest from '../../../../../../../hooks/useRequest';
import { TableSkeleton } from './components/skeleton/table-skeleton.component';
import { UseFormReturn } from 'react-hook-form';
import { StaticGroupFormValues } from '../../static-group-form.schema';

interface IProps {
  mode: 'create' | 'edit' | 'view';
  form: UseFormReturn<StaticGroupFormValues>;
}

export const DeviceUsersTab: FC<IProps> = (props) => {
  const { mode, form } = props;
  const { watch, setValue } = form;

  const selectedDeviceUserIds = watch('device_users');
  const setSelectedDeviceUserIds = (ids: string[]) => setValue('device_users', ids);

  const [deviceUsers, setDeviceUsers] = useState<DeviceUserSimpleDto[]>([]);
  const [deviceUsersCount, setDeviceUsersCount] = useState(0);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: PaginationRowsPerPageOptions[0]
  });
  const [sortState, setSortState] = useState<SortState>();
  const [filterText, setFilterText] = useState<string>('');
  const [debouncedFilterText] = useDebounce(filterText, 1000);

  const { t } = useTranslation();
  const params = useParams();
  const fetchRequest = useRequest<GetDeviceUserListResponseDto>();

  const getDeviceUsersInfo = async () => {
    let response: GetDeviceUserListResponseDto;
    if (mode === 'view') {
      if (!params.id) return;
      response = await fetchRequest.send(
        getDeviceUsersForStaticGroup(params.id, {
          page: paginationState.page,
          limit: paginationState.rowsPerPage,
          sort_field: sortState?.column as DeviceUserSortField,
          sort_order: sortState?.order as PaginationSortOrder
        })
      );
    } else {
      const conditions: SearchDeviceUserByConditionDto[] = [];
      if (debouncedFilterText) {
        conditions.push({
          criteria: 'username',
          value: debouncedFilterText
        });
      }
      response = await fetchRequest.send(
        getDeviceUserList({
          conditions,
          page: paginationState.page,
          limit: paginationState.rowsPerPage,
          sort_field: sortState?.column as DeviceUserSortField,
          sort_order: sortState?.order as PaginationSortOrder
        })
      );
    }

    setDeviceUsers(response.users);
    setDeviceUsersCount(response.count);
  };

  const handleChangePagination = (state: PaginationState) => {
    setPaginationState(state);
  };

  const handleChangeSort = (state?: SortState) => {
    setSortState(state);
  };

  const handleClearFilter = () => {
    if (filterText) setFilterText('');
  };

  const handleUpdateFilter = async (value: string) => {
    return setFilterText(value);
  };

  useEffect(() => {
    handleChangePagination({
      page: 1,
      rowsPerPage: PaginationRowsPerPageOptions[0]
    });
    handleChangeSort(undefined);
  }, [mode]);

  useEffect(() => {
    void getDeviceUsersInfo();
  }, [paginationState, sortState, debouncedFilterText]);

  const handleSelectDeviceUsers = (deviceUserIds: string[]) => {
    const currentPageSelectedDeviceUserIds = deviceUsers
      .filter((u) => deviceUserIds.includes(u.id))
      .map((u) => u.id);
    const otherPageSelectedDeviceUserIds = selectedDeviceUserIds.filter(
      (u) => !deviceUsers.some((user) => user.id === u)
    );
    setSelectedDeviceUserIds([
      ...currentPageSelectedDeviceUserIds,
      ...otherPageSelectedDeviceUserIds
    ]);
  };

  const getRowId = (row: DeviceUserSimpleDto) => row.id;

  const columns: Column<DeviceUserSimpleDto>[] = [
    {
      name: t('static_groups.page.device_users_assignments_tab.column_username'),
      selector: (row) => row.username,
      sortable: true,
      id: DeviceUserSortField.Username
    }
  ];

  const tableLeftContent = (
    <div className={css.Filter}>
      <TextInput
        className={css.FilterInput}
        value={filterText}
        onUpdate={handleUpdateFilter}
        placeholder={t('static_groups.page.assignments_tab.filter.message.device_users')}
      />
      <Button view="action" onClick={handleClearFilter}>
        {t('static_groups.page.assignments_tab.filter.clear_btn')}
      </Button>
    </div>
  );

  return (
    <div className={css.Root}>
      {fetchRequest.loading ? (
        <TableSkeleton />
      ) : mode === 'view' ? (
        <Table
          columns={columns}
          data={deviceUsers}
          onChangePagination={handleChangePagination}
          onChangeSort={handleChangeSort}
          pagination
          paginationState={paginationState}
          paginationTotalRows={deviceUsersCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          sortState={sortState}
          leftContent={<div></div>}
        />
      ) : (
        <Table
          columns={columns}
          data={deviceUsers}
          onChangePagination={handleChangePagination}
          onChangeSort={handleChangeSort}
          pagination
          paginationState={paginationState}
          paginationTotalRows={deviceUsersCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          sortState={sortState}
          selectable
          selectedRows={selectedDeviceUserIds}
          onSelectedRowsChange={handleSelectDeviceUsers}
          getRowId={getRowId}
          leftContent={tableLeftContent}
        />
      )}
    </div>
  );
};
