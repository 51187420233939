import {
  ConfigProfileFullDto,
  ConfigProfileSimpleDto,
  CreateConfigProfileDto,
  GetConfigProfileLogsRequestDto,
  GetConfigProfileLogsResponseDto,
  GetConfigProfilesDto,
  GetConfigProfilesRequestDto,
  ImportConfigProfileDto,
  PolicySimpleDto,
  UpdateConfigProfileDto
} from '../types/api';
import { createRequest } from './create-request';

export async function getConfigProfiles(
  params: GetConfigProfilesRequestDto
): Promise<GetConfigProfilesDto> {
  return createRequest({ url: 'config-profiles', params });
}

export async function getConfigProfile(id: string): Promise<ConfigProfileFullDto> {
  return createRequest({ url: `config-profiles/${id}` });
}

export async function getConfigProfilesForDevice(id: string): Promise<GetConfigProfilesDto> {
  return createRequest({ url: `config-profiles/device/${id}` });
}

export async function deleteConfigProfile(id: string): Promise<PolicySimpleDto> {
  return createRequest({ method: 'DELETE', url: `config-profiles/${id}` });
}

export async function importConfigProfile(
  data: ImportConfigProfileDto
): Promise<ConfigProfileFullDto> {
  return createRequest({
    method: 'POST',
    url: 'config-profiles/import',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}

export async function downloadConfigProfile(id: string): Promise<Blob> {
  return createRequest({
    method: 'GET',
    url: `config-profiles/${id}/download`,
    responseType: 'blob'
  });
}

export async function createConfigProfile(
  data: CreateConfigProfileDto
): Promise<ConfigProfileSimpleDto> {
  return createRequest({
    method: 'POST',
    url: 'config-profiles',
    data
  });
}

export async function updateConfigProfile(
  id: string,
  data: UpdateConfigProfileDto
): Promise<ConfigProfileSimpleDto> {
  return createRequest({
    method: 'PATCH',
    url: `config-profiles/${id}`,
    data
  });
}

export async function getConfigProfileLogs(
  id: string,
  params: GetConfigProfileLogsRequestDto
): Promise<GetConfigProfileLogsResponseDto> {
  return createRequest({ url: `config-profiles/${id}/logs`, params });
}
