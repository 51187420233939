import { FC } from 'react';
import { DeviceFullDto, DeviceUserAccountSimpleDto } from '../../../../../../../../../types/api';
import { isBoolean } from '../../../../../../../../../utils/assert.utils';
import { useTranslation } from 'react-i18next';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import css from './user-accounts-table.module.scss';

interface IProps {
  device?: DeviceFullDto;
}

export const UserAccountsTable: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const columns: Column<DeviceUserAccountSimpleDto>[] = [
    {
      id: 'uid',
      name: t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.heading.uid'),
      selector: (i) => i.uid
    },
    {
      id: 'gid',
      name: t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.heading.gid'),
      selector: (i) => i.gid
    },
    {
      id: 'name',
      name: t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.heading.name'),
      selector: (i) => i.name
    },
    {
      id: 'dir',
      name: t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.heading.dir'),
      selector: (i) => i.dir
    },
    {
      id: 'is_admin',
      name: t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.heading.is_admin'),
      selector: (i) =>
        isBoolean(i.is_admin)
          ? i.is_admin
            ? t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.yes')
            : t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.no')
          : t('inventory.device_page.inventory_tab.local_user_accounts_tab.table.unknown')
    }
  ];

  return (
    <>
      <Table columns={columns} data={device?.user_accounts || []} className={css.Table} />
    </>
  );
};
