import { boolean, object, string } from 'yup';
import { isOptionalHttpUrl } from '../../../../utils/yup.validations';

export interface ScepForm {
  use_server_as_scep_proxy: boolean;
  scep_certificate_url: string;
}

export const scepFormSchema = object({
  use_server_as_scep_proxy: boolean(),
  scep_certificate_url: string().test(isOptionalHttpUrl())
}).required();
