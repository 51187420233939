import { useLocation } from 'react-router-dom';
import { DeviceSections } from '../constants';

export enum DeviceType {
  COMPUTERS = 'computers',
  MOBILE = 'mobile'
}

interface IDeviceSectionContext {
  deviceType: DeviceType | null;
}

const useDeviceSection = (): IDeviceSectionContext => {
  const location = useLocation();
  if (location.pathname.startsWith(DeviceSections.COMPUTERS)) {
    return { deviceType: DeviceType.COMPUTERS };
  }
  if (location.pathname.startsWith(DeviceSections.MOBILE)) {
    return { deviceType: DeviceType.MOBILE };
  }
  return { deviceType: null };
};

export default useDeviceSection;
