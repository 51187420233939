import React, { FC, useState } from 'react';
import { ManagementTabTabs } from '../../../../constants';
import css from './management-tab.module.scss';
import { DeviceFullDto, Permission } from '../../../../../../../types/api';
import { Icon } from '@gravity-ui/uikit';
import { useTranslation } from 'react-i18next';
import { BarsPlay, BookOpen, FolderTree, Key, PersonGear } from '@gravity-ui/icons';
import useDeviceSection, { DeviceType } from '../../../../../../contexts/device-section.context';
import { usePermission } from '../../../../../../contexts/permission.context';
import { Restricted } from '../../../../../../components/common/restricted';
import { CommandsTab } from './components/commands-tab/commands-tab.component';
import { GroupsTab } from './components/groups-tab/groups-tab.component';
import { ActivationTab } from './components/activation-tab/activation-tab.component';
import { PoliciesTab } from './components/policies-tab/policies-tab.component';
import { ProfilesTab } from './components/profiles-tab/profiles-tab.component';
import { Navbar, NavbarItemProps } from '../../../../../../components/navbar/navbar.component';

interface IProps {
  device?: DeviceFullDto;
}

export const ManagementTab: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const [openedTab, setOpenedTab] = useState(ManagementTabTabs.COMMANDS);
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();
  const { isAllowedTo } = usePermission();

  const handleChangeTab = (tabName: string) => setOpenedTab(tabName);

  const computerTabs: NavbarItemProps[] = [
    {
      id: ManagementTabTabs.COMMANDS,
      icon: <Icon data={BarsPlay} />,
      title: t('inventory.device_page.management_tab.commands_tab.name'),
      disabled: !isAllowedTo(Permission.ReadCommands, Permission.EditCommands)
    },
    {
      id: ManagementTabTabs.POLICIES,
      icon: <Icon data={BookOpen} />,
      title: t('inventory.device_page.management_tab.policies_tab.name')
    },
    {
      id: ManagementTabTabs.PROFILES,
      icon: <Icon data={PersonGear} />,
      title: t('inventory.device_page.management_tab.profiles_tab.name')
    },
    {
      id: ManagementTabTabs.ACTIVATION,
      icon: <Icon data={Key} />,
      title: t('inventory.device_page.management_tab.activation_tab.name')
    },
    {
      id: ManagementTabTabs.GROUPS,
      icon: <Icon data={FolderTree} />,
      title: t('inventory.device_page.management_tab.groups_tab.name')
    }
  ];
  const mobileTabs: NavbarItemProps[] = [
    {
      id: ManagementTabTabs.COMMANDS,
      icon: <Icon data={BarsPlay} />,
      title: t('inventory_mobile.device_page.management_tab.commands_tab.name'),
      disabled: !isAllowedTo(Permission.ReadCommands, Permission.EditCommands)
    },
    {
      id: ManagementTabTabs.PROFILES,
      icon: <Icon data={PersonGear} />,
      title: t('inventory_mobile.device_page.management_tab.profiles_tab.name')
    },
    {
      id: ManagementTabTabs.ACTIVATION,
      icon: <Icon data={Key} />,
      title: t('inventory_mobile.device_page.management_tab.activation_tab.name')
    },
    {
      id: ManagementTabTabs.GROUPS,
      icon: <Icon data={FolderTree} />,
      title: t('inventory_mobile.device_page.management_tab.groups_tab.name')
    }
  ];
  const actualTabs = deviceType === DeviceType.COMPUTERS ? computerTabs : mobileTabs;

  return (
    <>
      <Navbar
        className={css.ManagementTabNavBar}
        activeTab={openedTab}
        items={actualTabs}
        onSelectTab={handleChangeTab}
      />
      <div className={css.ManagementTabContent}>
        <Restricted to={[Permission.ReadCommands, Permission.EditCommands]}>
          {openedTab === ManagementTabTabs.COMMANDS && <CommandsTab device={device} />}
        </Restricted>
        {openedTab === ManagementTabTabs.GROUPS && <GroupsTab device={device} />}
        {openedTab === ManagementTabTabs.ACTIVATION && <ActivationTab device={device} />}
        {openedTab === ManagementTabTabs.POLICIES && <PoliciesTab device={device} />}
        {openedTab === ManagementTabTabs.PROFILES && <ProfilesTab device={device} />}
      </div>
    </>
  );
};
