import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { createContainer } from 'react-tracked';
import {
  AuthLoginResponseDto,
  AuthRefreshResponseDto,
  GetActiveLicensesDto,
  LicenseStatus,
  RoleSimpleDto
} from '../../types/api';
import { createRequest } from '../../api/create-request';
import { t } from 'i18next';
import { useToaster } from '@gravity-ui/uikit';

interface IAuthContext {
  username: string;
  license: {
    iOS: LicenseStatus | null;
    macOS: LicenseStatus | null;
  };
  role: RoleSimpleDto | null;
}

const initialState: IAuthContext = {
  username: '',
  license: {
    iOS: null,
    macOS: null
  },
  role: null
};

const useMyState = () => useState(initialState);
const container = createContainer(useMyState);

let accessToken: string | undefined;
let refreshTokenPromise: Promise<AuthRefreshResponseDto> | undefined;

const AuthProvider = container.Provider;
const useAuthContext = (): { authState: IAuthContext; authService: typeof authService } => {
  const toaster = useToaster();
  const [state, setState] = container.useTracked();
  const updateState = (update: Partial<IAuthContext>) => {
    setState((prev) => ({ ...prev, ...update }));
  };

  const authService = {
    accessToken: () => accessToken,
    refreshTokenPromise: () => refreshTokenPromise,

    async signIn(username: string, password: string) {
      const result = await createRequest<AuthLoginResponseDto>({
        url: 'auth',
        method: 'POST',
        data: { username, password },
        withCredentials: true,
        public: true
      });
      accessToken = result.access_token;
      updateState({ username: result.username, role: result.role });
      return result;
    },

    async signOut() {
      await createRequest({ url: 'auth/logout', withCredentials: true });
      accessToken = undefined;
      updateState(initialState);
    },

    async refreshToken() {
      if (refreshTokenPromise) {
        return refreshTokenPromise;
      }
      const promise: Promise<AuthRefreshResponseDto> = new Promise((resolve, reject) => {
        createRequest<AuthRefreshResponseDto>({
          url: 'auth/refresh',
          public: true,
          withCredentials: true
        })
          .then((resp: AuthRefreshResponseDto) => {
            accessToken = resp.access_token;
            updateState({ username: resp.username, role: resp.role });
            refreshTokenPromise = undefined;
            resolve(resp);
          })
          .catch((err: unknown) => {
            refreshTokenPromise = undefined;
            reject(err);
            return;
          });
      });
      createRequest<GetActiveLicensesDto>({ url: `licenses` })
        .then((resp) => {
          if (!resp) return null;
          updateState({
            license: {
              iOS: resp.ios_license?.status ? resp.ios_license.status : null,
              macOS: resp.macos_license?.status ? resp.macos_license.status : null
            }
          });
        })
        .catch(() => {});
      refreshTokenPromise = promise;
      return promise;
    },

    isTokenValid(): boolean {
      if (!accessToken) {
        return false;
      }
      const expiresIn = jwtDecode<{ exp: number }>(accessToken).exp;
      return expiresIn * 1000 - Date.now() > 0;
    },

    async setLicenseStatus() {
      createRequest<GetActiveLicensesDto>({ url: `licenses` })
        .then((resp) => {
          if (!resp) return null;
          updateState({
            license: {
              iOS: resp.ios_license?.status ? resp.ios_license.status : null,
              macOS: resp.macos_license?.status ? resp.macos_license.status : null
            }
          });
          if (resp.macos_license?.status === LicenseStatus.Expired) {
            toaster.add({
              name: 'macos-license-expired',
              content: t('settings.tiles.license.page.macos_license_expire_popup'),
              theme: 'danger',
              autoHiding: 5000
            });
          }
          if (resp.ios_license?.status === LicenseStatus.Expired) {
            toaster.add({
              name: 'ios-license-expired',
              content: t('settings.tiles.license.page.ios_license_expire_popup'),
              theme: 'danger',
              autoHiding: 5000
            });
          }
        })
        .catch(() => {});
    }
  };

  return { authState: state, authService };
};

export { AuthProvider, useAuthContext };
