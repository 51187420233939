import React, { FC, useEffect, useState } from 'react';
import { Icon } from '@gravity-ui/uikit';
import { Cubes3, FileCode, Gear, TriangleExclamation } from '@gravity-ui/icons';
import { useTranslation } from 'react-i18next';
import { PolicyOptionsScripts } from './components/policy-options-scripts/policy-options-scripts.component';
import { PolicyOptionsPackages } from './components/policy-options-packages/policy-options-packages.component';
import { PolicyOptionsGeneral } from './components/policy-options-general/policy-options-general.component';
import { PolicyOptionsMaintenance } from './components/policy-options-maintenance/policy-options-maintenance.component';
import { UseFormReturn } from 'react-hook-form';
import { Navbar, NavbarItemProps } from '../../../../../../components/navbar/navbar.component';
import { PolicyFormValues } from '../../policy-form.schema';

enum OptionsTabs {
  GENERAL = 'general',
  SCRIPTS = 'scripts',
  PACKAGES = 'packages',
  MAINTENANCE = 'maintenance'
}

interface IProps {
  mode: 'view' | 'create' | 'edit';
  form: UseFormReturn<PolicyFormValues>;
}

export const OptionsTab: FC<IProps> = (props) => {
  const { mode, form } = props;
  const { getValues } = form;

  const [selectedTab, setSelectedTab] = useState<OptionsTabs>(OptionsTabs.GENERAL);
  const { t } = useTranslation();

  const handleChangeTab = (tab: string) => setSelectedTab(tab as OptionsTabs);

  useEffect(() => {
    if (mode === 'view') {
      setSelectedTab(OptionsTabs.GENERAL);
    }
  }, [mode]);

  const items: NavbarItemProps[] = [
    {
      id: OptionsTabs.GENERAL,
      title: t('policies.policy_form.menu_general'),
      icon: <Icon data={Gear} />
    },
    {
      id: OptionsTabs.MAINTENANCE,
      title: t('policies.policy_form.menu_maintenance'),
      icon: <Icon data={TriangleExclamation} />
    }
  ];
  if (mode !== 'view' || !!getValues().scripts?.length) {
    items.push({
      id: OptionsTabs.SCRIPTS,
      title: t('policies.policy_form.menu_scripts'),
      icon: <Icon data={FileCode} />
    });
  }
  if (mode !== 'view' || !!getValues().packages?.items.length) {
    items.push({
      id: OptionsTabs.PACKAGES,
      title: t('policies.policy_form.menu_packages'),
      icon: <Icon data={Cubes3} />
    });
  }

  return (
    <>
      <Navbar items={items} activeTab={selectedTab} onSelectTab={handleChangeTab} />
      {selectedTab === 'general' && <PolicyOptionsGeneral disabled={mode === 'view'} form={form} />}
      {selectedTab === 'maintenance' && (
        <PolicyOptionsMaintenance disabled={mode === 'view'} form={form} />
      )}
      {selectedTab === 'scripts' && <PolicyOptionsScripts disabled={mode === 'view'} form={form} />}
      {selectedTab === 'packages' && (
        <PolicyOptionsPackages disabled={mode === 'view'} form={form} />
      )}
    </>
  );
};
