import {
  CreateStaticGroupDeviceUsersRequestDto,
  GetDeviceUserListRequestDto,
  GetDeviceUserListResponseDto,
  GetStaticGroupsDeviceUsersRequestDto,
  GetStaticGroupsDeviceUsersResponseDto,
  StaticGroupDeviceUsersFullDto,
  UpdateStaticGroupDeviceUsersRequestDto
} from '../types/api';
import { createRequest } from './create-request';

export async function getStaticGroups(
  params: GetStaticGroupsDeviceUsersRequestDto
): Promise<GetStaticGroupsDeviceUsersResponseDto> {
  return createRequest({ url: 'groups/device-users/static', params });
}

export async function getStaticGroup(id: string): Promise<StaticGroupDeviceUsersFullDto> {
  return createRequest({ url: `groups/device-users/static/${id}` });
}

export async function getDeviceUsersForStaticGroup(
  id: string,
  params?: GetDeviceUserListRequestDto
): Promise<GetDeviceUserListResponseDto> {
  return createRequest({ url: `groups/device-users/static/${id}/users`, params });
}

export async function createStaticGroup(
  data: CreateStaticGroupDeviceUsersRequestDto
): Promise<StaticGroupDeviceUsersFullDto> {
  return createRequest({ method: 'POST', url: 'groups/device-users/static', data });
}

export async function updateStaticGroup(
  data: UpdateStaticGroupDeviceUsersRequestDto,
  id: string
): Promise<StaticGroupDeviceUsersFullDto> {
  return createRequest({ method: 'PATCH', url: `groups/device-users/static/${id}`, data });
}

export async function deleteStaticGroup(id: string): Promise<StaticGroupDeviceUsersFullDto> {
  return createRequest({ method: 'DELETE', url: `groups/device-users/static/${id}` });
}
