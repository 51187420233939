import {
  DeviceActivationInfoDto,
  DeviceCommandResponseDto,
  DeviceFullDto,
  DeviceLockRequestDto,
  EraseDeviceRequestDto,
  GetCommandsRequestDto,
  GetCommandsResponseDto,
  GetDevicesCSVRequestDto,
  GetDevicesCountResponseDto,
  GetDevicesRequestDto,
  GetDevicesResponseDto,
  GetHistoryRequestDto,
  GetHistoryResponseDto,
  PerformOsUpdateActionDto,
  SetFirmwarePasswordResponseDto,
  SetFirmwarePasswordRequestDto,
  SetRecoveryLockResponseDto,
  SetRecoveryLockRequestDto,
  DeviceOsType,
  ClearPasscodeResponseDto,
  UpdateExtensionAttributeValueDto,
  DeviceExtensionAttributeDto,
  GetCriterionValuesRequestDto,
  GetCriteriaRequestDto,
  EnableLostModeRequestDto,
  EnableLostModeResponseDto,
  SearchDevicesRequestDto,
  DeviceUserSimpleDto,
  DeviceCriteriaResponseDto,
  DeviceLockMobileRequestDto,
  LinkToUserDto
} from '../types/api';
import { createRequest } from './create-request';

export async function getAllDevices(os_type: DeviceOsType): Promise<GetDevicesResponseDto> {
  const params: GetDevicesRequestDto = { get_all: true, os_type };
  return createRequest({ url: 'devices', params });
}

export async function getDevices(params: GetDevicesRequestDto): Promise<GetDevicesResponseDto> {
  return createRequest({ url: 'devices', params });
}

export async function getDeviceInfo(deviceId: string): Promise<DeviceFullDto> {
  return createRequest({ url: `devices/${deviceId}` });
}

export async function linkDeviceToUser(
  deviceId: string,
  data: LinkToUserDto
): Promise<DeviceUserSimpleDto> {
  return createRequest({
    url: `devices/${deviceId}/user/link`,
    data,
    method: 'POST'
  });
}

export async function unlinkDeviceUser(deviceId: string): Promise<DeviceUserSimpleDto> {
  return createRequest({
    url: `devices/${deviceId}/user/unlink`,
    method: 'POST'
  });
}

export async function getDeviceUserByDeviceId(deviceId: string): Promise<DeviceUserSimpleDto> {
  return createRequest({ url: `devices/${deviceId}/user` });
}

export async function deleteDevice(deviceId: string): Promise<void> {
  return createRequest({ url: `devices/${deviceId}`, method: 'DELETE' });
}

export async function lockDevice(
  deviceId: string,
  data: DeviceLockRequestDto
): Promise<DeviceFullDto> {
  return createRequest({
    url: `devices/${deviceId}/lock`,
    method: 'POST',
    data
  });
}

export async function lockMobileDevice(
  deviceId: string,
  data: DeviceLockMobileRequestDto
): Promise<DeviceFullDto> {
  return createRequest({
    url: `devices/${deviceId}/lock-mobile`,
    method: 'POST',
    data
  });
}

export async function unrollDevice(deviceId: string): Promise<DeviceFullDto> {
  return createRequest({
    url: `devices/${deviceId}/unroll`,
    method: 'POST'
  });
}

export async function wipeDevice(deviceId: string, data?: EraseDeviceRequestDto) {
  return createRequest({
    url: `devices/${deviceId}/wipe`,
    method: 'POST',
    data: data || {}
  });
}

export async function performOsUpdateAction(deviceId: string, data: PerformOsUpdateActionDto) {
  return createRequest<string>({
    url: `devices/${deviceId}/os-update`,
    method: 'POST',
    data
  });
}

export async function enableBluetooth(deviceId: string): Promise<DeviceFullDto> {
  return createRequest({
    url: `devices/${deviceId}/bluetooth/enable`,
    method: 'POST'
  });
}

export async function disableBluetooth(deviceId: string): Promise<DeviceFullDto> {
  return createRequest({
    url: `devices/${deviceId}/bluetooth/disable`,
    method: 'POST'
  });
}

export async function sendBlankPush(deviceId: string): Promise<DeviceFullDto> {
  return createRequest({
    url: `devices/${deviceId}/send-blank-push`,
    method: 'POST'
  });
}

export async function getTotalEnrolled(
  params: GetDevicesRequestDto
): Promise<GetDevicesCountResponseDto> {
  return createRequest({
    url: 'devices/count',
    params: { ...params, enrolled: true }
  });
}

export async function getActivationInfo(deviceId: string): Promise<DeviceActivationInfoDto | null> {
  const response: DeviceCommandResponseDto = await createRequest<DeviceCommandResponseDto>({
    url: `devices/${deviceId}/activation-info`,
    method: 'POST'
  });
  return response.payload as DeviceActivationInfoDto;
}

export async function enableRemoteDesktop(deviceId: string) {
  return createRequest({
    url: `devices/${deviceId}/ard/enable`,
    method: 'POST'
  });
}

export async function disableRemoteDesktop(deviceId: string) {
  return createRequest({
    url: `devices/${deviceId}/ard/disable`,
    method: 'POST'
  });
}

export async function searchDevices(data: SearchDevicesRequestDto): Promise<GetDevicesResponseDto> {
  return createRequest({
    url: `devices/search`,
    method: 'POST',
    data
  });
}

export async function getCriteria(
  osType: DeviceOsType,
  data: GetCriteriaRequestDto
): Promise<DeviceCriteriaResponseDto> {
  return createRequest({
    url: `devices/${osType}/criteria`,
    method: 'POST',
    data
  });
}

export async function getCriteriaValues(osType: DeviceOsType): Promise<Record<string, string[]>> {
  return createRequest({
    url: `devices/${osType}/criteria-values`
  });
}

export async function getCriterionValues(
  params: GetCriterionValuesRequestDto
): Promise<Record<string, string[]>> {
  return createRequest({
    url: `devices/criterion-values`,
    params
  });
}

export async function getHistory(params: GetHistoryRequestDto): Promise<GetHistoryResponseDto> {
  return createRequest({
    url: 'history',
    params
  });
}

export async function getCommands(
  deviceId: string,
  params: GetCommandsRequestDto
): Promise<GetCommandsResponseDto> {
  return createRequest({
    url: `devices/${deviceId}/commands`,
    params
  });
}

export async function getDevicesCSV(data: GetDevicesCSVRequestDto): Promise<Blob> {
  return createRequest({
    url: `devices/export-csv`,
    responseType: 'blob',
    method: 'POST',
    data
  });
}

export async function setFirmwarePassword(
  deviceId: string,
  data: SetFirmwarePasswordRequestDto
): Promise<SetFirmwarePasswordResponseDto> {
  const response: DeviceCommandResponseDto = await createRequest<DeviceCommandResponseDto>({
    url: `devices/${deviceId}/set-firmware-password`,
    method: 'POST',
    data
  });
  return response.payload as SetFirmwarePasswordResponseDto;
}

export async function setRecoveryLock(
  deviceId: string,
  data: SetRecoveryLockRequestDto
): Promise<SetRecoveryLockResponseDto> {
  const response: DeviceCommandResponseDto = await createRequest<DeviceCommandResponseDto>({
    url: `devices/${deviceId}/set-recovery-lock`,
    method: 'POST',
    data
  });
  return response.payload as SetRecoveryLockResponseDto;
}

export async function updateInventory(deviceId: string) {
  return createRequest({
    url: `devices/${deviceId}/update-inventory`,
    method: 'POST'
  });
}

export async function clearPasscode(deviceId: string): Promise<ClearPasscodeResponseDto> {
  const response: DeviceCommandResponseDto = await createRequest<DeviceCommandResponseDto>({
    url: `devices/${deviceId}/clear-passcode`,
    method: 'POST'
  });
  return response.payload as ClearPasscodeResponseDto;
}

export async function updateExtensionAttributeValue(
  deviceId: string,
  data: UpdateExtensionAttributeValueDto
) {
  return createRequest({
    method: 'POST',
    url: `devices/${deviceId}/update-extension-attribute-value`,
    data
  });
}

export async function getDeviceExtensionAttributes(
  deviceId: string
): Promise<DeviceExtensionAttributeDto[]> {
  return createRequest({
    method: 'GET',
    url: `devices/${deviceId}/extension-attributes`
  });
}

export async function enableLostMode(
  deviceId: string,
  data: EnableLostModeRequestDto
): Promise<EnableLostModeResponseDto> {
  const response: DeviceCommandResponseDto = await createRequest<DeviceCommandResponseDto>({
    url: `devices/${deviceId}/lost-mode/enable`,
    method: 'POST',
    data
  });
  return response.payload as EnableLostModeResponseDto;
}

export async function disableLostMode(deviceId: string): Promise<DeviceCommandResponseDto> {
  const response: DeviceCommandResponseDto = await createRequest<DeviceCommandResponseDto>({
    url: `devices/${deviceId}/lost-mode/disable`,
    method: 'POST'
  });
  return response.payload as DeviceCommandResponseDto;
}

export async function renewMdmProfile(deviceId: string): Promise<DeviceCommandResponseDto> {
  const response: DeviceCommandResponseDto = await createRequest<DeviceCommandResponseDto>({
    url: `devices/${deviceId}/renew-enroll-profile`,
    method: 'POST'
  });
  return response.payload as DeviceCommandResponseDto;
}
