import { FC, FormEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import css from './upload-modal.module.scss';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@gravity-ui/uikit';
import { FileInput } from '../../../../components/file-input-v2/file-input.component';
import { ConfigurationProfileUploadFormValues } from '../configuration-profile-form/configuration-profile-form.schema';

interface IProps {
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  form: UseFormReturn<ConfigurationProfileUploadFormValues>;
}

export const UploadModal: FC<IProps> = (props: IProps) => {
  const { isOpen, onSubmit, form, onCancel } = props;
  const { t } = useTranslation();

  const {
    setValue,
    formState: { errors },
    watch
  } = form;

  const configProfileFile = watch('config_profile');

  const handleFormSubmit = (event: FormEvent) => event.preventDefault();
  const handleUpload = () => onSubmit();

  return (
    <>
      <Dialog open={isOpen} onClose={onCancel}>
        <Dialog.Header caption={t('profiles.profile_form.upload_modal.title')} />
        <Dialog.Body>
          <form className={css.Form} onSubmit={handleFormSubmit}>
            <FileInput
              accept={'.mobileconfig'}
              maxFileSize={1000000}
              error={errors.config_profile?.message}
              onUpdate={(file) => setValue('config_profile', file)}
            />
          </form>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('common.modal.default_submit_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleUpload}
          onClickButtonCancel={onCancel}
          renderButtons={(buttonApply, buttonCancel) => (
            <>
              {buttonCancel}
              {configProfileFile && buttonApply}
            </>
          )}
        />
      </Dialog>
    </>
  );
};
