import { FC, FormEvent, useEffect } from 'react';
import css from './settings-scripts-config.module.scss';
import { GetSettingsResponseDto, Permission, UpdateSettingsDto } from '../../../../types/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { ScriptsConfigForm, scriptsConfigFormSchema } from './settings-scripts-config.schema';
import { getSettings, updateSettings } from '../../../../api/settings';
import { usePermission } from '../../../contexts/permission.context';
import { Header } from '../../../components/header/header.component';
import { Button, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';

export const SettingsScriptsConfig: FC = () => {
  const crudRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const { register, formState, setValue, trigger, getValues } = useForm<ScriptsConfigForm>({
    mode: 'onChange',
    resolver: yupResolver(scriptsConfigFormSchema),
    defaultValues: scriptsConfigFormSchema.getDefault()
  });
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
  };

  const handleClickSave = async () => {
    if (crudRequest.loading) return;
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    try {
      const requestBody: Partial<UpdateSettingsDto> = {
        policy_custom_script_timeout: Number(values.policy_custom_script_timeout),
        get_device_information_timeout: Number(values.get_device_information_timeout)
      };
      await crudRequest.send(updateSettings(requestBody), 1000);
      toaster.add({
        name: 'success',
        content: t('settings.tiles.scripts_config.page.successfully_update'),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedError = getLocalizedErrorString(error as Error);
      console.log(error);
      toaster.add({
        name: 'error',
        content: localizedError,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      const settings = await crudRequest.send(
        getSettings(['policy_custom_script_timeout', 'get_device_information_timeout']),
        1000
      );
      if (settings.policy_custom_script_timeout) {
        setValue('policy_custom_script_timeout', settings.policy_custom_script_timeout);
      }
      if (settings.get_device_information_timeout) {
        setValue('get_device_information_timeout', settings.get_device_information_timeout);
      }
    };
    void init();
  }, []);

  const headerContent = (
    <Button
      view="action"
      loading={crudRequest.loading}
      disabled={!isAllowedTo(Permission.EditScripts) || !isAllowedTo(Permission.EditAdministration)}
      onClick={handleClickSave}
    >
      {t('settings.tiles.scripts_config.page.update_btn')}
    </Button>
  );

  return (
    <div className={css.Root}>
      <Header rightContent={headerContent} />
      <div className={css.Content}>
        <div className={css.Title}>
          <Text variant="display-2">{t('settings.tiles.scripts_config.name')}</Text>
        </div>
        {crudRequest.loading ? (
          <FormSkeleton />
        ) : (
          <form className={css.FieldSet} onSubmit={handleFormSubmit}>
            <div>
              <Text>{t('settings.tiles.scripts_config.page.policy_script_timeout')}</Text>
              <TextInput
                type="number"
                disabled={
                  !isAllowedTo(Permission.EditScripts) ||
                  !isAllowedTo(Permission.EditAdministration)
                }
                error={formState.errors.policy_custom_script_timeout?.message}
                {...register('policy_custom_script_timeout')}
              />
            </div>
            <div>
              <Text>{t('settings.tiles.scripts_config.page.get_device_information_timeout')}</Text>
              <TextInput
                type="number"
                disabled={
                  !isAllowedTo(Permission.EditScripts) ||
                  !isAllowedTo(Permission.EditAdministration)
                }
                error={formState.errors.get_device_information_timeout?.message}
                {...register('get_device_information_timeout')}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
