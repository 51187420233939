import {
  CheckAgentStatusResponseDto,
  SendAgentCommandRequestDto,
  SendAgentCommandResponseDto
} from '../types/api';
import { createRequest } from './create-request';

export async function getAgentStatus(udid: string): Promise<CheckAgentStatusResponseDto> {
  return createRequest({ url: `agent/${udid}/status` });
}

export async function sendCommand(
  udid: string,
  data: SendAgentCommandRequestDto
): Promise<SendAgentCommandResponseDto> {
  return createRequest({
    method: 'POST',
    url: `agent/${udid}/command`,
    data
  });
}
