import { FC, useState } from 'react';
import { Button, Icon, Select, SelectOption } from '@gravity-ui/uikit';
import { ChevronDown } from '@gravity-ui/icons';
import { IconData } from '@gravity-ui/uikit/build/esm/components/Icon/Icon';
import css from './async-select.module.scss';

interface IProps {
  defaultOptions: SelectOption[];
  onUpdate: (value: string) => void;
  onLoad: () => Promise<SelectOption[]>;
  icon?: IconData;
}

export const AsyncSelect: FC<IProps> = (props) => {
  const { defaultOptions, onLoad, icon, onUpdate } = props;

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);

  const handleLoad = () => {
    setLoading(true);
    onLoad().then((loadedOptions) => {
      setOptions(loadedOptions);
      setLoading(false);
    });
  };

  return (
    <Select
      popupClassName={css.SelectPopup}
      options={options}
      loading={loading}
      view="clear"
      onOpenChange={() => handleLoad()}
      onUpdate={(value) => onUpdate(value[0])}
      renderControl={({ onClick, onKeyDown, ref }) => {
        return (
          <Button
            ref={ref}
            view="flat-secondary"
            onClick={onClick}
            extraProps={{
              onKeyDown
            }}
          >
            <Icon data={icon || ChevronDown} />
          </Button>
        );
      }}
    />
  );
};
