import { FC, useEffect, useState } from 'react';
import css from './settings-organization.module.scss';
import { getLicenses, getSettings } from '../../../../api/settings';
import { getTotalEnrolled } from '../../../../api/device';
import { useTranslation } from 'react-i18next';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import {
  DeviceOsType,
  GetActiveLicensesDto,
  GetDevicesCountResponseDto,
  GetSettingsResponseDto
} from '../../../../types/api';
import { Header } from '../../../components/header/header.component';
import { Text, TextInput, useToaster } from '@gravity-ui/uikit';
import useRequest from '../../../../hooks/useRequest';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';

export const SettingsOrganization: FC = () => {
  const [organizationName, setOrganizationName] = useState<string>('');
  const [totalMacOSEnrolled, setTotalMacOSEnrolled] = useState<number>(0);
  const [totalIOSEnrolled, setTotalIOSEnrolled] = useState<number>(0);
  const [totalMacOSLicenses, setTotalMacOSLicenses] = useState<number>(0);
  const [totalIOSLicenses, setTotalIOSLicenses] = useState<number>(0);

  const { t } = useTranslation();
  const toaster = useToaster();
  const fetchRequest = useRequest();

  useEffect(() => {
    const init = async () => {
      try {
        const settings = (await fetchRequest.send(
          getSettings(['organization_name'])
        )) as Partial<GetSettingsResponseDto>;
        const licenses = (await fetchRequest.send(getLicenses())) as GetActiveLicensesDto;
        const macOSEnrolled = (await fetchRequest.send(
          getTotalEnrolled({ os_type: DeviceOsType.MacOS })
        )) as GetDevicesCountResponseDto;
        const iOSEnrolled = (await fetchRequest.send(
          getTotalEnrolled({ os_type: DeviceOsType.IOS })
        )) as GetDevicesCountResponseDto;

        setOrganizationName(settings.organization_name || '');
        setTotalMacOSLicenses(
          licenses.macos_license?.max_device ? licenses.macos_license.max_device : 0
        );
        setTotalIOSLicenses(licenses.ios_license?.max_device ? licenses.ios_license.max_device : 0);
        setTotalMacOSEnrolled(macOSEnrolled.count);
        setTotalIOSEnrolled(iOSEnrolled.count);
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        console.log(localizedErrorString);
        toaster.add({
          name: 'settings-organization-error',
          content: localizedErrorString,
          theme: 'danger',
          autoHiding: 5000
        });
      }
    };
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Header />
      <div className={css.Content}>
        <div className={css.Title}>
          <Text variant="display-2">{t('settings.tiles.organization_information.page.title')}</Text>
        </div>
        {fetchRequest.loading ? (
          <FormSkeleton />
        ) : (
          <form className={css.Form}>
            <div>
              <Text>{t('settings.tiles.organization_information.page.organization_name')}</Text>
              <TextInput value={organizationName} disabled />
            </div>
            <div>
              <Text>
                {t(
                  'settings.tiles.organization_information.page.max_num_of_devices_in_macos_license'
                )}
              </Text>
              <TextInput value={String(totalMacOSLicenses)} disabled={true} />
            </div>
            <div>
              <Text>
                {t('settings.tiles.organization_information.page.macos_devices_enrolled')}
              </Text>
              <TextInput value={String(totalMacOSEnrolled)} disabled />
            </div>
            <div>
              <Text>
                {t(
                  'settings.tiles.organization_information.page.max_num_of_devices_in_ios_license'
                )}
              </Text>
              <TextInput value={String(totalIOSLicenses)} disabled={true} />
            </div>
            <div>
              <Text>{t('settings.tiles.organization_information.page.ios_devices_enrolled')}</Text>
              <TextInput value={String(totalIOSEnrolled)} disabled />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
