import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@gravity-ui/uikit';
import { FileCode, Gear } from '@gravity-ui/icons';
import { GeneralOption } from './components/general-option/general.option';
import { UseFormReturn } from 'react-hook-form';
import { ConfigurationProfileFormValues } from '../../configuration-profile-form.schema';
import { RawOption } from './components/raw-option/raw-option.component';
import { Navbar, NavbarItemProps } from '../../../../../../components/navbar/navbar.component';

enum ConfigurationProfileFormOptions {
  RAW = 'raw',
  GENERAL = 'general'
}

interface IProps {
  form: UseFormReturn<ConfigurationProfileFormValues>;
  generalDisabled: boolean;
  payload?: string;
}

export const OptionsTab: FC<IProps> = (props) => {
  const { form, generalDisabled, payload } = props;
  const [selectedOption, setSelectedOption] = useState<ConfigurationProfileFormOptions>(
    ConfigurationProfileFormOptions.GENERAL
  );
  const { t } = useTranslation();

  const handleChangeTab = (tab: string) =>
    setSelectedOption(tab as ConfigurationProfileFormOptions);

  const optionTabs: NavbarItemProps[] = [
    {
      id: ConfigurationProfileFormOptions.GENERAL,
      title: t('profiles.profile_form.options_tab.general.name'),
      icon: <Icon data={Gear} />
    }
  ];
  if (payload) {
    optionTabs.push({
      id: ConfigurationProfileFormOptions.RAW,
      title: t('profiles.profile_form.options_tab.raw_xml'),
      icon: <Icon data={FileCode} />
    });
  }

  return (
    <>
      <Navbar items={optionTabs} activeTab={selectedOption} onSelectTab={handleChangeTab} />
      {selectedOption === ConfigurationProfileFormOptions.GENERAL && (
        <GeneralOption form={form} disabled={generalDisabled} />
      )}
      {selectedOption === ConfigurationProfileFormOptions.RAW && payload && (
        <RawOption payload={payload} />
      )}
    </>
  );
};
