import { FC, FormEvent, useEffect, useState } from 'react';
import css from './settings-package-point.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CreatePackagePointDto,
  PackageDistributionPointType,
  PackagePointFullDto,
  Permission,
  UpdatePackagePointDto
} from '../../../../types/api';
import {
  createPackagePoint,
  deletePackagePoint,
  getPackagePoint,
  updatePackagePoint
} from '../../../../api/packages';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRequest from '../../../../hooks/useRequest';
import { PackagePointForm, packagePointFormSchema } from './settings-package-point.schema';
import { useTranslation } from 'react-i18next';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { usePermission } from '../../../contexts/permission.context';
import { Header } from '../../../components/header/header.component';
import { ActionMenu } from './components/action-menu/action-menu.component';
import {
  Dialog,
  Popover,
  Select,
  SelectOption,
  Text,
  TextInput,
  useToaster
} from '@gravity-ui/uikit';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';

export const SettingsPackagePoint: FC = () => {
  const { id } = useParams();
  const crudRequest = useRequest<PackagePointFullDto>();
  const navigate = useNavigate();
  const toaster = useToaster();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    trigger,
    getValues,
    reset
  } = useForm<PackagePointForm>({
    mode: 'onChange',
    resolver: yupResolver(packagePointFormSchema),
    defaultValues: packagePointFormSchema.getDefault()
  });

  const [packagePoint, setPackagePoint] = useState<PackagePointFullDto>();
  const [formMode, setFormMode] = useState<'create' | 'edit' | 'view'>(
    id === 'new' ? 'create' : 'view'
  );
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState<PackagePointForm>();
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
  };

  const handlePackagePointCreate = async () => {
    if (!id || crudRequest.loading) return;

    const isValid = await trigger();
    if (!isValid) return;

    const values = getValues();
    try {
      const requestBody: CreatePackagePointDto = {
        display_name: values.display_name,
        type: values.type,
        server: values.server,
        port: values.port ? Number(values.port) : null,
        read_password: values.read_password,
        read_username: values.read_username,
        share_name: values.share_name
      };
      const created = await crudRequest.send(createPackagePoint(requestBody), 1000);

      setPackagePoint(created);
      setValue('display_name', created.display_name);
      setValue('type', created.type);
      setValue('server', created.server);
      setValue('port', !!created.port || created.port === 0 ? String(created.port) : '');
      setValue('share_name', created.share_name || '');
      setValue('read_username', created.read_username || '');
      setValue('read_password', created.read_password || '');

      setFormMode('view');
      navigate(`/settings/package-points/${created.id}`, { replace: true });
      toaster.add({
        name: 'package-point-create-success',
        content: t(
          'settings.tiles.package_distribution_points.page.package_distribution_point_page.successfully_created',
          {
            title: created.display_name
          }
        ),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'package-point-create-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handlePackagePointEdit = async () => {
    if (!id || crudRequest.loading) return;

    const isValid = await trigger();
    if (!isValid) return;

    const values = getValues();
    try {
      const requestBody: UpdatePackagePointDto = {
        display_name: values.display_name,
        type: values.type,
        server: values.server,
        port: values.port ? Number(values.port) : null,
        read_password: values.read_password,
        read_username: values.read_username,
        share_name: values.share_name
      };
      if (requestBody.read_password === packagePoint?.read_password) {
        delete requestBody.read_password;
      }
      const updated = await crudRequest.send(updatePackagePoint(id, requestBody), 1000);
      if (requestBody.read_password) {
        setValue('read_password', '********');
      }
      setPackagePoint(updated);
      setValue('display_name', updated.display_name);
      setValue('type', updated.type);
      setValue('server', updated.server);
      setValue('port', !!updated.port || updated.port === 0 ? String(updated.port) : '');
      setValue('share_name', updated.share_name || '');
      setValue('read_username', updated.read_username || '');
      setValue('read_password', updated.read_password || '');

      setFormMode('view');
      navigate(`/settings/package-points/${updated.id}`, { replace: true });
      toaster.add({
        name: 'package-point-update-success',
        content: t(
          'settings.tiles.package_distribution_points.page.package_distribution_point_page.successfully_updated',
          {
            title: updated.display_name
          }
        ),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'update-package-point-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handlePackagePointEditStart = () => {
    setValuesBeforeEdit(getValues());
    setFormMode('edit');
  };

  const handlePackagePointCancel = () => {
    if (formMode === 'create') {
      navigate(`/settings/package-points`);
      return;
    }
    reset(valuesBeforeEdit);
    setFormMode('view');
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalShown(true);
  };
  const handleDeleteModalClose = () => {
    if (crudRequest.loading) return;
    setIsDeleteModalShown(false);
  };
  const handleDeleteModalSubmit = async () => {
    if (!id || crudRequest.loading) return;
    try {
      await crudRequest.send(deletePackagePoint(id), 1000);
      navigate(`/settings/package-points`, { replace: true });

      toaster.add({
        name: 'package-point-delete-success',
        content: t(
          'settings.tiles.package_distribution_points.page.package_distribution_point_page.successfully_deleted',
          {
            title: packagePoint?.display_name
          }
        ),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'delete-package-point-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
    handleDeleteModalClose();
  };

  useEffect(() => {
    const init = async () => {
      if (!id || id === 'new') return;
      try {
        const result = await crudRequest.send(getPackagePoint(id), 1000);
        setPackagePoint(result);
        setValue('display_name', result.display_name);
        setValue('type', result.type);
        setValue('server', result.server);
        setValue('port', !!result.port || result.port === 0 ? String(result.port) : '');
        setValue('share_name', result.share_name || '');
        setValue('read_username', result.read_username || '');
        setValue('read_password', result.read_password || '');
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        toaster.add({
          name: 'get-package-point-error',
          content: localizedErrorString,
          theme: 'danger',
          autoHiding: 5000
        });
        navigate(`/settings/package-points`, { replace: true });
      }
    };
    void init();
  }, []);

  const typeOptions: SelectOption[] = [
    { content: 'HTTP', value: 'http' },
    { content: 'SMB', value: 'smb' }
  ];

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={packagePoint?.display_name}
        rightContent={
          <ActionMenu
            mode={formMode}
            disabled={crudRequest.loading || !isAllowedTo(Permission.EditPackages)}
            onPackagePointEditStart={handlePackagePointEditStart}
            onPackagePointCancel={handlePackagePointCancel}
            onPackagePointCreate={handlePackagePointCreate}
            onPackagePointDelete={handleDeleteModalOpen}
            onPackagePointEdit={handlePackagePointEdit}
          />
        }
      />
      <div className={css.Content}>
        {crudRequest.loading ? (
          <FormSkeleton />
        ) : (
          <>
            <div className={css.Title}>
              {formMode === 'view' && <Text variant="display-2">{packagePoint?.display_name}</Text>}
              {formMode !== 'view' && (
                <Popover content={t('common.click_to_edit')}>
                  <TextInput
                    placeholder={t(
                      'settings.tiles.package_distribution_points.page.package_distribution_point_page.display_name'
                    )}
                    size="xl"
                    view="clear"
                    error={errors.display_name?.message}
                    errorPlacement="inside"
                    controlProps={{ className: css.TitleInput }}
                    {...register('display_name')}
                  />
                </Popover>
              )}
            </div>
            <form onSubmit={handleFormSubmit}>
              <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditPackages)}>
                <div>
                  <Text>
                    {t(
                      'settings.tiles.package_distribution_points.page.package_distribution_point_page.protocol'
                    )}
                  </Text>
                  <Select
                    value={[getValues().type.toString()]}
                    onUpdate={(values) =>
                      setValue('type', values[0] as PackageDistributionPointType)
                    }
                    options={typeOptions}
                    disabled={formMode === 'view'}
                    errorMessage={errors.type?.message}
                    width="max"
                    {...register('type')}
                  />
                </div>
                <div>
                  <Text>
                    {t(
                      'settings.tiles.package_distribution_points.page.package_distribution_point_page.server'
                    )}
                  </Text>
                  <TextInput
                    {...register('server')}
                    error={errors.server?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
                <div>
                  <Text>
                    {t(
                      'settings.tiles.package_distribution_points.page.package_distribution_point_page.port'
                    )}
                  </Text>
                  <TextInput
                    {...register('port')}
                    error={errors.port?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
                <div>
                  <Text>
                    {watch('type') === 'smb'
                      ? t(
                          'settings.tiles.package_distribution_points.page.package_distribution_point_page.share_name'
                        )
                      : t(
                          'settings.tiles.package_distribution_points.page.package_distribution_point_page.path_to_packages'
                        )}
                  </Text>
                  <TextInput
                    {...register('share_name')}
                    error={errors.share_name?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
                <div>
                  <Text>
                    {t(
                      'settings.tiles.package_distribution_points.page.package_distribution_point_page.username'
                    )}
                  </Text>
                  <TextInput
                    {...register('read_username')}
                    error={errors.read_username?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
                <div>
                  <Text>
                    {t(
                      'settings.tiles.package_distribution_points.page.package_distribution_point_page.password'
                    )}
                  </Text>
                  <TextInput
                    type="password"
                    {...register('read_password')}
                    error={errors.read_password?.message}
                    disabled={formMode === 'view'}
                  />
                </div>
              </fieldset>
            </form>
          </>
        )}
      </div>
      <Dialog open={isDeleteModalShown} onClose={handleDeleteModalClose}>
        <Dialog.Header
          caption={t(
            'settings.tiles.package_distribution_points.page.package_distribution_point_page.delete_modal.title'
          )}
        />
        <Dialog.Body>
          <Text>
            {t(
              'settings.tiles.package_distribution_points.page.package_distribution_point_page.delete_modal.message',
              { point_name: packagePoint?.display_name }
            )}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t(
            'settings.tiles.package_distribution_points.page.package_distribution_point_page.delete_modal.delete_btn'
          )}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonCancel={handleDeleteModalClose}
          onClickButtonApply={handleDeleteModalSubmit}
          loading={crudRequest.loading}
        />
      </Dialog>
    </div>
  );
};
