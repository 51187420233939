import { FC } from 'react';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../types/api';
import { isBoolean } from '../../../../../../../../../utils/assert.utils';
import { useTranslation } from 'react-i18next';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import css from './os-info-table.module.scss';
import { Text } from '@gravity-ui/uikit';

interface IProps {
  device?: DeviceFullDto;
}

interface IKeyValue {
  key: string;
  value: string | null | undefined;
}

export const OsInfoTable: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const di = device?.device_information;

  const data: IKeyValue[] = [
    {
      key: t('inventory.device_page.inventory_tab.os_info_tab.table.heading.os'),
      value: di?.os_name
    },
    {
      key: t('inventory.device_page.inventory_tab.os_info_tab.table.heading.os_version'),
      value: di?.os_version
    },
    {
      key: t('inventory.device_page.inventory_tab.os_info_tab.table.heading.os_build'),
      value: di?.os_build_version
    },
    {
      key: t(
        'inventory.device_page.inventory_tab.os_info_tab.table.heading.os_rapid_security_response'
      ),
      value: di?.os_rapid_security_response
    },
    {
      key: t(
        'inventory.device_page.inventory_tab.os_info_tab.table.heading.software_update_device_id'
      ),
      value: di?.software_update_device_id
    },
    {
      key: t('inventory.device_page.inventory_tab.os_info_tab.table.heading.ad_status'),
      value: isBoolean(di?.os_ad_bind)
        ? di?.os_ad_bind
          ? t('inventory.device_page.inventory_tab.os_info_tab.table.enabled')
          : t('inventory.device_page.inventory_tab.os_info_tab.table.disabled')
        : undefined
    }
  ];

  const columns: Column<IKeyValue>[] = [
    {
      id: 'key',
      selector: (row) => <Text variant="subheader-1">{row.key}</Text>,
      width: 250
    },
    {
      id: 'value',
      selector: (row) => row.value
    }
  ];

  return (
    <>
      <Table columns={columns} data={data} className={css.Table} />
      <DeviceExtensionAttributes
        className={css.ExtensionAttributes}
        device={device}
        inventorySection={ExtensionAttributeInventorySection.OS}
      />
    </>
  );
};
