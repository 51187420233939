import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import { t } from 'i18next';
import css from './action-menu.module.scss';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  disabled: boolean;
  onStaticGroupCreate: () => void;
  onStaticGroupCancel: () => void;
  onStaticGroupDelete: () => void;
  onStaticGroupEditStart: () => void;
  onStaticGroupEdit: () => void;
}

export const ActionMenu: FC<IProps> = (props) => {
  const {
    mode,
    disabled,
    onStaticGroupCreate,
    onStaticGroupCancel,
    onStaticGroupDelete,
    onStaticGroupEditStart,
    onStaticGroupEdit
  } = props;

  return (
    <div className={css.ButtonGroup}>
      {mode === 'create' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onStaticGroupCancel}>
            {t('static_groups.page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onStaticGroupCreate}>
            {t('static_groups.page.create_btn')}
          </Button>
        </>
      )}
      {mode === 'view' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onStaticGroupDelete}>
            {t('static_groups.page.delete_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onStaticGroupEditStart}>
            {t('static_groups.page.edit_btn')}
          </Button>
        </>
      )}
      {mode === 'edit' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onStaticGroupCancel}>
            {t('static_groups.page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onStaticGroupEdit}>
            {t('static_groups.page.update_btn')}
          </Button>
        </>
      )}
    </div>
  );
};
