import { FC } from 'react';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../types/api';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';

interface IProps {
  device?: DeviceFullDto;
}

export const ExtensionAttributesTable: FC<IProps> = (props: IProps) => {
  const { device } = props;

  return (
    <>
      <DeviceExtensionAttributes
        device={device}
        displayHeading={false}
        inventorySection={ExtensionAttributeInventorySection.ExtensionAttributes}
      />
    </>
  );
};
