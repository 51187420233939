import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import css from './action-menu.module.scss';
import { t } from 'i18next';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  disabled: boolean;
  onScriptCreate: () => void;
  onScriptCancel: () => void;
  onScriptDelete: () => void;
  onScriptEditStart: () => void;
  onScriptEdit: () => void;
}

export const ActionMenu: FC<IProps> = (props) => {
  const {
    mode,
    disabled,
    onScriptCreate,
    onScriptCancel,
    onScriptDelete,
    onScriptEditStart,
    onScriptEdit
  } = props;

  return (
    <div className={css.ButtonGroup}>
      {mode === 'create' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onScriptCancel}>
            {t('settings.tiles.scripts.page.script_page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onScriptCreate}>
            {t('settings.tiles.scripts.page.script_page.create_btn')}
          </Button>
        </>
      )}
      {mode === 'edit' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onScriptCancel}>
            {t('settings.tiles.scripts.page.script_page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onScriptEdit}>
            {t('settings.tiles.scripts.page.script_page.save_btn')}
          </Button>
        </>
      )}
      {mode === 'view' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onScriptDelete}>
            {t('settings.tiles.scripts.page.script_page.delete_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onScriptEditStart}>
            {t('settings.tiles.scripts.page.script_page.edit_btn')}
          </Button>
        </>
      )}
    </div>
  );
};
