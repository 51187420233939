import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import css from './user.module.scss';
import {
  CreateUserRequestDto,
  GetRolesResponseDto,
  Permission,
  UpdateUserRequestDto,
  UserOriginType,
  UserSimpleDto
} from '../../../../types/api';
import { createUser, deleteUser, getUser, updateUser } from '../../../../api/users';
import { UserLocalFormComponent } from './components/user-local-form/user-local-form.component';
import {
  userLocalFormSchema,
  UserLocalFormValues
} from './components/user-local-form/user-local-form.schema';
import { getRoles } from '../../../../api/roles';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { SettingsPaths } from '../settings.const';
import { Paths } from '../../../constants';
import { UserLdapFormComponent } from './components/user-ldap-form/user-ldap-form.component';
import {
  userLdapFormSchema,
  UserLdapFormValues
} from './components/user-ldap-form/user-ldap-form.schema';
import { Header } from '../../../components/header/header.component';
import { Button, Dialog, Popover, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { ActionMenu } from './components/action-menu/action-menu.component';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { usePermission } from '../../../contexts/permission.context';

export interface IRole {
  id: string;
  title: string;
}

export const UserComponent: FC = () => {
  const { id } = useParams();
  const isNew = id === 'new';

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const navigate = useNavigate();
  const toaster = useToaster();
  const crudRequest = useRequest<UserSimpleDto>();
  const fetchRolesRequest = useRequest<GetRolesResponseDto>();
  const userRef = useRef<UserSimpleDto>();

  const [isDeleteModalShown, setDeleteModalShown] = useState(false);
  const [isLdapUserSelected, setIsLdapUserSelected] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>(isNew ? 'create' : 'view');
  const [roles, setRoles] = useState<IRole[]>([]);
  const [userType, setUserType] = useState<UserOriginType>();

  const userLocalForm = useForm<UserLocalFormValues>({
    mode: 'onChange',
    resolver: yupResolver(userLocalFormSchema),
    defaultValues: userLocalFormSchema.getDefault()
  });

  const userLdapForm = useForm<UserLdapFormValues>({
    mode: 'onChange',
    resolver: yupResolver(userLdapFormSchema),
    defaultValues: userLdapFormSchema.getDefault()
  });

  const userLocalToForm = (input: UserSimpleDto): UserLocalFormValues => {
    return {
      username: input.username,
      display_name: input.display_name,
      role: { id: input.role.id, title: input.role.title },
      password: '',
      confirmPassword: ''
    };
  };

  const userLdapToForm = (input: UserSimpleDto): UserLdapFormValues => {
    return {
      username: input.username,
      display_name: input.display_name,
      role_id: input.role.id
    };
  };

  const handleAddModeChange = (type: UserOriginType) => () => {
    setUserType(type);
  };

  const handleLocalUserCreate = async () => {
    if (crudRequest.loading) return;
    if (!(await userLocalForm.trigger())) return;

    const userLocalFormValues = userLocalForm.getValues();
    const request: CreateUserRequestDto = {
      username: userLocalFormValues.username,
      password: userLocalFormValues.password,
      role: userLocalFormValues.role.id,
      origin_type: UserOriginType.Local,
      ...(userLocalFormValues.display_name && { display_name: userLocalFormValues.display_name })
    };
    try {
      const response = await crudRequest.send(createUser(request), 1000);
      userLocalForm.reset(userLocalToForm(response));
      setFormMode('view');

      navigate(`/${Paths.SETTINGS}${SettingsPaths.USER.replace(':id', response.id)}`);
      toaster.add({
        name: 'user-created',
        content: t('settings.tiles.users.page.user_page.successfully_created', {
          username: userLocalFormValues.username
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'user-created-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleLdapUserCreate = async () => {
    if (crudRequest.loading) return;
    if (!(await userLdapForm.trigger())) return;

    const userLdapFormValues = userLdapForm.getValues();
    const request: CreateUserRequestDto = {
      username: userLdapFormValues.username,
      role: userLdapFormValues.role_id,
      origin_type: UserOriginType.Ldap,
      ...(userLdapFormValues.display_name && { display_name: userLdapFormValues.display_name })
    };
    try {
      const response = await crudRequest.send(createUser(request), 1000);
      userLdapForm.reset(userLdapToForm(response));
      setFormMode('view');

      navigate(`/${Paths.SETTINGS}${SettingsPaths.USER.replace(':id', response.id)}`);
      toaster.add({
        name: 'user-created',
        content: t('settings.tiles.users.page.user_page.successfully_created', {
          username: userLdapFormValues.username
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'user-created-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleUserCreate = (type: UserOriginType) => async () => {
    if (type === UserOriginType.Local) {
      await handleLocalUserCreate();
    } else {
      await handleLdapUserCreate();
    }
  };

  const handleUserEditStart = () => {
    setFormMode('edit');
  };

  const handleUserCancel = () => {
    if (formMode === 'create') {
      navigate(`/${Paths.SETTINGS}${SettingsPaths.USER_LIST}`, {});
      return;
    }

    if (userRef.current && userType === UserOriginType.Local) {
      userLocalForm.reset(userLocalToForm(userRef.current));
    }
    if (userRef.current && userType === UserOriginType.Ldap) {
      userLocalForm.reset(userLdapToForm(userRef.current));
    }
    setFormMode('view');
  };

  const handleLocalUserSave = async () => {
    if (crudRequest.loading || !userRef.current) return;
    if (!(await userLocalForm.trigger())) return;

    const userLocalFormValues = userLocalForm.getValues();
    const request: UpdateUserRequestDto = {
      ...(userLocalFormValues.username && { username: userLocalFormValues.username }),
      ...(userLocalFormValues.password && { password: userLocalFormValues.password }),
      ...(userLocalFormValues.role && { role: userLocalFormValues.role.id }),
      ...(userLocalFormValues.display_name && {
        display_name: userLocalFormValues.display_name
      })
    };
    try {
      const response = await crudRequest.send(updateUser(userRef.current?.id, request), 1000);
      userRef.current = response;
      userLocalForm.reset(userLocalToForm(response));
      setFormMode('view');

      navigate(`/${Paths.SETTINGS}${SettingsPaths.USER.replace(':id', response.id)}`);
      toaster.add({
        name: 'user-updated-error',
        content: t('settings.tiles.users.page.user_page.successfully_updated', {
          username: response.username
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'user-updated-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleLdapUserSave = async () => {
    if (crudRequest.loading || !userRef.current) return;
    if (!(await userLdapForm.trigger())) return;

    const userLdapFormValues = userLdapForm.getValues();
    const request: UpdateUserRequestDto = {
      ...(userLdapFormValues.role_id && { role: userLdapFormValues.role_id })
    };
    try {
      const response = await crudRequest.send(updateUser(userRef.current?.id, request), 1000);
      userLdapForm.reset(userLdapToForm(response));
      setFormMode('view');
      userRef.current = response;

      navigate(`/${Paths.SETTINGS}${SettingsPaths.USER.replace(':id', response.id)}`);
      toaster.add({
        name: 'user-updated-success',
        content: t('settings.tiles.users.page.user_page.successfully_updated', {
          username: response.username
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'user-updated-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleUserSave = (type: UserOriginType) => async () => {
    if (type === UserOriginType.Local) {
      await handleLocalUserSave();
    } else {
      await handleLdapUserSave();
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalShown(false);
  };

  const handleDeleteModalOpen = () => {
    setDeleteModalShown(true);
  };

  const handleDeleteModalConfirm = async () => {
    if (crudRequest.loading || !userRef.current) return;
    try {
      const response = await crudRequest.send(deleteUser(userRef.current?.id), 1000);
      navigate(`/${Paths.SETTINGS}${SettingsPaths.USER_LIST}`);
      toaster.add({
        name: 'user-deleted',
        content: t('settings.tiles.users.page.user_page.successfully_deleted', {
          username: response.username
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'user-deleted-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      const { roles: roleDtos } = await fetchRolesRequest.send(getRoles(), 1000);
      setRoles(roleDtos.map((roleDto) => ({ id: roleDto.id, title: roleDto.title })));

      if (!id || formMode === 'create') return;
      try {
        if (!isNew) {
          const response = await crudRequest.send(getUser(id), 1000);
          userRef.current = response;
          setUserType(response.origin_type);
          if (response.origin_type === UserOriginType.Local) {
            userLocalForm.reset(userLocalToForm(response));
          } else if (response.origin_type === UserOriginType.Ldap) {
            userLdapForm.reset(userLdapToForm(response));
            setIsLdapUserSelected(true);
          }
        }
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        toaster.add({
          name: 'user-fetch-error',
          content: localizedErrorString,
          theme: 'danger',
          autoHiding: 5000
        });
      }
    };
    void init();
  }, [id]);

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={
          isNew
            ? t('settings.tiles.users.page.user_page.new_title')
            : userRef.current?.display_name || userRef.current?.username
        }
        rightContent={
          userType &&
          (userType === UserOriginType.Local || isLdapUserSelected) && (
            <ActionMenu
              mode={formMode}
              disabled={crudRequest.loading || !isAllowedTo(Permission.EditAdministration)}
              onUserCreate={handleUserCreate(userType)}
              onUserEditStart={handleUserEditStart}
              onUserEdit={handleUserSave(userType)}
              onUserDelete={handleDeleteModalOpen}
              onUserCancel={handleUserCancel}
            />
          )
        }
      />
      <div className={css.Content}>
        {crudRequest.loading || fetchRolesRequest.loading ? (
          <FormSkeleton />
        ) : (
          <>
            {isNew && !userType && (
              <>
                <div className={css.Title}>
                  <Text variant="display-2">
                    {t('settings.tiles.users.page.user_page.new_title')}
                  </Text>
                </div>
                <div className={css.SelectCreateTypeBlock}>
                  <Text>{t('settings.tiles.users.page.user_page.choose_create_type')}:</Text>
                  <div className={css.SelectCreateTypeBtnContainer}>
                    <Button view="action" onClick={handleAddModeChange(UserOriginType.Local)}>
                      {t('settings.tiles.users.page.user_page.choose_create_type_local')}
                    </Button>
                    <Button view="action" onClick={handleAddModeChange(UserOriginType.Ldap)}>
                      {t('settings.tiles.users.page.user_page.choose_create_type_ldap')}
                    </Button>
                  </div>
                </div>
              </>
            )}
            {userType === UserOriginType.Local && (
              <>
                <div className={css.Title}>
                  {formMode === 'view' && (
                    <Text variant="display-2">
                      {userRef.current?.display_name || userRef.current?.username}
                    </Text>
                  )}
                  {formMode !== 'view' && (
                    <Popover content={t('common.click_to_edit')}>
                      <TextInput
                        placeholder={t('settings.tiles.users.page.user_page.display_name')}
                        size="xl"
                        view="clear"
                        error={userLocalForm.formState.errors.display_name?.message}
                        errorPlacement="inside"
                        controlProps={{ className: css.TitleInput }}
                        {...userLocalForm.register('display_name')}
                      />
                    </Popover>
                  )}
                </div>
                <UserLocalFormComponent mode={formMode} form={userLocalForm} roles={roles} />
              </>
            )}
            {userType === UserOriginType.Ldap && (
              <>
                <div className={css.Title}>
                  {!isLdapUserSelected && (
                    <Text variant="display-2">
                      {t('settings.tiles.users.page.user_page.new_title')}
                    </Text>
                  )}
                  {isLdapUserSelected && (
                    <Text variant="display-2">
                      {userRef.current?.display_name || userRef.current?.username}
                    </Text>
                  )}
                </div>
                <UserLdapFormComponent
                  mode={formMode}
                  roles={roles}
                  form={userLdapForm}
                  isLdapUserSelected={isLdapUserSelected}
                  onSelectLdapUser={setIsLdapUserSelected}
                />
              </>
            )}
          </>
        )}
      </div>
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalShown}>
        <Dialog.Header caption={t('settings.tiles.users.page.user_page.modal_delete.title')} />
        <Dialog.Body>
          <Text>
            {t('settings.tiles.users.page.user_page.modal_delete.message', {
              title: userRef.current?.display_name || userRef.current?.username
            })}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('settings.tiles.users.page.user_page.modal_delete.delete_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleDeleteModalConfirm}
          onClickButtonCancel={handleDeleteModalClose}
        />
      </Dialog>
    </div>
  );
};
