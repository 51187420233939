import { DeviceHistoryType, HistoryMdmCommandType } from '../types/api';
import i18n from '../i18n/i18n';

export const deviceHistoryType: Record<DeviceHistoryType, string> = {
  [DeviceHistoryType.DeviceAdd]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_add'
  ),
  [DeviceHistoryType.DeviceDelete]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_delete'
  ),
  [DeviceHistoryType.DeviceEdit]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_edit'
  ),
  [DeviceHistoryType.DeviceSmartGroupAdd]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_smart_group_add'
  ),
  [DeviceHistoryType.DeviceSmartGroupRemove]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_smart_group_remove'
  ),
  [DeviceHistoryType.DeviceStaticGroupAdd]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_static_group_add'
  ),
  [DeviceHistoryType.DeviceStaticGroupRemove]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_static_group_remove'
  ),
  [DeviceHistoryType.DeviceScopePolicy]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_scope_policy'
  ),
  [DeviceHistoryType.DeviceScopeProfile]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_scope_profile'
  ),
  [DeviceHistoryType.DeviceScopeApp]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_scope_app'
  ),
  [DeviceHistoryType.DeviceMdmCommandSent]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_mdm_command_sent'
  ),
  [DeviceHistoryType.DeviceMdmCommandFailed]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_mdm_command_failed'
  ),
  [DeviceHistoryType.DeviceTerminalStarted]: i18n.t(
    'inventory.device_page.history_tab.device_history_type.device_terminal_started'
  )
};

export const historyMdmCommandType: Record<HistoryMdmCommandType, string> = {
  [HistoryMdmCommandType.Restart]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.restart'
  ),
  [HistoryMdmCommandType.UnrollDevice]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.unroll_device'
  ),
  [HistoryMdmCommandType.DownloadUpdates]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.download_updates'
  ),
  [HistoryMdmCommandType.InstallUpdates]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.install_updates'
  ),
  [HistoryMdmCommandType.EnableBluetooth]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.enable_bluetooth'
  ),
  [HistoryMdmCommandType.DisableBluetooth]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.disable_bluetooth'
  ),
  [HistoryMdmCommandType.WipeDevice]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.wipe_device'
  ),
  [HistoryMdmCommandType.LockDevice]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.lock_device'
  ),
  [HistoryMdmCommandType.SendBlankPush]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.send_blank_push'
  ),
  [HistoryMdmCommandType.EnableArd]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.enable_ard'
  ),
  [HistoryMdmCommandType.DisableArd]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.disable_ard'
  ),
  [HistoryMdmCommandType.GetActivationLockBypassCode]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.get_activation_lock_bypass_code'
  ),
  [HistoryMdmCommandType.SetFirmwarePassword]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.set_firmware_password'
  ),
  [HistoryMdmCommandType.SetRecoveryLock]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.set_recovery_lock'
  ),
  [HistoryMdmCommandType.ClearPasscode]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.set_recovery_lock'
  ),
  [HistoryMdmCommandType.EnableLostMode]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.enable_lost_mode'
  ),
  [HistoryMdmCommandType.DisableLostMode]: i18n.t(
    'inventory.device_page.history_tab.history_mdm_command_type.disable_lost_mode'
  )
};
