import { FC } from 'react';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../types/api';
import { formatDate } from '../../../../../../../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import css from './general-table.module.scss';
import { Text } from '@gravity-ui/uikit';

interface IProps {
  device?: DeviceFullDto;
}

interface IKeyValue {
  key: string;
  value: string | null | undefined;
}

export const GeneralTable: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const data: IKeyValue[] = [
    {
      key: t('inventory.device_page.inventory_tab.general_tab.table.heading.device_name'),
      value: device?.device_information?.device_name
    },
    {
      key: t('inventory.device_page.inventory_tab.general_tab.table.heading.last_update'),
      value:
        device && device.device_information
          ? new Date(device.updated_at) > new Date(device.device_information.updated_at)
            ? formatDate(device.updated_at)
            : formatDate(device.device_information.updated_at)
          : undefined
    },
    {
      key: t('inventory.device_page.inventory_tab.general_tab.table.heading.created_at'),
      value: device && formatDate(device?.created_at)
    },
    {
      key: t('inventory.device_page.inventory_tab.general_tab.table.heading.local_ip'),
      value: device?.device_information?.local_ip
    },
    {
      key: t('inventory.device_page.inventory_tab.general_tab.table.heading.external_ip'),
      value: device?.agent_instance?.last_remote_address
    },
    {
      key: t('inventory.device_page.inventory_tab.general_tab.table.heading.username'),
      value: undefined
    }
  ];

  const columns: Column<IKeyValue>[] = [
    {
      id: 'key',
      selector: (row) => <Text variant="subheader-1">{row.key}</Text>,
      width: 250
    },
    {
      id: 'value',
      selector: (row) => row.value
    }
  ];

  return (
    <>
      <Table columns={columns} data={data} className={css.Table} />
      <DeviceExtensionAttributes
        className={css.ExtensionAttributes}
        device={device}
        inventorySection={ExtensionAttributeInventorySection.General}
      />
    </>
  );
};
