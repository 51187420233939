import { FC, useEffect, useState } from 'react';
import css from './smart-group-devices-list.module.scss';
import {
  DeviceOsType,
  GetSmartGroupsDevicesResponseDto,
  PaginationSortOrder,
  Permission,
  SmartGroupDevicesSimpleDto,
  SmartGroupDevicesSortField
} from '../../../types/api';
import { getSmartGroups } from '../../../api/smart-group-devices';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import { usePermission } from '../../contexts/permission.context';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';
import { Column, PaginationState, SortState, Table } from '../../components/table/table.component';
import { Header } from '../../components/header/header.component';
import { Button, Text } from '@gravity-ui/uikit';
import useRequest from '../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';
import noDataImage from '../../../assets/images/no-data.png';

export const SmartGroupDevicesList: FC = () => {
  const navigate = useNavigate();

  const [smartGroupsData, setSmartGroupsData] = useState<SmartGroupDevicesSimpleDto[]>([]);
  const [smartGroupsCount, setSmartGroupsCount] = useState(0);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: 10
  });
  const [sortState, setSortState] = useState<SortState>();
  const fetchRequest = useRequest<GetSmartGroupsDevicesResponseDto>();

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const handleChangePagination = (state: PaginationState) => setPaginationState(state);
  const handleChangeSort = (state?: SortState) => setSortState(state);
  const handleRowClick = (row: SmartGroupDevicesSimpleDto) => navigate(row.id);
  const handleAddButtonClick = () => navigate('new');

  useEffect(() => {
    const fetchSmartGroupsData = async () => {
      const result: GetSmartGroupsDevicesResponseDto = await fetchRequest.send(
        getSmartGroups({
          sort_field: sortState?.column as SmartGroupDevicesSortField,
          sort_order: sortState?.order as PaginationSortOrder,
          page: paginationState.page,
          limit: paginationState.rowsPerPage,
          os_type: osType
        }),
        1000
      );
      const smartGroups: SmartGroupDevicesSimpleDto[] = result.groups;
      setSmartGroupsData(smartGroups);
      setSmartGroupsCount(result.count);
    };

    void fetchSmartGroupsData();
  }, [paginationState, sortState, osType]);

  const columns: Column<SmartGroupDevicesSimpleDto>[] = [
    {
      name: t('smart_groups.table.heading.name'),
      selector: (row: SmartGroupDevicesSimpleDto) => (row.title ? row.title : ''),
      id: SmartGroupDevicesSortField.Title,
      sortable: true
    },
    {
      name: t('smart_groups.table.heading.count'),
      selector: (row: SmartGroupDevicesSimpleDto) => (row.devices_count ? row.devices_count : 0),
      sortable: true,
      id: SmartGroupDevicesSortField.DevicesCount
    },
    {
      name: t('smart_groups.table.heading.id'),
      selector: (row: SmartGroupDevicesSimpleDto) => row.id,
      sortable: true,
      id: SmartGroupDevicesSortField.Id
    },
    {
      name: t('smart_groups.table.heading.created'),
      selector: (row: SmartGroupDevicesSimpleDto) => formatDate(row.created_at),
      sortable: true,
      id: SmartGroupDevicesSortField.CreatedAt
    },
    {
      name: t('smart_groups.table.heading.updated'),
      selector: (row: SmartGroupDevicesSimpleDto) => formatDate(row.updated_at),
      sortable: true,
      id: SmartGroupDevicesSortField.UpdatedAt
    }
  ];

  const headerContent = (
    <Button
      view="action"
      disabled={!isAllowedTo(Permission.EditGroups)}
      onClick={handleAddButtonClick}
    >
      {t('smart_groups.new_btn')}
    </Button>
  );

  return (
    <div id="smart-groups" className={css.SmartGroupsContainer}>
      <Header rightContent={headerContent} />
      <div className={css.Content}>
        {fetchRequest.loading ? (
          <TableSkeleton />
        ) : smartGroupsCount > 0 ? (
          <div className={css.DataTableContainer}>
            <Table
              columns={columns}
              data={smartGroupsData}
              onChangePagination={handleChangePagination}
              onChangeSort={handleChangeSort}
              onRowClick={handleRowClick}
              pagination
              paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
              paginationTotalRows={smartGroupsCount}
              paginationState={paginationState}
              sortState={sortState}
              leftContent={<Text variant="display-2">{t('smart_groups.title')}</Text>}
            />
          </div>
        ) : (
          <>
            <Text variant="display-2">{t('smart_groups.title')}</Text>
            <div className={css.NoDataContainer}>
              <img alt="no-data" src={noDataImage} />
              <Text variant="subheader-3">{t('smart_groups.no_data')}</Text>
              {headerContent}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
