import { FC } from 'react';
import { BlankPage } from '../../components/blank-page/blank-page.component';
import { useTranslation } from 'react-i18next';
import { Header } from '../../components/header/header.component';

export const Home: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <BlankPage text={t('home.welcome_message')} />
    </>
  );
};
