import { FC, useEffect, useState } from 'react';
import css from './settings-pki-certificate.module.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DeviceOsType, PkiCertificateFullDto } from '../../../../types/api';
import { useTranslation } from 'react-i18next';
import { getPkiCertificate } from '../../../../api/pki';
import { formatDate } from '../../../../utils/time.utils';
import { Text, useToaster } from '@gravity-ui/uikit';
import useRequest from '../../../../hooks/useRequest';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { FormSkeleton } from './component/form-skeleton/form-skeleton.component';

interface IProps {
  onTitleChange?: (title: string) => unknown;
}

export const SettingsPkiCertificate: FC<IProps> = (props) => {
  const { onTitleChange } = props;
  const { id } = useParams();
  const { t } = useTranslation();
  const toaster = useToaster();
  const navigate = useNavigate();
  const fetchRequest = useRequest<PkiCertificateFullDto>();

  const [certificate, setCertificate] = useState<PkiCertificateFullDto>();

  useEffect(() => {
    if (!id) return;
    const fetch = async () => {
      try {
        const result = await fetchRequest.send(getPkiCertificate(id));
        setCertificate(result);
        onTitleChange?.(result.subject);
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        toaster.add({
          name: 'fetch-pki-cert-error',
          content: localizedErrorString,
          theme: 'danger',
          autoHiding: 5000
        });
        navigate('./../');
      }
    };
    void fetch();
  }, []);

  const linkToDevice = (deviceId: string, osType: DeviceOsType): string => {
    const section = osType === DeviceOsType.MacOS ? 'computers' : 'mobile';
    return `/${section}/inventory/${deviceId}`;
  };
  return (
    <div>
      {fetchRequest.loading || !certificate ? (
        <FormSkeleton />
      ) : (
        <div className={css.CertificateInfo}>
          <div className={css.CertificateInfoItem}>
            <Text variant="subheader-2">{t('settings.tiles.pki.page.subject')}</Text>
            <Text>{certificate.subject}</Text>
          </div>
          <div className={css.CertificateInfoItem}>
            <Text variant="subheader-2">{t('settings.tiles.pki.page.serial_number')}</Text>
            <Text>{certificate.serial_number}</Text>
          </div>
          <div className={css.CertificateInfoItem}>
            <Text variant="subheader-2">{t('settings.tiles.pki.page.issue_date')}</Text>
            <Text>{formatDate(certificate.issue_date)}</Text>
          </div>
          <div className={css.CertificateInfoItem}>
            <Text variant="subheader-2">{t('settings.tiles.pki.page.expiration_date')}</Text>
            <Text>{formatDate(certificate.expiration_date)}</Text>
          </div>
          <div className={css.CertificateInfoItem}>
            <Text variant="subheader-2">{t('settings.tiles.pki.page.linked_devices')}</Text>
            <div className={css.CertificateDeviceLinks}>
              {certificate.devices.length ? (
                certificate.devices.map((device) => (
                  <Link
                    key={device.id}
                    to={linkToDevice(device.id, device.os_type)}
                    target="_blank"
                  >
                    <Text>{device.device_name || device.serial_number}</Text>
                  </Link>
                ))
              ) : (
                <Text>{t('settings.tiles.pki.page.linked_devices_empty')}</Text>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
