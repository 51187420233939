import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { getDevices, getDevicesCSV, searchDevices } from '../../../api/device';
import {
  DeviceOsType,
  DeviceSimpleDto,
  DevicesSortField,
  GetDevicesResponseDto,
  PaginationSortOrder,
  SearchDeviceByConditionDto
} from '../../../types/api';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { InventoryDevicePage } from './components/inventory-device-page/inventory-device-page.component';
import { formatDateFrom } from '../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';
import { Column, PaginationState, SortState, Table } from '../../components/table/table.component';
import { Header } from '../../components/header/header.component';
import { Button, Icon, Popup, Text, useToaster } from '@gravity-ui/uikit';
import css from './inventory.module.scss';
import { BarsDescendingAlignCenter } from '@gravity-ui/icons';
import { FilterPopup } from './components/filter-popup/filter-popup.component';
import useRequest from '../../../hooks/useRequest';
import { DeviceTableSkeleton } from './components/device-table-skeleton/device-table-skeleton.component';
import noDataImage from '../../../assets/images/no-data.png';
import { getLocalizedErrorString } from '../../../utils/localize-error';

export const Inventory: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [filterWidth, setFilterWidth] = useState(0);

  const [searchedDeviceConditions, setSearchedDeviceConditions] = useState<
    SearchDeviceByConditionDto[]
  >([]);
  const [deviceData, setDeviceData] = useState<DeviceSimpleDto[]>([]);
  const [devicesCount, setDevicesCount] = useState(0);
  const [isSearchPerformed, setIsSearchPerformed] = useState<boolean>(false);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: PaginationRowsPerPageOptions[0]
  });
  const [sortState, setSortState] = useState<SortState | undefined>();

  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;
  const navigate = useNavigate();
  const location = useLocation();
  const toaster = useToaster();

  const filterButtonRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const getDeviceInfoRequest = useRequest<GetDevicesResponseDto>();

  const getDevicesInfo = async () => {
    try {
      const response = await getDeviceInfoRequest.send(
        isSearchPerformed
          ? searchDevices({
              conditions: searchedDeviceConditions,
              page: paginationState.page,
              limit: paginationState.rowsPerPage,
              sort_field: sortState?.column as DevicesSortField,
              sort_order: sortState?.order as PaginationSortOrder,
              os_type: osType
            })
          : getDevices({
              page: paginationState.page,
              limit: paginationState.rowsPerPage,
              sort_field: sortState?.column as DevicesSortField,
              sort_order: sortState?.order as PaginationSortOrder,
              os_type: osType
            }),
        1000
      );
      setDeviceData(response.devices);
      setDevicesCount(response.count);
    } catch (e) {
      const localizedErrorString = getLocalizedErrorString(e as Error);
      toaster.add({
        name: 'get-device-info-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleRowClick = (device: DeviceSimpleDto) => {
    setSearchedDeviceConditions([]);
    setIsSearchPerformed(false);
    navigate(device.id);
  };

  const handlePaginationChange = (state: PaginationState) => setPaginationState(state);

  const handleSortChange = (state: SortState | undefined) => setSortState(state);

  const handleClickFilterButton = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleCancelClick = () => {
    setIsFilterOpen(false);
  };

  const handleSearch = (conditions: SearchDeviceByConditionDto[]) => {
    setSearchedDeviceConditions(conditions);
    setIsSearchPerformed(conditions.length > 0);
    setIsFilterOpen(false);
    setPaginationState({
      page: 1,
      rowsPerPage: paginationState.rowsPerPage
    });
  };

  useEffect(() => {
    void getDevicesInfo();
  }, [paginationState, sortState, isSearchPerformed, searchedDeviceConditions, location]);

  useLayoutEffect(() => {
    function updateFilterWidth() {
      setFilterWidth(ref.current?.clientWidth || 0);
    }
    window.addEventListener('resize', updateFilterWidth);
    updateFilterWidth();
    return () => window.removeEventListener('resize', updateFilterWidth);
  }, []);

  const handleClickExportCSV = async () => {
    try {
      const data = await getDevicesCSV({
        is_filtered: isSearchPerformed,
        conditions: searchedDeviceConditions,
        os_type: osType
      });
      const blob = new Blob([data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', 'export.csv');
      link.click();
    } catch (e) {
      const localizedErrorString = getLocalizedErrorString(e as Error);
      toaster.add({
        name: 'export-csv-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const columns: Column<DeviceSimpleDto>[] = [
    {
      id: DevicesSortField.DisplayName,
      name: t('inventory.table.heading.name'),
      selector: (row) =>
        row.device_information?.device_name ? row.device_information?.device_name : '',
      sortable: true
    },
    {
      id: DevicesSortField.ModelName,
      name: t('inventory.table.heading.model_name'),
      selector: (row) => (row.model_name ? row.model_name : ''),
      sortable: true
    },
    {
      id: DevicesSortField.OsVersion,
      name: t('inventory.table.heading.os'),
      selector: (row) =>
        row.device_information && row.device_information.os_version
          ? row.device_information.os_version
          : '',
      sortable: true
    },
    {
      id: DevicesSortField.SerialNumber,
      name: t('inventory.table.heading.serial'),
      selector: (row) => (row.serial_number ? row.serial_number : ''),
      sortable: true
    },
    {
      id: DevicesSortField.LastSeen,
      name: t('inventory.table.heading.last_check_in'),
      selector: (row) => formatDateFrom(row.last_seen),
      sortable: true
    }
  ];

  const headerContent = (
    <>
      {devicesCount > 0 && (
        <Button view="action" onClick={handleClickExportCSV}>
          {t('inventory.export_btn')}
        </Button>
      )}
    </>
  );

  const tableContent = (
    <div className={css.FilterButtonGroup}>
      <Button
        ref={filterButtonRef}
        view="outlined"
        onClick={handleClickFilterButton}
        selected={isFilterOpen}
      >
        <Icon data={BarsDescendingAlignCenter} />
      </Button>
    </div>
  );

  return (
    <div id="inventory" className={css.Root}>
      <Routes>
        <Route
          path=""
          element={
            <>
              <Header rightContent={headerContent} />
              <div className={css.Content}>
                <div ref={ref} className={css.Title}>
                  <Text variant="display-2">{t('inventory.title')}</Text>
                </div>
                {getDeviceInfoRequest.loading ? (
                  <DeviceTableSkeleton />
                ) : devicesCount === 0 && searchedDeviceConditions.length == 0 ? (
                  <div className={css.NoDataContainer}>
                    <img alt="no-data" src={noDataImage} />
                    <Text variant="subheader-3">{t('inventory.no_data')}</Text>
                  </div>
                ) : (
                  <Table
                    columns={columns}
                    data={deviceData}
                    onRowClick={handleRowClick}
                    onChangePagination={handlePaginationChange}
                    onChangeSort={handleSortChange}
                    pagination
                    paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
                    paginationState={paginationState}
                    paginationTotalRows={devicesCount}
                    sortState={sortState}
                    leftContent={tableContent}
                  />
                )}
              </div>
              <Popup
                className={isFilterOpen ? undefined : css.Hidden}
                open
                anchorRef={filterButtonRef}
                placement="bottom-start"
                style={{ width: filterWidth }}
                strategy="fixed"
              >
                <FilterPopup
                  conditions={searchedDeviceConditions}
                  onSearch={handleSearch}
                  onCancel={handleCancelClick}
                />
              </Popup>
            </>
          }
        />
        <Route path=":deviceId" element={<InventoryDevicePage />} />
      </Routes>
    </div>
  );
};
