import { FC, FormEvent, useState } from 'react';
import css from './static-group-form.module.scss';
import { Permission } from '../../../../../types/api';
import { DeviceUsersTab } from './components/device-users-tab/device-users-tab.component';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../../contexts/permission.context';
import { StaticGroupFormMode } from '../../static-group-device-users.component';
import { Tabs, TabsItemProps } from '@gravity-ui/uikit';
import { StaticGroupFormValues } from './static-group-form.schema';
import { UseFormReturn } from 'react-hook-form';

export enum StaticGroupPageTabs {
  ASSIGNMENTS = 'Assignments'
}

interface IProps {
  mode: StaticGroupFormMode;
  form: UseFormReturn<StaticGroupFormValues>;
}

export const StaticGroupForm: FC<IProps> = (props: IProps) => {
  const { mode, form } = props;

  const [activeTab, setActiveTab] = useState<string>(StaticGroupPageTabs.ASSIGNMENTS);

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const tabs: TabsItemProps[] = [
    {
      id: StaticGroupPageTabs.ASSIGNMENTS,
      title: t('static_groups.page.device_users_assignments_tab.name')
    }
  ];

  return (
    <form onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditGroups)}>
        <Tabs
          className={css.NavBar}
          items={tabs}
          activeTab={activeTab}
          onSelectTab={handleChangeTab}
        />
        {activeTab === StaticGroupPageTabs.ASSIGNMENTS && (
          <DeviceUsersTab mode={mode} form={form} />
        )}
      </fieldset>
    </form>
  );
};
