import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from './app-store-application-list.module.scss';
import { SearchDeviceApplication } from '../../../../../../../types/api';
import { Column, Table } from '../../../../../../components/table/table.component';
import { Button, Text } from '@gravity-ui/uikit';
import { Navbar, NavbarItemProps } from '../../../../../../components/navbar/navbar.component';

interface IProps {
  apps: SearchDeviceApplication[];
  disabled: boolean;
  onCancel: () => void;
  onPreviousStep: () => void;
  onAddApp: (app: SearchDeviceApplication) => void;
}

enum DeviceType {
  IPHONE = 'iPhone',
  IPAD = 'iPAD'
}

export const AppStoreApplicationList: FC<IProps> = (props: IProps) => {
  const { apps, disabled, onAddApp, onCancel, onPreviousStep } = props;

  const { t } = useTranslation();

  const [deviceType, setDeviceType] = useState<DeviceType>(DeviceType.IPHONE);
  const [filteredApps, setFilteredApps] = useState<SearchDeviceApplication[]>([]);

  const filterApps = (type: DeviceType) => {
    const deviceApps = apps.filter((i) => {
      const arr = i.supportedDevices.filter((e) => e.startsWith(type));
      if (arr.length) return i;
    });
    setFilteredApps(deviceApps);
  };

  const handleSelectDeviceType = (tab: string) => {
    setDeviceType(tab as DeviceType);
  };

  useEffect(() => {
    filterApps(deviceType);
  }, [deviceType]);

  const deviceTypeTabs: NavbarItemProps[] = [
    {
      id: DeviceType.IPHONE,
      title: t('device_apps.installation.search_step.app_list.device_type.iphone')
    },
    {
      id: DeviceType.IPAD,
      title: t('device_apps.installation.search_step.app_list.device_type.ipad')
    }
  ];

  const deviceAppsColumns: Column<SearchDeviceApplication>[] = [
    {
      id: 'logo',
      name: '',
      selector: (row: SearchDeviceApplication) => (
        <img src={row.artworkUrl60} alt="logo" className={css.Logo} />
      )
    },
    {
      id: 'name',
      name: t('device_apps.installation.search_step.app_list.column_name'),
      selector: (row: SearchDeviceApplication) => (
        <Text whiteSpace="break-spaces" wordBreak="break-word">
          {row.trackName}
        </Text>
      ),
      width: 400
    },
    {
      id: 'price',
      name: t('device_apps.installation.search_step.app_list.column_price'),
      selector: (row: SearchDeviceApplication) => row.formattedPrice || row.price
    },
    {
      id: 'seller',
      name: t('device_apps.installation.search_step.app_list.column_company'),
      selector: (row: SearchDeviceApplication) => (
        <Text whiteSpace="break-spaces" wordBreak="break-all">
          {row.sellerName}
        </Text>
      )
    },
    {
      id: 'view',
      name: '',
      selector: (row: SearchDeviceApplication) => (
        <Button
          view="outlined-info"
          onClick={() => window.open(row.trackViewUrl)}
          disabled={disabled}
        >
          {t('device_apps.installation.search_step.app_list.column_track_view_url')}
        </Button>
      ),
      align: 'right',
      width: 0
    },
    {
      id: 'add',
      name: '',
      selector: (row: SearchDeviceApplication) => (
        <Button view="outlined-success" onClick={() => onAddApp(row)} disabled={disabled}>
          {t('device_apps.installation.search_step.app_list.column_add')}
        </Button>
      ),
      align: 'right',
      width: 0
    }
  ];

  return (
    <div className={css.Root}>
      <div className={css.Content}>
        <Navbar
          activeTab={deviceType}
          onSelectTab={handleSelectDeviceType}
          items={deviceTypeTabs}
        />
        <Table columns={deviceAppsColumns} data={filteredApps} />
      </div>
      <div className={css.ActionButton}>
        <Button view="action" onClick={onCancel} disabled={disabled}>
          {t('device_apps.installation.buttons.cancel')}
        </Button>
        <Button view="action" onClick={onPreviousStep} disabled={disabled}>
          {t('device_apps.installation.buttons.previous')}
        </Button>
      </div>
    </div>
  );
};
