import { FC } from 'react';
import {
  DeviceFullDto,
  DeviceProvisioningProfileSimpleDto
} from '../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../../../../../utils/time.utils';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import css from './provisioning-profiles-table-mobile.module.scss';

interface IProps {
  device?: DeviceFullDto;
}

export const ProvisioningProfilesTable: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const columns: Column<DeviceProvisioningProfileSimpleDto>[] = [
    {
      id: 'name',
      name: t(
        'inventory_mobile.device_page.inventory_tab.provisioning_profiles_tab.table.heading.name'
      ),
      selector: (profile) => profile.name
    },
    {
      id: 'uuid',
      name: t(
        'inventory_mobile.device_page.inventory_tab.provisioning_profiles_tab.table.heading.uuid'
      ),
      selector: (profile) => profile.uuid
    },
    {
      id: 'expiry_date',
      name: t(
        'inventory_mobile.device_page.inventory_tab.provisioning_profiles_tab.table.heading.expiry_date'
      ),
      selector: (profile) => (profile.expiry_date ? formatDate(profile.expiry_date) : undefined)
    }
  ];

  return (
    <>
      <Table columns={columns} data={device?.provisioning_profiles || []} className={css.Table} />
    </>
  );
};
