import { FC } from 'react';
import css from './os-info-table-mobile.module.scss';
import { DeviceFullDto } from '../../../../../../../../../types/api';
import { isBoolean } from '../../../../../../../../../utils/assert.utils';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../../../../../utils/time.utils';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import { Text } from '@gravity-ui/uikit';

interface IProps {
  device?: DeviceFullDto;
}

interface IKeyValue {
  key: string;
  value: string | null | undefined;
}

export const OsInfoTableMobile: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const di = device?.device_information;

  const data: IKeyValue[] = [
    {
      key: t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.os_version'),
      value: di?.os_version
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.rapid_security_response'
      ),
      value: di?.os_rapid_security_response
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.os_build'),
      value: di?.os_build_version
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.supplemental_build_version'
      ),
      value: di?.supplemental_build_version
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.icloud_backup'),
      value: isBoolean(di?.is_cloud_backup_enabled)
        ? di?.is_cloud_backup_enabled
          ? t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.enabled_f')
          : t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.disabled_f')
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.last_icloud_backup'
      ),
      value: di?.last_cloud_backup_date ? formatDate(di?.last_cloud_backup_date) : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.os_info_tab.table.heading.is_do_not_disturb_in_effect'
      ),
      value: isBoolean(di?.is_do_not_disturb_in_effect)
        ? di?.is_do_not_disturb_in_effect
          ? t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.enabled_m')
          : t('inventory_mobile.device_page.inventory_tab.os_info_tab.table.disabled_m')
        : undefined
    }
  ];

  const columns: Column<IKeyValue>[] = [
    {
      id: 'key',
      selector: (row) => <Text variant="subheader-1">{row.key}</Text>,
      width: 250
    },
    {
      id: 'value',
      selector: (row) => row.value
    }
  ];

  return (
    <>
      <Table columns={columns} data={data} className={css.Table} />
    </>
  );
};
