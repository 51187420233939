import { FC, FormEvent, useEffect } from 'react';
import css from './device-user-form.module.scss';
import { useTranslation } from 'react-i18next';
import { deviceUserFormSchema, DeviceUserFormValues } from './device-user-form.schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { usePermission } from '../../../../../../../../../../contexts/permission.context';
import { Permission } from '../../../../../../../../../../../types/api';
import { Button, Text, TextInput } from '@gravity-ui/uikit';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  values?: DeviceUserFormValues;
  hideActions?: boolean;
  onCreate?: (values: DeviceUserFormValues) => void;
  onCreateCancel?: () => void;
  onUnlink?: () => void;
}

export const DeviceUsersForm: FC<IProps> = (props) => {
  const { mode, values, onCreate, onUnlink, onCreateCancel } = props;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const deviceUserForm = useForm<DeviceUserFormValues>({
    mode: 'onChange',
    resolver: yupResolver(deviceUserFormSchema),
    defaultValues: values || deviceUserFormSchema.getDefault()
  });

  useEffect(() => {
    deviceUserForm.reset(values);
  }, [values]);

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleCreate = async () => {
    const isValid = await deviceUserForm.trigger();
    if (!isValid) return;

    onCreate?.(deviceUserForm.getValues());
  };

  const handleUnlink = () => onUnlink?.();
  const handleCreateCancel = async () => {
    deviceUserForm.reset();
    onCreateCancel?.();
  };

  return (
    <form className={css.Root} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditDeviceUsers)}>
        <Text variant="header-1">
          {mode === 'create' ? t('device_users.user_form.new_title') : values?.username || '—'}
        </Text>
        <div className={css.Content}>
          <div>
            <Text variant="subheader-1">{t('device_users.user_form.column_username')}</Text>
            <TextInput
              type="text"
              error={deviceUserForm.formState.errors.username?.message}
              disabled={mode === 'view'}
              {...deviceUserForm.register('username')}
            />
          </div>
          <div>
            <Text variant="subheader-1">{t('device_users.user_form.column_email')}</Text>
            <TextInput
              type="email"
              error={deviceUserForm.formState.errors.email?.message}
              disabled={mode === 'view'}
              {...deviceUserForm.register('email')}
            />
          </div>
          <div>
            <Text variant="subheader-1">{t('device_users.user_form.column_employee_name')}</Text>
            <TextInput
              type="text"
              error={deviceUserForm.formState.errors.employee_name?.message}
              disabled={mode === 'view'}
              {...deviceUserForm.register('employee_name')}
            />
          </div>
          <div>
            <Text variant="subheader-1">{t('device_users.user_form.column_phone_number')}</Text>
            <TextInput
              type="tel"
              error={deviceUserForm.formState.errors.phone_number?.message}
              disabled={mode === 'view'}
              {...deviceUserForm.register('phone_number')}
            />
          </div>
          <div>
            <Text variant="subheader-1">{t('device_users.user_form.column_position')}</Text>
            <TextInput
              type="text"
              error={deviceUserForm.formState.errors.position?.message}
              disabled={mode === 'view'}
              {...deviceUserForm.register('position')}
            />
          </div>
        </div>
        <div className={css.ActionButtons}>
          {mode === 'view' && (
            <Button view="action" onClick={handleUnlink}>
              {t('inventory.device_page.inventory_tab.device_user_tab.buttons.unlink')}
            </Button>
          )}
          {mode === 'create' && (
            <>
              <Button view="flat" onClick={handleCreateCancel}>
                {t('inventory.device_page.inventory_tab.device_user_tab.buttons.cancel')}
              </Button>
              <Button view="action" onClick={handleCreate}>
                {t('inventory.device_page.inventory_tab.device_user_tab.buttons.create')}
              </Button>
            </>
          )}
        </div>
      </fieldset>
    </form>
  );
};
