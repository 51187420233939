import { object, string } from 'yup';
import i18next from '../../../../i18n/i18n';
import { notEmpty } from '../../../../utils/yup.validations';

export interface PackageForm {
  display_name: string;
  filename: string;
}

export const packageFormSchema = object({
  display_name: string()
    .required(i18next.t('settings.tiles.packages.page.package_page.error.no_title'))
    .test(notEmpty(i18next.t('settings.tiles.packages.page.package_page.error.no_title'))),
  filename: string()
    .required(i18next.t('settings.tiles.packages.page.package_page.error.filename_is_required'))
    .matches(
      /.+\.pkg$/,
      i18next.t('settings.tiles.packages.page.package_page.error.wrong_filename')
    )
}).required();
