import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DeviceApplicationFormValues } from '../../../../device-application.schema';
import { useTranslation } from 'react-i18next';
import { Checkbox, Text, TextInput } from '@gravity-ui/uikit';
import css from './general-tab.module.scss';

interface IProps {
  disabled: boolean;
}

export const GeneralTab: FC<IProps> = (props) => {
  const { disabled } = props;

  const appForm = useFormContext<DeviceApplicationFormValues>();
  const { t } = useTranslation();

  return (
    <div className={css.Root}>
      <div className={css.FormItem}>
        <Text variant="subheader-2">{t('device_apps.app_form.display_name')}</Text>
        <Text variant="caption-2" color="secondary">
          {t('device_apps.app_form.description.display_name')}
        </Text>
        <TextInput
          error={appForm.formState.errors.display_name?.message}
          disabled={disabled}
          {...appForm.register('display_name')}
        />
      </div>
      <div className={css.FormItem}>
        <Text variant="subheader-2">{t('device_apps.app_form.short_version')}</Text>
        <Text variant="caption-2" color="secondary">
          {t('device_apps.app_form.description.short_version')}
        </Text>
        <TextInput
          {...appForm.register('version')}
          error={appForm.formState.errors.version?.message}
          disabled={disabled}
        />
      </div>
      <div className={css.FormItem}>
        <Text variant="subheader-2">{t('device_apps.app_form.bundle_id')}</Text>
        <Text variant="caption-2" color="secondary">
          {t('device_apps.app_form.description.bundle_id')}
        </Text>
        <TextInput
          {...appForm.register('bundle_id')}
          error={appForm.formState.errors.bundle_id?.message}
          disabled={disabled}
        />
      </div>
      <Checkbox
        className={css.Checkbox}
        disabled
        checked
        content={<Text>{t('device_apps.app_form.app_is_free')}</Text>}
      />
      <div className={css.FormItem}>
        <Text variant="subheader-2">{t('device_apps.app_form.app_url')}</Text>
        <Text variant="caption-2" color="secondary">
          {t('device_apps.app_form.description.app_url_template')}
        </Text>
        <TextInput
          {...appForm.register('app_view_url')}
          error={appForm.formState.errors.app_view_url?.message}
          disabled={disabled}
        />
      </div>
      <Text variant="body-2">{t('device_apps.app_form.install_automatically')}</Text>
    </div>
  );
};
