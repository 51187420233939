import { createRequest } from './create-request';
import {
  CreatePackageDto,
  CreatePackagePointDto,
  GetPackagePointsResponseDto,
  GetPackagesResponseDto,
  PackagePointFullDto,
  PackageSimpleDto,
  UpdatePackageDto,
  UpdatePackagePointDto
} from '../types/api';
import { UUIDString } from '../types/common';

export async function getPackages(): Promise<GetPackagesResponseDto> {
  return createRequest({ url: 'packages' });
}

export async function getPackage(id: UUIDString): Promise<PackageSimpleDto> {
  return createRequest({ url: `packages/${id}` });
}

export async function createPackage(data: CreatePackageDto): Promise<PackageSimpleDto> {
  return createRequest({ url: `packages`, method: 'POST', data });
}

export async function updatePackage(
  id: UUIDString,
  data: UpdatePackageDto
): Promise<PackageSimpleDto> {
  return createRequest({ url: `packages/${id}`, method: 'PATCH', data });
}

export async function deletePackage(id: UUIDString): Promise<PackageSimpleDto> {
  return createRequest({ url: `packages/${id}`, method: 'DELETE' });
}

export async function getPackagePoints(): Promise<GetPackagePointsResponseDto> {
  return createRequest({ url: 'package-points' });
}

export async function getPackagePoint(id: UUIDString): Promise<PackagePointFullDto> {
  return createRequest({ url: `package-points/${id}` });
}

export async function createPackagePoint(
  data: CreatePackagePointDto
): Promise<PackagePointFullDto> {
  return createRequest({ url: `package-points`, method: 'POST', data });
}

export async function updatePackagePoint(
  id: UUIDString,
  data: UpdatePackagePointDto
): Promise<PackagePointFullDto> {
  return createRequest({ url: `package-points/${id}`, method: 'PATCH', data });
}

export async function deletePackagePoint(id: UUIDString): Promise<PackagePointFullDto> {
  return createRequest({ url: `package-points/${id}`, method: 'DELETE' });
}
