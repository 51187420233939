import { array, boolean, mixed, object, string } from 'yup';
import {
  CreateSmartGroupDeviceUsersConditionRequestDto,
  DeviceUserCriteria,
  DeviceUserSimpleDto
} from '../../../../../types/api';
import i18next from '../../../../../i18n/i18n';
import { notEmpty } from '../../../../../utils/yup.validations';

export interface SmartGroupDeviceUserGeneralForm {
  display_name: string;
}
export type SmartGroupCriteriaValues = CreateSmartGroupDeviceUsersConditionRequestDto[];
export type SmartGroupReportsValues = DeviceUserSimpleDto[];

export interface SmartGroupDeviceUsersFormValues {
  group: SmartGroupDeviceUserGeneralForm;
  criteria?: SmartGroupCriteriaValues;
  reports?: SmartGroupReportsValues;
}

export const smartGroupDeviceUsersGeneralFormSchema = object({
  display_name: string()
    .required(i18next.t('smart_groups.page.group_information_tab.errors.no_display_name'))
    .test(notEmpty(i18next.t('smart_groups.page.group_information_tab.errors.no_display_name')))
}).required();

export const smartGroupDeviceUsersFormSchema = object({
  group: smartGroupDeviceUsersGeneralFormSchema,
  criteria: array().of(
    object({
      start_bracket: boolean().required().default(false),
      end_bracket: boolean().required().default(false),
      criteria: mixed<DeviceUserCriteria>().required(
        i18next.t('smart_groups.page.criteria_tab.new_criteria.errors.no_criteria')
      ),
      operator: mixed<'=' | '!=' | 'like' | 'not like' | 'member of' | 'not member of'>()
        .required('Operator is required!')
        .oneOf(['=', '!=', 'like', 'not like', 'member of', 'not member of']),
      value: string().required(
        i18next.t('smart_groups.page.criteria_tab.new_criteria.errors.value_required')
      ),
      conjunctive: mixed<'and' | 'or' | null>().oneOf(['and', 'or', null])
    })
  )
});
