import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import css from './extension-attribute-list.module.scss';
import { usePermission } from '../../../contexts/permission.context';
import {
  ExtensionAttributeFullDto,
  GetExtensionAttributesResponseDto,
  Permission
} from '../../../../types/api';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { getExtensionAttributes } from '../../../../api/extension-attributes';
import { Column, Table } from '../../../components/table/table.component';
import { Button, Text, useToaster } from '@gravity-ui/uikit';
import { Header } from '../../../components/header/header.component';
import useRequest from '../../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';
import noDataImage from '../../../../assets/images/no-data.png';

export const ExtensionAttributeList: FC = () => {
  const navigate = useNavigate();
  const toaster = useToaster();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const fetchRequest = useRequest<GetExtensionAttributesResponseDto>();

  const [extensionAttributes, setExtensionAttributes] = useState<ExtensionAttributeFullDto[]>([]);
  const [extensionAttributeCount, setExtensionAttributeCount] = useState<number>(0);

  const extensionAttributeColumns: Column<ExtensionAttributeFullDto>[] = [
    {
      id: 'display_name',
      name: t('settings.tiles.extension_attributes.page.table.heading.display_name'),
      selector: (row: ExtensionAttributeFullDto) => row.display_name
    }
  ];

  const handleRowClick = (row: ExtensionAttributeFullDto) => {
    navigate(row.id);
  };

  const handleClickNew = () => {
    navigate('new');
  };

  const init = async () => {
    try {
      const response = await fetchRequest.send(getExtensionAttributes(), 1000);
      setExtensionAttributes(response.extension_attributes);
      setExtensionAttributeCount(response.count);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'fetch-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  useEffect(() => {
    void init();
  }, []);

  const headerRightContent = (
    <Button view="action" onClick={handleClickNew} disabled={!isAllowedTo(Permission.EditDevices)}>
      {t('settings.tiles.extension_attributes.page.new_btn')}
    </Button>
  );

  const tableLeftContent = (
    <Text variant="display-2">{t('settings.tiles.extension_attributes.page.title')}</Text>
  );

  return (
    <div className={css.Root}>
      <Header rightContent={headerRightContent} />
      <div className={css.DataTableContainer}>
        {fetchRequest.loading ? (
          <>
            <div className={css.Title}>{tableLeftContent}</div>
            <TableSkeleton />
          </>
        ) : extensionAttributeCount === 0 ? (
          <>
            <div className={css.Title}>{tableLeftContent}</div>
            <div className={css.NoDataContainer}>
              <img alt="no-data" src={noDataImage} />
              <Text variant="subheader-3">
                {t('settings.tiles.extension_attributes.page.no_data')}
              </Text>
              {headerRightContent}
            </div>
          </>
        ) : (
          <Table
            columns={extensionAttributeColumns}
            data={extensionAttributes}
            onRowClick={handleRowClick}
            leftContent={tableLeftContent}
          />
        )}
      </div>
    </div>
  );
};
