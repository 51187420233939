import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import css from './action-menu.module.scss';
import { Permission } from '../../../../../types/api';
import { t } from 'i18next';
import { usePermission } from '../../../../contexts/permission.context';

interface IProps {
  mode: 'create' | 'edit' | 'view';
  disabled: boolean;
  onPolicyCreate: () => void;
  onPolicyCreateCancel: () => void;
  onPolicyEditStart: () => void;
  onPolicyEdit: () => void;
  onPolicyEditCancel: () => void;
  onPolicyDelete: () => void;
}

export const ActionMenu: FC<IProps> = (props) => {
  const {
    mode,
    disabled,
    onPolicyCreate,
    onPolicyCreateCancel,
    onPolicyEditStart,
    onPolicyEdit,
    onPolicyEditCancel,
    onPolicyDelete
  } = props;
  const { isAllowedTo } = usePermission();

  return (
    <div className={css.ButtonGroup}>
      {mode === 'create' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onPolicyCreateCancel}>
            {t('policies.policy_form.button_cancel')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onPolicyCreate}>
            {t('policies.policy_form.button_create_policy')}
          </Button>
        </>
      )}
      {mode === 'edit' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onPolicyEditCancel}>
            {t('policies.policy_form.button_cancel')}
          </Button>
          <Button
            view="action"
            onClick={onPolicyEdit}
            disabled={disabled || !isAllowedTo(Permission.EditPolicies)}
          >
            {t('policies.policy_form.button_edit_policy_confirm')}
          </Button>
        </>
      )}
      {mode === 'view' && (
        <>
          <Button
            view="flat"
            onClick={onPolicyDelete}
            disabled={disabled || !isAllowedTo(Permission.EditPolicies)}
          >
            {t('policies.policy_form.button_delete_policy')}
          </Button>
          <Button
            view="action"
            onClick={onPolicyEditStart}
            disabled={disabled || !isAllowedTo(Permission.EditPolicies)}
          >
            {t('policies.policy_form.button_edit_policy')}
          </Button>
        </>
      )}
    </div>
  );
};
