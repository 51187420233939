import { FC } from 'react';
import css from './settings-last-license-list.module.scss';
import { useTranslation } from 'react-i18next';
import { LicenseDto } from '../../../../../../types/api';
import { formatDate } from '../../../../../../utils/time.utils';
import { Column, Table } from '../../../../../components/table/table.component';
import { Text } from '@gravity-ui/uikit';

const dateSort = (dateA: string | number | Date, dateB: string | number | Date) => {
  const timestampA = new Date(dateA).getTime();
  const timestampB = new Date(dateB).getTime();
  return timestampA - timestampB;
};

interface IProps {
  allLicenses: LicenseDto[];
}

export const LicenseListComponent: FC<IProps> = (props: IProps) => {
  const { allLicenses } = props;
  const { t } = useTranslation();

  const columns: Column<LicenseDto>[] = [
    {
      id: 'activation_date',
      name: t('settings.tiles.license.table.activation_date'),
      selector: (row) =>
        row.activated_at ? formatDate(row.activated_at, 'dd-MM-yyyy') : undefined,
      sortable: (a, b) =>
        a.activated_at && b.activated_at ? dateSort(a.activated_at, b.activated_at) : 0
    },
    {
      id: 'expiration_date',
      name: t('settings.tiles.license.table.expiration_date'),
      selector: (row) => (row.expires_at ? formatDate(row.expires_at, 'dd-MM-yyyy') : undefined),
      sortable: (a, b) => (a.expires_at && b.expires_at ? dateSort(a.expires_at, b.expires_at) : 0)
    },
    {
      id: 'device_num',
      name: t('settings.tiles.license.table.device_num'),
      selector: (row) => (row.max_device ? row.max_device : 0),
      sortable: (a, b) => (a.max_device || 0) - (b.max_device || 0)
    }
  ];

  return (
    <div className={css.LicensesListContainer}>
      <Table
        columns={columns}
        data={allLicenses}
        leftContent={<Text variant="header-1">{t('settings.tiles.license.table.title')}</Text>}
      />
    </div>
  );
};
