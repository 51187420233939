import {
  ConfigProfileFullDto,
  ConfigProfileSimpleDto,
  CreateConfigProfileScopeRequestDto,
  ScopeEntryOperationType,
  ScopeOptionType
} from '../../../types/api';
import { ScopeValues } from '../../components/scope-form/scope-form.component';
import { ConfigurationProfileUploadFormValues } from './components/configuration-profile-form/configuration-profile-form.schema';

export function formScopeEntries(scope?: ScopeValues): {
  scope_entries: CreateConfigProfileScopeRequestDto[];
} {
  const scopeEntriesInclude: CreateConfigProfileScopeRequestDto[] =
    scope?.scopeEntriesInclude.map((entry) => ({
      id: entry.id,
      type: entry.type,
      operation: ScopeEntryOperationType.Include
    })) || [];
  const scopeEntriesExclude: CreateConfigProfileScopeRequestDto[] =
    scope?.scopeEntriesExclude.map((entry) => ({
      id: entry.id,
      type: entry.type,
      operation: ScopeEntryOperationType.Exclude
    })) || [];
  const scopeEntries = [...(scope?.scoped ? scopeEntriesInclude : []), ...scopeEntriesExclude];
  return {
    scope_entries: scopeEntries
  };
}

export const configurationProfileToForm = (
  input: ConfigProfileFullDto
): ConfigurationProfileUploadFormValues => {
  return {
    display_name: input.display_name,
    ...(input?.description && { description: input?.description }),
    ...(input?.organization_name && { organization_name: input?.organization_name }),
    ...(input?.payload && { payload: input?.payload }),
    ...(input?.imported_payload && { imported_payload: atob(input?.imported_payload) }),
    scope: {
      scoped: input.scoped,
      scopeEntriesExclude:
        input.scope_entries
          ?.filter((i) => i.operation_type === 'Exclude')
          .map((i) => ({
            id: i.target_id,
            display_name: i.target_title || '',
            type: i.target_type as ScopeOptionType
          })) || [],
      scopeEntriesInclude:
        input.scope_entries
          ?.filter((i) => i.operation_type === 'Include')
          .map((i) => ({
            id: i.target_id,
            display_name: i.target_title || '',
            type: i.target_type as ScopeOptionType
          })) || []
    }
  };
};

export const configurationProfileSimpleToForm = (
  input: ConfigProfileSimpleDto,
  configProfile?: ConfigProfileFullDto
): ConfigurationProfileUploadFormValues => {
  return {
    display_name: input.display_name,
    ...(input?.description && { description: input?.description }),
    ...(input?.organization_name && { organization_name: input?.organization_name }),
    ...(configProfile?.payload && { payload: configProfile.payload }),
    ...(configProfile?.imported_payload && {
      imported_payload: atob(configProfile.imported_payload)
    }),
    scope: {
      scoped: input.scoped,
      scopeEntriesExclude:
        input.scope_entries
          ?.filter((i) => i.operation_type === 'Exclude')
          .map((i) => ({
            id: i.target_id,
            display_name: i.target_title || '',
            type: i.target_type as ScopeOptionType
          })) || [],
      scopeEntriesInclude:
        input.scope_entries
          ?.filter((i) => i.operation_type === 'Include')
          .map((i) => ({
            id: i.target_id,
            display_name: i.target_title || '',
            type: i.target_type as ScopeOptionType
          })) || []
    }
  };
};

export function partialUpdateConfigurationProfile(
  simpleDto: ConfigProfileSimpleDto,
  fullDto?: ConfigProfileFullDto
): ConfigProfileFullDto | undefined {
  if (!fullDto) return undefined;
  return {
    ...fullDto,
    id: simpleDto.id,
    created_at: simpleDto.created_at,
    updated_at: simpleDto.updated_at,
    profile_id: simpleDto.profile_id,
    display_name: simpleDto.display_name,
    organization_name: simpleDto.organization_name,
    description: simpleDto.description,
    scoped: simpleDto.scoped,
    imported: simpleDto.imported,
    remove_pending: simpleDto.remove_pending,
    scope_entries: simpleDto.scope_entries,
    completed_profile_instances_count: simpleDto.completed_profile_instances_count,
    pending_profile_instances_count: simpleDto.pending_profile_instances_count,
    failed_profile_instances_count: simpleDto.failed_profile_instances_count
  };
}
