import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import css from './device-application-list.module.scss';
import { usePermission } from '../../contexts/permission.context';
import {
  DeviceApplicationDto,
  DeviceApplicationSortField,
  GetDeviceApplicationListResponseDto,
  PaginationSortOrder,
  Permission
} from '../../../types/api';
import { getDeviceApplicationList } from '../../../api/device-applications';
import { useDebounce } from 'use-debounce';
import { Column, SortState, Table } from '../../components/table/table.component';
import { Header } from '../../components/header/header.component';
import { Button, Text, TextInput } from '@gravity-ui/uikit';
import noDataImage from '../../../assets/images/no-data.png';
import useRequest from '../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';

export const DeviceApplicationList: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const fetchRequest = useRequest<GetDeviceApplicationListResponseDto>();

  const [deviceApps, setDeviceApps] = useState<DeviceApplicationDto[]>([]);
  const [deviceAppsCount, setDeviceAppsCount] = useState(0);
  const [sortState, setSortState] = useState<SortState>();
  const [filterText, setFilterText] = useState<string>('');
  const [debouncedFilterText] = useDebounce(filterText, 1000);

  const updateData = async () => {
    const response = await fetchRequest.send(
      getDeviceApplicationList({
        ...(filterText && { search: filterText }),
        sort_order: sortState?.order as PaginationSortOrder,
        sort_field: sortState?.column as DeviceApplicationSortField
      })
    );
    setDeviceApps(response.apps);
    setDeviceAppsCount(response.apps.length);
  };

  const handleClickRow = (row: DeviceApplicationDto) => {
    navigate(row.id);
  };

  const handleSortChange = async (state: SortState | undefined) => {
    setSortState(state);
  };

  const handleClearFilter = () => {
    if (filterText) setFilterText('');
  };

  const handleNewClick = () => {
    navigate('new');
  };

  const columns: Column<DeviceApplicationDto>[] = [
    {
      id: DeviceApplicationSortField.DisplayName,
      name: t('device_apps.app_list.column_name'),
      selector: (row: DeviceApplicationDto) => row.display_name,
      sortable: true
    },
    {
      id: DeviceApplicationSortField.Version,
      name: t('device_apps.app_list.column_version'),
      selector: (row: DeviceApplicationDto) => row.version,
      sortable: true
    },
    {
      id: DeviceApplicationSortField.Source,
      name: t('device_apps.app_list.column_source'),
      selector: (row: DeviceApplicationDto) => row.source,
      sortable: true
    },
    {
      id: 'scope_count',
      name: t('device_apps.app_list.column_scoped_devices_count'),
      selector: (row: DeviceApplicationDto) => row.scoped_devices_count
    }
  ];

  useEffect(() => {
    void updateData();
  }, [debouncedFilterText, sortState]);

  const headerContent = (
    <Button
      view="action"
      onClick={handleNewClick}
      disabled={!isAllowedTo(Permission.EditDeviceApplications)}
    >
      <Text>{t('device_apps.app_list.new_btn')}</Text>
    </Button>
  );

  const tableContent = (
    <div className={css.Filter}>
      <TextInput
        className={css.FilterInput}
        type="text"
        value={filterText}
        onUpdate={setFilterText}
        placeholder={t('device_apps.app_list.filter_placeholder')}
      />
      <Button view="action" onClick={handleClearFilter}>
        {t('device_apps.app_list.filter_clear_btn')}
      </Button>
    </div>
  );

  return (
    <>
      <Header rightContent={headerContent} />
      <div className={css.Content}>
        <div className={css.Title}>
          <Text variant="display-2">{t('device_apps.app_list.title')}</Text>
        </div>
        {fetchRequest.loading ? (
          <TableSkeleton />
        ) : deviceAppsCount === 0 && debouncedFilterText === '' ? (
          <div className={css.NoDataContainer}>
            <img alt="no-data" src={noDataImage} />
            <Text variant="subheader-3">{t('device_apps.app_list.no_data')}</Text>
            {headerContent}
          </div>
        ) : (
          <Table
            columns={columns}
            data={deviceApps}
            onRowClick={handleClickRow}
            onChangeSort={handleSortChange}
            sortState={sortState}
            leftContent={tableContent}
          />
        )}
      </div>
    </>
  );
};
