import { FC, ReactNode } from 'react';
import { WithChildren } from '../../../types/common';
import { Permission } from '../../../types/api';
import { usePermission } from '../../contexts/permission.context';
import { Spin } from '@gravity-ui/uikit';

type IProps = {
  to: Permission[];
  fallback?: ReactNode;
};

export const Restricted: FC<IProps & WithChildren> = (props) => {
  const { to, children, fallback } = props;

  const { loading, isAllowedTo } = usePermission();

  if (loading) {
    return <Spin />;
  }

  if (isAllowedTo(...to)) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};
