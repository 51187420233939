import css from './settings-script-list.module.scss';
import { FC, useEffect, useState } from 'react';
import { GetScriptsResponseDto, Permission, ScriptBasicDto } from '../../../../types/api';
import { getScripts } from '../../../../api/scripts';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../contexts/permission.context';
import { Header } from '../../../components/header/header.component';
import { Button, Text } from '@gravity-ui/uikit';
import { useNavigate } from 'react-router-dom';
import { Column, Table } from '../../../components/table/table.component';
import useRequest from '../../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';
import noDataImage from '../../../../assets/images/no-data.png';

export const ScriptList: FC = () => {
  const [scripts, setScripts] = useState<ScriptBasicDto[]>();
  const [scriptCount, setScriptCount] = useState<number>(0);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const navigate = useNavigate();
  const fetchRequest = useRequest<GetScriptsResponseDto>();

  const handleNewButtonClick = () => {
    navigate('new');
  };

  const handleRowClick = (script: ScriptBasicDto) => {
    navigate(script.id);
  };

  const init = async () => {
    const response = await fetchRequest.send(getScripts());
    setScripts(response.scripts);
    setScriptCount(response.count);
  };

  useEffect(() => {
    void init();
  }, []);

  const columns: Column<ScriptBasicDto>[] = [
    {
      id: 'display_name',
      name: t('settings.tiles.scripts.page.table.heading.name'),
      selector: (script) => script.display_name
    }
  ];

  const headerContent = (
    <Button
      disabled={!isAllowedTo(Permission.EditScripts)}
      view="action"
      onClick={handleNewButtonClick}
    >
      {t('settings.tiles.scripts.page.new_btn')}
    </Button>
  );

  return (
    <div className={css.Root}>
      <Header rightContent={headerContent} />
      <div className={css.DataTableContainer}>
        {fetchRequest.loading ? (
          <>
            <div className={css.Title}>
              <Text variant="display-2">{t('settings.tiles.scripts.page.title')}</Text>
            </div>
            <TableSkeleton />
          </>
        ) : scriptCount === 0 ? (
          <>
            <div className={css.Title}>
              <Text variant="display-2">{t('settings.tiles.scripts.page.title')}</Text>
            </div>
            <div className={css.NoDataContainer}>
              <img alt="no-data" src={noDataImage} />
              <Text variant="subheader-3">{t('settings.tiles.scripts.page.no_data')}</Text>
              {headerContent}
            </div>
          </>
        ) : (
          <Table
            columns={columns}
            data={scripts || []}
            onRowClick={handleRowClick}
            leftContent={<Text variant="display-2">{t('settings.tiles.scripts.page.title')}</Text>}
          />
        )}
      </div>
    </div>
  );
};
