import { FC, useEffect, useState } from 'react';
import css from './settings-package-point-list.module.scss';
import { useNavigate } from 'react-router-dom';
import {
  GetPackagePointsResponseDto,
  PackagePointSimpleDto,
  Permission
} from '../../../../types/api';
import { getPackagePoints } from '../../../../api/packages';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../contexts/permission.context';
import { Column, Table } from '../../../components/table/table.component';
import { Header } from '../../../components/header/header.component';
import { Button, Text } from '@gravity-ui/uikit';
import useRequest from '../../../../hooks/useRequest';
import noDataImage from '../../../../assets/images/no-data.png';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';

export const SettingsPackagePointList: FC = () => {
  const [packagePoints, setPackagePoints] = useState<PackagePointSimpleDto[]>();
  const [packagePointCount, setPackagePointCount] = useState<number>(0);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const navigate = useNavigate();
  const fetchRequest = useRequest<GetPackagePointsResponseDto>();

  const handleClickCreate = () => {
    navigate('new');
  };

  const handleRowClick = (packagePoint: PackagePointSimpleDto) => {
    navigate(packagePoint.id);
  };

  useEffect(() => {
    const fetch = async () => {
      const result = await fetchRequest.send(getPackagePoints(), 1000);
      setPackagePoints(result.package_points);
      setPackagePointCount(result.count);
    };
    void fetch();
  }, []);

  const columns: Column<PackagePointSimpleDto>[] = [
    {
      id: 'display_name',
      name: t('settings.tiles.package_distribution_points.page.table.heading.title'),
      selector: (row) => row.display_name
    }
  ];

  const headerContent = (
    <Button
      disabled={fetchRequest.loading || !isAllowedTo(Permission.EditPackages)}
      view="action"
      onClick={handleClickCreate}
    >
      {t('settings.tiles.package_distribution_points.page.new_btn')}
    </Button>
  );

  return (
    <div className={css.Root}>
      <Header rightContent={headerContent} />
      <div className={css.DataTableContainer}>
        {fetchRequest.loading ? (
          <>
            <div className={css.Title}>
              <Text variant="display-2">
                {t('settings.tiles.package_distribution_points.page.title')}
              </Text>
            </div>
            <TableSkeleton />
          </>
        ) : packagePointCount === 0 ? (
          <>
            <div className={css.Title}>
              <Text variant="display-2">
                {t('settings.tiles.package_distribution_points.page.title')}
              </Text>
            </div>
            <div className={css.NoDataContainer}>
              <img alt="no-data" src={noDataImage} />
              <Text variant="subheader-3">
                {t('settings.tiles.package_distribution_points.page.no_data')}
              </Text>
              {headerContent}
            </div>
          </>
        ) : (
          <Table
            columns={columns}
            data={packagePoints || []}
            onRowClick={handleRowClick}
            leftContent={
              <Text variant="display-2">
                {t('settings.tiles.package_distribution_points.page.title')}
              </Text>
            }
          />
        )}
      </div>
    </div>
  );
};
