import { KeyboardEvent, ChangeEvent, FC, useEffect, useState, createRef } from 'react';
import css from './terminal-tab.module.scss';
import { DeviceFullDto } from '../../../../../../../types/api';
import { getAgentStatus, sendCommand } from '../../../../../../../api/agent';
import { useTranslation } from 'react-i18next';
import useInterval from '../../../../../../../hooks/useInterval';
import useRequest from '../../../../../../../hooks/useRequest';
import { Spin } from '@gravity-ui/uikit';
import { Check } from '@gravity-ui/icons';

interface IProps {
  device?: DeviceFullDto;
}

export const TerminalTab: FC<IProps> = (props) => {
  const { device } = props;
  const inputRef = createRef<HTMLInputElement>();
  const [input, setInput] = useState<string>('');
  const [inputDisabled, setInputDisabled] = useState<boolean>(false);
  const [output, setOutput] = useState<string>('');
  const [deviceOnline, setDeviceOnline] = useState<boolean>(false);
  const commandRequest = useRequest<string[]>();
  const { t } = useTranslation();

  const setFocusToInput = () => {
    inputRef.current?.scrollIntoView();
    inputRef.current?.focus();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!inputDisabled) {
      setInput(e.target.value);
    }
  };

  const executeCommandOnDevice = async (command: string): Promise<string[]> => {
    if (!device) return [t('inventory.device_page.terminal_tab.device_offline_reconnect')];
    try {
      const response = await sendCommand(device.udid, { input: command });
      return response.output;
    } catch (error) {
      console.log(String(error));
      return [String(error)];
    }
  };

  const handleInputKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (commandRequest.loading) return;
    if (e.key === 'Enter') {
      setInputDisabled(true);

      let commandResponse: string[] = [];
      if (input !== '') {
        commandResponse = await commandRequest.send(executeCommandOnDevice(input), 1000);
      }
      let newOutput: string;
      newOutput = output === '' ? `$ ${input}` : `${output} \n  $ ${input}`;
      const responseString = commandResponse.join('\n');
      if (responseString !== '') {
        newOutput += '\n';
      }
      newOutput += commandResponse.join('\n');
      setOutput(newOutput);
      setInput('');

      setInputDisabled(false);
    }
    setFocusToInput();
  };

  const checkDeviceStatus = async () => {
    if (!device || deviceOnline) return;
    const response = await getAgentStatus(device.udid);
    setDeviceOnline(response.online);
  };

  useEffect(() => {
    setFocusToInput();
  });

  useEffect(() => {
    void checkDeviceStatus();
  }, [device]);

  useInterval(() => {
    void checkDeviceStatus();
  }, 10_000);

  return (
    <div className={css.Root}>
      <div className={css.Terminal} onClick={setFocusToInput}>
        {!deviceOnline && (
          <div>
            <Spin size="xs" className={css.Spinner} />
            <span>{t('inventory.device_page.terminal_tab.device_connecting')}</span>
          </div>
        )}
        {deviceOnline && (
          <div>
            <Check className={css.Link} />
            <span>{t('inventory.device_page.terminal_tab.device_connected')}</span>
          </div>
        )}
        <div className={css.Output}>{output}</div>
        {deviceOnline && (
          <div className={css.InputContainer}>
            <span className={css.InputPrefix}>$</span>
            <input
              className={css.Input}
              ref={inputRef}
              type="text"
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
            />
          </div>
        )}
      </div>
    </div>
  );
};
