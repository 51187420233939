import { FC, useEffect, useState } from 'react';
import css from './devices-list-tab.module.scss';
import {
  DeviceSimpleDto,
  DevicesSortField,
  GetDevicesFromSmartGroupResponseDto,
  PaginationSortOrder
} from '../../../../../../../types/api';
import { SmartGroupFormValues } from '../../smart-group-devices-form.schema';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatDateFrom } from '../../../../../../../utils/time.utils';
import { getDevicesFromSmartGroup } from '../../../../../../../api/groups';
import { PaginationRowsPerPageOptions } from '../../../../../../../const/pagination.const';
import {
  Column,
  PaginationState,
  SortState,
  Table
} from '../../../../../../components/table/table.component';
import useRequest from '../../../../../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';
import noDataImage from '../../../../../../../assets/images/no-data.png';
import { Text } from '@gravity-ui/uikit';

export const DevicesListTab: FC = () => {
  const form = useFormContext<SmartGroupFormValues>();
  const { t } = useTranslation();
  const fetchRequest = useRequest<GetDevicesFromSmartGroupResponseDto>();

  const [deviceData, setDeviceData] = useState<DeviceSimpleDto[]>([]);
  const [devicesCount, setDevicesCount] = useState(0);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: PaginationRowsPerPageOptions[0]
  });
  const [sortState, setSortState] = useState<SortState>();

  const getDevicesInfo = async () => {
    const groupId = form.getValues().group.id;
    if (!groupId || fetchRequest.loading) return;

    const response = await fetchRequest.send(
      getDevicesFromSmartGroup(form.getValues().group.id, {
        page: paginationState.page,
        limit: paginationState.rowsPerPage,
        sort_field: sortState?.column as DevicesSortField,
        sort_order: sortState?.order as PaginationSortOrder
      })
    );

    setDeviceData(response.devices);
    setDevicesCount(response.count);
  };

  const columns: Column<DeviceSimpleDto>[] = [
    {
      id: DevicesSortField.DisplayName,
      name: t('inventory.table.heading.name'),
      selector: (row) => row.device_information?.device_name,
      sortable: true
    },
    {
      id: DevicesSortField.ModelName,
      name: t('inventory.table.heading.model_name'),
      selector: (row) => row.model_name,
      sortable: true
    },
    {
      id: DevicesSortField.SerialNumber,
      name: t('inventory.table.heading.serial'),
      selector: (row) => row.serial_number,
      sortable: true
    },
    {
      id: DevicesSortField.LastSeen,
      name: t('inventory.table.heading.last_check_in'),
      selector: (row) => formatDateFrom(row.last_seen),
      sortable: true
    }
  ];

  const handleChangePagination = (state: PaginationState) => {
    setPaginationState(state);
  };

  const handleChangeSort = (state?: SortState) => {
    setSortState(state);
  };

  useEffect(() => {
    void getDevicesInfo();
  }, [paginationState, sortState, form.getValues().group.id]);

  return (
    <>
      {fetchRequest.loading && <TableSkeleton />}
      {!fetchRequest.loading && devicesCount === 0 && (
        <div className={css.NoDataContainer}>
          <img alt="no-data" src={noDataImage} />
          <Text variant="subheader-3">{t('inventory.no_data')}</Text>
        </div>
      )}
      {!fetchRequest.loading && devicesCount > 0 && (
        <Table
          className={css.Table}
          columns={columns}
          data={deviceData}
          pagination
          paginationState={paginationState}
          paginationTotalRows={devicesCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          onChangePagination={handleChangePagination}
          sortState={sortState}
          onChangeSort={handleChangeSort}
          leftContent={<div></div>}
        />
      )}
    </>
  );
};
