import css from './settings-script.module.scss';
import { FC, useEffect, useState } from 'react';
import { Permission, ScriptFullDto } from '../../../../types/api';
import { useNavigate, useParams } from 'react-router-dom';
import { ScriptForm } from './components/script-form/script-form.component';
import { scriptFormSchema, ScriptFormValues } from './components/script-form/script-form.schema';
import { createScript, deleteScript, getScript, updateScript } from '../../../../api/scripts';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { Paths } from '../../../constants';
import { SettingsPaths } from '../settings.const';
import { Header } from '../../../components/header/header.component';
import { Dialog, Popover, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { ActionMenu } from './components/action-menu/action-menu.component';
import { t } from 'i18next';
import useRequest from '../../../../hooks/useRequest';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';
import { usePermission } from '../../../contexts/permission.context';

export const Script: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toaster = useToaster();
  const crudRequest = useRequest<ScriptFullDto>();
  const { isAllowedTo } = usePermission();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>(
    id === 'new' ? 'create' : 'view'
  );
  const [script, setScript] = useState<ScriptFullDto>();

  const scriptForm = useForm<ScriptFormValues>({
    mode: 'onChange',
    resolver: yupResolver(scriptFormSchema),
    defaultValues: scriptFormSchema.getDefault()
  });

  const handleScriptCreate = async () => {
    if (crudRequest.loading) return;

    const isValid = await scriptForm.trigger();
    if (!isValid) return;

    const values = scriptForm.getValues();
    try {
      const response = await crudRequest.send(
        createScript({
          name: values.display_name,
          description: values.description,
          script: values.script
        })
      );
      navigate(`/${Paths.SETTINGS}${SettingsPaths.SCRIPT.replace(':id', response.id)}`);
      setScript(response);
      scriptForm.reset({
        display_name: response.display_name,
        description: response.description || undefined,
        script: response.content || scriptForm.watch().script
      });

      setFormMode('view');
      toaster.add({
        name: 'create-script-success',
        content: t('settings.tiles.scripts.page.script_page.successfully_create', {
          title: response.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'create-script-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleScriptDelete = async () => {
    if (crudRequest.loading || !script) return;

    try {
      await crudRequest.send(deleteScript(script.id));
      navigate('/settings/scripts');
      toaster.add({
        name: 'delete-script-success',
        content: t('settings.tiles.scripts.page.script_page.successfully_delete', {
          title: script.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'delete-script-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleScriptEditStart = () => {
    setFormMode('edit');
  };

  const handleScriptCancel = () => {
    if (formMode === 'create') {
      navigate('./../');
      return;
    }
    setFormMode('view');
  };

  const handleScriptSave = async () => {
    if (crudRequest.loading || !script) return;

    const isValid = await scriptForm.trigger();
    if (!isValid) return;

    const values = scriptForm.getValues();
    try {
      const response = await crudRequest.send(
        updateScript(script.id, {
          name: values.display_name,
          description: values.description,
          script: values.script
        })
      );
      setFormMode('view');
      setScript(response);
      scriptForm.reset({
        display_name: response.display_name,
        description: response.description || undefined,
        script: scriptForm.getValues().script
      });

      navigate(`/${Paths.SETTINGS}${SettingsPaths.SCRIPT.replace(':id', response.id)}`);
      toaster.add({
        name: 'update-script-success',
        content: t('settings.tiles.scripts.page.script_page.successfully_update', {
          title: response.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'update-script-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const init = async () => {
    if (!id) return;
    if (id !== 'new') {
      const response = await crudRequest.send(getScript(id));
      setScript(response);
      scriptForm.reset({
        display_name: response.display_name,
        description: response.description || undefined,
        script: response.content
      });
    }
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={script?.display_name}
        rightContent={
          <ActionMenu
            mode={formMode}
            disabled={crudRequest.loading || !isAllowedTo(Permission.EditScripts)}
            onScriptCreate={handleScriptCreate}
            onScriptCancel={handleScriptCancel}
            onScriptDelete={handleDeleteModalOpen}
            onScriptEditStart={handleScriptEditStart}
            onScriptEdit={handleScriptSave}
          />
        }
      />
      <div className={css.Content}>
        {crudRequest.loading ? (
          <FormSkeleton />
        ) : (
          <>
            <div className={css.Title}>
              {formMode === 'view' && <Text variant="display-2">{script?.display_name}</Text>}
              {formMode !== 'view' && (
                <Popover content={t('common.click_to_edit')}>
                  <TextInput
                    placeholder={t('settings.tiles.scripts.page.script_page.display_name')}
                    size="xl"
                    view="clear"
                    error={scriptForm.formState.errors.display_name?.message}
                    errorPlacement="inside"
                    controlProps={{ className: css.TitleInput }}
                    {...scriptForm.register('display_name')}
                  />
                </Popover>
              )}
            </div>
            <ScriptForm mode={id === 'new' ? 'create' : formMode} form={scriptForm} />
          </>
        )}
      </div>
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalOpen}>
        <Dialog.Header caption={t('settings.tiles.scripts.page.script_page.modal_delete.title')} />
        <Dialog.Body>
          <Text>
            {t('settings.tiles.scripts.page.script_page.modal_delete.message', {
              title: script?.display_name
            })}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('settings.tiles.scripts.page.script_page.modal_delete.delete_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleScriptDelete}
          onClickButtonCancel={handleDeleteModalClose}
        />
      </Dialog>
    </div>
  );
};
