import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import css from './action-menu.module.scss';
import { t } from 'i18next';
import { Permission } from '../../../../../types/api';
import { usePermission } from '../../../../contexts/permission.context';

interface IProps {
  mode: 'view' | 'upload' | 'update' | 'create' | 'edit';
  disabled: boolean;
  onProfileDownload: () => void;
  onProfileEditStart: () => void;
  onProfileDelete: () => void;
  onProfileCreate: () => void;
  onProfileCancel: () => void;
  onProfileEdit: () => void;
  onProfileUpload: () => void;
}

export const ActionMenu: FC<IProps> = (props) => {
  const {
    mode,
    disabled,
    onProfileDownload,
    onProfileEditStart,
    onProfileDelete,
    onProfileCreate,
    onProfileCancel,
    onProfileEdit,
    onProfileUpload
  } = props;
  const { isAllowedTo } = usePermission();

  return (
    <div className={css.ButtonGroup}>
      {mode === 'create' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onProfileCancel}>
            {t('profiles.profile_form.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onProfileCreate}>
            {t('profiles.profile_form.create_profile_btn')}
          </Button>
        </>
      )}
      {mode === 'edit' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onProfileCancel}>
            {t('profiles.profile_form.cancel_btn')}
          </Button>
          <Button
            view="action"
            onClick={onProfileEdit}
            disabled={disabled || !isAllowedTo(Permission.EditPolicies)}
          >
            {t('profiles.profile_form.edit_btn')}
          </Button>
        </>
      )}
      {mode === 'view' && (
        <>
          <Button
            view="flat"
            onClick={onProfileDelete}
            disabled={disabled || !isAllowedTo(Permission.EditPolicies)}
          >
            {t('profiles.profile_form.delete_profile_btn')}
          </Button>
          <Button
            view="action"
            onClick={onProfileEditStart}
            disabled={disabled || !isAllowedTo(Permission.EditPolicies)}
          >
            {t('profiles.profile_form.edit_profile_btn')}
          </Button>
          <Button
            view="action"
            onClick={onProfileDownload}
            disabled={disabled || !isAllowedTo(Permission.EditPolicies)}
          >
            {t('profiles.profile_form.download_profile_btn')}
          </Button>
        </>
      )}
      {mode === 'upload' && (
        <>
          <Button
            view="flat"
            onClick={onProfileCancel}
            disabled={disabled || !isAllowedTo(Permission.EditPolicies)}
          >
            {t('profiles.profile_form.cancel_btn')}
          </Button>
          <Button
            view="action"
            onClick={onProfileUpload}
            disabled={disabled || !isAllowedTo(Permission.EditPolicies)}
          >
            {t('profiles.profile_form.upload_profile_btn')}
          </Button>
        </>
      )}
    </div>
  );
};
