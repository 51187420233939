import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import { t } from 'i18next';
import { StaticGroupFormMode } from '../../static-group-devices.component';
import css from './action-menu.module.scss';

interface IProps {
  mode: StaticGroupFormMode;
  disabled: boolean;
  onStaticGroupEditStart: () => void;
  onStaticGroupDelete: () => void;
  onStaticGroupCreate: () => void;
  onStaticGroupCreateCancel: () => void;
  onStaticGroupEdit: () => void;
  onStaticGroupEditCancel: () => void;
}

export const ActionMenu: FC<IProps> = (props) => {
  const {
    mode,
    disabled,
    onStaticGroupEditStart,
    onStaticGroupDelete,
    onStaticGroupCreate,
    onStaticGroupCreateCancel,
    onStaticGroupEdit,
    onStaticGroupEditCancel
  } = props;

  return (
    <div className={css.ButtonGroup}>
      {mode === StaticGroupFormMode.CREATE && (
        <>
          <Button view="flat" disabled={disabled} onClick={onStaticGroupCreateCancel}>
            {t('static_groups.page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onStaticGroupCreate}>
            {t('static_groups.page.create_btn')}
          </Button>
        </>
      )}
      {mode === StaticGroupFormMode.UPDATE && (
        <>
          <Button view="flat" disabled={disabled} onClick={onStaticGroupEditCancel}>
            {t('static_groups.page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onStaticGroupEdit}>
            {t('static_groups.page.update_btn')}
          </Button>
        </>
      )}
      {mode === StaticGroupFormMode.VIEW && (
        <>
          <Button view="flat" disabled={disabled} onClick={onStaticGroupDelete}>
            {t('static_groups.page.delete_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onStaticGroupEditStart}>
            {t('static_groups.page.edit_btn')}
          </Button>
        </>
      )}
    </div>
  );
};
