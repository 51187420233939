import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

export default function useIdle({
  onIdle,
  idleMinutes
}: {
  onIdle: () => void;
  idleMinutes: number;
}) {
  const [isIdle, setIsIdle] = useState<boolean>();

  const handleOnIdle = () => {
    setIsIdle(true);
    onIdle();
  };
  useIdleTimer({
    timeout: 1000 * 60 * idleMinutes,
    onIdle: handleOnIdle,
    debounce: 500
  });

  return {
    isIdle
  };
}
