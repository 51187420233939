import css from './policy-run-step.module.scss';
import { FC, ReactNode } from 'react';
import {
  PolicyPayloadPackageModifyType,
  PolicyRunFullDto,
  PolicyRunStepSimpleDto
} from '../../../../../../../../../../../types/api';
import { Dictionary } from '../../../../../../../../../../../types/common';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { policyPayloadPackageModifyTypes } from '../../../../../../../../../../../const/policies.const';
import { isResultShownForCommandType } from './policy-run-step.const';
import { Paths } from '../../../../../../../../../../constants';
import { SettingsPaths } from '../../../../../../../../../settings/settings.const';
import { Label, Text } from '@gravity-ui/uikit';

interface IProps {
  policyRun: PolicyRunFullDto;
  step: PolicyRunStepSimpleDto;
}

export const PolicyRunStep: FC<IProps> = (props) => {
  const { step, policyRun } = props;
  const { t } = useTranslation();

  const getStepStatusLabel = (): ReactNode => {
    if (step.success)
      return <Label theme="success">{t('policies.policy_run_step.step_status_success')}</Label>;
    if (step.success === false)
      return <Label theme="danger">{t('policies.policy_run_step.step_status_failed')}</Label>;
    if (policyRun.canceled)
      return <Label theme="warning">{t('policies.policy_run_step.step_status_canceled')}</Label>;
    if (policyRun.cancel_pending)
      return (
        <Label theme="warning">{t('policies.policy_run_step.step_status_cancel_pending')}</Label>
      );
    if (step.in_progress)
      return <Label theme="info">{t('policies.policy_run_step.step_status_in_progress')}</Label>;
    return <Label theme="normal">{t('policies.policy_run_step.step_status_scheduled')}</Label>;
  };

  const configuration = step.configuration as Dictionary;
  return (
    <div className={css.Root}>
      {step.command_type === 'RunCustomScript' && !!step.configuration && (
        <Text>
          {t('policies.policy_run_step.action_run_script')}{' '}
          <Link
            to={`/${Paths.SETTINGS}${SettingsPaths.SCRIPT.replace(':id', configuration.script_id)}`}
            target="_blank"
          >
            {configuration.script_title}
          </Link>
          {configuration.script_arguments &&
            (configuration.script_arguments as unknown as string[]).some((i) => i !== '') && (
              <Text>
                , {t('policies.policy_run_step.action_arguments')}: [
                {(configuration.script_arguments as unknown as string[]).join()}]
              </Text>
            )}
        </Text>
      )}
      {step.command_type === 'ModifyPackage' && (
        <Text>
          {
            policyPayloadPackageModifyTypes[
              configuration.modify_type as PolicyPayloadPackageModifyType
            ]
          }{' '}
          <Link
            to={`/${Paths.SETTINGS}${SettingsPaths.PACKAGE.replace(
              ':id',
              configuration.package_id
            )}`}
            target="_blank"
          >
            {t('policies.policy_run_step.link_package')}
          </Link>
        </Text>
      )}
      {step.command_type === 'GetDeviceInformation' && (
        <Text>{t('policies.policy_run_step.action_get_device_information')}</Text>
      )}
      <div>
        <Text className={css.StatusPrefix}>{t('policies.policy_run_step.title_step_status')}</Text>
        {getStepStatusLabel()}
      </div>
      {isResultShownForCommandType(step.command_type) && (
        <div>
          {step.agent_command && step.agent_command?.result?.length && (
            <div className={css.ResultBlock}>
              <Text className={css.ResultPrefix}>
                {t('policies.policy_run_step.title_step_script_stdout')}
              </Text>
              <div className={css.ResultCodeBlock}>
                {step.agent_command.result.filter(Boolean).map((i) => (
                  <Text className={css.ResultCode} key={i} variant="code-1">
                    {i}
                  </Text>
                ))}
              </div>
            </div>
          )}
          {step.agent_command && step.agent_command?.result_error?.length && (
            <div className={css.ResultBlock}>
              <Text className={css.ResultPrefix}>
                {t('policies.policy_run_step.title_step_script_stderr')}
              </Text>
              <div className={css.ResultCodeBlock}>
                {step.agent_command.result_error.filter(Boolean).map((i) => (
                  <Text className={css.ResultCode} key={i} variant="code-1">
                    {i}
                  </Text>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
