import { FC } from 'react';
import { Skeleton } from '@gravity-ui/uikit';
import css from './table-skeleton.module.scss';

export const TableSkeleton: FC = () => {
  return (
    <>
      <Skeleton className={css.FilterButtonSkeleton} />
      <Skeleton className={css.TableSkeleton} />
    </>
  );
};
