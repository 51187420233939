import css from './header.module.scss';
import { FC, ReactNode, useState } from 'react';
import { ActionBar } from '@gravity-ui/navigation';
import { LayoutSideContentLeft } from '@gravity-ui/icons';
import cn from 'classnames';
import layoutSideContentLeftImg from '../../../assets/images/layout-side-content-left.svg';
import { Breadcrumbs } from '../breadcrumbs/breadcrumbs.component.v2';
import { useSidebar } from '../../contexts/sidebar.context';

interface IProps {
  breadcrumbsTitle?: string;
  rightContent?: ReactNode;
}

export const Header: FC<IProps> = (props) => {
  const { breadcrumbsTitle, rightContent } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { setCompact } = useSidebar();

  const handleClick = () => {
    setCompact(!isSidebarOpen);
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <ActionBar className={cn(css.MainHeader)}>
      <ActionBar.Section type="primary">
        <ActionBar.Group>
          <ActionBar.Item className={css.SideContentIconItem}>
            {isSidebarOpen ? (
              <LayoutSideContentLeft onClick={handleClick} cursor={'pointer'} />
            ) : (
              <img
                src={layoutSideContentLeftImg}
                alt="Layout Side Content Left"
                onClick={handleClick}
                className={cn(css.Icon)}
              />
            )}
          </ActionBar.Item>
        </ActionBar.Group>
        <ActionBar.Group pull="left">
          <ActionBar.Item>
            <Breadcrumbs title={breadcrumbsTitle} />
          </ActionBar.Item>
        </ActionBar.Group>
        {rightContent && (
          <ActionBar.Group pull="right">
            <ActionBar.Item className={css.RightContentItem}>{rightContent}</ActionBar.Item>
          </ActionBar.Group>
        )}
      </ActionBar.Section>
    </ActionBar>
  );
};
