import css from './policy.module.scss';
import { FC, useEffect, useState } from 'react';
import { createPolicy, deletePolicy, getPolicy, updatePolicy } from '../../../api/policies';
import {
  Permission,
  PolicyFullDto,
  UpdatePolicyRequestDto,
  UpdatePolicyRerun
} from '../../../types/api';
import { useNavigate, useParams } from 'react-router-dom';
import { PolicyForm } from './components/policy-form/policy-form.component';
import useRequest from '../../../hooks/useRequest';
import { formToPolicy, policyToForm } from './policy.utils';
import { useTranslation } from 'react-i18next';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import { Header } from '../../components/header/header.component';
import { Dialog, Popover, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { DeviceSections, Paths } from '../../constants';
import { ActionMenu } from './components/action-menu/action-menu.component';
import { PolicySkeleton } from './components/skeleton/skeleton.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { policyFormSchema, PolicyFormValues } from './components/policy-form/policy-form.schema';
import { usePermission } from '../../contexts/permission.context';

export const Policy: FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const toaster = useToaster();
  const crudRequest = useRequest();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const [formMode, setFormMode] = useState<'create' | 'edit' | 'view'>(
    id === 'new' ? 'create' : 'view'
  );
  const [policy, setPolicy] = useState<PolicyFullDto>();
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);

  const policyForm = useForm<PolicyFormValues>({
    mode: 'onChange',
    resolver: yupResolver(policyFormSchema),
    defaultValues: policyFormSchema.getDefault()
  });
  const {
    register,
    getValues,
    reset,
    formState: { errors }
  } = policyForm;

  const handlePolicyCreateCancel = () => navigate(`./../`);
  const handlePolicyCreate = async () => {
    if (crudRequest.loading) return;

    const isValid = await policyForm.trigger();
    if (!isValid) return;

    const request = formToPolicy(getValues());
    try {
      const response = (await crudRequest.send(createPolicy(request), 1000)) as PolicyFullDto;

      setFormMode('view');
      navigate(`${DeviceSections.COMPUTERS}/${Paths.POLICY_LIST}/${response.id}`);
      toaster.add({
        name: 'create-success',
        content: t('policies.policy.successfully_created', { policy_title: response.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'create-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleEditStart = () => setFormMode('edit');
  const handleEditCancel = () => {
    if (crudRequest.loading) return;
    if (policy) {
      reset(policyToForm(policy));
    }
    setFormMode('view');
  };
  const handleEdit = async () => {
    if (!id || crudRequest.loading) return;

    const isValid = await policyForm.trigger();
    if (!isValid) return;

    const request: UpdatePolicyRequestDto = {
      ...formToPolicy(getValues()),
      rerun: UpdatePolicyRerun.All
    };
    try {
      const response = (await crudRequest.send(updatePolicy(id, request), 1000)) as PolicyFullDto;
      setPolicy(response);
      reset(policyToForm(response));
      navigate(`/computers/policies/${response.id}`);

      setFormMode('view');
      toaster.add({
        name: 'update-success',
        content: t('policies.policy.successfully_updated', { policy_title: response.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'update-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handlePolicyDelete = () => setIsDeleteModalShown(true);
  const handleDeleteModalClose = () => {
    if (crudRequest.loading) return;
    setIsDeleteModalShown(false);
  };
  const handleDeleteModalSubmit = async () => {
    if (crudRequest.loading || !policy) return;
    try {
      await crudRequest.send(deletePolicy(policy.id), 1000);
      navigate('/computers/policies');
      toaster.add({
        name: 'delete-success',
        content: t('policies.policy.successfully_deleted', { policy_title: policy.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'delete-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
    handleDeleteModalClose();
  };

  const init = async () => {
    if (!id) return;
    if (id !== 'new') {
      const response = (await crudRequest.send(getPolicy(id), 1000)) as PolicyFullDto;
      setPolicy(response);
      reset(policyToForm(response));
    }
  };

  useEffect(() => {
    void init();
  }, [id]);

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={policy?.title}
        rightContent={
          <ActionMenu
            mode={formMode}
            disabled={crudRequest.loading || !isAllowedTo(Permission.EditPolicies)}
            onPolicyCreate={handlePolicyCreate}
            onPolicyCreateCancel={handlePolicyCreateCancel}
            onPolicyEditStart={handleEditStart}
            onPolicyEdit={handleEdit}
            onPolicyEditCancel={handleEditCancel}
            onPolicyDelete={handlePolicyDelete}
          />
        }
      />
      <div className={css.Content}>
        <div className={css.Title}>
          <Text variant="display-2">
            {formMode === 'view' && policy?.title}
            {formMode !== 'view' && (
              <Popover content={t('common.click_to_edit')}>
                <TextInput
                  placeholder={t('policies.options_general.display_name')}
                  size="xl"
                  view="clear"
                  error={errors.general?.display_name?.message}
                  errorPlacement="inside"
                  controlProps={{ className: css.TitleInput }}
                  {...register('general.display_name')}
                />
              </Popover>
            )}
          </Text>
        </div>
        {crudRequest.loading ? (
          <PolicySkeleton />
        ) : (
          <PolicyForm mode={formMode} form={policyForm} policyId={id} />
        )}
      </div>
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalShown}>
        <Dialog.Header caption={t('policies.policy.modal_title_delete')} />
        <Dialog.Body>
          <Text>{t('policies.policy.modal_message', { policy_title: policy?.title })}</Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('policies.policy.modal_button_delete')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleDeleteModalSubmit}
          onClickButtonCancel={handleDeleteModalClose}
          loading={crudRequest.loading}
        />
      </Dialog>
    </div>
  );
};
