import { mixed, number, object } from 'yup';
import i18next from '../../../../i18n/i18n';

export interface SmartGroupsSettingsForm {
  smart_groups_update_interval: number | 'custom';
  custom_interval?: number | null | undefined;
}

export const smartGroupsSettingsFormSchema = object({
  smart_groups_update_interval: mixed().required(),
  custom_interval: number()
    .min(1, i18next.t('settings.tiles.smart_groups_config.page.interval_should_be_more'))
    .max(24 * 30, i18next.t('settings.tiles.smart_groups_config.page.interval_should_be_less'))
}).required();
