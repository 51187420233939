import { FC, FormEvent, useEffect } from 'react';
import css from './settings-groups.module.scss';
import { GetSettingsResponseDto, Permission, UpdateSettingsDto } from '../../../../types/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { getSettings, updateSettings } from '../../../../api/settings';
import { usePermission } from '../../../contexts/permission.context';
import { SmartGroupsSettingsForm, smartGroupsSettingsFormSchema } from './settings-groups.schema';
import { Header } from '../../../components/header/header.component';
import { Button, Select, SelectOption, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { FormSkeleton } from '../settings-scripts-config/components/form-skeleton/form-skeleton.component';

export const SettingsGroups: FC = () => {
  const initRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const updateRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const { register, setValue, trigger, getValues, watch, formState } =
    useForm<SmartGroupsSettingsForm>({
      mode: 'onChange',
      resolver: yupResolver(smartGroupsSettingsFormSchema),
      defaultValues: smartGroupsSettingsFormSchema.getDefault()
    });
  const watchCustomInterval = watch('smart_groups_update_interval');
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleSave = async () => {
    if (initRequest.loading || updateRequest.loading) return;
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();

    try {
      const requestBody: Partial<UpdateSettingsDto> = {
        smart_groups_update_interval:
          values.smart_groups_update_interval === 'custom' && Number(values.custom_interval)
            ? Number(values.custom_interval)
            : Number(values.smart_groups_update_interval)
      };
      await updateRequest.send(updateSettings(requestBody));
      toaster.add({
        name: 'smart-group-config-save-success',
        content: t('settings.tiles.smart_groups_config.page.settings_successfully_saved'),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error: unknown) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'smart-group-config-save-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const intervalOptions: SelectOption[] = [
    {
      content: '1',
      value: '1'
    },
    {
      content: '6',
      value: '6'
    },
    {
      content: '12',
      value: '12'
    },
    {
      content: '24',
      value: '24'
    },
    {
      content: t('settings.tiles.smart_groups_config.page.custom_interval'),
      value: 'custom'
    }
  ];

  useEffect(() => {
    const init = async () => {
      const settings = await initRequest.send(getSettings(['smart_groups_update_interval']));
      if (
        settings.smart_groups_update_interval &&
        [1, 6, 12, 24].includes(Number(settings.smart_groups_update_interval))
      ) {
        setValue('smart_groups_update_interval', settings.smart_groups_update_interval);
      } else {
        setValue('smart_groups_update_interval', 'custom');
        setValue('custom_interval', Number(settings.smart_groups_update_interval));
      }
    };
    void init();
  }, []);

  const headerContent = (
    <Button
      view="action"
      onClick={handleSave}
      loading={updateRequest.loading}
      disabled={
        initRequest.loading ||
        !isAllowedTo(Permission.EditGroups) ||
        !isAllowedTo(Permission.EditAdministration)
      }
    >
      {t('settings.tiles.smart_groups_config.page.update_btn')}
    </Button>
  );

  const handleUpdateInterval = (value: string[]) => {
    let interval: number | 'custom';
    if (value[0] === 'custom') interval = value[0];
    else interval = Number(value[0]);
    setValue(`smart_groups_update_interval`, interval);
  };

  return (
    <div className={css.Root}>
      <Header rightContent={headerContent} />
      <div className={css.Content}>
        <div className={css.Title}>
          <Text variant="display-2">{t('settings.tiles.smart_groups_config.name')}</Text>
        </div>
        {initRequest.loading || updateRequest.loading ? (
          <FormSkeleton />
        ) : (
          <form className={css.Form} onSubmit={handleFormSubmit}>
            <div className={css.FormItem}>
              <Text>
                {t('settings.tiles.smart_groups_config.page.smart_groups_update_interval')}
              </Text>
              <Select
                options={intervalOptions}
                value={[String(watchCustomInterval)]}
                onUpdate={handleUpdateInterval}
              />
            </div>
            {watchCustomInterval === 'custom' && (
              <div className={css.FormItem}>
                <Text>{t('settings.tiles.smart_groups_config.page.custom_interval')}</Text>
                <TextInput
                  type="text"
                  error={formState.errors.custom_interval?.message}
                  {...register('custom_interval')}
                />
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};
