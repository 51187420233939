import { FC, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SettingsPkiCertificateList } from '../settings-pki-certificate-list/settings-pki-certificate-list';
import { SettingsPkiCa } from '../settings-pki-ca/settings-pki-ca';
import { SettingsPkiCertificate } from '../settings-pki-certificate/settings-pki-certificate';
import { useTranslation } from 'react-i18next';
import { Tabs, TabsItemProps, Text } from '@gravity-ui/uikit';
import { Header } from '../../../components/header/header.component';
import css from './settings-pki.module.scss';

enum PkiTabs {
  CERTIFICATES = 'certificates',
  MANAGE_CA = 'manage_ca'
}

export const SettingsPki: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [breadcrumbTitle, setBreadcrumbTitle] = useState('');

  const handleTitleChange = (title: string) => {
    setBreadcrumbTitle(title);
  };

  const navbarTabs: TabsItemProps[] = [
    { id: PkiTabs.CERTIFICATES, title: t('settings.tiles.pki.page.certificates') },
    { id: PkiTabs.MANAGE_CA, title: t('settings.tiles.pki.page.manage_ca') }
  ];

  const activeTab = () => {
    if (location.pathname.endsWith('/pki/ca')) {
      return PkiTabs.MANAGE_CA;
    }
    return PkiTabs.CERTIFICATES;
  };

  const handleSelectTab = (tab: string) => {
    if (tab === PkiTabs.MANAGE_CA) {
      navigate('ca');
    } else {
      navigate('');
    }
  };

  useEffect(() => {
    setBreadcrumbTitle('');
  }, [location]);

  return (
    <div className={css.Root}>
      <Header breadcrumbsTitle={breadcrumbTitle} />
      <div className={css.Content}>
        <div className={css.Title}>
          <Text variant="display-2">{t('settings.tiles.pki.page.pki_certificates')}</Text>
        </div>
        <Tabs
          className={css.NavBar}
          items={navbarTabs}
          activeTab={activeTab()}
          onSelectTab={handleSelectTab}
        />
        <Routes>
          <Route path="/" element={<SettingsPkiCertificateList />} />
          <Route path="/ca" element={<SettingsPkiCa />} />
          <Route
            path="/:id"
            element={<SettingsPkiCertificate onTitleChange={handleTitleChange} />}
          />
        </Routes>
      </div>
    </div>
  );
};
