import { FC, FormEvent, useState } from 'react';
import css from './configuration-profile-form.module.scss';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import {
  ConfigurationProfileFormValues,
  ConfigurationProfileUploadFormValues
} from './configuration-profile-form.schema';
import { ScopeForm, ScopeValues } from '../../../../components/scope-form/scope-form.component';
import { LogsTab } from './components/logs-tab/logs-tab.component';
import { Tabs, TabsItemProps } from '@gravity-ui/uikit';
import { OptionsTab } from './components/options-tab/options-tab.component';

export enum ConfigurationProfilePageTabs {
  OPTIONS = 'Options',
  SCOPE = 'Scope',
  LOGS = 'Logs'
}

interface IProps {
  mode: 'view' | 'upload' | 'create' | 'edit';
  configProfileForm: UseFormReturn<ConfigurationProfileFormValues>;
  configProfileUploadForm: UseFormReturn<ConfigurationProfileUploadFormValues>;
}

export const ConfigurationProfileForm: FC<IProps> = (props) => {
  const { configProfileForm, configProfileUploadForm, mode } = props;
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<string>(
    mode === 'upload' ? ConfigurationProfilePageTabs.SCOPE : ConfigurationProfilePageTabs.OPTIONS
  );

  const form = mode === 'upload' ? configProfileUploadForm : configProfileForm;
  const { watch } = form;

  const scopeValues = watch().scope;
  const payload = watch().imported_payload || JSON.stringify(watch().payload, null, 2);
  const isImported = !!form.watch().imported_payload;

  const handleScopeChange = (input: Partial<ScopeValues>) => {
    const newScope = { ...scopeValues, ...input };
    if (mode === 'upload') {
      configProfileUploadForm.setValue('scope', newScope);
    } else {
      configProfileForm.setValue('scope', newScope);
    }
  };

  const handleFormSubmit = (event: FormEvent) => event.preventDefault();

  const handleChangeTab = (tab: string) => setActiveTab(tab);

  const commonTabs: TabsItemProps[] = [
    {
      id: ConfigurationProfilePageTabs.OPTIONS,
      title: t('profiles.profile_form.options_tab.name')
    },
    {
      id: ConfigurationProfilePageTabs.SCOPE,
      title: t('profiles.profile_form.scope_tab.name')
    }
  ];
  if (mode !== 'create') {
    commonTabs.push({
      id: ConfigurationProfilePageTabs.LOGS,
      title: t('profiles.profile_form.logs_tab.name')
    });
  }
  const uploadTabs: TabsItemProps[] = [
    {
      id: ConfigurationProfilePageTabs.SCOPE,
      title: t('profiles.profile_form.scope_tab.name')
    }
  ];
  const tabs: TabsItemProps[] = mode === 'upload' ? uploadTabs : commonTabs;

  return (
    <form onSubmit={handleFormSubmit}>
      <Tabs
        className={css.NavBar}
        items={tabs}
        activeTab={activeTab}
        onSelectTab={handleChangeTab}
      />
      {activeTab === ConfigurationProfilePageTabs.OPTIONS && (
        <OptionsTab
          form={configProfileForm}
          generalDisabled={isImported || mode === 'view'}
          payload={payload}
        />
      )}
      {activeTab === ConfigurationProfilePageTabs.SCOPE && (
        <ScopeForm values={scopeValues} disabled={mode === 'view'} onChange={handleScopeChange} />
      )}
      {activeTab === ConfigurationProfilePageTabs.LOGS && <LogsTab />}
    </form>
  );
};
