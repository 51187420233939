import { FC } from 'react';
import css from './success-state-dropzone.module.scss';
import { Icon, Progress, Text } from '@gravity-ui/uikit';
import { CircleCheck, FileText } from '@gravity-ui/icons';
import { formatFileSize } from '../../../../../utils/file.utils';

interface IProps {
  fileName?: string;
  fileSize?: number; // in bytes
}

export const SuccessStateDropzone: FC<IProps> = (props) => {
  const { fileName, fileSize } = props;

  return (
    <>
      <div className={css.FileInfoAndStatus}>
        <div className={css.FileInfoAndIcon}>
          <Icon data={FileText} size={20} />
          <div className={css.FileInfo}>
            <Text variant="subheader-1">{fileName}</Text>
            <Text color="secondary">{formatFileSize(fileSize || 0)}</Text>
          </div>
        </div>
        <div className={css.SuccessIcon}>
          <Icon data={CircleCheck} size={20} />
        </div>
      </div>
      <div className={css.ProgressBarWrapper}>
        <Progress className={css.ProgressBar} value={100} size="xs" theme="success" />
        <Text>100%</Text>
      </div>
    </>
  );
};
