import { FC, FormEventHandler, useEffect, useMemo, useState } from 'react';
import css from './criteria-tab.module.scss';
import {
  ConditionConjunctive,
  CreateSmartGroupDeviceUsersConditionRequestDto,
  DeviceUserCriteria,
  DeviceUsersCriteriaValue,
  GetDeviceUsersCriteriaResponseDto,
  SmartGroupDevicesConditionStrippedDto
} from '../../../../../../../types/api';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { deviceUserCriteriaTitles } from '../../../../../../../const/criteria-titles.const';
import { SelectOption } from '@gravity-ui/uikit/build/esm/components/Select/types';
import { Button, Label, RadioButton, Select, Text, TextInput } from '@gravity-ui/uikit';
import { Column, Table } from '../../../../../../components/table/table.component';
import {
  getDeviceUsersCriteriaOptions,
  getDeviceUsersCriteriaValues
} from '../../../../../../../api/device-users';
import useRequest from '../../../../../../../hooks/useRequest';
import { CriteriaTableSkeleton } from './components/criteria-table-skeleton/criteria-table-skeleton.component';
import noDataImage from '../../../../../../../assets/images/no-data.png';
import { AsyncSelect } from '../../../../../../components/async-select/async-select.component';
import { Ellipsis } from '@gravity-ui/icons';
import { SmartGroupDeviceUsersFormValues } from '../../smart-group-device-users-form.schema';

interface IProps {
  mode: 'view' | 'create' | 'edit';
}

export const CriteriaTab: FC<IProps> = (props: IProps) => {
  const { mode } = props;

  const { control, register, setValue, getValues, watch } =
    useFormContext<SmartGroupDeviceUsersFormValues>();
  const { t } = useTranslation();

  const [criteria, setCriteria] = useState<DeviceUserCriteria[]>();
  const [groupCriteriaValues, setGroupCriteriaValues] = useState<DeviceUsersCriteriaValue[]>();
  const criteriaValues: Record<string, SelectOption[]> = useMemo(() => ({}), []);
  const fetchCriteriaRequest = useRequest<GetDeviceUsersCriteriaResponseDto>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'criteria'
  });

  const conjunctiveOptions: SelectOption[] = [
    { content: t('smart_groups.page.criteria_tab.new_criteria.and_or.and'), value: 'and' },
    { content: t('smart_groups.page.criteria_tab.new_criteria.and_or.or'), value: 'or' }
  ];
  const stringOperatorOptions: SelectOption[] = [
    { content: t('smart_groups.page.criteria_tab.new_criteria.operator.is'), value: '=' },
    { content: t('smart_groups.page.criteria_tab.new_criteria.operator.is_not'), value: '!=' },
    { content: t('smart_groups.page.criteria_tab.new_criteria.operator.like'), value: 'like' },
    {
      content: t('smart_groups.page.criteria_tab.new_criteria.operator.not_like'),
      value: 'not like'
    }
  ];
  const groupOperatorOptions: SelectOption[] = [
    {
      content: t('smart_groups.page.criteria_tab.new_criteria.operator.member_of'),
      value: 'member of'
    },
    {
      content: t('smart_groups.page.criteria_tab.new_criteria.operator.not_member_of'),
      value: 'not member of'
    }
  ];
  const criteriaOptions: SelectOption[] = criteria
    ? criteria.map((item) => ({
        content: deviceUserCriteriaTitles[item] || item,
        value: item
      }))
    : [];
  const groupOptions: SelectOption[] | undefined = groupCriteriaValues?.map((item) => ({
    content: item.title || '',
    value: item.value
  }));

  const handleAddCriterionClick = () => {
    append({
      conjunctive: fields?.length ? ConditionConjunctive.And : null,
      criteria: criteria && criteria.length > 0 ? criteria[0] : DeviceUserCriteria.Username,
      operator: '=',
      value: '',
      start_bracket: false,
      end_bracket: false
    });
  };

  const fetchCriteriaValue = (criterion: string) => async () => {
    if (!criteriaValues[criterion]) {
      const response = await getDeviceUsersCriteriaValues({
        criteria: criterion as DeviceUserCriteria
      });
      criteriaValues[criterion] = response.values
        .map((item) => item.value)
        .map((item) => ({
          value: item.toString(),
          content: item.toString()
        }));
    }

    return criteriaValues[criterion];
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
  };

  console.log(getValues().criteria);

  const columns: Column<CreateSmartGroupDeviceUsersConditionRequestDto>[] = [
    {
      id: 'conjunctive',
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.and_or'),
      selector: (_, index) => {
        if (index == 0) {
          setValue(`criteria.${index}.conjunctive`, null);
          return <></>;
        }

        const selected = watch(`criteria.${index}.conjunctive`);
        const handleConjunctiveChange = (value: string) =>
          setValue(
            `criteria.${index}.conjunctive`,
            value as SmartGroupDevicesConditionStrippedDto['conjunctive']
          );

        if (mode === 'view') {
          return <Text>{conjunctiveOptions.find((item) => item.value === selected)?.content}</Text>;
        }
        return (
          <RadioButton
            key={fields[index].id}
            className={css.RadioButton}
            options={conjunctiveOptions}
            defaultValue={selected || undefined}
            onUpdate={handleConjunctiveChange}
            {...register(`criteria.${index}.conjunctive`)}
          />
        );
      },
      width: 100
    },
    {
      id: 'start_bracket',
      name: '',
      selector: (_, index) => {
        const checked = watch(`criteria.${index}.start_bracket`);
        const handleStartBracketClick = () => {
          setValue(`criteria.${index}.start_bracket`, !checked);
        };

        if (mode === 'view') {
          return <Text>{checked && '('}</Text>;
        }
        return (
          <Button
            view="outlined"
            className={checked ? undefined : css.UncheckedBracketCheckbox}
            onClick={handleStartBracketClick}
          >
            (
          </Button>
        );
      },
      width: 40
    },
    {
      id: 'criteria',
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.criteria'),
      selector: (_, index) => {
        const watchedCriteria = watch(`criteria.${index}.criteria`);
        const handleChangeCriteria = (value: string[]) => {
          setValue(`criteria.${index}.criteria`, value[0] as DeviceUserCriteria);

          const options: SelectOption[] =
            value[0] === DeviceUserCriteria.Group ? groupOperatorOptions : stringOperatorOptions;
          setValue(
            `criteria.${index}.operator`,
            options[0].value as CreateSmartGroupDeviceUsersConditionRequestDto['operator']
          );
        };

        if (mode == 'view') {
          return (
            <Text>{criteriaOptions.find((item) => item.value === watchedCriteria)?.content}</Text>
          );
        }
        return (
          <Select
            key={fields[index].id}
            options={criteriaOptions}
            defaultValue={criteria && criteria.length > 0 ? [criteria[0]] : []}
            width="max"
            value={[watchedCriteria]}
            onUpdate={handleChangeCriteria}
            {...register(`criteria.${index}.criteria`)}
          />
        );
      },
      width: 300
    },
    {
      id: 'operator',
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.operator'),
      selector: (_, index) => {
        const watchedCriteria = watch(`criteria.${index}.criteria`);
        const options: SelectOption[] =
          watchedCriteria === DeviceUserCriteria.Group
            ? groupOperatorOptions
            : stringOperatorOptions;
        const watchedOperator = watch(`criteria.${index}.operator`);
        const handleChangeOperator = (value: string[]) =>
          setValue(
            `criteria.${index}.operator`,
            value[0] as CreateSmartGroupDeviceUsersConditionRequestDto['operator']
          );

        if (mode === 'view') {
          return <Label>{options.find((item) => item.value === watchedOperator)?.content}</Label>;
        }
        return (
          <Select
            key={fields[index].id}
            options={options}
            defaultValue={options && options.length > 0 ? [options[0].value] : []}
            width="max"
            value={[watchedOperator]}
            onUpdate={handleChangeOperator}
            {...register(`criteria.${index}.operator`)}
          />
        );
      },
      width: 150
    },
    {
      id: 'value',
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.value'),
      selector: (_, index) => {
        const watchedCriteria = watch(`criteria.${index}.criteria`);
        const watchedValue = watch(`criteria.${index}.value`);

        if (mode === 'view') {
          return <Text>{watchedValue}</Text>;
        }
        switch (watchedCriteria) {
          default:
            return <TextInput type="text" autoFocus {...register(`criteria.${index}.value`)} />;
          case DeviceUserCriteria.Group: {
            const handleBooleanValueChange = (value: string[]) =>
              setValue(`criteria.${index}.value`, value[0]);

            return (
              <Select
                options={groupOptions || []}
                defaultValue={
                  groupOptions && groupOptions.length > 0 ? [groupOptions[0].value] : []
                }
                width="max"
                value={[watchedValue]}
                onUpdate={handleBooleanValueChange}
                {...register(`criteria.${index}.value`)}
              />
            );
          }
        }
      }
    },
    {
      id: 'available_value',
      name: '',
      selector: (_, index) => {
        const selectedCriteria = getValues().criteria?.[index].criteria || criteriaOptions[0].value;
        const options: SelectOption[] = criteriaValues[selectedCriteria];

        if (mode === 'view' || selectedCriteria === DeviceUserCriteria.Group) {
          return <></>;
        }
        return (
          <AsyncSelect
            defaultOptions={options}
            onUpdate={(value) => setValue(`criteria.${index}.value`, value)}
            onLoad={fetchCriteriaValue(selectedCriteria)}
            icon={Ellipsis}
          />
        );
      },
      width: 40
    },
    {
      id: 'end_bracket',
      name: '',
      selector: (_, index) => {
        const checked = watch(`criteria.${index}.end_bracket`);
        const handleEndBracketClick = () => setValue(`criteria.${index}.end_bracket`, !checked);

        if (mode === 'view') {
          return <Text>{checked && ')'}</Text>;
        }
        return (
          <Button
            key={fields[index].id}
            view="outlined"
            className={checked ? undefined : css.UncheckedBracketCheckbox}
            onClick={handleEndBracketClick}
          >
            )
          </Button>
        );
      },
      width: 40
    },
    {
      id: 'delete',
      name: '',
      selector: (_, index) => {
        const handleRemoveCriterionClick = () => {
          if (index === 0 && fields?.length > 1) {
            setValue('criteria.1.conjunctive', null);
            fields[1].conjunctive = null;
          }
          remove(index);
        };

        if (mode === 'view') {
          return <></>;
        }
        return (
          <Button view="outlined-danger" onClick={handleRemoveCriterionClick}>
            {t('smart_groups.page.criteria_tab.new_criteria.delete_btn')}
          </Button>
        );
      },
      align: 'end',
      width: 0
    }
  ];

  useEffect(() => {
    const fetchCriteria = async () => {
      const result = await fetchCriteriaRequest.send(getDeviceUsersCriteriaOptions());
      setCriteria(result.criteria);
    };

    void fetchCriteria();
  }, []);

  useEffect(() => {
    const fetchGroupCriteriaValues = async () => {
      if (groupCriteriaValues && groupCriteriaValues.length > 0) return;

      const values = getValues('criteria');
      const hasGroupCriteria = values?.some((i) => i.criteria === DeviceUserCriteria.Group);
      if (mode !== 'view' || (mode === 'view' && hasGroupCriteria)) {
        const response = await getDeviceUsersCriteriaValues({ criteria: DeviceUserCriteria.Group });
        setGroupCriteriaValues(response.values);
      } else {
        setGroupCriteriaValues([]);
      }
    };

    void fetchGroupCriteriaValues();
  }, [mode]);

  return (
    <form className={css.Root} onSubmit={handleSubmit}>
      {fetchCriteriaRequest.loading && <CriteriaTableSkeleton />}
      {!fetchCriteriaRequest.loading && fields.length === 0 && mode === 'view' && (
        <div className={css.NoDataContainer}>
          <img alt="no-data" src={noDataImage} />
          <Text variant="subheader-3">{t('inventory.filter.no_data')}</Text>
        </div>
      )}
      {!fetchCriteriaRequest.loading && (fields.length > 0 || mode !== 'view') && (
        <Table className={css.Table} columns={columns} data={fields} />
      )}
      {mode !== 'view' && (
        <Button view="action" width="auto" onClick={handleAddCriterionClick}>
          {t('inventory.filter.add_criterion_btn')}
        </Button>
      )}
    </form>
  );
};
