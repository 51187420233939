import { FC } from 'react';
import { WithClassname } from '../../../types/common';
import css from './blank-page.module.scss';
import cn from 'classnames';
import { Text } from '@gravity-ui/uikit';

interface IProps {
  text: string;
}

export const BlankPage: FC<IProps & WithClassname> = (props) => {
  const { text, className } = props;

  return (
    <div className={cn(className, css.Text)}>
      <Text>{text}</Text>
    </div>
  );
};
