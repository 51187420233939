import { createRequest } from './create-request';
import {
  CreatePolicyRequestDto,
  GetPoliciesRequestDto,
  GetPoliciesResponseDto,
  GetPolicyRunsRequestDto,
  GetPolicyRunsResponseDto,
  PolicyFullDto,
  PolicyRunFullDto,
  PolicyRunSimpleDto,
  PolicySimpleDto,
  UpdatePolicyRequestDto
} from '../types/api';

export async function getPolicies(params: GetPoliciesRequestDto): Promise<GetPoliciesResponseDto> {
  return createRequest({ url: 'policies', params });
}

export async function getPolicy(id: string): Promise<PolicyFullDto> {
  return createRequest({ url: `policies/${id}` });
}

export async function getPolicyRuns(
  params: GetPolicyRunsRequestDto
): Promise<GetPolicyRunsResponseDto> {
  return createRequest({ url: 'policies/runs', params });
}

export async function getPolicyRun(id: string): Promise<PolicyRunFullDto> {
  return createRequest({ url: `policies/runs/${id}` });
}

export async function getPoliciesForDevice(id: string): Promise<GetPoliciesResponseDto> {
  return createRequest({ url: `policies/device/${id}` });
}

export async function createPolicy(data: CreatePolicyRequestDto): Promise<PolicyFullDto> {
  return createRequest({ method: 'POST', url: 'policies', data });
}

export async function updatePolicy(
  id: string,
  data: UpdatePolicyRequestDto
): Promise<PolicyFullDto> {
  return createRequest({ method: 'PATCH', url: `policies/${id}`, data });
}

export async function deletePolicy(id: string): Promise<PolicySimpleDto> {
  return createRequest({ method: 'DELETE', url: `policies/${id}` });
}

export async function acknowledgePolicyRun(id: string): Promise<PolicyRunSimpleDto> {
  return createRequest({ method: 'POST', url: `policies/runs/${id}/acknowledge` });
}

export async function cancelPolicyRun(id: string): Promise<PolicyRunSimpleDto> {
  return createRequest({ method: 'POST', url: `policies/runs/${id}/cancel` });
}

export async function rerunPolicyRun(id: string): Promise<PolicyRunFullDto> {
  return createRequest({ method: 'POST', url: `policies/runs/${id}/rerun` });
}

export async function rerunPolicy(id: string): Promise<void> {
  return createRequest({ method: 'POST', url: `policies/${id}/rerun` });
}

export async function acknowledgeAllPolicyRuns(policyId: string): Promise<void> {
  return createRequest({ method: 'POST', url: `policies/${policyId}/acknowledge` });
}
