import { FC } from 'react';
import { Skeleton } from '@gravity-ui/uikit';
import css from './skeleton.module.scss';

export const ProfileSkeleton: FC = () => {
  return (
    <>
      <Skeleton className={css.TitleSkeleton} />
      <Skeleton className={css.TabSkeleton} />
      <Skeleton className={css.TabSkeleton} />
      <Skeleton className={css.ContentSkeleton} />
    </>
  );
};
