import { object, string, ref } from 'yup';
import i18next from '../../../../i18n/i18n';
import { notEmpty } from '../../../../utils/yup.validations';

export interface CaBackupForm {
  backup_password: string;
  backup_password_verify: string;
}

export interface CsrRequestForm {
  csr: string;
}

export const caBackupFormSchema = object({
  backup_password: string()
    .test(notEmpty(i18next.t('settings.tiles.pki.page.error_password_required')))
    .max(100),
  backup_password_verify: string().oneOf(
    [ref('backup_password'), null],
    i18next.t('settings.tiles.pki.page.error_passwords_must_match')
  )
}).required();

export const csrRequestFormSchema = object({
  csr: string().test(notEmpty(i18next.t('settings.tiles.pki.page.error_csr_required')))
}).required();
