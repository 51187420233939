import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import css from './settings-sections.module.scss';
import { Header } from '../../../components/header/header.component';
import { Tabs, TabsItemProps, Text } from '@gravity-ui/uikit';
import { AllTab } from './components/all-tab/all-tab.component';

enum SettingsPageTab {
  ALL = 'All',
  SYSTEM = 'System'
}

export const SettingsSections: FC = () => {
  const [activeTab, setActiveTab] = useState<string>(SettingsPageTab.ALL);
  const { t } = useTranslation();

  const handleChangeTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  const navBarItems: TabsItemProps[] = [
    {
      id: SettingsPageTab.ALL,
      title: t('settings.bar.items.all')
    },
    {
      id: SettingsPageTab.SYSTEM,
      title: t('settings.bar.items.system')
    }
  ];

  return (
    <div className={css.Root}>
      <Header />
      <div className={css.Content}>
        <div className={css.Title}>
          <Text variant="display-2">{t('settings.name')}</Text>
        </div>
        <Tabs
          className={css.NavBar}
          items={navBarItems}
          activeTab={activeTab}
          onSelectTab={handleChangeTab}
        />
        {activeTab === SettingsPageTab.ALL && <AllTab />}
      </div>
    </div>
  );
};
