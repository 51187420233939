import { t } from 'i18next';
import { number, object, string } from 'yup';
import { isEmail } from '../../../../../../../../../../../utils/yup.validations';

export interface DeviceUserFormValues {
  username: string;
  email?: string;
  employee_name?: string;
  phone_number?: string;
  position?: string;
  computers_count?: number;
  mobile_devices_count?: number;
}

export const deviceUserFormSchema = object({
  username: string().required(t('device_users.user_form.errors.username_required')),
  email: string().test(isEmail()),
  employee_name: string(),
  phone_number: string(),
  position: string(),
  computers_count: number().default(0),
  mobile_devices_count: number().default(0)
}).required();
