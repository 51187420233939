import { useState } from 'react';

const useRequest = <T = unknown>() => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const send = async (promise: Promise<T>, timeout?: number): Promise<T> => {
    setLoading(true);

    const startTime = Date.now();

    try {
      const response = await promise;

      const elapsedTime = Date.now() - startTime;
      if (timeout) {
        await new Promise((resolve) => setTimeout(resolve, Math.max(timeout - elapsedTime, 0)));
      }

      setData(response);
      setError(null);
      setLoading(false);
      return response;
    } catch (e) {
      const elapsedTime = Date.now() - startTime;
      if (timeout) {
        await new Promise((resolve) => setTimeout(resolve, Math.max(timeout - elapsedTime, 0)));
      }

      setError(e);
      setLoading(false);
      throw e;
    }
  };

  return { send, data, loading, error };
};

export default useRequest;
