import { FC, FormEvent, useState } from 'react';
import css from './policy-form.module.scss';
import { UseFormReturn } from 'react-hook-form';
import { PolicyRunsTab } from './components/policy-runs-tab/policy-runs-tab.component';
import { useTranslation } from 'react-i18next';
import { ScopeForm, ScopeValues } from '../../../../components/scope-form/scope-form.component';
import { Tabs, TabsItemProps } from '@gravity-ui/uikit';
import { OptionsTab } from './components/options-tab/options-tab.component';
import { PolicyFormValues } from './policy-form.schema';

enum PolicyFormTabs {
  OPTIONS = 'Options',
  SCOPE = 'Scope',
  POLICY_RUNS = 'Policy runs'
}

interface IProps {
  mode: 'view' | 'create' | 'edit';
  form: UseFormReturn<PolicyFormValues>;
  policyId?: string;
}

export const PolicyForm: FC<IProps> = (props) => {
  const { form, policyId, mode } = props;
  const { watch, setValue } = form;

  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<string>(PolicyFormTabs.OPTIONS);

  const scopeValues = watch().scope;
  const handleScopeChange = (input: Partial<ScopeValues>) => {
    const newScope = { ...scopeValues, ...input };
    setValue('scope', newScope);
  };

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleFormSubmit = (event: FormEvent) => event.preventDefault();

  const commonItems: TabsItemProps[] = [
    {
      id: PolicyFormTabs.OPTIONS,
      title: t('policies.policy_form.tab_options')
    },
    {
      id: PolicyFormTabs.SCOPE,
      title: t('policies.policy_form.tab_scope')
    }
  ];
  const notCreateItems: TabsItemProps[] = [
    {
      id: PolicyFormTabs.POLICY_RUNS,
      title: t('policies.policy_form.tab_policy_runs')
    }
  ];
  const items: TabsItemProps[] =
    mode === 'create' ? commonItems : [...commonItems, ...notCreateItems];

  return (
    <form onSubmit={handleFormSubmit}>
      <Tabs
        className={css.NavBar}
        items={items}
        activeTab={selectedTab}
        onSelectTab={handleTabChange}
      />
      {selectedTab === PolicyFormTabs.OPTIONS && <OptionsTab mode={mode} form={form} />}
      {selectedTab === PolicyFormTabs.SCOPE && (
        <ScopeForm values={scopeValues} disabled={mode === 'view'} onChange={handleScopeChange} />
      )}
      {selectedTab === PolicyFormTabs.POLICY_RUNS && <PolicyRunsTab policyId={policyId} />}
    </form>
  );
};
