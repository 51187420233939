import { FC, useEffect, useState } from 'react';
import {
  ConfigProfileSimpleDto,
  DeviceFullDto,
  GetConfigProfilesDto
} from '../../../../../../../../../types/api';
import { useNavigate } from 'react-router-dom';
import { getConfigProfilesForDevice } from '../../../../../../../../../api/config-profiles';
import { useTranslation } from 'react-i18next';
import useDeviceSection, {
  DeviceType
} from '../../../../../../../../contexts/device-section.context';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import css from './profiles-tab.module.scss';
import useRequest from '../../../../../../../../../hooks/useRequest';
import { Skeleton } from '@gravity-ui/uikit';

enum ProfileSortField {
  DisplayName = 'display_name'
}

interface IProps {
  device?: DeviceFullDto;
}

export const ProfilesTab: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const [profiles, setProfiles] = useState<ConfigProfileSimpleDto[]>([]);
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();
  const navigate = useNavigate();
  const getConfigProfilesRequest = useRequest<GetConfigProfilesDto>();

  useEffect(() => {
    if (!device) return;
    getConfigProfilesRequest
      .send(getConfigProfilesForDevice(device.id), 1000)
      .then((res) => setProfiles(res.config_profiles));
  }, []);

  const createProfileLink = (profile: ConfigProfileSimpleDto): string => {
    const profileDeviceType = deviceType === DeviceType.COMPUTERS ? 'computers' : 'mobile';
    return `/${profileDeviceType}/configuration-profiles/${profile.id}`;
  };

  const columns: Column<ConfigProfileSimpleDto>[] = [
    {
      id: ProfileSortField.DisplayName,
      name: t('inventory.device_page.management_tab.policies_tab.table.heading.title'),
      selector: (profile) => profile.display_name
    }
  ];
  const onRowClick = (profile: ConfigProfileSimpleDto) => navigate(createProfileLink(profile));

  return (
    <>
      {getConfigProfilesRequest.loading ? (
        <Skeleton style={{ width: '100%', height: 500 }} />
      ) : (
        <Table columns={columns} data={profiles} onRowClick={onRowClick} className={css.Table} />
      )}
    </>
  );
};
