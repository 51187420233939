import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetPkiCertificatesResponseDto, PkiCertificateSimpleDto } from '../../../../types/api';
import { useTranslation } from 'react-i18next';
import { getPkiCertificates } from '../../../../api/pki';
import { PaginationRowsPerPageOptions } from '../../../../const/pagination.const';
import { Column, PaginationState, Table } from '../../../components/table/table.component';
import css from './settings-pki-certificate-list.module.scss';
import useRequest from '../../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';

export const SettingsPkiCertificateList: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fetchRequest = useRequest<GetPkiCertificatesResponseDto>();

  const [certificates, setCertificates] = useState<PkiCertificateSimpleDto[]>([]);
  const [certificatesCount, setCertificatesCount] = useState(0);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: PaginationRowsPerPageOptions[0]
  });

  const fetchCertificates = async () => {
    const result = await fetchRequest.send(
      getPkiCertificates({
        page: paginationState.page,
        limit: paginationState.rowsPerPage
      })
    );
    setCertificates(result.certificates);
    setCertificatesCount(result.count);
  };

  useEffect(() => {
    void fetchCertificates();
  }, [paginationState]);

  const handleRowClick = (row: PkiCertificateSimpleDto) => {
    navigate(row.id);
  };

  const handleChangePagination = async (state: PaginationState) => {
    setPaginationState(state);
  };

  const columns: Column<PkiCertificateSimpleDto>[] = [
    {
      id: 'subject',
      name: t('settings.tiles.pki.page.subject'),
      selector: (row) => row.subject
    }
  ];

  return (
    <>
      {fetchRequest.loading ? (
        <TableSkeleton />
      ) : (
        <Table
          className={css.Table}
          columns={columns}
          data={certificates}
          pagination
          paginationState={paginationState}
          paginationTotalRows={certificatesCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          leftContent={<div></div>}
          onRowClick={handleRowClick}
          onChangePagination={handleChangePagination}
        />
      )}
    </>
  );
};
