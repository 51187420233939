import { FC, useState } from 'react';
import css from './sign-in.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useAuthContext } from '../../contexts/auth.context';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import { Paths } from '../../constants';
import { Eye, EyeSlash } from '@gravity-ui/icons';
import { Button, Icon, Text, TextInput } from '@gravity-ui/uikit';

interface IEyeButtonProps {
  opened: boolean;
  onClick: () => void;
}

const EyeButton = (props: IEyeButtonProps) => {
  const { opened, onClick } = props;
  return (
    <Button view="flat" size="xs" onClick={onClick}>
      <Icon data={opened ? Eye : EyeSlash} />
    </Button>
  );
};

interface ISignInFormData {
  username: string;
  password: string;
}

export const SignIn: FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authService } = useAuthContext();
  const schema = object({
    username: string().required(t('sign_in.username_required')),
    password: string().required(t('sign_in.password_required'))
  }).required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<ISignInFormData & { serverError: string }>({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: ISignInFormData) => {
    try {
      await authService.signIn(data.username, data.password);
      await authService.setLicenseStatus();
      navigate(Paths.HOME);
    } catch (error: unknown) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      setError('serverError', { message: localizedErrorString });
    }
  };

  const handleEyeButtonClick = () => setShowPassword(!showPassword);

  return (
    <div className={css.Root}>
      <form onSubmit={handleSubmit(onSubmit)} className={css.Form}>
        <div>
          <Text variant="subheader-2">{t('sign_in.username')}</Text>
          <TextInput
            type="text"
            size="l"
            placeholder={t('sign_in.username')}
            error={errors.username?.message}
            {...register('username')}
          />
        </div>
        <div>
          <Text variant="subheader-2">{t('sign_in.password')}</Text>
          <TextInput
            type={showPassword ? 'text' : 'password'}
            size="l"
            placeholder={t('sign_in.password')}
            error={errors.password?.message}
            endContent={<EyeButton opened={showPassword} onClick={handleEyeButtonClick} />}
            {...register('password')}
          />
        </div>
        {errors.serverError && (
          <Text color="danger" variant="body-2">
            {errors.serverError.message}
          </Text>
        )}
        <Button type="submit" view="action" size="l">
          <Text variant="body-2">{t('sign_in.sign_in_button')}</Text>
        </Button>
      </form>
    </div>
  );
};
