import { FC, ReactNode, useEffect, useState } from 'react';
import css from './device-extension-attributes.module.scss';
import {
  DeviceExtensionAttributeDto,
  DeviceFullDto,
  ExtensionAttributeDataType,
  ExtensionAttributeFullDto,
  ExtensionAttributeInputType,
  ExtensionAttributeInventorySection,
  GetExtensionAttributesResponseDto,
  Permission
} from '../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { getExtensionAttributes } from '../../../../../../../../../api/extension-attributes';
import {
  getDeviceExtensionAttributes,
  updateExtensionAttributeValue
} from '../../../../../../../../../api/device';
import { getLocalizedErrorString } from '../../../../../../../../../utils/localize-error';
import { formatDate } from '../../../../../../../../../utils/time.utils';
import { usePermission } from '../../../../../../../../contexts/permission.context';
import { Button, Icon, Select, SelectOption, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { DatePicker } from '@gravity-ui/date-components';
import { dateTimeParse } from '@gravity-ui/date-utils';
import { Pencil } from '@gravity-ui/icons';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import useRequest from '../../../../../../../../../hooks/useRequest';
import { WithClassname } from '../../../../../../../../../types/common';
import cn from 'classnames';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';

interface IProps {
  inventorySection: ExtensionAttributeInventorySection;
  displayHeading?: boolean;
  device?: DeviceFullDto;
}

interface DeviceExtensionAttributesValue {
  extension_attribute: ExtensionAttributeFullDto;
  extension_value?: string | null;
}

interface IKeyValue {
  key: string;
  value: ReactNode | string | null | undefined;
}

export const DeviceExtensionAttributes: FC<IProps & WithClassname> = (props) => {
  const { className, device, inventorySection, displayHeading = true } = props;
  const [currentlyChangingValues, setCurrentlyChangingValues] = useState<string[]>([]);
  const [deviceExtensionAttributes, setDeviceExtensionAttributes] = useState<
    DeviceExtensionAttributesValue[]
  >([]);
  const getExtensionAttributesRequest = useRequest<GetExtensionAttributesResponseDto>();
  const getDeviceExtensionAttributesRequest = useRequest<DeviceExtensionAttributeDto[]>();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();

  const getExtensionAttributesValues = async () => {
    const deviceAttributeValues: DeviceExtensionAttributesValue[] = [];

    const result = await getExtensionAttributesRequest.send(getExtensionAttributes(), 1000);
    result.extension_attributes.forEach((attribute) => {
      const deviceAttributeValue = device?.device_extension_attributes?.find(
        (attr) => attr.extension_attribute?.id === attribute.id
      )?.value;
      const deviceExtensionAttributesValue: DeviceExtensionAttributesValue = {
        extension_attribute: attribute,
        extension_value: deviceAttributeValue
      };
      deviceAttributeValues.push(deviceExtensionAttributesValue);
    });

    setDeviceExtensionAttributes(deviceAttributeValues);
  };

  const addToCurrentlyChanging = (id: string) => {
    const currentlyChangingValuesNow = currentlyChangingValues.slice();
    currentlyChangingValuesNow.push(id);
    setCurrentlyChangingValues(currentlyChangingValuesNow);
  };

  const handleEditExtensionAttributeClick = (id: string) => {
    addToCurrentlyChanging(id);
  };

  const handleSaveExtensionAttributesValuesClick = async () => {
    if (device) {
      for (let i = 0, l = deviceExtensionAttributes.length; i < l; i++) {
        const existingValue = device.device_extension_attributes?.find(
          (attr) =>
            attr.extension_attribute?.id === deviceExtensionAttributes[i].extension_attribute.id
        )?.value;
        try {
          if (deviceExtensionAttributes[i].extension_value) {
            if (existingValue !== deviceExtensionAttributes[i].extension_value) {
              await updateExtensionAttributeValue(device.id, {
                extension_attribute_id: deviceExtensionAttributes[i].extension_attribute.id,
                value: deviceExtensionAttributes[i].extension_value
              });
            }
          } else {
            await updateExtensionAttributeValue(device.id, {
              extension_attribute_id: deviceExtensionAttributes[i].extension_attribute.id,
              value: null
            });
          }
        } catch (error) {
          const localizedErrorString = getLocalizedErrorString(error as Error);
          console.log(localizedErrorString);
          toaster.add({
            name: 'save-attribute-values-error',
            title: localizedErrorString,
            theme: 'danger',
            autoHiding: 5000
          });
          return;
        }
      }
      device.device_extension_attributes = await getDeviceExtensionAttributesRequest.send(
        getDeviceExtensionAttributes(device.id),
        1000
      );
      setCurrentlyChangingValues([]);
    }
  };

  const handleCancelExtensionAttributesValuesClick = () => {
    setCurrentlyChangingValues([]);
    void getExtensionAttributesValues();
  };

  const handleAttributeChange = (
    deviceExtensionAttribute: DeviceExtensionAttributesValue,
    value: string | null | undefined
  ) => {
    const currentDeviceExtensionAttributes = deviceExtensionAttributes.slice();
    const attributeIndex = currentDeviceExtensionAttributes.findIndex(
      (attribute) => attribute.extension_attribute === deviceExtensionAttribute.extension_attribute
    );
    currentDeviceExtensionAttributes[attributeIndex].extension_value = value;
    setDeviceExtensionAttributes(currentDeviceExtensionAttributes);
  };

  useEffect(() => {
    void getExtensionAttributesValues();
  }, [device]);

  const data: IKeyValue[] = deviceExtensionAttributes
    .filter((attr) => attr.extension_attribute.inventory_display === inventorySection)
    .map((attr) => {
      const id = attr.extension_attribute.id;
      const displayName = attr.extension_attribute.display_name;
      const inputType = attr.extension_attribute.input_type;
      const dataType = attr.extension_attribute.data_type;
      const value = attr.extension_value;

      if (currentlyChangingValues.includes(id)) {
        // Editing mode
        switch (inputType) {
          case ExtensionAttributeInputType.Text:
            return {
              key: displayName,
              value:
                dataType === ExtensionAttributeDataType.Date ? (
                  <DatePicker
                    disabled={!isAllowedTo(Permission.EditDevices)}
                    value={dateTimeParse(value, { format: 'DD.MM.YYYY HH.mm' })}
                    onUpdate={(e) =>
                      handleAttributeChange(attr, e?.format('DD.MM.YYYY HH.mm') || '')
                    }
                    format="DD.MM.YYYY HH.mm"
                    hasClear
                    style={{ width: '100%' }}
                  />
                ) : (
                  <TextInput
                    autoFocus
                    disabled={!isAllowedTo(Permission.EditDevices)}
                    value={value || ''}
                    onUpdate={(v) => handleAttributeChange(attr, v)}
                    type={dataType === ExtensionAttributeDataType.Number ? 'number' : 'text'}
                  />
                )
            };
          case ExtensionAttributeInputType.Select:
            // eslint-disable-next-line no-case-declarations
            const options: SelectOption[] =
              attr.extension_attribute.options?.map((option) => {
                return {
                  value: option,
                  content: option
                };
              }) || [];

            return {
              key: displayName,
              value: (
                <Select
                  disabled={!isAllowedTo(Permission.EditDevices)}
                  value={value ? [value] : []}
                  onUpdate={(o) => handleAttributeChange(attr, o[0])}
                  options={options}
                  hasClear
                  width="max"
                />
              )
            };
          default:
            return {
              key: displayName,
              value: value
                ? dataType === ExtensionAttributeDataType.Date
                  ? formatDate(value)
                  : value
                : undefined
            };
        }
      } else {
        // Viewing mode
        return {
          key: displayName,
          value: (
            <div className={css.EditableValue}>
              <Text>
                {value
                  ? dataType === ExtensionAttributeDataType.Date
                    ? formatDate(value)
                    : value
                  : undefined}
              </Text>
              {inputType !== ExtensionAttributeInputType.Script && (
                <Button
                  disabled={!isAllowedTo(Permission.EditDevices)}
                  view="action"
                  onClick={() => handleEditExtensionAttributeClick(id)}
                >
                  <Icon data={Pencil} />
                </Button>
              )}
            </div>
          )
        };
      }
    });

  const columns: Column<IKeyValue>[] = [
    {
      id: 'key',
      selector: (row) => <Text variant="subheader-1">{row.key}</Text>,
      width: 250
    },
    {
      id: 'value',
      selector: (row) => row.value,
      align: 'end'
    }
  ];

  return (
    <div className={cn(className, css.Root)}>
      {displayHeading && (
        <Text variant="subheader-1">
          {t('inventory.device_page.inventory_tab.device_extension_attributes.heading')}
        </Text>
      )}
      {getExtensionAttributesRequest.loading || getDeviceExtensionAttributesRequest.loading ? (
        <TableSkeleton />
      ) : (
        <Table columns={columns} data={data} className={css.WithoutHead} />
      )}
      {currentlyChangingValues.length > 0 && (
        <div className={css.Actions}>
          <Button
            disabled={!isAllowedTo(Permission.EditDevices)}
            view="flat"
            onClick={handleCancelExtensionAttributesValuesClick}
          >
            {t('inventory.device_page.inventory_tab.device_extension_attributes.cancel_btn')}
          </Button>
          <Button
            disabled={!isAllowedTo(Permission.EditDevices)}
            view="action"
            onClick={handleSaveExtensionAttributesValuesClick}
          >
            {t('inventory.device_page.inventory_tab.device_extension_attributes.save_btn')}
          </Button>
        </div>
      )}
    </div>
  );
};
