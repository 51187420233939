export const InventoryDevicePageTabs = {
  INVENTORY: 'inventory',
  MANAGEMENT: 'management',
  HISTORY: 'history',
  TERMINAL: 'terminal'
};

export const InventoryTabTabs = {
  GENERAL: 'general',
  HARDWARE: 'hardware',
  OS_INFO: 'os_info',
  SECURITY: 'security',
  STORAGE: 'storage',
  ENCRYPTION: 'encryption',
  APPLICATIONS: 'applications',
  PROFILES: 'profiles',
  USER_ACCOUNTS: 'user_accounts',
  SERVICES: 'services',
  EXTENSION_ATTRIBUTES: 'extension_attributes',
  DEVICE_USER: 'device_user'
};

export const ManagementTabTabs = {
  COMMANDS: 'commands',
  POLICIES: 'policies',
  PROFILES: 'profiles',
  ACTIVATION: 'activation',
  GROUPS: 'groups'
};

export const HistoryTabTabs = {
  APP_USAGE: 'app_usage',
  LOGINS: 'logins',
  COMMANDS: 'commands',
  POLICIES: 'policies',
  PROFILES: 'profiles'
};

export const InventoryTabMobileTabs = {
  GENERAL: 'general',
  OS_INFO: 'os_info',
  HARDWARE: 'hardware',
  SECURITY: 'security',
  APPLICATIONS: 'applications',
  PROFILES: 'profiles',
  PROVISIONING_PROFILES: 'provisioning_profiles',
  CERTIFICATES: 'certificates'
};
