import {
  CreateSmartGroupDeviceUsersRequestDto,
  GetSmartGroupsDeviceUsersRequestDto,
  GetSmartGroupsDeviceUsersResponseDto,
  SmartGroupDeviceUsersFullDto,
  UpdateSmartGroupDeviceUsersRequestDto
} from '../types/api';
import { createRequest } from './create-request';

export async function getSmartGroups(
  params: GetSmartGroupsDeviceUsersRequestDto
): Promise<GetSmartGroupsDeviceUsersResponseDto> {
  return createRequest({ url: 'groups/device-users/smart', params });
}

export async function getSmartGroup(id: string): Promise<SmartGroupDeviceUsersFullDto> {
  return createRequest({ url: `groups/device-users/smart/${id}` });
}

export async function createSmartGroup(
  data: CreateSmartGroupDeviceUsersRequestDto
): Promise<SmartGroupDeviceUsersFullDto> {
  return createRequest({ method: 'POST', url: 'groups/device-users/smart', data });
}

export async function updateSmartGroup(
  id: string,
  data: UpdateSmartGroupDeviceUsersRequestDto
): Promise<SmartGroupDeviceUsersFullDto> {
  return createRequest({ method: 'PATCH', url: `groups/device-users/smart/${id}`, data });
}

export async function deleteSmartGroup(id: string): Promise<SmartGroupDeviceUsersFullDto> {
  return createRequest({ method: 'DELETE', url: `groups/device-users/smart/${id}` });
}
