import { FC, useEffect, useState } from 'react';
import {
  PackageSimpleDto,
  PolicyPayloadPackageModifyType,
  PolicyPayloadPackageDistributionType
} from '../../../../../../../../../types/api';
import css from './policy-options-packages.module.scss';
import cn from 'classnames';
import { WithClassname } from '../../../../../../../../../types/common';
import { getPackages } from '../../../../../../../../../api/packages';
import { useTranslation } from 'react-i18next';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import { Button, Select, SelectOption, Text } from '@gravity-ui/uikit';
import { UseFormReturn } from 'react-hook-form';
import { PackageValue, PolicyFormValues } from '../../../../policy-form.schema';

interface IProps {
  disabled?: boolean;
  form: UseFormReturn<PolicyFormValues>;
}

export const PolicyOptionsPackages: FC<IProps & WithClassname> = (props) => {
  const { className, form, disabled = false } = props;
  const { getValues, setValue } = form;

  const [fetchedPackages, setFetchedPackages] = useState<PackageSimpleDto[]>();
  const [areFetchedPackagesShown, setAreFetchedPackagesShown] = useState(
    !getValues().packages?.items
  );
  const { t } = useTranslation();

  const fetchPackages = async () => {
    const response = await getPackages();
    setFetchedPackages(response.packages);
  };

  const handleSelectAddedPackage = (pkg: PackageSimpleDto) => () => {
    const updatedAddedPackages: PackageValue[] = [
      ...(getValues().packages?.items || []),
      {
        id: pkg.id,
        display_name: pkg.display_name,
        modify_type: PolicyPayloadPackageModifyType.Install
      }
    ];
    setAreFetchedPackagesShown(false);
    setValue('packages', {
      distribution_type:
        getValues().packages?.distribution_type || PolicyPayloadPackageDistributionType.Default,
      items: updatedAddedPackages
    });
  };

  const handleAddPackage = () => {
    setAreFetchedPackagesShown(true);
  };

  const handleDeletePackage = (index: number) => () => {
    const updatedAddedPackages = getValues().packages?.items.filter((_, i) => i !== index);
    setValue('packages', {
      distribution_type:
        getValues().packages?.distribution_type || PolicyPayloadPackageDistributionType.Default,
      items: updatedAddedPackages || []
    });
  };

  const handleChangePackageAction = (index: number): ((value: string[]) => void) => {
    return (value) => {
      const updatedPackages = getValues().packages?.items.map((pkg, i) => {
        if (i !== index) return pkg;
        return { ...pkg, modify_type: value[0] as PolicyPayloadPackageModifyType };
      });
      setValue('packages', {
        distribution_type:
          getValues().packages?.distribution_type || PolicyPayloadPackageDistributionType.Default,
        items: updatedPackages || []
      });
    };
  };

  useEffect(() => {
    if (disabled) return;
    if (areFetchedPackagesShown) fetchPackages().then();
  }, [areFetchedPackagesShown]);

  const columns: Column<PackageSimpleDto>[] = [
    {
      id: 'name',
      name: t('policies.options_packages.column_package_name'),
      selector: (pkg) => pkg.display_name
    },
    {
      id: 'add',
      name: '',
      selector: (pkg) => (
        <Button view="outlined-success" onClick={handleSelectAddedPackage(pkg)}>
          {t('policies.options_packages.add_script_button')}
        </Button>
      ),
      width: 150,
      align: 'end'
    }
  ];
  const packageActionDropdownOptions: SelectOption[] = [
    {
      value: PolicyPayloadPackageModifyType.Install,
      content: t('policies.options_packages.package_action_dropdown_install')
    },
    {
      value: PolicyPayloadPackageModifyType.Cache,
      content: t('policies.options_packages.package_action_dropdown_cache')
    },
    {
      value: PolicyPayloadPackageModifyType.InstallCached,
      content: t('policies.options_packages.package_action_dropdown_install_cached')
    }
  ];
  const distributionTypeDropdownOptions: SelectOption[] = [
    {
      value: PolicyPayloadPackageDistributionType.Default,
      content: t('policies.options_packages.distribution_type_each_computer_default')
    }
  ];
  return (
    <div className={cn(css.Root, className)}>
      {areFetchedPackagesShown && fetchedPackages && (
        <Table columns={columns} data={fetchedPackages} />
      )}
      {!areFetchedPackagesShown && (
        <div className={css.AddedPackageListContainer}>
          <div className={css.AddedPackageListTop}>
            <Text variant="header-1">{t('policies.options_packages.added_packages')}</Text>
            {!disabled && (
              <Button view="action" onClick={handleAddPackage}>
                {t('policies.options_packages.add_package_button')}
              </Button>
            )}
          </div>
          <Select
            options={distributionTypeDropdownOptions}
            value={[PolicyPayloadPackageDistributionType.Default]}
            width="max"
          />
          {getValues().packages?.items.map((pkg, index) => (
            <div className={css.AddedPackage} key={index}>
              <div className={cn(css.AddedPackageHeader)}>
                <Text variant="body-2">{pkg.display_name}</Text>
                <div className={css.AddedPackageHeaderManagement}>
                  <Select
                    options={packageActionDropdownOptions}
                    value={[pkg.modify_type]}
                    onUpdate={handleChangePackageAction(index)}
                  />
                  {!disabled && (
                    <Button view="outlined-danger" onClick={handleDeletePackage(index)}>
                      {t('policies.options_packages.delete_package_button')}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
