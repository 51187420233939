import { object, number } from 'yup';
import i18next from '../../../../i18n/i18n';

export interface ScriptsConfigForm {
  policy_custom_script_timeout: number;
  get_device_information_timeout: number;
}

export const scriptsConfigFormSchema = object({
  policy_custom_script_timeout: number()
    .transform((value) => (value ? Number(value) : 0))
    .min(60, i18next.t('settings.tiles.scripts_config.page.error_timeout_min'))
    .max(86_400, i18next.t('settings.tiles.scripts_config.page.error_timeout_max_1_day')),
  get_device_information_timeout: number()
    .transform((value) => (value ? Number(value) : 0))
    .min(60, i18next.t('settings.tiles.scripts_config.page.error_timeout_min'))
    .max(43_200, i18next.t('settings.tiles.scripts_config.page.error_timeout_max_12_hours'))
}).required();
