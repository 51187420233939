import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import css from './role-list.module.scss';
import { usePermission } from '../../../contexts/permission.context';
import { GetRolesResponseDto, Permission, RoleSimpleDto } from '../../../../types/api';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { getRoles } from '../../../../api/roles';
import { dateSort, formatDate } from '../../../../utils/time.utils';
import { Column, Table } from '../../../components/table/table.component';
import { Button, Text, useToaster } from '@gravity-ui/uikit';
import { Header } from '../../../components/header/header.component';
import useRequest from '../../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';
import noDataImage from '../../../../assets/images/no-data.png';

export const RoleListComponent: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();
  const fetchRequest = useRequest<GetRolesResponseDto>();

  const [roles, setRoles] = useState<RoleSimpleDto[]>([]);
  const [roleCount, setRoleCount] = useState<number>(0);

  const roleColumns: Column<RoleSimpleDto>[] = [
    {
      id: 'role',
      name: t('settings.tiles.roles.page.table.heading.role'),
      selector: (row: RoleSimpleDto) => row.title
    },
    {
      id: 'created_at',
      name: t('settings.tiles.roles.page.table.heading.created'),
      selector: (row: RoleSimpleDto) => formatDate(row.created_at),
      sortable: (rowA, rowB) => dateSort(rowA.created_at, rowB.created_at)
    },
    {
      id: 'updated_at',
      name: t('settings.tiles.roles.page.table.heading.updated'),
      selector: (row: RoleSimpleDto) => formatDate(row.updated_at),
      sortable: (rowA, rowB) => dateSort(rowA.updated_at, rowB.updated_at)
    }
  ];

  const handleRowClick = (row: RoleSimpleDto) => {
    navigate(row.id);
  };

  const handleAddClick = () => {
    navigate('new');
  };

  const init = async () => {
    try {
      const response = await fetchRequest.send(getRoles(), 1000);
      setRoles(response.roles);
      setRoleCount(response.count);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'get-roles-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  useEffect(() => {
    void init();
  }, []);

  const tableTitle = <Text variant="display-2">{t('settings.tiles.roles.page.title')}</Text>;
  const headerContent = (
    <Button
      disabled={!isAllowedTo(Permission.EditAdministration)}
      onClick={handleAddClick}
      view="action"
    >
      {t('settings.tiles.roles.page.new_btn')}
    </Button>
  );

  return (
    <div className={css.Root}>
      <Header rightContent={headerContent} />
      <div className={css.DataTableContainer}>
        {fetchRequest.loading ? (
          <>
            <div className={css.Title}>{tableTitle}</div>
            <TableSkeleton />
          </>
        ) : roleCount === 0 ? (
          <>
            <div className={css.Title}>{tableTitle}</div>
            <div className={css.NoDataContainer}>
              <img alt="no-data" src={noDataImage} />
              <Text variant="subheader-3">{t('settings.tiles.roles.page.no_data')}</Text>
              {headerContent}
            </div>
          </>
        ) : (
          <Table
            columns={roleColumns}
            data={roles}
            onRowClick={handleRowClick}
            leftContent={tableTitle}
          />
        )}
      </div>
    </div>
  );
};
