import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import { t } from 'i18next';
import { Permission } from '../../../../../../types/api';
import { usePermission } from '../../../../../contexts/permission.context';
import css from './action-menu.module.scss';

interface IProps {
  mode: 'view' | 'update';
  onLdapCancel: () => void;
  onLdapEditStart: () => void;
  onLdapSave: () => void;
  isLoading: boolean;
}

export const ActionMenu: FC<IProps> = (props) => {
  const { mode, onLdapCancel, onLdapEditStart, onLdapSave, isLoading } = props;
  const { isAllowedTo } = usePermission();

  return (
    <>
      {mode === 'view' && (
        <Button
          view="action"
          onClick={onLdapEditStart}
          disabled={!isAllowedTo(Permission.EditAdministration)}
        >
          {t('settings.tiles.ldap.page.form_edit_btn')}
        </Button>
      )}
      {mode === 'update' && (
        <div className={css.ButtonGroup}>
          <Button view="flat" onClick={onLdapCancel}>
            {t('settings.tiles.ldap.page.form_cancel_btn')}
          </Button>
          <Button view="action" onClick={onLdapSave} loading={isLoading}>
            {t('settings.tiles.ldap.page.form_save_btn')}
          </Button>
        </div>
      )}
    </>
  );
};
