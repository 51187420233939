import { FC } from 'react';
import css from './general-option.module.scss';
import { UseFormReturn } from 'react-hook-form';
import { ConfigurationProfileFormValues } from '../../../../configuration-profile-form.schema';
import { useTranslation } from 'react-i18next';
import { Icon, Text, TextInput } from '@gravity-ui/uikit';
import { ArrowDownToSquare } from '@gravity-ui/icons';

interface IProps {
  form: UseFormReturn<ConfigurationProfileFormValues>;
  disabled?: boolean;
}

export const GeneralOption: FC<IProps> = (props) => {
  const { disabled = false, form } = props;
  const { register, formState, watch } = form;
  const { t } = useTranslation();

  const errors = formState.errors;

  return (
    <div className={css.Root}>
      <Text variant="subheader-2">{t('profiles.profile_form.options_tab.general.name')}</Text>
      {watch('imported_payload') && (
        <div>
          {t('profiles.profile_form.options_tab.general.imported')}{' '}
          <Icon data={ArrowDownToSquare} />
        </div>
      )}
      <div>
        <Text>{t('profiles.profile_form.options_tab.general.description')}</Text>
        <TextInput
          disabled={disabled}
          error={errors.description?.message}
          {...register('description')}
        />
      </div>
      <div>
        <Text>{t('profiles.profile_form.options_tab.general.organization_name')}</Text>
        <TextInput
          disabled={disabled}
          error={errors.organization_name?.message}
          {...register('organization_name')}
        />
      </div>
    </div>
  );
};
