import { FC } from 'react';
import { Skeleton } from '@gravity-ui/uikit';
import css from './form-table-skeleton.module.scss';

export const FormTableSkeleton: FC = () => {
  return (
    <div className={css.Root}>
      <Skeleton className={css.TabSkeleton} />
      <Skeleton className={css.FormSkeleton} />
      <Skeleton className={css.TableSkeleton} />
    </div>
  );
};
