import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import css from './action-menu.module.scss';
import { t } from 'i18next';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  disabled: boolean;
  onPackagePointCreate: () => void;
  onPackagePointCancel: () => void;
  onPackagePointDelete: () => void;
  onPackagePointEditStart: () => void;
  onPackagePointEdit: () => void;
}

export const ActionMenu: FC<IProps> = (props) => {
  const {
    mode,
    disabled,
    onPackagePointCreate,
    onPackagePointCancel,
    onPackagePointDelete,
    onPackagePointEditStart,
    onPackagePointEdit
  } = props;

  return (
    <div className={css.ButtonGroup}>
      {mode === 'create' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onPackagePointCancel}>
            {t(
              'settings.tiles.package_distribution_points.page.package_distribution_point_page.cancel_btn'
            )}
          </Button>
          <Button view="action" disabled={disabled} onClick={onPackagePointCreate}>
            {t(
              'settings.tiles.package_distribution_points.page.package_distribution_point_page.create_btn'
            )}
          </Button>
        </>
      )}
      {mode === 'edit' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onPackagePointCancel}>
            {t(
              'settings.tiles.package_distribution_points.page.package_distribution_point_page.cancel_btn'
            )}
          </Button>
          <Button view="action" disabled={disabled} onClick={onPackagePointEdit}>
            {t(
              'settings.tiles.package_distribution_points.page.package_distribution_point_page.save_btn'
            )}
          </Button>
        </>
      )}
      {mode === 'view' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onPackagePointDelete}>
            {t(
              'settings.tiles.package_distribution_points.page.package_distribution_point_page.delete_btn'
            )}
          </Button>
          <Button view="action" disabled={disabled} onClick={onPackagePointEditStart}>
            {t(
              'settings.tiles.package_distribution_points.page.package_distribution_point_page.edit_btn'
            )}
          </Button>
        </>
      )}
    </div>
  );
};
