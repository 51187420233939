import { FC, useEffect, useState } from 'react';
import {
  DeviceFullDto,
  GetGroupsForDeviceDto,
  GroupSimpleDto
} from '../../../../../../../../../types/api';
import { getGroupsForDevice } from '../../../../../../../../../api/groups';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDeviceSection, {
  DeviceType
} from '../../../../../../../../contexts/device-section.context';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import css from './groups-tab.module.scss';
import useRequest from '../../../../../../../../../hooks/useRequest';
import { Skeleton } from '@gravity-ui/uikit';

enum GroupSortField {
  Name = 'name',
  Type = 'type'
}

interface IProps {
  device?: DeviceFullDto;
}

export const GroupsTab: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const [groups, setGroups] = useState<GroupSimpleDto[]>([]);
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();
  const navigate = useNavigate();
  const getGroupsRequest = useRequest<GetGroupsForDeviceDto>();

  const createGroupLink = (group: GroupSimpleDto): string => {
    if (group.type === 'SmartDevices' || group.type === 'StaticDevices') {
      const groupDeviceType = deviceType === DeviceType.COMPUTERS ? 'computers' : 'mobile';
      const groupType = group.type === 'SmartDevices' ? 'smart-groups' : 'static-groups';
      return `/${groupDeviceType}/${groupType}/${group.id}`;
    } else {
      const groupType = group.type === 'SmartDeviceUsers' ? 'smart-groups' : 'static-groups';
      return `/device-users/${groupType}/${group.id}`;
    }
  };
  useEffect(() => {
    if (!device) return;
    getGroupsRequest.send(getGroupsForDevice(device.id), 1000).then((res) => setGroups(res.groups));
  }, []);

  const columns: Column<GroupSimpleDto>[] = [
    {
      id: GroupSortField.Name,
      name: t('inventory.device_page.management_tab.groups_tab.table.heading.name'),
      selector: (group) => group.title
    },
    {
      id: GroupSortField.Type,
      name: t('inventory.device_page.management_tab.groups_tab.table.heading.type'),
      selector: (group) => t(`inventory.device_page.management_tab.groups_tab.table.${group.type}`)
    }
  ];
  const onRowClick = (group: GroupSimpleDto) => navigate(createGroupLink(group));

  return (
    <>
      {getGroupsRequest.loading ? (
        <Skeleton style={{ width: '100%', height: 500 }} />
      ) : (
        <Table columns={columns} data={groups} onRowClick={onRowClick} className={css.Table} />
      )}
    </>
  );
};
