import { object, string } from 'yup';
import { notEmpty } from '../../../../../../utils/yup.validations';
import i18next from '../../../../../../i18n/i18n';

export interface ScriptFormValues {
  display_name: string;
  script: string;
  description?: string;
}

export const scriptFormSchema = object({
  display_name: string()
    .required(i18next.t('settings.tiles.scripts.page.error.no_name'))
    .test(notEmpty(i18next.t('settings.tiles.scripts.page.error.no_name'))),
  description: string(),
  script: string()
    .required(i18next.t('settings.tiles.scripts.page.error.no_script_body'))
    .test(notEmpty(i18next.t('settings.tiles.scripts.page.error.empty_script')))
}).required();
