import { t } from 'i18next';
import { array, boolean, object, string } from 'yup';
import { notEmpty } from '../../../../../../utils/yup.validations';
import i18next from '../../../../../../i18n/i18n';

export interface IFormPermission {
  resource: string;
  read: boolean;
  edit: boolean;
}

export interface RoleFormValues {
  title: string;
  permissions: IFormPermission[];
  superadmin: boolean;
}

export const resourceNames = [
  'Devices',
  'Groups',
  'Profiles',
  'Policies',
  'Commands',
  'Scripts',
  'Packages',
  'Administration',
  'DeviceUsers',
  'DeviceApplications'
];

const defaultPermissions = resourceNames.map((resource) => ({
  resource,
  read: false,
  edit: false
}));

export const roleFormSchema = object({
  title: string()
    .required(t('settings.tiles.roles.page.role_page.role_title_required'))
    .test(notEmpty(i18next.t('settings.tiles.roles.page.role_page.role_title_required'))),
  permissions: array()
    .of(
      object({
        resource: string().required().oneOf(resourceNames),
        read: boolean().required().default(false),
        edit: boolean().required().default(false)
      })
    )
    .required()
    .default(defaultPermissions),
  superadmin: boolean()
}).required();
