import { FC, FormEvent, useEffect, useState } from 'react';
import css from './settings-package.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { CreatePackageDto, PackageSimpleDto, Permission } from '../../../../types/api';
import { createPackage, deletePackage, getPackage, updatePackage } from '../../../../api/packages';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PackageForm, packageFormSchema } from './settings-package.schema';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { usePermission } from '../../../contexts/permission.context';
import { Dialog, Popover, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { Header } from '../../../components/header/header.component';
import { ActionMenu } from './components/action-menu/action-menu.component';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';

export const SettingsPackageComponent: FC = () => {
  const { id } = useParams();
  const crudRequest = useRequest<PackageSimpleDto>();
  const navigate = useNavigate();
  const toaster = useToaster();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset
  } = useForm<PackageForm>({
    mode: 'onChange',
    resolver: yupResolver(packageFormSchema),
    defaultValues: packageFormSchema.getDefault()
  });

  const [pkg, setPkg] = useState<PackageSimpleDto>();
  const [prevValues, setPrevValues] = useState<PackageForm>();
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const [formMode, setFormMode] = useState<'create' | 'edit' | 'view'>(
    id === 'new' ? 'create' : 'view'
  );

  const handleFormSubmit = async (event: FormEvent) => {
    if (!id || crudRequest.loading) return;
    event.preventDefault();
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalShown(true);
  };

  const handleDeleteModalClose = () => {
    if (crudRequest.loading) return;
    setIsDeleteModalShown(false);
  };

  const handlePackageDelete = async () => {
    if (!id || crudRequest.loading) return;
    try {
      await crudRequest.send(deletePackage(id));
      navigate(`/settings/packages`, { replace: true });
      toaster.add({
        name: 'delete-package-success',
        content: t('settings.tiles.packages.page.package_page.successfully_delete', {
          title: pkg?.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'delete-package-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
    handleDeleteModalClose();
  };

  const handlePackageCreate = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    const values = getValues();
    try {
      const requestBody: CreatePackageDto = {
        display_name: values.display_name,
        filename: values.filename
      };
      const created = await crudRequest.send(createPackage(requestBody));
      setPkg(created);
      setFormMode('view');
      setValue('display_name', created.display_name);
      setValue('filename', created.filename);

      navigate(`/settings/packages/${created.id}`, { replace: true });
      toaster.add({
        name: 'create-package-success',
        content: t('settings.tiles.packages.page.package_page.successfully_create', {
          title: created.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error: unknown) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'create-package-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handlePackageUpdate = async () => {
    const isValid = await trigger();
    if (!id || !isValid) return;

    const values = getValues();
    try {
      const requestBody: CreatePackageDto = {
        display_name: values.display_name,
        filename: values.filename
      };
      const updated = await crudRequest.send(updatePackage(id, requestBody));
      setPkg(updated);
      setValue('display_name', updated.display_name);
      setValue('filename', updated.filename);

      setFormMode('view');
      navigate(`/settings/packages/${updated.id}`, { replace: true });
      toaster.add({
        name: 'update-package-success',
        content: t('settings.tiles.packages.page.package_page.successfully_update', {
          title: updated.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error: unknown) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'update-package-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handlePackageEditStart = () => {
    setPrevValues(getValues());
    setFormMode('edit');
  };
  const handlePackageCancel = () => {
    if (formMode === 'create') {
      navigate(`/settings/packages`);
      return;
    }
    reset(prevValues);
    setFormMode('view');
  };

  useEffect(() => {
    const init = async () => {
      if (!id || id === 'new') return;
      try {
        const result = await crudRequest.send(getPackage(id));
        setPkg(result);
        setValue('display_name', result.display_name);
        setValue('filename', result.filename);
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        console.log(localizedErrorString);
        toaster.add({
          name: 'get-package-error',
          content: localizedErrorString,
          theme: 'danger',
          autoHiding: 5000
        });
        navigate(`/settings/packages`, { replace: true });
      }
    };
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={pkg?.display_name}
        rightContent={
          <ActionMenu
            mode={formMode}
            disabled={crudRequest.loading || !isAllowedTo(Permission.EditPackages)}
            onPackageCancel={handlePackageCancel}
            onPackageCreate={handlePackageCreate}
            onPackageDelete={handleDeleteModalOpen}
            onPackageEditStart={handlePackageEditStart}
            onPackageSave={handlePackageUpdate}
          />
        }
      />
      <div className={css.Content}>
        {crudRequest.loading ? (
          <FormSkeleton />
        ) : (
          <>
            <div className={css.Title}>
              {formMode === 'view' && <Text variant="display-2">{pkg?.display_name}</Text>}
              {formMode !== 'view' && (
                <Popover content={t('common.click_to_edit')}>
                  <TextInput
                    placeholder={t('settings.tiles.packages.page.package_page.display_name')}
                    size="xl"
                    view="clear"
                    error={errors.display_name?.message}
                    errorPlacement="inside"
                    controlProps={{ className: css.TitleInput }}
                    {...register('display_name')}
                  />
                </Popover>
              )}
            </div>
            <form onSubmit={handleFormSubmit}>
              <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditPackages)}>
                <div>
                  <Text>{t('settings.tiles.packages.page.package_page.filename')}</Text>
                  <TextInput
                    error={errors.filename?.message}
                    disabled={formMode === 'view'}
                    {...register('filename')}
                  />
                </div>
              </fieldset>
            </form>
          </>
        )}
      </div>
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalShown}>
        <Dialog.Header
          caption={t('settings.tiles.packages.page.package_page.delete_modal.title')}
        />
        <Dialog.Body>
          <Text>
            {t('settings.tiles.packages.page.package_page.delete_modal.message', {
              package_name: pkg?.display_name
            })}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('settings.tiles.packages.page.package_page.delete_modal.delete_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handlePackageDelete}
          onClickButtonCancel={handleDeleteModalClose}
          loading={crudRequest.loading}
        />
      </Dialog>
    </div>
  );
};
