import {
  CreatePolicyRequestDto,
  CreatePolicyScopeRequestDto,
  PolicyFullDto,
  PolicyPayload,
  PolicyTrigger,
  ScopeEntryOperationType,
  ScopeOptionType
} from '../../../types/api';
import { isEmptyObject } from '../../../utils/assert.utils';
import { PolicyFormValues } from './components/policy-form/policy-form.schema';

export function formToPolicy(values: PolicyFormValues): CreatePolicyRequestDto {
  const triggers: PolicyTrigger[] = (
    Object.entries(values.general.triggers) as [PolicyTrigger, boolean][]
  )
    .filter(([_, value]) => value)
    .map(([key]) => key);
  const payload: PolicyPayload = {};
  if (values.scripts) {
    payload.scripts = values.scripts.map((script) => ({
      script_id: script.id,
      script_arguments: script.arguments
    }));
  }
  if (values.packages) {
    payload.packages = {
      distribution_type: values.packages.distribution_type,
      items: values.packages.items.map((item) => ({
        package_id: item.id,
        modify_type: item.modify_type
      }))
    };
  }
  if (values.maintenance) {
    payload.maintenance = {
      update_inventory: values.maintenance.update_inventory
    };
  }
  const scopeEntriesInclude: CreatePolicyScopeRequestDto[] = values.scope.scopeEntriesInclude.map(
    (entry) => ({
      id: entry.id,
      type: entry.type,
      operation: ScopeEntryOperationType.Include
    })
  );
  const scopeEntriesExclude: CreatePolicyScopeRequestDto[] = values.scope.scopeEntriesExclude.map(
    (entry) => ({
      id: entry.id,
      type: entry.type,
      operation: ScopeEntryOperationType.Exclude
    })
  );
  const scopeEntries = [
    ...(values.scope?.scoped ? scopeEntriesInclude : []),
    ...scopeEntriesExclude
  ];
  const intervalAmount = triggers.includes(PolicyTrigger.Interval)
    ? Number(values.general.interval_amount)
    : undefined;
  return {
    title: values.general.display_name,
    enabled: values.general.enabled,
    scoped: values.scope.scoped,
    configuration: {
      triggers,
      frequency: values.general.frequency,
      interval_amount: intervalAmount
      // custom_event_emit: values.general.custom_event_emit || undefined,
      // custom_event_trigger: values.general.custom_event_trigger || undefined
    },
    scope_entries: scopeEntries,
    payload: isEmptyObject(payload) ? null : payload
  };
}

export function policyToForm(input: PolicyFullDto): PolicyFormValues {
  if (!input.configuration) {
    throw new Error('Missing configuration');
  }

  const triggers = Object.fromEntries(input.configuration.triggers.map((i) => [i, true]));
  const scripts = input.payload?.scripts;
  const packages = input.payload?.packages;
  const maintenance = input.payload?.maintenance;
  return {
    general: {
      display_name: input.title,
      enabled: input.enabled,
      triggers: triggers as Record<PolicyTrigger, boolean>,
      frequency: input.configuration.frequency,
      interval_amount: input.configuration.interval_amount
      // custom_event_emit: input.configuration.custom_event_emit,
      // custom_event_trigger: input.configuration.custom_event_trigger
    },
    scope: {
      scoped: input.scoped,
      scopeEntriesExclude:
        input.scope_entries
          ?.filter((i) => i.operation_type === 'Exclude')
          .map((i) => ({
            id: i.target_id,
            display_name: i.target_title || '',
            type: i.target_type as ScopeOptionType
          })) || [],
      scopeEntriesInclude:
        input.scope_entries
          ?.filter((i) => i.operation_type === 'Include')
          .map((i) => ({
            id: i.target_id,
            display_name: i.target_title || '',
            type: i.target_type as ScopeOptionType
          })) || []
    },
    scripts: scripts?.map((i) => {
      return {
        id: i.script_id as string,
        display_name: i.script_title as string,
        arguments: i.script_arguments || []
      };
    }),
    ...(packages && {
      packages: {
        distribution_type: packages.distribution_type,
        items: packages.items.map((item) => ({
          id: item.package_id,
          display_name: item.package_title || '',
          modify_type: item.modify_type
        }))
      }
    }),
    maintenance: {
      update_inventory: maintenance?.update_inventory || false
    }
  };
}
