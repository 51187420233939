import { FC, useEffect, useState } from 'react';
import css from './history-tab.module.scss';
import {
  DeviceFullDto,
  GetHistoryResponseDto,
  HistoryDto,
  HistoryElementType,
  HistoryMdmCommandType,
  HistorySortField,
  PaginationSortOrder
} from '../../../../../../../types/api';
import { getHistory } from '../../../../../../../api/device';
import { formatDate } from '../../../../../../../utils/time.utils';
import { deviceHistoryType, historyMdmCommandType } from '../../../../../../../const/history.const';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../../../../../const/pagination.const';
import {
  Column,
  PaginationState,
  SortState,
  Table
} from '../../../../../../components/table/table.component';
import { DatePicker } from '@gravity-ui/date-components';
import { dateTime, DateTime } from '@gravity-ui/date-utils';
import { Button } from '@gravity-ui/uikit';
import useRequest from '../../../../../../../hooks/useRequest';
import { HistoryTableSkeleton } from './components/history-table-skeleton/history-table-skeleton.component';

interface IProps {
  device?: DeviceFullDto;
}

export const HistoryTab: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const [historyData, setHistoryData] = useState<HistoryDto[]>([]);
  const [historyCount, setHistoryCount] = useState(0);
  const getHistoryRequest = useRequest<GetHistoryResponseDto>();

  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: 10
  });
  const [sortState, setSortState] = useState<SortState>({
    column: HistorySortField.CreatedAt,
    order: PaginationSortOrder.Desc
  });

  const [startTime, setStartTime] = useState<DateTime>();
  const [endTime, setEndTime] = useState<DateTime>();

  const [confirmedStartTime, setConfirmedStartTime] = useState<string>('');
  const [confirmedEndTime, setConfirmedEndTime] = useState<string>('');

  const { t } = useTranslation();

  const getHistoryForDevice = () => {
    if (!device) return;
    getHistoryRequest
      .send(
        getHistory({
          element_type: HistoryElementType.Device,
          element_id: device.id,
          ...(confirmedStartTime ? { start_time: `${confirmedStartTime}T00:00:00.000Z` } : {}),
          ...(confirmedEndTime ? { end_time: `${confirmedEndTime}T23:59:59.999Z` } : {}),
          page: paginationState.page,
          limit: paginationState.rowsPerPage,
          sort_field: sortState.column as HistorySortField,
          sort_order: sortState.order as PaginationSortOrder
        }),
        1000
      )
      .then((req: GetHistoryResponseDto) => {
        const history = req.history;
        setHistoryData(history);
        setHistoryCount(req.count);
      });
  };

  const handleFilterClick = () => {
    setConfirmedStartTime(startTime?.format('YYYY-MM-DD') || '');
    setConfirmedEndTime(endTime?.format('YYYY-MM-DD') || '');
  };

  const handleStartTimeUpdate = (date: DateTime | null) => {
    setStartTime(date || undefined);
  };

  const handleEndTimeUpdate = (date: DateTime | null) => {
    setEndTime(date || undefined);
  };

  const handleChangePagination = (state: PaginationState) => {
    setPaginationState(state);
  };

  const handleChangeSort = (state?: SortState) => {
    setSortState(state || { column: HistorySortField.CreatedAt, order: PaginationSortOrder.Asc });
  };

  useEffect(() => {
    getHistoryForDevice();
  }, [paginationState, sortState, confirmedStartTime, confirmedEndTime]);

  const columns: Column<HistoryDto>[] = [
    {
      id: HistorySortField.HistoryType,
      name: t('inventory.device_page.history_tab.table.heading.event'),
      selector: (row) => (row.history_type ? deviceHistoryType[row.history_type] : '')
    },
    {
      id: HistorySortField.AdditionalInfo,
      name: t('inventory.device_page.history_tab.table.heading.info'),
      selector: (row) =>
        row.additional_info &&
        Object.keys(row.additional_info).length &&
        Object.entries(row.additional_info)[0].includes('command')
          ? historyMdmCommandType[Object.values(row.additional_info)[0] as HistoryMdmCommandType]
          : ''
    },
    {
      id: HistorySortField.CreatedAt,
      name: t('inventory.device_page.history_tab.table.heading.time'),
      selector: (row) => (row.created_at ? formatDate(row.created_at) : ''),
      sortable: true
    }
  ];

  const HistoryDateFilter = () => (
    <div className={css.HistoryDateFilter}>
      <DatePicker
        value={startTime}
        onUpdate={handleStartTimeUpdate}
        maxValue={endTime}
        format="DD.MM.YYYY"
        hasClear
        style={{ minWidth: 140 }}
      />
      <DatePicker
        value={endTime}
        onUpdate={handleEndTimeUpdate}
        minValue={startTime}
        maxValue={dateTime()}
        format="DD.MM.YYYY"
        hasClear
        style={{ minWidth: 140 }}
      />
      <Button view="action" onClick={handleFilterClick}>
        {t('inventory.device_page.history_tab.filter_btn')}
      </Button>
    </div>
  );

  return (
    <>
      {getHistoryRequest.loading ? (
        <HistoryTableSkeleton />
      ) : (
        <Table
          className={css.Table}
          columns={columns}
          data={historyData}
          pagination
          paginationTotalRows={historyCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          paginationState={paginationState}
          onChangePagination={handleChangePagination}
          onChangeSort={handleChangeSort}
          sortState={sortState}
          leftContent={<HistoryDateFilter />}
        />
      )}
    </>
  );
};
