import { FC } from 'react';
import css from './encryption-table.module.scss';
import { DeviceFullDto } from '../../../../../../../../../types/api';
import { isBoolean } from '../../../../../../../../../utils/assert.utils';
import { useTranslation } from 'react-i18next';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import { Text } from '@gravity-ui/uikit';

interface IProps {
  device?: DeviceFullDto;
}

interface IKeyValue {
  key: string;
  value: string | null | undefined;
}

export const EncryptionTable: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const di = device?.device_information;

  const data: IKeyValue[] = [
    {
      key: t(
        'inventory.device_page.inventory_tab.disk_encryption_tab.table.heading.encryption_state'
      ),
      value: isBoolean(di?.filevault_enabled)
        ? di?.filevault_enabled
          ? t('inventory.device_page.inventory_tab.disk_encryption_tab.table.enabled')
          : t('inventory.device_page.inventory_tab.disk_encryption_tab.table.disabled')
        : undefined
    },
    {
      key: t(
        'inventory.device_page.inventory_tab.disk_encryption_tab.table.heading.institutional_recovery_key_status'
      ),
      value: isBoolean(di?.filevault_has_institutional_key)
        ? di?.filevault_has_institutional_key
          ? t('inventory.device_page.inventory_tab.disk_encryption_tab.table.enabled')
          : t('inventory.device_page.inventory_tab.disk_encryption_tab.table.disabled')
        : undefined
    },
    {
      key: t(
        'inventory.device_page.inventory_tab.disk_encryption_tab.table.heading.personal_recovery_key_status'
      ),
      value: isBoolean(di?.filevault_has_personal_key)
        ? di?.filevault_has_personal_key
          ? t('inventory.device_page.inventory_tab.disk_encryption_tab.table.enabled')
          : t('inventory.device_page.inventory_tab.disk_encryption_tab.table.disabled')
        : undefined
    },
    {
      key: t(
        'inventory.device_page.inventory_tab.disk_encryption_tab.table.heading.personal_recovery_key'
      ),
      value: di?.personal_recovery_key
    }
  ];

  const columns: Column<IKeyValue>[] = [
    {
      id: 'key',
      selector: (row) => <Text variant="subheader-1">{row.key}</Text>,
      width: 250
    },
    {
      id: 'value',
      selector: (row) => row.value
    }
  ];

  return (
    <>
      <Table columns={columns} data={data} className={css.Table} />
    </>
  );
};
