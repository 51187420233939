import {
  SaveLdapInstanceDto,
  LdapInstanceFullDto,
  TestLdapConnectionDto,
  SearchLdapUsersResponseDto,
  SearchLdapUsersRequestDto
} from '../types/api';
import { createRequest } from './create-request';

export async function getCurrentLdapInstance(): Promise<LdapInstanceFullDto> {
  return createRequest({ url: 'ldap' });
}

export async function saveLdapInstance(data: SaveLdapInstanceDto): Promise<LdapInstanceFullDto> {
  return createRequest({ url: 'ldap', method: 'POST', data });
}

export async function testLdapConnection(data: TestLdapConnectionDto): Promise<void> {
  return createRequest({ url: 'ldap/test', method: 'POST', data });
}

export async function searchUsers(
  data: SearchLdapUsersRequestDto
): Promise<SearchLdapUsersResponseDto> {
  return createRequest({ url: 'ldap/users/search', method: 'POST', data });
}
