import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import css from './configuration-profile-list.module.scss';
import {
  ConfigProfileSimpleDto,
  ConfigProfilesSortField,
  DeviceType as ApiDeviceType,
  GetConfigProfilesDto,
  PaginationSortOrder,
  Permission
} from '../../../types/api';
import { getConfigProfiles } from '../../../api/config-profiles';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import { usePermission } from '../../contexts/permission.context';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';
import { Column, PaginationState, SortState, Table } from '../../components/table/table.component';
import { Header } from '../../components/header/header.component';
import { Button, Icon, Text, Tooltip } from '@gravity-ui/uikit';
import { ArrowDownToSquare } from '@gravity-ui/icons';
import useRequest from '../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';

export const ConfigurationProfileList: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const deviceSection = useDeviceSection();
  const deviceType =
    deviceSection.deviceType === DeviceType.COMPUTERS
      ? ApiDeviceType.Computer
      : ApiDeviceType.Mobile;

  const [configProfilesData, setConfigProfilesData] = useState<ConfigProfileSimpleDto[]>([]);
  const [configProfilesCount, setConfigProfilesCount] = useState(0);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: 10
  });
  const [sortState, setSortState] = useState<SortState>();
  const fetchConfigProfilesRequest = useRequest<GetConfigProfilesDto>();

  const handleRowClicked = (row: ConfigProfileSimpleDto) => {
    navigate(row.id);
  };

  const handleChangePage = (state: PaginationState) => setPaginationState(state);

  const handleSort = (state?: SortState) => setSortState(state);

  const handleAddClick = () => navigate('new');

  const handleImportClick = () => navigate('import');

  useEffect(() => {
    const fetchConfigProfilesData = async () => {
      const result: GetConfigProfilesDto = await fetchConfigProfilesRequest.send(
        getConfigProfiles({
          page: paginationState.page,
          limit: paginationState.rowsPerPage,
          sort_field: sortState?.column as ConfigProfilesSortField,
          sort_order: sortState?.order as PaginationSortOrder,
          device_type: deviceType
        }),
        1000
      );

      setConfigProfilesData(result.config_profiles);
      setConfigProfilesCount(result.count);
    };

    void fetchConfigProfilesData();
  }, [paginationState, sortState]);

  const columns: Column<ConfigProfileSimpleDto>[] = [
    {
      id: ConfigProfilesSortField.DisplayName,
      name: t('profiles.table.heading.name'),
      selector: (row) => (
        <div className={css.TableDisplayName}>
          {row.display_name}
          {row.imported && (
            <Tooltip content={t('profiles.table.label.imported')}>
              <Icon data={ArrowDownToSquare} />
            </Tooltip>
          )}
        </div>
      ),
      sortable: true
    },
    {
      id: ConfigProfilesSortField.CompletedProfileInstancesCount,
      name: t('profiles.table.heading.completed'),
      selector: (row: ConfigProfileSimpleDto) => row.completed_profile_instances_count,
      sortable: true
    },
    {
      id: ConfigProfilesSortField.PendingProfileInstancesCount,
      name: t('profiles.table.heading.pending'),
      selector: (row: ConfigProfileSimpleDto) => row.pending_profile_instances_count,
      sortable: true
    },
    {
      id: ConfigProfilesSortField.FailedProfileInstancesCount,
      name: t('profiles.table.heading.failed'),
      selector: (row: ConfigProfileSimpleDto) => row.failed_profile_instances_count,
      sortable: true
    },
    {
      id: ConfigProfilesSortField.Scoped,
      name: t('profiles.table.heading.scope'),
      selector: (row: ConfigProfileSimpleDto) =>
        row.scoped
          ? t('policy_list.policy_table_specific_computers')
          : t('policy_list.policy_table_all_computers')
    }
  ];

  const headerButtons = (
    <div className={css.BtnGroup}>
      <Button
        view="action"
        disabled={!isAllowedTo(Permission.EditProfiles)}
        onClick={handleAddClick}
      >
        {t('profiles.new_btn')}
      </Button>
      <Button
        view="action"
        disabled={!isAllowedTo(Permission.EditProfiles)}
        onClick={handleImportClick}
      >
        {t('profiles.upload_btn')}
      </Button>
    </div>
  );

  const tableTitle = <Text variant="display-2">{t('profiles.title')}</Text>;

  return (
    <div className={css.Root}>
      <Header rightContent={headerButtons} />
      <div className={css.DataTableContainer}>
        {fetchConfigProfilesRequest.loading ? (
          <TableSkeleton />
        ) : (
          <Table
            columns={columns}
            data={configProfilesData}
            onRowClick={handleRowClicked}
            onChangeSort={handleSort}
            onChangePagination={handleChangePage}
            pagination
            paginationTotalRows={configProfilesCount}
            paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
            paginationState={paginationState}
            sortState={sortState}
            leftContent={tableTitle}
          />
        )}
      </div>
    </div>
  );
};
