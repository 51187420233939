import { FC, FormEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import css from './role-form.module.scss';
import { Permission } from '../../../../../../types/api';
import { usePermission } from '../../../../../contexts/permission.context';
import { IFormPermission, RoleFormValues } from './role-form.schema';
import { Checkbox } from '@gravity-ui/uikit';
import { Column, Table } from '../../../../../components/table/table.component';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  form: UseFormReturn<RoleFormValues>;
}

export const RoleFormComponent: FC<IProps> = (props) => {
  const { mode, form } = props;
  const { t } = useTranslation();
  const { isAllowedTo, isSuperAdmin } = usePermission();

  const watchedSuperadmin = form.watch(`superadmin`);

  const resourceTitles: Record<string, string> = {
    ['Devices']: t('settings.tiles.roles.page.role_page.table.resource_titles.devices'),
    ['Groups']: t('settings.tiles.roles.page.role_page.table.resource_titles.groups'),
    ['Profiles']: t('settings.tiles.roles.page.role_page.table.resource_titles.profiles'),
    ['Policies']: t('settings.tiles.roles.page.role_page.table.resource_titles.policies'),
    ['Commands']: t('settings.tiles.roles.page.role_page.table.resource_titles.commands'),
    ['Scripts']: t('settings.tiles.roles.page.role_page.table.resource_titles.scripts'),
    ['Packages']: t('settings.tiles.roles.page.role_page.table.resource_titles.packages'),
    ['Administration']: t(
      'settings.tiles.roles.page.role_page.table.resource_titles.administration'
    ),
    ['DeviceUsers']: t('settings.tiles.roles.page.role_page.table.resource_titles.device_users'),
    ['DeviceApplications']: t(
      'settings.tiles.roles.page.role_page.table.resource_titles.device_applications'
    )
  };

  const permissionColumns: Column<IFormPermission>[] = [
    {
      id: 'resource',
      name: t('settings.tiles.roles.page.role_page.table.heading.resource'),
      selector: (row) => resourceTitles[row.resource] || row.resource
    },
    {
      id: 'read',
      name: t('settings.tiles.roles.page.role_page.table.heading.read'),
      selector: (row, index) => {
        const watchedRead = form.watch(`permissions.${index}.read`);
        return (
          <Checkbox
            className={css.Checkbox}
            key={`Read${row.resource}`}
            disabled={mode === 'view' || watchedSuperadmin}
            checked={watchedRead || watchedSuperadmin}
            onUpdate={(checked) => form.setValue(`permissions.${index}.read`, checked)}
          />
        );
      }
    },
    {
      id: 'edit',
      name: t('settings.tiles.roles.page.role_page.table.heading.edit'),
      selector: (row, index) => {
        const watchedEdit = form.watch(`permissions.${index}.edit`);
        return (
          <Checkbox
            className={css.Checkbox}
            key={`Edit${row.resource}`}
            disabled={mode === 'view' || watchedSuperadmin}
            checked={watchedEdit || watchedSuperadmin}
            onUpdate={(checked) => form.setValue(`permissions.${index}.edit`, checked)}
          />
        );
      }
    }
  ];

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditAdministration)}>
        <div>
          <Checkbox
            className={css.Checkbox}
            content={t('settings.tiles.users.page.user_page.superadmin')}
            disabled={!isSuperAdmin || mode === 'view'}
            checked={watchedSuperadmin}
            onUpdate={(checked) => form.setValue('superadmin', checked)}
          />
        </div>
        <div className={css.DataTableContainer}>
          <Table columns={permissionColumns} data={form.getValues().permissions} />
        </div>
      </fieldset>
    </form>
  );
};
