import {
  GetDevicesFromSmartGroupRequestDto,
  GetDevicesFromSmartGroupResponseDto,
  GetGroupsForDeviceDto,
  GetSmartGroupDevicesCSVRequestDto
} from '../types/api';
import { createRequest } from './create-request';
import { UUIDString } from '../types/common';

export async function getGroupsForDevice(id: UUIDString): Promise<GetGroupsForDeviceDto> {
  return createRequest({ url: `groups/device/${id}` });
}

export async function getDevicesFromSmartGroup(
  id: UUIDString,
  params: GetDevicesFromSmartGroupRequestDto
): Promise<GetDevicesFromSmartGroupResponseDto> {
  return createRequest({ url: `groups/devices/smart/${id}/devices`, params });
}

export async function getSmartGroupsDevicesCSV(
  data: GetSmartGroupDevicesCSVRequestDto
): Promise<Blob> {
  return createRequest({
    url: `groups/devices/export`,
    responseType: 'blob',
    method: 'POST',
    data
  });
}
