import { ChangeEvent, FC, useEffect, useState } from 'react';
import { ScriptBasicDto } from '../../../../../../../../../types/api';
import css from './policy-options-scripts.module.scss';
import { getScripts } from '../../../../../../../../../api/scripts';
import cn from 'classnames';
import { WithClassname } from '../../../../../../../../../types/common';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../../../../../../../constants';
import { SettingsPaths } from '../../../../../../../settings/settings.const';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import { Button, Text, TextInput } from '@gravity-ui/uikit';
import { UseFormReturn } from 'react-hook-form';
import { PolicyFormValues, ScriptValue } from '../../../../policy-form.schema';

interface IProps {
  disabled?: boolean;
  form: UseFormReturn<PolicyFormValues>;
}

export const PolicyOptionsScripts: FC<IProps & WithClassname> = (props) => {
  const { className, form, disabled = false } = props;
  const { getValues, setValue } = form;

  const [fetchedScripts, setFetchedScripts] = useState<ScriptBasicDto[]>();
  const [addedScripts, setAddedScripts] = useState<ScriptValue[]>(getValues().scripts || []);
  const [areFetchedScriptsShown, setAreFetchedScriptsShown] = useState(!getValues().scripts);

  const { t } = useTranslation();

  const fetchScripts = async () => {
    const response = await getScripts();
    setFetchedScripts(response.scripts);
  };

  const handleScriptAdd = (script: ScriptBasicDto) => () => {
    const updatedAddedScripts: ScriptValue[] = [
      ...addedScripts,
      {
        id: script.id,
        display_name: script.display_name,
        arguments: Array(7).fill('')
      }
    ];
    setAddedScripts(updatedAddedScripts);
    setAreFetchedScriptsShown(false);
    setValue('scripts', updatedAddedScripts);
  };

  const handleSelectAddedScript = () => {
    setAreFetchedScriptsShown(true);
  };

  const handleDeleteScript = (index: number) => () => {
    const updatedAddedScripts = addedScripts.filter((_, i) => i !== index);
    setAddedScripts(updatedAddedScripts);
    setValue('scripts', updatedAddedScripts);
  };

  const handleParameterChange =
    (scriptIndex: number, paramIndex: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const updatedAddedScripts = addedScripts.map((item, index) => {
        if (index !== scriptIndex) return item;
        const newArguments = [...item.arguments];
        newArguments[paramIndex] = event.target.value;
        return { ...item, arguments: newArguments };
      });
      setAddedScripts(updatedAddedScripts);
      setValue('scripts', updatedAddedScripts);
    };

  useEffect(() => {
    if (disabled) return;
    if (areFetchedScriptsShown) fetchScripts().then();
  }, [areFetchedScriptsShown]);

  const columns: Column<ScriptBasicDto>[] = [
    {
      id: 'name',
      name: t('policies.policy_options_scripts.column_script_name'),
      selector: (script) => (
        <Link
          to={`/${Paths.SETTINGS}${SettingsPaths.SCRIPT.replace(':id', script.id)}`}
          target="_blank"
        >
          {script.display_name}
        </Link>
      )
    },
    {
      id: 'add',
      name: '',
      selector: (script) => (
        <Button view="outlined-success" onClick={handleScriptAdd(script)}>
          {t('policies.policy_options_scripts.button_add')}
        </Button>
      ),
      width: 150,
      align: 'end'
    }
  ];

  return (
    <div className={cn(css.Root, className)}>
      {areFetchedScriptsShown && fetchedScripts && (
        <Table data={fetchedScripts} columns={columns} />
      )}
      {!areFetchedScriptsShown && (
        <div className={css.AddedScriptListContainer}>
          <div className={css.AddedScriptListTop}>
            <Text variant="subheader-2">{t('policies.policy_options_scripts.added_scripts')}</Text>
            {!disabled && (
              <Button view="action" onClick={handleSelectAddedScript}>
                {t('policies.policy_options_scripts.button_add_script')}
              </Button>
            )}
          </div>
          {addedScripts.map((script, index) => (
            <div className={css.AddedScript} key={index}>
              <div className={cn(css.AddedScriptHeader)}>
                <Link
                  to={`/${Paths.SETTINGS}${SettingsPaths.SCRIPT.replace(':id', script.id)}`}
                  target="_blank"
                >
                  <Text variant="subheader-3">{script.display_name}</Text>
                </Link>
                {!disabled && (
                  <Button view="outlined-danger" onClick={handleDeleteScript(index)}>
                    {t('policies.policy_options_scripts.button_delete')}
                  </Button>
                )}
              </div>
              <Text variant="subheader-1">
                {t('policies.policy_options_scripts.title_parameters')}
              </Text>
              <Text variant="caption-2" color="secondary">
                {t('policies.policy_options_scripts.parameters_disclaimer')}
              </Text>
              {[0, 1, 2, 3, 4, 5, 6, 7].map((num) => (
                <div key={num}>
                  <Text className={css.AddedScriptLabel}>
                    {t('policies.policy_options_scripts.label_parameter_n', { n: num + 4 })}
                  </Text>
                  <TextInput
                    onChange={handleParameterChange(index, num)}
                    value={script.arguments[num] || ''}
                    disabled={disabled}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
