import {
  CreateSmartGroupDevicesRequestDto,
  GetSmartGroupsDevicesRequestDto,
  GetSmartGroupsDevicesResponseDto,
  SmartGroupDevicesFullDto,
  UpdateSmartGroupDevicesRequestDto
} from '../types/api';
import { createRequest } from './create-request';

export async function getSmartGroups(
  params: GetSmartGroupsDevicesRequestDto
): Promise<GetSmartGroupsDevicesResponseDto> {
  return createRequest({ url: 'groups/devices/smart', params });
}

export async function getSmartGroup(id: string): Promise<SmartGroupDevicesFullDto> {
  return createRequest({ url: `groups/devices/smart/${id}` });
}

export async function createSmartGroup(
  data: CreateSmartGroupDevicesRequestDto
): Promise<SmartGroupDevicesFullDto> {
  return createRequest({ method: 'POST', url: 'groups/devices/smart', data });
}

export async function deleteSmartGroup(id: string): Promise<SmartGroupDevicesFullDto> {
  return createRequest({ method: 'DELETE', url: `groups/devices/smart/${id}` });
}

export async function updateSmartGroup(
  id: string,
  data: UpdateSmartGroupDevicesRequestDto
): Promise<SmartGroupDevicesFullDto> {
  return createRequest({ method: 'PATCH', url: `groups/devices/smart/${id}`, data });
}
