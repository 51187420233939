import { FC } from 'react';
import css from './device-application-search-form.module.scss';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { DeviceApplicationSearchFormValues } from './device-application-search-form.schema';
import { Button, Select, SelectOption, Text, TextInput } from '@gravity-ui/uikit';
import { CountryCode } from '../../../../../../../types/api';

interface IProps {
  loading: boolean;
  onCancel: () => void;
  onPreviousStep: () => void;
  onCreateManually: () => void;
  onNextStep: () => void;
}

export const DeviceApplicationSearchFormComponent: FC<IProps> = (props: IProps) => {
  const { loading, onCancel, onNextStep, onPreviousStep, onCreateManually } = props;
  const appSearchForm = useFormContext<DeviceApplicationSearchFormValues>();
  const { t } = useTranslation();

  const countryOptions: SelectOption[] = Object.values(CountryCode).map((i) => ({
    content: i,
    value: i
  }));

  return (
    <div className={css.Root}>
      <div className={css.Form}>
        <div className={css.FormItem}>
          <Text variant="subheader-1">{t('device_apps.installation.search_step.title')}</Text>
          <TextInput
            type="text"
            disabled={loading}
            placeholder={t('device_apps.installation.search_step.search_placeholder')}
            error={appSearchForm.formState.errors.term?.message}
            {...appSearchForm.register('term')}
          />
        </div>
        <div className={css.FormItem}>
          <Text variant="subheader-1">{t('device_apps.installation.search_step.country')}</Text>
          <Select
            options={countryOptions}
            disabled={loading}
            width="max"
            value={[appSearchForm.watch('country_code')]}
            onUpdate={(value) => appSearchForm.setValue('country_code', value[0])}
          />
        </div>
      </div>
      <div className={css.ActionButton}>
        <Button view="action" onClick={onCancel} disabled={loading}>
          {t('device_apps.installation.buttons.cancel')}
        </Button>
        <Button view="action" onClick={onPreviousStep} disabled={loading}>
          {t('device_apps.installation.buttons.previous')}
        </Button>
        <Button view="action" onClick={onNextStep} disabled={loading} loading={loading}>
          {t('device_apps.installation.buttons.next')}
        </Button>
        <Button view="action" onClick={onCreateManually} disabled={loading}>
          {t('device_apps.installation.buttons.enter_manually')}
        </Button>
      </div>
    </div>
  );
};
