import { FC } from 'react';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import css from './hardware-table.module.scss';
import { Text } from '@gravity-ui/uikit';

interface IProps {
  device?: DeviceFullDto;
}

interface IKeyValue {
  key: string;
  value: string | null | undefined;
}

export const HardwareTable: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const di = device?.device_information;

  const data: IKeyValue[] = [
    {
      key: t('inventory.device_page.inventory_tab.hardware_tab.table.heading.model'),
      value: device?.model_name
    },
    {
      key: t('inventory.device_page.inventory_tab.hardware_tab.table.heading.model_identifier'),
      value: di?.model_identifier
    },
    {
      key: t('inventory.device_page.inventory_tab.hardware_tab.table.heading.id'),
      value: device?.id.toUpperCase()
    },
    {
      key: t('inventory.device_page.inventory_tab.hardware_tab.table.heading.udid'),
      value: device?.udid.toUpperCase()
    },
    {
      key: t('inventory.device_page.inventory_tab.hardware_tab.table.heading.serial'),
      value: device?.serial_number
    },
    {
      key: t('inventory.device_page.inventory_tab.hardware_tab.table.heading.cpu_type'),
      value: di?.cpu_type
    },
    {
      key: t(
        'inventory.device_page.inventory_tab.hardware_tab.table.heading.primary_network_adapter'
      ),
      value: di?.main_network_interface
    },
    {
      key: t('inventory.device_page.inventory_tab.hardware_tab.table.heading.primary_mac_address'),
      value: di?.main_network_interface_mac
    },
    {
      key: t('inventory.device_page.inventory_tab.hardware_tab.table.heading.total_ram'),
      value: di?.total_ram_mb ? `${Math.round(di.total_ram_mb / 1024)} GB` : undefined
    },
    {
      key: t('inventory.device_page.inventory_tab.hardware_tab.table.heading.battery_capacity'),
      value: device?.device_information?.battery_capacity
    }
  ];

  const columns: Column<IKeyValue>[] = [
    {
      id: 'key',
      selector: (row) => <Text variant="subheader-1">{row.key}</Text>,
      width: 250
    },
    {
      id: 'value',
      selector: (row) => row.value
    }
  ];

  return (
    <>
      <Table columns={columns} data={data} className={css.Table} />
      <DeviceExtensionAttributes
        className={css.ExtensionAttributes}
        device={device}
        inventorySection={ExtensionAttributeInventorySection.Hardware}
      />
    </>
  );
};
