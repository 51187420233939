import { FC, useEffect, useState } from 'react';
import { HistoryTab } from './components/history-tab/history-tab.component';
import { InventoryTab } from './components/inventory-tab/inventory-tab.component';
import { ManagementTab } from './components/management-tab/management-tab.component';
import { DeviceFullDto, Permission } from '../../../../../types/api';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteDevice, getDeviceInfo } from '../../../../../api/device';
import { TerminalTab } from './components/terminal-tab/terminal-tab.component';
import { useTranslation } from 'react-i18next';
import { getLocalizedErrorString } from '../../../../../utils/localize-error';
import { Restricted } from '../../../../components/common/restricted';
import { usePermission } from '../../../../contexts/permission.context';
import useDeviceSection, { DeviceType } from '../../../../contexts/device-section.context';
import { Header } from '../../../../components/header/header.component';
import { Button, Dialog, Icon, Tabs, TabsItemProps, Text } from '@gravity-ui/uikit';
import { TrashBin } from '@gravity-ui/icons';
import { InventoryDevicePageTabs } from '../../constants';
import css from './inventory-device-page.module.scss';
import useRequest from '../../../../../hooks/useRequest';
import { DevicePageSkeleton } from './components/device-page-skeleton/device-page-skeleton.component';

export const InventoryDevicePage: FC = () => {
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const [openedTab, setOpenedTab] = useState<string>(InventoryDevicePageTabs.INVENTORY);
  const [device, setDevice] = useState<DeviceFullDto>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const { deviceType } = useDeviceSection();
  const getDeviceInfoRequest = useRequest<DeviceFullDto>();
  const deleteDeviceRequest = useRequest<void>();

  const handleChangeTab = (tabName: string) => setOpenedTab(tabName);

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleDeleteDevice = async () => {
    if (!device || deleteDeviceRequest.loading) return;
    try {
      await deleteDeviceRequest.send(deleteDevice(device.id), 1000);
      if (deviceType === DeviceType.MOBILE) {
        navigate('/mobile/inventory');
      } else {
        navigate('/computers/inventory');
      }
    } catch (error) {
      const errorString = getLocalizedErrorString(error as Error);
      console.log(errorString);
    }
  };

  const fetchDeviceInfo = async () => {
    if (!deviceId || getDeviceInfoRequest.loading) return;

    const result = await getDeviceInfoRequest.send(getDeviceInfo(deviceId), 1000);
    setDevice(result);
  };

  useEffect(() => {
    void fetchDeviceInfo();
  }, [deviceId]);

  const computerTabs: TabsItemProps[] = [
    {
      id: InventoryDevicePageTabs.INVENTORY,
      title: t('inventory.device_page.inventory_tab.name')
    },
    {
      id: InventoryDevicePageTabs.MANAGEMENT,
      title: t('inventory.device_page.management_tab.name')
    },
    {
      id: InventoryDevicePageTabs.HISTORY,
      title: t('inventory.device_page.history_tab.name')
    },
    {
      id: InventoryDevicePageTabs.TERMINAL,
      title: t('inventory.device_page.terminal_tab.name'),
      disabled: !isAllowedTo(Permission.EditCommands)
    }
  ];
  const mobileTabs: TabsItemProps[] = [
    {
      id: InventoryDevicePageTabs.INVENTORY,
      title: t('inventory.device_page.inventory_tab.name')
    },
    {
      id: InventoryDevicePageTabs.MANAGEMENT,
      title: t('inventory.device_page.management_tab.name')
    },
    {
      id: InventoryDevicePageTabs.HISTORY,
      title: t('inventory.device_page.history_tab.name')
    }
  ];
  const actualTabs = deviceType === DeviceType.COMPUTERS ? computerTabs : mobileTabs;

  const title = device?.device_information?.device_name
    ? device?.device_information?.device_name
    : device?.model_name
    ? device?.model_name
    : '';

  return (
    <>
      <Header
        breadcrumbsTitle={title}
        rightContent={
          <Button
            view="flat"
            onClick={handleOpenDeleteModal}
            disabled={
              getDeviceInfoRequest.loading ||
              deleteDeviceRequest.loading ||
              !isAllowedTo(Permission.EditCommands)
            }
          >
            <Icon data={TrashBin} />
            {t('inventory.device_page.delete_btn')}
          </Button>
        }
      />
      <div className={css.Content}>
        {getDeviceInfoRequest.loading ? (
          <DevicePageSkeleton />
        ) : (
          <>
            <div className={css.Title}>
              <Text variant="display-2">{title}</Text>
            </div>
            <Tabs
              activeTab={openedTab}
              items={actualTabs}
              onSelectTab={handleChangeTab}
              className={css.NavBar}
            />
            {openedTab === InventoryDevicePageTabs.INVENTORY && <InventoryTab device={device} />}
            {openedTab === InventoryDevicePageTabs.MANAGEMENT && <ManagementTab device={device} />}
            {openedTab === InventoryDevicePageTabs.HISTORY && <HistoryTab device={device} />}
            <Restricted to={[Permission.EditCommands]}>
              {openedTab === InventoryDevicePageTabs.TERMINAL && <TerminalTab device={device} />}
            </Restricted>
            <Dialog onClose={handleCloseDeleteModal} open={isDeleteModalOpen}>
              <Dialog.Header caption={t('inventory.device_page.delete_modal.title')} />
              <Dialog.Body>
                <Text>
                  {t('inventory.device_page.delete_modal.message', {
                    model_name: device?.model_name,
                    serial_number: device?.serial_number
                  })}
                </Text>
              </Dialog.Body>
              <Dialog.Footer
                errorText={
                  deleteDeviceRequest.error
                    ? getLocalizedErrorString(deleteDeviceRequest.error as Error)
                    : undefined
                }
                onClickButtonCancel={handleCloseDeleteModal}
                onClickButtonApply={handleDeleteDevice}
                textButtonApply={t('inventory.device_page.delete_modal.btn')}
                textButtonCancel={t('common.modal.cancel_btn')}
              />
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};
