import { FC, FormEvent, useState } from 'react';
import css from './smart-group-device-users-form.module.scss';
import { CriteriaTab } from './components/criteria-tab/criteria-tab.component';
import { BlankPage } from '../../../../components/blank-page/blank-page.component';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../../contexts/permission.context';
import { Permission } from '../../../../../types/api';
import { Tabs, TabsItemProps } from '@gravity-ui/uikit';

export enum SmartGroupDeviceUsersPageTabs {
  USERS_LIST = 'Users List',
  CRITERIA = 'Group Information'
}

interface IProps {
  mode: 'view' | 'create' | 'edit';
}

export const SmartGroupDevicesForm: FC<IProps> = (props: IProps) => {
  const { mode } = props;

  const [activeTab, setActiveTab] = useState<string>(SmartGroupDeviceUsersPageTabs.USERS_LIST);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const tabs: TabsItemProps[] = [
    {
      id: SmartGroupDeviceUsersPageTabs.USERS_LIST,
      title: t('smart_groups.page.users_list_tab.name')
    },
    {
      id: SmartGroupDeviceUsersPageTabs.CRITERIA,
      title: t('smart_groups.page.criteria_tab.name')
    }
  ];

  return (
    <form className={css.Form} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditGroups)}>
        <Tabs
          className={css.NavBar}
          items={tabs}
          activeTab={activeTab}
          onSelectTab={handleChangeTab}
        />
        {activeTab === SmartGroupDeviceUsersPageTabs.USERS_LIST && (
          <BlankPage text={t('smart_groups.page.reports_tab.no_content')} />
        )}
        {activeTab == SmartGroupDeviceUsersPageTabs.CRITERIA && <CriteriaTab mode={mode} />}
      </fieldset>
    </form>
  );
};
