import { createRequest } from './create-request';
import { CreateScriptRequestDto, GetScriptsResponseDto, ScriptFullDto } from '../types/api';

export async function getScripts(): Promise<GetScriptsResponseDto> {
  return createRequest({ url: 'scripts' });
}

export async function getScript(id: string): Promise<ScriptFullDto> {
  return createRequest({ url: `scripts/${id}` });
}

export async function createScript(data: CreateScriptRequestDto): Promise<ScriptFullDto> {
  return createRequest({ method: 'POST', url: 'scripts', data });
}

export async function updateScript(
  id: string,
  data: CreateScriptRequestDto
): Promise<ScriptFullDto> {
  return createRequest({ method: 'PATCH', url: `scripts/${id}`, data });
}

export async function deleteScript(id: string): Promise<ScriptFullDto> {
  return createRequest({ method: 'DELETE', url: `scripts/${id}` });
}
