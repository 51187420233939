import {
  CheckSettingsResponseDto,
  GetSettingsResponseDto,
  UpdateSettingsDto,
  LicenseDto,
  UploadLicenseFileRequestDto,
  GetActiveLicensesDto,
  GetLicenseListDto
} from '../types/api';
import { createRequest } from './create-request';

export async function getSettings(
  names: (keyof GetSettingsResponseDto)[]
): Promise<Partial<GetSettingsResponseDto>> {
  return createRequest({ url: `settings`, params: { names } });
}

export async function checkSettings(
  names: (keyof GetSettingsResponseDto)[]
): Promise<Partial<CheckSettingsResponseDto>> {
  return createRequest({ url: `settings/check`, params: { names } });
}

export async function updateSettings(
  data: Partial<UpdateSettingsDto>
): Promise<Partial<GetSettingsResponseDto>> {
  return createRequest({ method: 'PATCH', url: `settings`, data });
}
export async function getLicenses(): Promise<GetActiveLicensesDto> {
  return createRequest({ url: `licenses` });
}

export async function getInactiveLicenseList(): Promise<GetLicenseListDto> {
  return createRequest({ url: `licenses/all/inactive` });
}

export async function uploadLicense(data: UploadLicenseFileRequestDto): Promise<LicenseDto> {
  return createRequest({
    method: 'POST',
    url: `licenses/upload`,
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  });
}
