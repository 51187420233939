import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import css from './device-users.module.scss';
import {
  CreateDeviceUserRequestDto,
  DeviceUserSimpleDto,
  Permission,
  UpdateDeviceUserRequestDto
} from '../../../types/api';
import { DeviceUsersForm } from './components/user-form/device-user-form.component';
import {
  deviceUserFormSchema,
  DeviceUserFormValues
} from './components/user-form/device-user-form.schema';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import useRequest from '../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../constants';
import {
  createDeviceUser,
  deleteDeviceUser,
  getDeviceUser,
  updateDeviceUser
} from '../../../api/device-users';
import { Dialog, Popover, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { Header } from '../../components/header/header.component';
import { ActionMenu } from './components/action-menu/action-menu.component';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePermission } from '../../contexts/permission.context';

export const DeviceUsersComponent: FC = () => {
  const { id } = useParams();
  const isNew = id === 'new';

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const navigate = useNavigate();
  const toaster = useToaster();
  const crudRequest = useRequest<DeviceUserSimpleDto>();
  const userRef = useRef<DeviceUserSimpleDto>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>(isNew ? 'create' : 'view');

  const deviceUserForm = useForm<DeviceUserFormValues>({
    mode: 'onChange',
    resolver: yupResolver(deviceUserFormSchema),
    defaultValues: deviceUserFormSchema.getDefault()
  });
  const {
    trigger,
    reset,
    getValues,
    register,
    formState: { errors }
  } = deviceUserForm;

  const userToForm = (input: DeviceUserSimpleDto): DeviceUserFormValues => {
    return {
      username: input.username,
      ...(input?.email && { email: input?.email }),
      ...(input?.employee_name && { employee_name: input?.employee_name }),
      ...(input?.phone_number && { phone_number: input?.phone_number }),
      ...(input?.position && { position: input?.position }),
      computers_count: input.computers_count,
      mobile_devices_count: input.mobile_devices_count
    };
  };

  const handleUserCreate = async () => {
    if (crudRequest.loading) return;

    const isValid = await trigger();
    if (!isValid) return;

    const userFormValues = getValues();
    const request: CreateDeviceUserRequestDto = {
      username: userFormValues.username,
      ...(userFormValues.email && { email: userFormValues.email }),
      ...(userFormValues.employee_name && { employee_name: userFormValues.employee_name }),
      ...(userFormValues.phone_number && { phone_number: userFormValues.phone_number }),
      ...(userFormValues.position && { position: userFormValues.position })
    };
    try {
      const response = await crudRequest.send(createDeviceUser(request), 1000);
      userRef.current = response;
      reset(userToForm(response));

      setFormMode('view');
      navigate(`/${Paths.DEVICE_USER.replace(':id', response.id)}`);
      toaster.add({
        name: 'user-create-success',
        content: t('device_users.user_form.responses.successfully_created', {
          username: userFormValues.username
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'user-create-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleUserEditStart = () => {
    setFormMode('edit');
  };

  const handleUserCancel = () => {
    if (isNew) {
      navigate(`/${Paths.DEVICE_USER_LIST}`);
      return;
    }
    setFormMode('view');
  };

  const handleUserSave = async () => {
    if (crudRequest.loading || !userRef.current) return;

    const isValid = await trigger();
    if (!isValid) return;

    const userFormValues = getValues();
    const request: UpdateDeviceUserRequestDto = {
      username: userFormValues.username,
      email: userFormValues.email,
      employee_name: userFormValues.employee_name,
      phone_number: userFormValues.phone_number,
      position: userFormValues.position
    };
    try {
      const response = await crudRequest.send(updateDeviceUser(userRef.current?.id, request), 1000);
      userRef.current = response;
      reset(userToForm(response));

      setFormMode('view');
      navigate(`/${Paths.DEVICE_USER.replace(':id', response.id)}`);
      toaster.add({
        name: 'user-edit-success',
        content: t('device_users.user_form.responses.successfully_updated', {
          username: response.username
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'user-edit-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleUserDelete = async () => {
    if (crudRequest.loading || !userRef.current) return;

    try {
      const response = await crudRequest.send(deleteDeviceUser(userRef.current?.id), 1000);
      navigate(`/${Paths.DEVICE_USER_LIST}`);
      toaster.add({
        name: 'user-delete-success',
        content: t('device_users.user_form.responses.successfully_deleted', {
          username: response.username
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (e) {
      const localizedErrorString = getLocalizedErrorString(e as Error);
      toaster.add({
        name: 'user-delete-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalSubmit = async () => {
    await handleUserDelete();
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    const init = async () => {
      if (!id) return;
      try {
        if (!isNew) {
          const response = await crudRequest.send(getDeviceUser(id), 1000);
          userRef.current = response;
          reset(userToForm(response));
        }
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        toaster.add({
          name: 'user-load-error',
          content: localizedErrorString,
          theme: 'danger',
          autoHiding: 5000
        });
      }
    };
    void init();
  }, [id]);

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={isNew ? t('device_users.user_form.new_title') : userRef.current?.username}
        rightContent={
          <ActionMenu
            mode={formMode}
            disabled={crudRequest.loading || !isAllowedTo(Permission.EditDeviceUsers)}
            onUserCreate={handleUserCreate}
            onUserEditStart={handleUserEditStart}
            onUserEdit={handleUserSave}
            onUserCancel={handleUserCancel}
            onUserDelete={handleDeleteModalOpen}
          />
        }
      />
      <div className={css.Content}>
        {crudRequest.loading ? (
          <FormSkeleton />
        ) : (
          <>
            <div className={css.Title}>
              {formMode === 'view' && <Text variant="display-2">{userRef.current?.username}</Text>}
              {formMode !== 'view' && (
                <Popover content={t('common.click_to_edit')}>
                  <TextInput
                    placeholder={t('device_users.user_form.column_username')}
                    size="xl"
                    view="clear"
                    error={errors.username?.message}
                    errorPlacement="inside"
                    controlProps={{ className: css.TitleInput }}
                    {...register('username')}
                  />
                </Popover>
              )}
            </div>
            <DeviceUsersForm mode={formMode} form={deviceUserForm} />
          </>
        )}
      </div>
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalOpen}>
        <Dialog.Header caption={t('device_users.modal_delete.title')} />
        <Dialog.Body>
          <Text>
            {t('device_users.modal_delete.message', {
              title: userRef.current?.username
            })}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('device_users.modal_delete.delete_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleDeleteModalSubmit}
          onClickButtonCancel={handleDeleteModalClose}
          loading={crudRequest.loading}
        />
      </Dialog>
    </div>
  );
};
