import { FC, FormEvent, useEffect, useState } from 'react';
import css from './user-ldap-form.module.scss';
import {
  Permission,
  SearchLdapUsersEntryDto,
  SearchLdapUsersResponseDto,
  UserOriginType
} from '../../../../../../types/api';
import { usePermission } from '../../../../../contexts/permission.context';
import { useTranslation } from 'react-i18next';
import { UserLdapFormValues } from './user-ldap-form.schema';
import { UseFormReturn } from 'react-hook-form';
import { IRole } from '../../user.component';
import { searchUsers } from '../../../../../../api/ldap';
import useRequest from '../../../../../../hooks/useRequest';
import { userOriginTypeTitles } from '../../../../../../const/users.const';
import { Button, Select, SelectOption, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { Column, Table } from '../../../../../components/table/table.component';
import { getLocalizedErrorString } from '../../../../../../utils/localize-error';
import { useDebounce } from 'use-debounce';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  roles?: IRole[];
  form: UseFormReturn<UserLdapFormValues>;
  isLdapUserSelected: boolean;
  onSelectLdapUser: (checked: boolean) => void;
}

export const UserLdapFormComponent: FC<IProps> = (props) => {
  const { mode, roles = [], form, onSelectLdapUser, isLdapUserSelected } = props;
  const {
    setValue,
    register,
    watch,
    formState: { errors }
  } = form;

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();

  const [ldapUsers, setLdapUsers] = useState<SearchLdapUsersEntryDto[]>();
  const [searchUser, setSearchUser] = useState('');
  const [debouncedSearchUser] = useDebounce(searchUser, 1000);
  const searchRequest = useRequest<SearchLdapUsersResponseDto>();

  const rolesOptions: SelectOption[] = roles.map((role) => ({
    content: role.title,
    value: role.id
  }));

  const handleSelectLdapUser = (user: SearchLdapUsersEntryDto) => () => {
    setValue('username', user.username[0]);
    setValue('display_name', user.full_name[0]);
    onSelectLdapUser(true);
  };

  const handleClearSearch = () => {
    setSearchUser('');
  };

  const handleUserFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
  };

  const fetchLdapUsers = async () => {
    if (searchRequest.loading) return;
    try {
      const result = await searchRequest.send(searchUsers({ search: debouncedSearchUser }), 1000);
      setLdapUsers(result.entries);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'search-ldap-users-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  useEffect(() => {
    void fetchLdapUsers();
  }, [debouncedSearchUser]);

  const columns: Column<SearchLdapUsersEntryDto>[] = [
    {
      id: 'username',
      name: t('settings.tiles.users.page.user_page.ldap_search_label_username'),
      selector: (entry) => entry.username[0],
      sortable: (a, b) => b.username[0].localeCompare(a.username[0])
    },
    {
      id: 'full_name',
      name: t('settings.tiles.users.page.user_page.ldap_search_label_full_name'),
      selector: (entry) => entry.full_name[0],
      sortable: (a, b) => b.full_name[0].localeCompare(a.full_name[0])
    },
    {
      id: 'email',
      name: t('settings.tiles.users.page.user_page.ldap_search_label_email'),
      selector: (entry) => entry.email[0],
      sortable: (a, b) => b.email[0].localeCompare(a.email[0])
    },
    {
      id: 'select',
      name: '',
      selector: (entry) => (
        <Button onClick={handleSelectLdapUser(entry)} view="outlined-success">
          {t('settings.tiles.users.page.user_page.ldap_search_select_user')}
        </Button>
      ),
      align: 'end'
    }
  ];

  const tableContent = (
    <div className={css.SearchForm}>
      <TextInput
        className={css.SearchInput}
        placeholder={t('settings.tiles.users.page.user_page.ldap_search_label')}
        value={searchUser}
        onUpdate={setSearchUser}
      />
      <Button view="action" onClick={handleClearSearch}>
        {t('settings.tiles.users.page.user_page.ldap_search_clear')}
      </Button>
    </div>
  );

  useEffect(() => {
    if (roles) {
      setValue('role_id', roles[0].id);
    }
  }, [roles]);

  return (
    <>
      {!isLdapUserSelected && (
        <div className={css.Fieldset}>
          <Text variant="subheader-2">
            {t('settings.tiles.users.page.user_page.ldap_search_title')}
          </Text>
          <Text>{t('settings.tiles.users.page.user_page.ldap_search_description')}</Text>
          {searchRequest.loading ? (
            <TableSkeleton />
          ) : (
            <Table columns={columns} data={ldapUsers || []} leftContent={tableContent} />
          )}
        </div>
      )}
      {isLdapUserSelected && (
        <form onSubmit={handleUserFormSubmit}>
          <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditAdministration)}>
            <div>
              <Text>{t('settings.tiles.users.page.user_page.username')}</Text>
              <TextInput disabled {...register('username')} />
            </div>
            <div>
              <Text>{t('settings.tiles.users.page.user_page.origin_type')}</Text>
              <TextInput value={userOriginTypeTitles[UserOriginType.Ldap]} disabled />
            </div>
            <div>
              <Text>{t('settings.tiles.users.page.user_page.role')}</Text>
              <Select
                options={rolesOptions}
                disabled={mode === 'view'}
                value={[watch('role_id')]}
                width="max"
                hasClear
                errorMessage={errors.role_id?.message}
                onUpdate={(value) => setValue('role_id', value[0])}
              />
            </div>
          </fieldset>
        </form>
      )}
    </>
  );
};
