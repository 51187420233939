import cn from 'classnames';
import css from './scope-form.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { getScopeOptions } from '../../../api/scope';
import {
  DeviceOsType,
  PaginationSortOrder,
  ScopeEntryOperationType,
  ScopeOptionDto,
  ScopeOptionType
} from '../../../types/api';
import { scopeOptionTitles } from '../../../const/scope.const';
import { WithClassname } from '../../../types/common';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';
import { Column, PaginationState, SortState, Table } from '../table/table.component';
import { Button, Select, SelectOption, Text } from '@gravity-ui/uikit';
import { Navbar, NavbarItemProps } from '../navbar/navbar.component';

export interface ScopeValues {
  scoped: boolean;
  scopeEntriesInclude: ScopeOptionDto[];
  scopeEntriesExclude: ScopeOptionDto[];
}

interface IProps {
  values: ScopeValues;
  disabled?: boolean;
  onChange?: (values: Partial<ScopeValues>) => void;
}

export const ScopeForm: FC<IProps & WithClassname> = (props) => {
  const { className, values, disabled = false, onChange } = props;
  const selectedScopeIncludeSet = useRef(new Set(values?.scopeEntriesInclude.map((i) => i.id)));
  const selectedScopeExcludeSet = useRef(new Set(values?.scopeEntriesExclude.map((i) => i.id)));
  const [areShownScopeOptions, setAreShownScopeOptions] = useState(false);
  const [scopeOptions, setScopeOptions] = useState<ScopeOptionDto[]>();
  const [scopeOptionsCount, setScopeOptionsCount] = useState(0);
  const [includeScopeActiveTab, setIncludeScopeActiveTab] = useState<ScopeOptionType>(
    ScopeOptionType.Device
  );
  const [excludeScopeActiveTab, setExcludeScopeActiveTab] = useState<ScopeOptionType>(
    ScopeOptionType.Device
  );
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: 10
  });
  const [sortState, setSortState] = useState<SortState>();
  const [shownScopeOperation, setShownScopeOperation] = useState<ScopeEntryOperationType>(
    ScopeEntryOperationType.Include
  );

  const { t } = useTranslation();

  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const handleChangePaginationState = (state: PaginationState) => setPaginationState(state);
  const handleChangeSortState = (state?: SortState) => setSortState(state);

  const handleOpenTargetAdd = async () => {
    setAreShownScopeOptions(true);
  };

  const handleSelectScopeOption = (option: ScopeOptionDto) => () => {
    if (shownScopeOperation === ScopeEntryOperationType.Include) {
      selectedScopeIncludeSet.current.add(option.id);
      onChange?.({ scopeEntriesInclude: [...values.scopeEntriesInclude, option] });
    } else {
      selectedScopeExcludeSet.current.add(option.id);
      onChange?.({ scopeEntriesExclude: [...values.scopeEntriesExclude, option] });
    }
  };

  const handleRemoveScopeOption = (option: ScopeOptionDto) => () => {
    if (shownScopeOperation === ScopeEntryOperationType.Include) {
      selectedScopeIncludeSet.current.delete(option.id);
      onChange?.({
        scopeEntriesInclude: values.scopeEntriesInclude.filter((i) => i.id !== option.id)
      });
    } else {
      selectedScopeExcludeSet.current.delete(option.id);
      onChange?.({
        scopeEntriesExclude: values.scopeEntriesExclude.filter((i) => i.id !== option.id)
      });
    }
  };

  const handleSelectChange = (value: string[]) => {
    const updatedScoped = value[0] === 'specific';
    onChange?.({
      scoped: updatedScoped,
      scopeEntriesInclude: values.scopeEntriesInclude
    });
  };

  const handleShownOperationChange = (operation: string) => {
    setShownScopeOperation(operation as ScopeEntryOperationType);
    setAreShownScopeOptions(false);
  };

  const handleOperationActiveTabChange = (operation: ScopeEntryOperationType) => (tab: string) => {
    if (operation === ScopeEntryOperationType.Include) {
      setIncludeScopeActiveTab(tab as ScopeOptionType);
    } else {
      setExcludeScopeActiveTab(tab as ScopeOptionType);
    }

    setPaginationState({
      page: 1,
      rowsPerPage: 10
    });
    setSortState(undefined);
  };

  useEffect(() => {
    if (disabled) setAreShownScopeOptions(false);
  }, [disabled]);

  useEffect(() => {
    const activeTab =
      shownScopeOperation === ScopeEntryOperationType.Include
        ? includeScopeActiveTab
        : excludeScopeActiveTab;

    const updateData = async () => {
      const result = await getScopeOptions({
        page: paginationState.page,
        limit: paginationState.rowsPerPage,
        sort_order: sortState?.order as PaginationSortOrder,
        type: activeTab,
        target_os_type: osType
      });
      setScopeOptions(result.options);
      setScopeOptionsCount(result.count);
    };

    updateData().then();
  }, [
    areShownScopeOptions,
    paginationState,
    sortState,
    includeScopeActiveTab,
    excludeScopeActiveTab,
    shownScopeOperation
  ]);

  const targetComputersOptions: SelectOption[] = [
    {
      content:
        osType === DeviceOsType.MacOS
          ? t('scope_form.target_computers_all_computers')
          : t('scope_form.target_computers_all_mobile'),
      value: 'all'
    },
    {
      content:
        osType === DeviceOsType.MacOS
          ? t('scope_form.target_computers_specific_computers')
          : t('scope_form.target_computers_specific_mobile'),
      value: 'specific'
    }
  ];

  const selectedOptionsTableColumns: Column<ScopeOptionDto>[] = [
    {
      id: 'display_name',
      name: t('scope_form.selected_options_column_name'),
      selector: (row) => row.display_name
    },
    {
      id: 'type',
      name: t('scope_form.selected_options_column_type'),
      selector: (row) => scopeOptionTitles[row.type]
    }
  ];
  if (!disabled) {
    selectedOptionsTableColumns.push({
      id: 'remove',
      name: '',
      selector: (row) => (
        <Button view="outlined-danger" onClick={handleRemoveScopeOption(row)}>
          {t('scope_form.selected_options_button_remove')}
        </Button>
      ),
      align: 'end',
      width: 150
    });
  }

  const includeOptionsTableColumns: Column<ScopeOptionDto>[] = [
    {
      id: 'display_name',
      name: t('scope_form.options_column_name'),
      selector: (row) => row.display_name,
      sortable: true
    }
  ];
  if (!disabled) {
    includeOptionsTableColumns.push({
      id: 'add',
      name: '',
      selector: (row) =>
        selectedScopeIncludeSet.current.has(row.id) ? (
          <Button view="outlined-success" disabled>
            {t('scope_form.options_button_added')}
          </Button>
        ) : (
          <Button view="outlined-success" onClick={handleSelectScopeOption(row)}>
            {t('scope_form.options_button_add')}
          </Button>
        ),
      align: 'end',
      width: 150
    });
  }

  const excludeOptionsTableColumns: Column<ScopeOptionDto>[] = [
    {
      id: 'display_name',
      name: t('scope_form.options_column_name'),
      selector: (row) => row.display_name,
      sortable: true
    }
  ];
  if (!disabled) {
    excludeOptionsTableColumns.push({
      id: 'add',
      name: '',
      selector: (row) =>
        selectedScopeExcludeSet.current.has(row.id) ? (
          <Button view="outlined-success" disabled>
            {t('scope_form.options_button_added')}
          </Button>
        ) : (
          <Button view="outlined-success" onClick={handleSelectScopeOption(row)}>
            {t('scope_form.options_button_add')}
          </Button>
        ),
      align: 'end',
      width: 150
    });
  }

  const shownScopeEntries =
    shownScopeOperation === ScopeEntryOperationType.Include
      ? values.scopeEntriesInclude
      : values.scopeEntriesExclude;
  const shownScopeOptions = (): ScopeOptionDto[] => {
    if (!scopeOptions) return [];
    return scopeOptions;
  };

  const operationTabs: NavbarItemProps[] = [
    {
      id: ScopeEntryOperationType.Include,
      title: t('scope_form.inclusions_option')
    },
    {
      id: ScopeEntryOperationType.Exclude,
      title: t('scope_form.exclusions_option')
    }
  ];

  const optionTabs: NavbarItemProps[] = [
    {
      id: ScopeOptionType.Device,
      title: t('scope_form.options_tab_devices')
    },
    {
      id: ScopeOptionType.StaticGroupDevices,
      title: t('scope_form.options_tab_static_groups_devices')
    },
    {
      id: ScopeOptionType.SmartGroupDevices,
      title: t('scope_form.options_tab_smart_groups_devices')
    },
    {
      id: ScopeOptionType.DeviceUser,
      title: t('scope_form.options_tab_device_users')
    },
    {
      id: ScopeOptionType.StaticGroupDeviceUsers,
      title: t('scope_form.options_tab_static_groups_device_users')
    },
    {
      id: ScopeOptionType.SmartGroupDeviceUsers,
      title: t('scope_form.options_tab_smart_groups_device_users')
    }
  ];

  return (
    <div className={cn(css.Root, className)}>
      <Navbar
        items={operationTabs}
        activeTab={shownScopeOperation}
        onSelectTab={handleShownOperationChange}
      />
      {shownScopeOperation === ScopeEntryOperationType.Include && (
        <div className={css.Content}>
          <div>
            <Text className={css.Label} variant="subheader-2">
              {t(
                osType === DeviceOsType.MacOS
                  ? 'scope_form.dropdown_label_target_computers'
                  : 'scope_form.dropdown_label_target_mobile'
              )}
            </Text>
            <Select
              value={values.scoped ? ['specific'] : ['all']}
              onUpdate={handleSelectChange}
              options={targetComputersOptions}
              disabled={disabled}
              width="max"
            />
          </div>
          {values.scoped && (
            <div>
              <div className={css.SelectedTargets}>
                <div className={css.SelectedTargetsTitle}>
                  <Text variant="subheader-2">{t('scope_form.title_selected_entities')}</Text>
                  {!disabled && (
                    <Button view="action" onClick={handleOpenTargetAdd}>
                      {t('scope_form.button_add_target')}
                    </Button>
                  )}
                </div>
                <Table columns={selectedOptionsTableColumns} data={shownScopeEntries} />
              </div>
              {areShownScopeOptions && scopeOptions && (
                <div>
                  <Text className={css.AvailableTargetsTitle} variant="subheader-2">
                    {t('scope_form.title_available_options')}
                  </Text>
                  <Navbar
                    items={optionTabs}
                    activeTab={includeScopeActiveTab}
                    onSelectTab={handleOperationActiveTabChange(ScopeEntryOperationType.Include)}
                    size="m"
                  />
                  <Table
                    columns={includeOptionsTableColumns}
                    data={shownScopeOptions()}
                    pagination
                    paginationState={paginationState}
                    paginationTotalRows={scopeOptionsCount}
                    paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
                    onChangePagination={handleChangePaginationState}
                    onChangeSort={handleChangeSortState}
                    sortState={sortState}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {shownScopeOperation === ScopeEntryOperationType.Exclude && (
        <div>
          <div className={css.SelectedTargets}>
            <div className={css.SelectedTargetsTitle}>
              <Text variant="subheader-2">
                {t(
                  osType === DeviceOsType.MacOS
                    ? 'scope_form.title_selected_computer_targets'
                    : 'scope_form.title_selected_mobile_targets'
                )}
              </Text>
              {!disabled && (
                <Button
                  className={css.ShowScopeOptionBtn}
                  view="action"
                  onClick={handleOpenTargetAdd}
                >
                  {t('scope_form.button_add_target')}
                </Button>
              )}
            </div>
            <Table columns={selectedOptionsTableColumns} data={shownScopeEntries} />
          </div>
          {areShownScopeOptions && scopeOptions && (
            <div>
              <Text className={css.AvailableTargetsTitle} variant="subheader-2">
                {t('scope_form.title_available_options')}
              </Text>
              <Navbar
                items={optionTabs}
                activeTab={excludeScopeActiveTab}
                onSelectTab={handleOperationActiveTabChange(ScopeEntryOperationType.Exclude)}
                size="m"
              />
              <Table
                columns={excludeOptionsTableColumns}
                data={shownScopeOptions()}
                pagination
                paginationState={paginationState}
                paginationTotalRows={scopeOptionsCount}
                paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
                onChangePagination={handleChangePaginationState}
                onChangeSort={handleChangeSortState}
                sortState={sortState}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
