import { FC, useEffect, useState } from 'react';
import css from './static-group-device-users-list.module.scss';
import {
  GetStaticGroupsDeviceUsersResponseDto,
  PaginationSortOrder,
  Permission,
  StaticGroupDeviceUsersSimpleDto,
  StaticGroupDeviceUsersSortField
} from '../../../types/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import { usePermission } from '../../contexts/permission.context';
import { getStaticGroups } from '../../../api/static-groups-device-users';
import { Column, PaginationState, SortState, Table } from '../../components/table/table.component';
import { Header } from '../../components/header/header.component';
import { Button, Text } from '@gravity-ui/uikit';
import useRequest from '../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';
import noDataImage from '../../../assets/images/no-data.png';

export const StaticGroupDeviceUsersList: FC = () => {
  const navigate = useNavigate();

  const [staticGroupsCount, setStaticGroupsCount] = useState(0);
  const [staticGroupsData, setStaticGroupsData] = useState<StaticGroupDeviceUsersSimpleDto[]>([]);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: PaginationRowsPerPageOptions[0]
  });
  const [sortState, setSortState] = useState<SortState>();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const fetchRequest = useRequest<GetStaticGroupsDeviceUsersResponseDto>();

  const columns: Column<StaticGroupDeviceUsersSimpleDto>[] = [
    {
      name: t('static_groups.table.heading.name'),
      id: StaticGroupDeviceUsersSortField.Title,
      selector: (row) => row.title,
      sortable: true
    },
    {
      name: t('static_groups.table.heading.count'),
      id: StaticGroupDeviceUsersSortField.DeviceUsersCount,
      selector: (row) => row.device_users_count,
      sortable: true
    }
  ];

  const handleRowClick = (row: StaticGroupDeviceUsersSimpleDto) => {
    navigate(row.id);
  };

  const handleChangePagination = (state: PaginationState) => {
    setPaginationState(state);
  };

  const handleChangeSort = (state?: SortState) => {
    setSortState(state);
  };

  const handleClickNew = () => {
    navigate('new');
  };

  useEffect(() => {
    const fetchStaticGroupsData = async () => {
      const result = await fetchRequest.send(
        getStaticGroups({
          limit: paginationState.rowsPerPage,
          page: paginationState.page,
          sort_field: sortState?.column as StaticGroupDeviceUsersSortField,
          sort_order: sortState?.order as PaginationSortOrder
        }),
        1000
      );
      setStaticGroupsData(result.groups);
      setStaticGroupsCount(result.count);
    };

    void fetchStaticGroupsData();
  }, [paginationState, sortState]);

  const headerRightContent = (
    <Button disabled={!isAllowedTo(Permission.EditGroups)} view="action" onClick={handleClickNew}>
      {t('static_groups.new_btn')}
    </Button>
  );

  const tableLeftContent = <Text variant="display-2">{t('static_groups.title')}</Text>;

  return (
    <div className={css.Root}>
      <Header rightContent={headerRightContent} />
      <div className={css.DataTableContainer}>
        {fetchRequest.loading ? (
          <TableSkeleton />
        ) : staticGroupsCount === 0 ? (
          <>
            <Text variant="display-2">{t('static_groups.title')}</Text>
            <div className={css.NoDataContainer}>
              <img alt="no-data" src={noDataImage} />
              <Text variant="subheader-3">{t('static_groups.no_data')}</Text>
              {headerRightContent}
            </div>
          </>
        ) : (
          <Table
            columns={columns}
            data={staticGroupsData || []}
            onRowClick={handleRowClick}
            onChangePagination={handleChangePagination}
            onChangeSort={handleChangeSort}
            pagination
            paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
            paginationState={paginationState}
            paginationTotalRows={staticGroupsCount}
            sortState={sortState}
            leftContent={tableLeftContent}
          />
        )}
      </div>
    </div>
  );
};
