import { FC } from 'react';
import { Skeleton } from '@gravity-ui/uikit';
import css from './form-skeleton.module.scss';

export const FormSkeleton: FC = () => {
  return (
    <>
      <Skeleton className={css.TitleSkeleton} />
      <Skeleton className={css.FormSkeleton} />
    </>
  );
};
