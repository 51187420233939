import { FC } from 'react';
import css from './empty-state-dropzone.module.scss';
import { Icon, Text } from '@gravity-ui/uikit';
import { FolderArrowUpIn } from '@gravity-ui/icons';
import { formatFileSize } from '../../../../../utils/file.utils';
import { useTranslation } from 'react-i18next';

interface IProps {
  maxFileSize?: number; // in bytes
}

export const EmptyStateDropzone: FC<IProps> = (props) => {
  const { maxFileSize } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={css.LoaderIcon}>
        <Icon data={FolderArrowUpIn} size={24} />
      </div>
      <Text>
        <a>{t('common.file_input.choose_file_1')}</a> {t('common.file_input.choose_file_2')}
      </Text>
      {maxFileSize && (
        <Text color="secondary">
          {t('common.file_input.max_file_size', { file_size: formatFileSize(maxFileSize) })}
        </Text>
      )}
    </>
  );
};
