import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ConfigProfileLogDto,
  ConfigProfileLogField,
  GetConfigProfileLogsResponseDto,
  PaginationSortOrder
} from '../../../../../../../types/api';
import { formatDate } from '../../../../../../../utils/time.utils';
import { getConfigProfileLogs } from '../../../../../../../api/config-profiles';
import css from './logs-tab.module.scss';
import { PaginationRowsPerPageOptions } from '../../../../../../../const/pagination.const';
import {
  Column,
  PaginationState,
  SortState,
  Table
} from '../../../../../../components/table/table.component';
import { Label } from '@gravity-ui/uikit';
import useRequest from '../../../../../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton.componet';

export const LogsTab: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [logs, setLogs] = useState<ConfigProfileLogDto[]>([]);
  const [logsCount, setLogsCount] = useState(0);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: 10
  });
  const [sortState, setSortState] = useState<SortState>();
  const fetchRequest = useRequest<GetConfigProfileLogsResponseDto>();

  const configProfileInstanceStatusNames: Record<string, string> = {
    ['in_progress']: t('profiles.profile_form.logs_tab.table.status_names.in_progress'),
    ['success']: t('profiles.profile_form.logs_tab.table.status_names.success'),
    ['failed']: t('profiles.profile_form.logs_tab.table.status_names.failed')
  };

  const configProfileInstanceStatusThemes: Record<
    string,
    'normal' | 'info' | 'danger' | 'warning' | 'success' | 'utility' | 'unknown' | 'clear'
  > = {
    ['in_progress']: 'info',
    ['success']: 'success',
    ['failed']: 'danger'
  };

  const columns: Column<ConfigProfileLogDto>[] = [
    {
      name: t('profiles.profile_form.logs_tab.table.heading.device_name'),
      selector: (row) =>
        row.device?.device_information?.device_name
          ? row.device?.device_information?.device_name
          : '',
      sortable: true,
      id: ConfigProfileLogField.DeviceInformationDeviceName
    },
    {
      name: t('profiles.profile_form.logs_tab.table.heading.serial_number'),
      selector: (row) => row.device?.serial_number,
      sortable: true,
      id: ConfigProfileLogField.DeviceSerialNumber
    },
    {
      name: t('profiles.profile_form.logs_tab.table.heading.time'),
      selector: (row) => (row.created_at ? formatDate(row.created_at) : ''),
      sortable: true,
      id: ConfigProfileLogField.CreatedAt
    },
    {
      name: t('profiles.profile_form.logs_tab.table.heading.status'),
      selector: (row) => {
        return (
          <Label theme={configProfileInstanceStatusThemes[row.status] || 'warning'}>
            {configProfileInstanceStatusNames[row.status] || 'Unknown'}
          </Label>
        );
      },
      sortable: true,
      id: ConfigProfileLogField.Status
    }
  ];

  const reloadData = async () => {
    if (!id) return;
    const response = await fetchRequest.send(
      getConfigProfileLogs(id, {
        page: paginationState.page,
        limit: paginationState.rowsPerPage,
        sort_field: sortState?.column as ConfigProfileLogField,
        sort_order: sortState?.order as PaginationSortOrder
      }),
      1
    );
    setLogs(response.logs);
  };

  const handleChangePagination = (state: PaginationState) => setPaginationState(state);

  const handleSort = (state?: SortState) => setSortState(state);

  useEffect(() => {
    reloadData().then();
  }, [paginationState, sortState]);

  useEffect(() => {
    const fetchLogs = async () => {
      if (!id) return;
      const result: GetConfigProfileLogsResponseDto = await fetchRequest.send(
        getConfigProfileLogs(id, {
          page: paginationState.page,
          limit: paginationState.rowsPerPage,
          sort_field: sortState?.column as ConfigProfileLogField,
          sort_order: sortState?.order as PaginationSortOrder
        })
      );
      setLogs(result.logs);

      setLogsCount(result.count);
    };

    void fetchLogs();
  }, []);

  return (
    <div className={css.DataTableContainer}>
      {fetchRequest.loading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          className={css.Table}
          data={logs}
          onChangePagination={handleChangePagination}
          onChangeSort={handleSort}
          pagination
          paginationTotalRows={logsCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          paginationState={paginationState}
          sortState={sortState}
        />
      )}
    </div>
  );
};
