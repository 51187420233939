import { array, boolean, mixed, object, string } from 'yup';
import {
  ConditionConjunctive,
  DeviceSimpleDto,
  SmartGroupDevicesConditionStrippedDto
} from '../../../../../types/api';
import i18next from '../../../../../i18n/i18n';
import { notEmpty } from '../../../../../utils/yup.validations';

export interface SmartGroupGeneralForm {
  id: string;
  display_name: string;
}

export const smartGroupDevicesGeneralFormSchema = object({
  id: string(),
  display_name: string()
    .trim()
    .required(i18next.t('smart_groups.page.group_information_tab.errors.no_display_name'))
    .test(notEmpty(i18next.t('smart_groups.page.group_information_tab.errors.no_display_name')))
}).required();

export type SmartGroupCriteriaValues = SmartGroupDevicesConditionStrippedDto[];
export type SmartGroupReportsValues = DeviceSimpleDto[];

export interface SmartGroupFormValues {
  group: SmartGroupGeneralForm;
  criteria?: SmartGroupCriteriaValues;
  reports?: SmartGroupReportsValues;
}

export const smartGroupDevicesFormSchema = object({
  group: smartGroupDevicesGeneralFormSchema,
  criteria: array().of(
    object({
      start_bracket: boolean().required().default(false),
      end_bracket: boolean().required().default(false),
      criteria: mixed<string>().required(
        i18next.t('smart_groups.page.criteria_tab.new_criteria.errors.no_criteria')
      ),
      operator: mixed<'=' | '!=' | 'like' | 'not like' | '>' | '>=' | '<' | '<='>()
        .required('Operator is required!')
        .oneOf(['=', '!=', 'like', 'not like', '>', '>=', '<', '<=']),
      value: string().required(
        i18next.t('smart_groups.page.criteria_tab.new_criteria.errors.value_required')
      ),
      conjunctive: mixed<ConditionConjunctive | null>().oneOf([
        ConditionConjunctive.And,
        ConditionConjunctive.Or,
        null
      ])
    })
  )
});
