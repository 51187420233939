import { FC } from 'react';
import css from './policy-options-maintenance.module.scss';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, Text } from '@gravity-ui/uikit';
import { PolicyFormValues } from '../../../../policy-form.schema';

interface IProps {
  form: UseFormReturn<PolicyFormValues>;
  disabled?: boolean;
}

export const PolicyOptionsMaintenance: FC<IProps> = (props) => {
  const { form, disabled = false } = props;
  const { control } = form;
  const { t } = useTranslation();

  return (
    <div className={css.Root}>
      <Text variant="subheader-2">{t('policies.options_maintenance.title_maintenance')}</Text>
      <Controller
        control={control}
        name="maintenance.update_inventory"
        render={({ field: { value, onChange, ref } }) => (
          <Checkbox
            ref={ref}
            className={css.Checkbox}
            content={<Text>{t('policies.options_maintenance.update_inventory')}</Text>}
            checked={value}
            disabled={disabled}
            onChange={onChange}
          />
        )}
      />
    </div>
  );
};
