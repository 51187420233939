import { array, object, string } from 'yup';
import i18next from '../../../../../i18n/i18n';
import { notEmpty } from '../../../../../utils/yup.validations';
import { DeviceSimpleDto } from '../../../../../types/api';

export interface StaticGroupFormValues {
  general: StaticGroupGeneralFormValues;
  devices: DeviceSimpleDto[];
}

export interface StaticGroupGeneralFormValues {
  display_name: string;
}

export const staticGroupFormSchema = object({
  general: object({
    display_name: string()
      .required(i18next.t('static_groups.page.group_information_tab.errors.no_display_name'))
      .test(notEmpty(i18next.t('static_groups.page.group_information_tab.errors.no_display_name')))
  }).required(),
  devices: array().default([])
});
