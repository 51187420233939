import { mixed, object, string } from 'yup';
import { isHttpUrl, isPort, notEmpty } from '../../../../utils/yup.validations';
import { PackageDistributionPointType } from '../../../../types/api';
import i18next from '../../../../i18n/i18n';

export interface PackagePointForm {
  display_name: string;
  type: PackageDistributionPointType;
  server: string;
  share_name: string;
  port: string;
  read_username: string;
  read_password: string;
}

export const packagePointFormSchema = object({
  display_name: string()
    .required(
      i18next.t(
        'settings.tiles.package_distribution_points.page.package_distribution_point_page.error.no_display_name'
      )
    )
    .test(
      notEmpty(
        i18next.t(
          'settings.tiles.package_distribution_points.page.package_distribution_point_page.error.no_display_name'
        )
      )
    ),
  type: mixed<PackageDistributionPointType>().default(PackageDistributionPointType.Http),
  server: string()
    .required(
      i18next.t(
        'settings.tiles.package_distribution_points.page.package_distribution_point_page.error.no_server_path'
      )
    )
    .when('type', {
      is: 'http',
      then: string().test(isHttpUrl())
    }),
  share_name: string().when('type', {
    is: 'smb',
    then: string().required(
      i18next.t(
        'settings.tiles.package_distribution_points.page.package_distribution_point_page.error.no_share_name'
      )
    )
  }),
  port: string().test(isPort()),
  read_username: string(),
  read_password: string()
}).required();
