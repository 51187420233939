import React, { FC, useState } from 'react';
import { InventoryTabMobileTabs, InventoryTabTabs } from '../../../../constants';
import css from './inventory-tab.module.scss';
import { DeviceFullDto } from '../../../../../../../types/api';
import { Icon } from '@gravity-ui/uikit';
import { useTranslation } from 'react-i18next';
import useDeviceSection, { DeviceType } from '../../../../../../contexts/device-section.context';
import {
  CircleInfo,
  Cpu,
  Cubes3,
  Display,
  FileText,
  Gear,
  HardDrive,
  Person,
  ShieldKeyhole,
  Smartphone,
  SquarePlus,
  Sticker,
  Vault
} from '@gravity-ui/icons';
import { ExtensionAttributesTable } from './components/extension-attributes-table/extension-attributes-table.component';
import { GeneralTable } from './components/general-table/general-table.component';
import { HardwareTable } from './components/hardware-table/hardware-table.component';
import { OsInfoTable } from './components/os-info-table/os-info-table.component';
import { SecurityTable } from './components/security-table/security-table.component';
import { StorageTable } from './components/storage-table/storage-table.component';
import { EncryptionTable } from './components/encryption-table/encryption-table.component';
import { ApplicationsTable } from './components/applications-table/applications-table.component';
import { ProfilesTable } from './components/profiles-table/profiles-table.component';
import { UserAccountsTable } from './components/user-accounts-table/user-accounts-table.component';
import { ServicesTable } from './components/services-table/services-table.component';
import { GeneralTableMobile } from './components/general-table-mobile/general-table-mobile.component';
import { OsInfoTableMobile } from './components/os-info-table-mobile/os-info-table-mobile.component';
import { HardwareTableMobile } from './components/hardware-table-mobile/hardware-table-mobile.component';
import { SecurityTableMobile } from './components/security-table-mobile/security-table-mobile.component';
import { ProvisioningProfilesTable } from './components/provisioning-profiles-table-mobile/provisioning-profiles-table-mobile.component';
import { CertificatesTableMobile } from './components/certificates-table-mobile/certificates-table-mobile.component';
import { DeviceUser } from './components/device-user/device-user.component';
import { Navbar, NavbarItemProps } from '../../../../../../components/navbar/navbar.component';

interface IProps {
  device?: DeviceFullDto;
}

export const InventoryTab: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const [openedTab, setOpenedTab] = useState<string>(InventoryTabTabs.GENERAL);
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();

  const handleChangeTab = (tabName: string) => setOpenedTab(tabName);

  const computerTabs: NavbarItemProps[] = [
    {
      id: InventoryTabTabs.GENERAL,
      icon: <Icon data={CircleInfo} />,
      title: t('inventory.device_page.inventory_tab.general_tab.name')
    },
    {
      id: InventoryTabTabs.HARDWARE,
      icon: <Icon data={Cpu} />,
      title: t('inventory.device_page.inventory_tab.hardware_tab.name')
    },
    {
      id: InventoryTabTabs.OS_INFO,
      icon: <Icon data={Display} />,
      title: t('inventory.device_page.inventory_tab.os_info_tab.name')
    },
    {
      id: InventoryTabTabs.SECURITY,
      icon: <Icon data={ShieldKeyhole} />,
      title: t('inventory.device_page.inventory_tab.security_tab.name')
    },
    {
      id: InventoryTabTabs.STORAGE,
      icon: <Icon data={HardDrive} />,
      title: t('inventory.device_page.inventory_tab.storage_tab.name')
    },
    {
      id: InventoryTabTabs.ENCRYPTION,
      icon: <Icon data={Vault} />,
      title: t('inventory.device_page.inventory_tab.disk_encryption_tab.name')
    },
    {
      id: InventoryTabTabs.APPLICATIONS,
      icon: <Icon data={Cubes3} />,
      title: t('inventory.device_page.inventory_tab.applications_tab.name')
    },
    {
      id: InventoryTabTabs.PROFILES,
      icon: <Icon data={FileText} />,
      title: t('inventory.device_page.inventory_tab.profiles_tab.name')
    },
    {
      id: InventoryTabTabs.USER_ACCOUNTS,
      icon: <Icon data={Person} />,
      title: t('inventory.device_page.inventory_tab.local_user_accounts_tab.name')
    },
    {
      id: InventoryTabTabs.SERVICES,
      icon: <Icon data={Gear} />,
      title: t('inventory.device_page.inventory_tab.services_tab.name')
    },
    {
      id: InventoryTabTabs.EXTENSION_ATTRIBUTES,
      icon: <Icon data={SquarePlus} />,
      title: t('inventory.device_page.inventory_tab.extension_attributes_tab.name')
    }
  ];
  const mobileTabs: NavbarItemProps[] = [
    {
      id: InventoryTabMobileTabs.GENERAL,
      icon: <Icon data={CircleInfo} />,
      title: t('inventory_mobile.device_page.inventory_tab.general_tab.name')
    },
    {
      id: InventoryTabMobileTabs.OS_INFO,
      icon: <Icon data={Smartphone} />,
      title: t('inventory_mobile.device_page.inventory_tab.os_info_tab.name')
    },
    {
      id: InventoryTabMobileTabs.HARDWARE,
      icon: <Icon data={Gear} />,
      title: t('inventory_mobile.device_page.inventory_tab.hardware_tab.name')
    },
    {
      id: InventoryTabMobileTabs.SECURITY,
      icon: <Icon data={ShieldKeyhole} />,
      title: t('inventory_mobile.device_page.inventory_tab.security_tab.name')
    },
    {
      id: InventoryTabMobileTabs.APPLICATIONS,
      icon: <Icon data={Cubes3} />,
      title: t('inventory_mobile.device_page.inventory_tab.applications_tab.name')
    },
    {
      id: InventoryTabMobileTabs.PROFILES,
      icon: <Icon data={FileText} />,
      title: t('inventory_mobile.device_page.inventory_tab.profiles_tab.name')
    },
    {
      id: InventoryTabMobileTabs.PROVISIONING_PROFILES,
      icon: <Icon data={FileText} />,
      title: t('inventory_mobile.device_page.inventory_tab.provisioning_profiles_tab.name')
    },
    {
      id: InventoryTabMobileTabs.CERTIFICATES,
      icon: <Icon data={Sticker} />,
      title: t('inventory_mobile.device_page.inventory_tab.certificates_tab.name')
    }
  ];
  const deviceUserTab: NavbarItemProps = {
    id: InventoryTabTabs.DEVICE_USER,
    icon: <Icon data={Person} />,
    title: t('inventory.device_page.inventory_tab.device_user_tab.name')
  };
  const actualTabs = [
    ...(deviceType === DeviceType.COMPUTERS ? computerTabs : mobileTabs),
    deviceUserTab
  ];

  return (
    <>
      <Navbar
        className={css.InventoryTabNavBar}
        activeTab={openedTab}
        items={actualTabs}
        onSelectTab={handleChangeTab}
      />
      <div className={css.InventoryTabContent}>
        {deviceType === DeviceType.COMPUTERS ? (
          <>
            {openedTab === InventoryTabTabs.GENERAL && <GeneralTable device={device} />}
            {openedTab === InventoryTabTabs.HARDWARE && <HardwareTable device={device} />}
            {openedTab === InventoryTabTabs.OS_INFO && <OsInfoTable device={device} />}
            {openedTab === InventoryTabTabs.SECURITY && <SecurityTable device={device} />}
            {openedTab === InventoryTabTabs.STORAGE && <StorageTable device={device} />}
            {openedTab === InventoryTabTabs.ENCRYPTION && <EncryptionTable device={device} />}
            {openedTab === InventoryTabTabs.APPLICATIONS && <ApplicationsTable device={device} />}
            {openedTab === InventoryTabTabs.PROFILES && <ProfilesTable device={device} />}
            {openedTab === InventoryTabTabs.USER_ACCOUNTS && <UserAccountsTable device={device} />}
            {openedTab === InventoryTabTabs.SERVICES && <ServicesTable device={device} />}
            {openedTab === InventoryTabTabs.EXTENSION_ATTRIBUTES && (
              <ExtensionAttributesTable device={device} />
            )}
          </>
        ) : (
          <>
            {openedTab === InventoryTabMobileTabs.GENERAL && <GeneralTableMobile device={device} />}
            {openedTab === InventoryTabMobileTabs.OS_INFO && <OsInfoTableMobile device={device} />}
            {openedTab === InventoryTabMobileTabs.HARDWARE && (
              <HardwareTableMobile device={device} />
            )}
            {openedTab === InventoryTabMobileTabs.SECURITY && (
              <SecurityTableMobile device={device} />
            )}
            {openedTab === InventoryTabMobileTabs.APPLICATIONS && (
              <ApplicationsTable device={device} />
            )}
            {openedTab === InventoryTabMobileTabs.PROFILES && <ProfilesTable device={device} />}
            {openedTab === InventoryTabMobileTabs.PROVISIONING_PROFILES && (
              <ProvisioningProfilesTable device={device} />
            )}
            {openedTab === InventoryTabMobileTabs.CERTIFICATES && (
              <CertificatesTableMobile device={device} />
            )}
          </>
        )}
        {openedTab === InventoryTabTabs.DEVICE_USER && <DeviceUser device={device} />}
      </div>
    </>
  );
};
