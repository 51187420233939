import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeviceUserSimpleDto,
  DeviceUserSortField
} from '../../../../../../../../../../../types/api';
import { PaginationRowsPerPageOptionsDeviceUsers } from '../../../../../../../../../../../const/pagination.const';
import {
  Column,
  PaginationState,
  SortState,
  Table
} from '../../../../../../../../../../components/table/table.component';
import { Button } from '@gravity-ui/uikit';
import { WithClassname } from '../../../../../../../../../../../types/common';

interface IProps {
  handleLinkUser: (row: DeviceUserSimpleDto) => () => Promise<void>;
  deviceUsers: DeviceUserSimpleDto[];
  deviceUsersCount: number;
  paginationState: PaginationState;
  onChangePaginationState: (value: PaginationState) => void;
  sortState?: SortState;
  onChangeSortState: (value?: SortState) => void;
  leftContent?: ReactNode;
}

export const DeviceUserList: FC<IProps & WithClassname> = (props) => {
  const {
    deviceUsers,
    deviceUsersCount,
    handleLinkUser,
    paginationState,
    onChangePaginationState,
    sortState,
    onChangeSortState,
    leftContent,
    className
  } = props;
  const { t } = useTranslation();

  const deviceUsersColumns: Column<DeviceUserSimpleDto>[] = [
    {
      id: DeviceUserSortField.Username,
      name: t('inventory.device_page.inventory_tab.device_user_tab.form.column_username'),
      selector: (row: DeviceUserSimpleDto) => row.username,
      sortable: true
    },
    {
      id: DeviceUserSortField.EmployeeName,
      name: t('inventory.device_page.inventory_tab.device_user_tab.form.column_employee_name'),
      selector: (row: DeviceUserSimpleDto) => row?.employee_name,
      sortable: true
    },
    {
      id: DeviceUserSortField.Email,
      name: t('inventory.device_page.inventory_tab.device_user_tab.form.column_email'),
      selector: (row: DeviceUserSimpleDto) => row.email,
      sortable: true
    },
    {
      id: 'link-button',
      name: '',
      selector: (row: DeviceUserSimpleDto) => (
        <Button view="outlined-success" onClick={handleLinkUser(row)}>
          {t('inventory.device_page.inventory_tab.device_user_tab.buttons.link')}
        </Button>
      ),
      width: 150,
      align: 'end'
    }
  ];

  return (
    <>
      {deviceUsers && (
        <Table
          className={className}
          columns={deviceUsersColumns}
          data={deviceUsers}
          leftContent={leftContent}
          pagination
          paginationState={paginationState}
          paginationTotalRows={deviceUsersCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptionsDeviceUsers}
          onChangePagination={onChangePaginationState}
          onChangeSort={onChangeSortState}
          sortState={sortState}
        />
      )}
    </>
  );
};
