import i18next from '../i18n/i18n';

export function getLocalizedErrorString(error: Error): string {
  let name = error.name;
  name = name === undefined ? 'Error' : `${name}`;

  const localizedName = i18next.t(`common.errors.${name.toLowerCase()}`, '');
  name = localizedName || name;
  let msg = error.message;
  msg = msg === undefined ? '' : `${msg}`;
  if (name === '') {
    return msg;
  }
  if (msg === '') {
    return name;
  }
  return `${name}: ${msg}`;
}
