import { FC, FormEvent, useEffect, useState } from 'react';
import { getInactiveLicenseList, getLicenses, uploadLicense } from '../../../../api/settings';
import { useTranslation } from 'react-i18next';
import {
  DeviceOsType,
  GetActiveLicensesDto,
  GetDevicesCountResponseDto,
  GetLicenseListDto,
  LicenseDto,
  UploadLicenseFileRequestDto
} from '../../../../types/api';
import { formatDate } from '../../../../utils/time.utils';
import { LicenseListComponent } from './components/last-license-list/settings-last-license-list';
import { LicenseTab } from './components/license-form/settings-license-form';
import { getTotalEnrolled } from '../../../../api/device';
import { useForm } from 'react-hook-form';
import { UploadLicenseForm, UploadLicenseFormSchema } from './settings-license.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { Header } from '../../../components/header/header.component';
import css from './settings-license.module.scss';
import { Tabs, TabsItemProps, useToaster } from '@gravity-ui/uikit';
import useRequest from '../../../../hooks/useRequest';
import { FormTableSkeleton } from './components/form-table-skeleton/form-table-skeleton.component';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';

export const SettingsLicense: FC = () => {
  const [activeTab, setActiveTab] = useState<DeviceOsType>(DeviceOsType.MacOS);
  const [inactiveLicenses, setInactiveLicenses] = useState<LicenseDto[]>();

  const [macOSLicense, setMacOSLicense] = useState<LicenseDto | null>(null);
  const [expirationDateMacOSLicense, setExpirationDateMacOSLicense] = useState('');
  const [macOSLicenseActivationPeriod, setMacOSLicenseActivationPeriod] = useState(0);
  const [totalMacOSEnrolled, setTotalMacOSEnrolled] = useState<number>(0);

  const [iOSLicense, setIOSLicense] = useState<LicenseDto | null>(null);
  const [expirationDateIOSLicense, setExpirationDateIOSLicense] = useState('');
  const [iOSLicenseActivationPeriod, setIOSLicenseActivationPeriod] = useState(0);
  const [totalIOSEnrolled, setTotalIOSEnrolled] = useState<number>(0);

  const { t } = useTranslation();
  const toaster = useToaster();
  const fetchRequest = useRequest();
  const updateMacosRequest = useRequest<LicenseDto>();
  const updateIosRequest = useRequest<LicenseDto>();

  const licenseForm = useForm<UploadLicenseForm>({
    mode: 'onChange',
    resolver: yupResolver(UploadLicenseFormSchema),
    defaultValues: UploadLicenseFormSchema.getDefault()
  });

  const { trigger, getValues } = licenseForm;

  const tabs: TabsItemProps[] = [
    {
      id: DeviceOsType.MacOS,
      title: t('settings.tiles.license.license_os_tab.macos_license')
    },
    {
      id: DeviceOsType.IOS,
      title: t('settings.tiles.license.license_os_tab.ios_license')
    }
  ];

  const handleChangeTab = (tab: DeviceOsType) => {
    setActiveTab(tab);
  };

  const init = async () => {
    try {
      const licenses = (await fetchRequest.send(getLicenses(), 1000)) as GetActiveLicensesDto;
      if (licenses.macos_license) setMacOSLicense(licenses.macos_license);
      if (licenses.ios_license) setIOSLicense(licenses.ios_license);

      const inactiveLicenseList = (await fetchRequest.send(
        getInactiveLicenseList(),
        1000
      )) as GetLicenseListDto;
      setInactiveLicenses(inactiveLicenseList.licenses);

      const macOSEnrolled = (await fetchRequest.send(
        getTotalEnrolled({ os_type: DeviceOsType.MacOS }),
        1000
      )) as GetDevicesCountResponseDto;
      setTotalMacOSEnrolled(macOSEnrolled.count);
      const iOSEnrolled = (await fetchRequest.send(
        getTotalEnrolled({ os_type: DeviceOsType.IOS }),
        1000
      )) as GetDevicesCountResponseDto;
      setTotalIOSEnrolled(iOSEnrolled.count);

      const expDateMacOSLicense = licenses.macos_license?.expires_at;
      if (expDateMacOSLicense) {
        setExpirationDateMacOSLicense(formatDate(expDateMacOSLicense, 'dd-MM-yyyy'));
        const diff = new Date(expDateMacOSLicense).getTime() - new Date().getTime();
        setMacOSLicenseActivationPeriod(Math.ceil(diff / (1000 * 3600 * 24)));
      }
      const expDateIOSLicense = licenses.ios_license?.expires_at;
      if (expDateIOSLicense) {
        setExpirationDateIOSLicense(formatDate(expDateIOSLicense, 'dd-MM-yyyy'));
        const diff = new Date(expDateIOSLicense).getTime() - new Date().getTime();
        setIOSLicenseActivationPeriod(Math.ceil(diff / (1000 * 3600 * 24)));
      }
    } catch (e) {
      setMacOSLicense(null);
      setIOSLicense(null);
    }
  };

  const handleFormSubmit = async (event?: FormEvent) => {
    event?.preventDefault();

    if (fetchRequest.loading) return;

    const isValid = await trigger();
    if (!isValid) return;

    const values = getValues();
    const licenseFile = values.license;
    try {
      const request: UploadLicenseFileRequestDto = {
        os_type: activeTab,
        license: licenseFile
      };

      if (activeTab === DeviceOsType.MacOS) {
        const license = await updateMacosRequest.send(uploadLicense(request), 1000);
        setMacOSLicense(license);
        const expDateMacOSLicense = license.expires_at;
        if (expDateMacOSLicense) {
          setExpirationDateMacOSLicense(formatDate(expDateMacOSLicense, 'dd-MM-yyyy'));
          const diff = new Date(expDateMacOSLicense).getTime() - new Date().getTime();
          setMacOSLicenseActivationPeriod(Math.ceil(diff / (1000 * 3600 * 24)));
        }
        toaster.add({
          name: 'license-success',
          content: t('settings.tiles.license.page.successfully_macos_activate'),
          theme: 'success',
          autoHiding: 5000
        });
      }
      if (activeTab === DeviceOsType.IOS) {
        const license = await updateIosRequest.send(uploadLicense(request), 1000);
        setIOSLicense(license);
        const expDateIOSLicense = license.expires_at;
        if (expDateIOSLicense) {
          setExpirationDateIOSLicense(formatDate(expDateIOSLicense, 'dd-MM-yyyy'));
          const diff = new Date(expDateIOSLicense).getTime() - new Date().getTime();
          setIOSLicenseActivationPeriod(Math.ceil(diff / (1000 * 3600 * 24)));
        }
        toaster.add({
          name: 'license-success',
          content: t('settings.tiles.license.page.successfully_ios_activate'),
          theme: 'success',
          autoHiding: 5000
        });
      }
    } catch (error) {
      const localizedError = getLocalizedErrorString(error as Error);
      console.log(localizedError);
      toaster.add({
        name: 'license-error',
        content: localizedError,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Header />
      <div className={css.Content}>
        {fetchRequest.loading ? (
          <FormTableSkeleton />
        ) : (
          <>
            <Tabs
              className={css.NavBar}
              items={tabs}
              activeTab={activeTab}
              onSelectTab={handleChangeTab}
            />
            {activeTab === DeviceOsType.MacOS &&
              (updateMacosRequest.loading ? (
                <FormSkeleton />
              ) : (
                <LicenseTab
                  form={licenseForm}
                  licenseOSTab={activeTab}
                  expirationDate={expirationDateMacOSLicense}
                  activationPeriod={macOSLicenseActivationPeriod}
                  totalDevicesEnrolled={totalMacOSEnrolled}
                  license={macOSLicense}
                  onSubmit={handleFormSubmit}
                />
              ))}
            {activeTab === DeviceOsType.IOS &&
              (updateIosRequest.loading ? (
                <FormSkeleton />
              ) : (
                <LicenseTab
                  form={licenseForm}
                  licenseOSTab={activeTab}
                  expirationDate={expirationDateIOSLicense}
                  activationPeriod={iOSLicenseActivationPeriod}
                  totalDevicesEnrolled={totalIOSEnrolled}
                  license={iOSLicense}
                  onSubmit={handleFormSubmit}
                />
              ))}
            <hr />
            {inactiveLicenses && <LicenseListComponent allLicenses={inactiveLicenses} />}
          </>
        )}
      </div>
    </div>
  );
};
