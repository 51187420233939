import { FC, useEffect, useState } from 'react';
import css from './static-group-devices-list.module.scss';
import {
  DeviceOsType,
  GetStaticGroupsDevicesResponseDto,
  PaginationSortOrder,
  Permission,
  StaticGroupDevicesSimpleDto,
  StaticGroupDevicesSortField
} from '../../../types/api';
import { useNavigate } from 'react-router-dom';
import { getStaticGroups } from '../../../api/static-groups-devices';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import { usePermission } from '../../contexts/permission.context';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';
import { Column, PaginationState, SortState, Table } from '../../components/table/table.component';
import { Button, Text } from '@gravity-ui/uikit';
import { Header } from '../../components/header/header.component';
import useRequest from '../../../hooks/useRequest';
import noDataImage from '../../../assets/images/no-data.png';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';

export const StaticGroupDevicesList: FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const [staticGroupsCount, setStaticGroupsCount] = useState(0);
  const [staticGroupsData, setStaticGroupsData] = useState<StaticGroupDevicesSimpleDto[]>([]);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: 10
  });
  const [sortState, setSortState] = useState<SortState>();
  const fetchRequest = useRequest<GetStaticGroupsDevicesResponseDto>();

  const handleRowClick = (row: StaticGroupDevicesSimpleDto) => navigate(row.id);

  const handleChangePagination = (state: PaginationState) => setPaginationState(state);

  const handleChangeSort = (state?: SortState) => setSortState(state);

  const handleCreateStaticGroup = () => navigate('new');

  useEffect(() => {
    const fetchStaticGroupsData = async () => {
      const result: GetStaticGroupsDevicesResponseDto = await fetchRequest.send(
        getStaticGroups({
          limit: paginationState.rowsPerPage,
          page: paginationState.page,
          sort_field: sortState?.column as StaticGroupDevicesSortField,
          sort_order: sortState?.order as PaginationSortOrder,
          os_type: osType
        }),
        1000
      );
      const staticGroups: StaticGroupDevicesSimpleDto[] = result.groups;
      setStaticGroupsData(staticGroups);
      setStaticGroupsCount(result.count);
    };

    void fetchStaticGroupsData();
  }, [paginationState, sortState]);

  const columns: Column<StaticGroupDevicesSimpleDto>[] = [
    {
      name: t('static_groups.table.heading.name'),
      id: StaticGroupDevicesSortField.Title,
      selector: (row: StaticGroupDevicesSimpleDto) => row.title,
      sortable: true
    },
    {
      name: t('static_groups.table.heading.id'),
      id: StaticGroupDevicesSortField.Id,
      selector: (row: StaticGroupDevicesSimpleDto) => row.id,
      sortable: true
    },
    {
      name: t('static_groups.table.heading.count'),
      id: StaticGroupDevicesSortField.DevicesCount,
      selector: (row: StaticGroupDevicesSimpleDto) => row.devices_count,
      sortable: true
    }
  ];

  const headerContent = (
    <Button
      view="action"
      disabled={!isAllowedTo(Permission.EditGroups)}
      onClick={handleCreateStaticGroup}
    >
      {t('static_groups.new_btn')}
    </Button>
  );

  return (
    <div id="static-groups" className={css.StaticGroupsContainer}>
      <Header rightContent={headerContent} />
      <div className={css.Content}>
        {fetchRequest.loading ? (
          <TableSkeleton />
        ) : staticGroupsCount > 0 ? (
          <div className={css.DataTableContainer}>
            <Table
              columns={columns}
              data={staticGroupsData}
              onChangePagination={handleChangePagination}
              onChangeSort={handleChangeSort}
              onRowClick={handleRowClick}
              pagination
              paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
              paginationTotalRows={staticGroupsCount}
              paginationState={paginationState}
              sortState={sortState}
              leftContent={<Text variant="display-2">{t('static_groups.title')}</Text>}
            />
          </div>
        ) : (
          <>
            <Text variant="display-2">{t('static_groups.title')}</Text>
            <div className={css.NoDataContainer}>
              <img alt="no-data" src={noDataImage} />
              <Text variant="subheader-3">{t('static_groups.no_data')}</Text>
              {headerContent}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
