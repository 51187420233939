import { FC, useEffect, useState } from 'react';
import css from './smart-group-device-users-list.module.scss';
import {
  GetSmartGroupsDeviceUsersResponseDto,
  PaginationSortOrder,
  Permission,
  SmartGroupDeviceUsersSimpleDto,
  SmartGroupDeviceUsersSortField
} from '../../../types/api';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import { usePermission } from '../../contexts/permission.context';
import { getSmartGroups } from '../../../api/smart-group-device-users';
import { Column, PaginationState, SortState, Table } from '../../components/table/table.component';
import { Button, Text } from '@gravity-ui/uikit';
import { Header } from '../../components/header/header.component';
import useRequest from '../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';
import noDataImage from '../../../assets/images/no-data.png';

export const SmartGroupDeviceUsersListComponent: FC = () => {
  const navigate = useNavigate();

  const [smartGroupsData, setSmartGroupsData] = useState<SmartGroupDeviceUsersSimpleDto[]>([]);
  const [smartGroupsCount, setSmartGroupsCount] = useState(0);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: PaginationRowsPerPageOptions[0]
  });
  const [sortState, setSortState] = useState<SortState>();

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const fetchRequest = useRequest<GetSmartGroupsDeviceUsersResponseDto>();

  const columns: Column<SmartGroupDeviceUsersSimpleDto>[] = [
    {
      name: t('smart_groups.table.heading.name'),
      selector: (row) => row.title,
      id: SmartGroupDeviceUsersSortField.Title,
      sortable: true
    },
    {
      name: t('smart_groups.table.heading.count'),
      selector: (row) => (row.device_users_count ? row.device_users_count : 0),
      sortable: true,
      id: SmartGroupDeviceUsersSortField.DeviceUsersCount
    },
    {
      name: t('smart_groups.table.heading.created'),
      selector: (row) => formatDate(row.created_at),
      sortable: true,
      id: SmartGroupDeviceUsersSortField.CreatedAt
    },
    {
      name: t('smart_groups.table.heading.updated'),
      selector: (row) => formatDate(row.updated_at),
      sortable: true,
      id: SmartGroupDeviceUsersSortField.UpdatedAt
    }
  ];

  const handleRowClick = (row: SmartGroupDeviceUsersSimpleDto) => {
    navigate(row.id);
  };

  const handleChangePagination = (state: PaginationState) => {
    setPaginationState(state);
  };

  const handleChangeSort = (state?: SortState) => {
    setSortState(state);
  };

  const handleClickNew = () => {
    navigate('new');
  };

  useEffect(() => {
    const fetchSmartGroupsData = async () => {
      const result: GetSmartGroupsDeviceUsersResponseDto = await fetchRequest.send(
        getSmartGroups({
          limit: paginationState.rowsPerPage,
          page: paginationState.page,
          sort_field: sortState?.column as SmartGroupDeviceUsersSortField,
          sort_order: sortState?.order as PaginationSortOrder
        }),
        1000
      );
      const smartGroups: SmartGroupDeviceUsersSimpleDto[] = result.groups;
      setSmartGroupsData(smartGroups);
      setSmartGroupsCount(result.count);
    };

    void fetchSmartGroupsData();
  }, [paginationState, sortState]);

  const headerRightContent = (
    <Button disabled={!isAllowedTo(Permission.EditGroups)} view="action" onClick={handleClickNew}>
      {t('smart_groups.new_btn')}
    </Button>
  );

  const tableLeftContent = <Text variant="display-2">{t('smart_groups.title')}</Text>;

  return (
    <div className={css.Root}>
      <Header rightContent={headerRightContent} />
      <div className={css.Content}>
        {fetchRequest.loading ? (
          <TableSkeleton />
        ) : smartGroupsCount > 0 ? (
          <div className={css.DataTableContainer}>
            <Table
              columns={columns}
              data={smartGroupsData}
              onChangePagination={handleChangePagination}
              onChangeSort={handleChangeSort}
              onRowClick={handleRowClick}
              pagination
              paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
              paginationTotalRows={smartGroupsCount}
              paginationState={paginationState}
              sortState={sortState}
              leftContent={tableLeftContent}
            />
          </div>
        ) : (
          <>
            <Text variant="display-2">{t('smart_groups.title')}</Text>
            <div className={css.NoDataContainer}>
              <img alt="no-data" src={noDataImage} />
              <Text variant="subheader-3">{t('smart_groups.no_data')}</Text>
              {headerRightContent}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
