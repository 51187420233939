import { FC, useEffect, useState } from 'react';
import css from './settings-pki-ca.module.scss';
import {
  createCertificateFromCSR,
  downloadCaBackup,
  downloadCaCertificate,
  downloadProfileWithCaCertificate,
  getBuiltInCaCertificate
} from '../../../../api/pki';
import { PkiCertificateFullDto } from '../../../../types/api';
import { downloadFile } from '../../../../utils/file.utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CaBackupForm,
  caBackupFormSchema,
  CsrRequestForm,
  csrRequestFormSchema
} from './settings-pki-ca.schema';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { Button, Dialog, Text, TextArea, TextInput, useToaster } from '@gravity-ui/uikit';
import { FormSkeleton } from './component/form-skeleton/form-skeleton.component';

export const SettingsPkiCa: FC = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const fetchRequest = useRequest<PkiCertificateFullDto>();

  const [caCertificate, setCaCertificate] = useState<PkiCertificateFullDto>();
  const [isBackupModalOpen, setIsBackupModalOpen] = useState(false);
  const [isCsrModalOpen, setIsCsrModalOpen] = useState(false);
  const caBackupRequest = useRequest<ArrayBuffer>();
  const csrCreateRequest = useRequest<AxiosResponse<string>>();
  const caBackupForm = useForm<CaBackupForm>({
    mode: 'onChange',
    resolver: yupResolver(caBackupFormSchema),
    defaultValues: caBackupFormSchema.getDefault()
  });
  const csrRequestForm = useForm<CsrRequestForm>({
    mode: 'onChange',
    resolver: yupResolver(csrRequestFormSchema),
    defaultValues: csrRequestFormSchema.getDefault()
  });

  const init = async () => {
    const certificate = await fetchRequest.send(getBuiltInCaCertificate());
    setCaCertificate(certificate);
  };

  const handleCreateBackupClick = () => setIsBackupModalOpen(true);
  const handleBackupModalClose = async () => {
    if (caBackupRequest.loading) return;
    caBackupForm.reset({ backup_password: '', backup_password_verify: '' });
    setIsBackupModalOpen(false);
  };
  const handleBackupModalSubmit = async () => {
    if (!caCertificate) return;
    const isValid = await caBackupForm.trigger();
    if (!isValid) return;
    const values = caBackupForm.getValues();
    try {
      const response = await caBackupRequest.send(
        downloadCaBackup(caCertificate.id, { password: values.backup_password })
      );
      downloadFile('CABackup.p12', [response]);
      caBackupForm.reset({ backup_password: '', backup_password_verify: '' });
      setIsBackupModalOpen(false);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'download-ca-backup-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleCsrCreateClick = () => setIsCsrModalOpen(true);
  const handleCsrModalClose = () => {
    csrRequestForm.reset({ csr: '' });
    setIsCsrModalOpen(false);
  };
  const handleCsrModalSubmit = async () => {
    if (!caCertificate) return;
    const isValid = await csrRequestForm.trigger();
    if (!isValid) return;
    const values = csrRequestForm.getValues();
    try {
      const response = await csrCreateRequest.send(
        createCertificateFromCSR(caCertificate.id, { csr: values.csr })
      );
      const match = response.headers['content-disposition'].match(/filename="([^"]+)"/);
      const filename = match?.[1] || 'cert.pem';
      downloadFile(filename, [response.data]);
      csrRequestForm.reset({ csr: '' });
      setIsCsrModalOpen(false);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'download-cert-from-csr-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleClickCertificateDownload = async () => {
    if (!caCertificate) return;
    try {
      const pem = await downloadCaCertificate(caCertificate.id);
      downloadFile(`${caCertificate.subject}.pem`, [pem]);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'download-ca-cert-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleClickProfileDownload = async () => {
    if (!caCertificate) return;
    try {
      const profile = await downloadProfileWithCaCertificate(caCertificate.id);
      downloadFile(`certificateCA.mobileconfig`, [profile]);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'download-profile-with-ca-cert-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  useEffect(() => {
    void init();
  }, []);

  return (
    <div className={css.Root}>
      {fetchRequest.loading && <FormSkeleton />}
      {!fetchRequest.loading && caCertificate && (
        <div className={css.BuiltInCaInfo}>
          <Text>{t('settings.tiles.pki.page.ca_subject')}: </Text>
          <Text variant="subheader-1">{caCertificate.subject}</Text>
        </div>
      )}
      <div className={css.ActionButtons}>
        <Button view="action" onClick={handleClickCertificateDownload}>
          {t('settings.tiles.pki.page.download_certificate')}
        </Button>
        <Button view="action" onClick={handleClickProfileDownload}>
          {t('settings.tiles.pki.page.download_profile_with_certificate')}
        </Button>
        <Button view="action" onClick={handleCsrCreateClick}>
          {t('settings.tiles.pki.page.create_certificate_from_csr')}
        </Button>
        <Button view="action" onClick={handleCreateBackupClick}>
          {t('settings.tiles.pki.page.create_ca_backup')}
        </Button>
      </div>
      <Dialog onClose={handleCsrModalClose} open={isCsrModalOpen}>
        <Dialog.Header caption={t('settings.tiles.pki.page.create_certificate_from_csr')} />
        <Dialog.Body>
          <Text variant="subheader-1">{t('settings.tiles.pki.page.csr_label')}</Text>
          <TextArea
            controlProps={{ style: { resize: 'both' } }}
            error={csrRequestForm.formState.errors.csr?.message}
            {...csrRequestForm.register('csr')}
          />
        </Dialog.Body>
        <Dialog.Footer
          loading={csrCreateRequest.loading}
          textButtonApply={t('settings.tiles.pki.page.create_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonCancel={handleCsrModalClose}
          onClickButtonApply={handleCsrModalSubmit}
        />
      </Dialog>
      <Dialog onClose={handleBackupModalClose} open={isBackupModalOpen}>
        <Dialog.Header caption={t('settings.tiles.pki.page.create_ca_backup')} />
        <Dialog.Body>
          <div className={css.BackupModalForm}>
            <div className={css.BackupModalFormItem}>
              <Text variant="subheader-1">{t('settings.tiles.pki.page.password_label')}</Text>
              <TextInput
                type="password"
                error={caBackupForm.formState.errors.backup_password?.message}
                {...caBackupForm.register('backup_password')}
              />
            </div>
            <div className={css.BackupModalFormItem}>
              <Text variant="subheader-1">
                {t('settings.tiles.pki.page.password_verify_label')}
              </Text>
              <TextInput
                type="password"
                error={caBackupForm.formState.errors.backup_password_verify?.message}
                {...caBackupForm.register('backup_password_verify')}
              />
            </div>
          </div>
        </Dialog.Body>
        <Dialog.Footer
          loading={caBackupRequest.loading}
          textButtonApply={t('settings.tiles.pki.page.create_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleBackupModalSubmit}
          onClickButtonCancel={handleBackupModalClose}
        />
      </Dialog>
    </div>
  );
};
