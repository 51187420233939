import {
  PolicyFrequency,
  PolicyPayloadPackageDistributionType,
  PolicyPayloadPackageModifyType,
  PolicyTrigger
} from '../../../../../types/api';
import { ScopeValues } from '../../../../components/scope-form/scope-form.component';
import { array, boolean, mixed, number, object, string } from 'yup';
import i18next from '../../../../../i18n/i18n';
import { notEmpty } from '../../../../../utils/yup.validations';

export interface PolicyFormValues {
  general: PolicyOptionsGeneralFormValues;
  maintenance?: PolicyOptionsMaintenanceFormValues;
  scripts?: PolicyOptionsScriptsFormValues;
  packages?: PolicyOptionsPackagesFormValues;
  scope: ScopeValues;
}

export interface PolicyOptionsGeneralFormValues {
  display_name: string;
  enabled: boolean;
  triggers: Record<PolicyTrigger, boolean>;
  frequency: PolicyFrequency;
  interval_amount?: number;
}

export interface PolicyOptionsMaintenanceFormValues {
  update_inventory: boolean;
}

export type PolicyOptionsScriptsFormValues = ScriptValue[];

export interface ScriptValue {
  id: string;
  display_name: string;
  arguments: string[];
}

export type PolicyOptionsPackagesFormValues = {
  distribution_type: PolicyPayloadPackageDistributionType;
  items: PackageValue[];
};

export interface PackageValue {
  id: string;
  display_name: string;
  modify_type: PolicyPayloadPackageModifyType;
}

export const policyFormSchema = object({
  general: object({
    display_name: string()
      .required(i18next.t('policies.options_general.form.display_name_required'))
      .test(notEmpty(i18next.t('policies.options_general.form.display_name_required'))),
    enabled: boolean().default(true),
    triggers: object(),
    frequency: mixed<PolicyFrequency>()
      .required(i18next.t('policies.options_general.form.frequency_required'))
      .oneOf(Object.values(PolicyFrequency))
      .default(PolicyFrequency.Ongoing),
    interval_amount: number()
      .transform((value) => (value ? Number(value) : 0))
      .default(30)
      .when(`triggers.${PolicyTrigger.Interval}`, {
        is: (value?: boolean) => !!value,
        then: (schema) =>
          schema
            .required()
            .min(5, i18next.t('policies.options_general.form.interval_should_be_more'))
            .max(43_200, i18next.t('policies.options_general.form.interval_should_be_less'))
      })
  }).required(),
  maintenance: object({
    update_inventory: boolean().default(false)
  }),
  scripts: array(),
  packages: object({
    distribution_type: mixed<PolicyPayloadPackageDistributionType>()
      .oneOf(Object.values(PolicyPayloadPackageDistributionType))
      .default(PolicyPayloadPackageDistributionType.Default),
    items: array().default([])
  }),
  scope: object({
    scoped: boolean().default(true),
    scopeEntriesInclude: array().default([]),
    scopeEntriesExclude: array().default([])
  }).required()
}).required();
