import { FC } from 'react';
import css from './raw-option.module.scss';
import { useTranslation } from 'react-i18next';
import { Text, TextArea } from '@gravity-ui/uikit';

interface IProps {
  payload: string;
}

export const RawOption: FC<IProps> = (props) => {
  const { payload } = props;
  const { t } = useTranslation();

  return (
    <div className={css.Root}>
      <Text variant="subheader-2">{t('profiles.profile_form.options_tab.raw_xml')}</Text>
      <TextArea value={payload} disabled />
    </div>
  );
};
