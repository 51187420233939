import { useEffect, useState } from 'react';
import { createContainer } from 'react-tracked';

import { useAuthContext } from './auth.context';
import { Permission } from '../../types/api';

type PermissionContextType = {
  loading: boolean;
  isAllowedTo: (...permissions: Permission[]) => boolean;
  isSuperAdmin: boolean;
};

const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => false,
  loading: true,
  isSuperAdmin: false
};

const useInitialState = () => useState(defaultBehaviour);
const container = createContainer(useInitialState);
const PermissionProvider = container.Provider;

const usePermission = (): PermissionContextType => {
  const [state, setState] = container.useTracked();
  const { authState } = useAuthContext();

  useEffect(() => {
    if (!authState.role?.permissions) return;
    const permissions = authState.role?.permissions;
    const isAllowedTo = (...requiredPermissions: Permission[]) => {
      if (authState.role?.superadmin) {
        return true;
      }
      if (permissions) {
        return requiredPermissions.some((permission) => permissions.includes(permission));
      }
      return false;
    };
    const isSuperAdmin = authState.role.superadmin || false;
    setState({ isAllowedTo, loading: false, isSuperAdmin: isSuperAdmin });
  }, [authState]);

  return {
    isAllowedTo: state.isAllowedTo,
    loading: state.loading,
    isSuperAdmin: state.isSuperAdmin
  };
};

export { PermissionProvider, usePermission };
