import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import { Permission } from '../../../../../types/api';
import css from './action-menu.module.scss';
import { t } from 'i18next';
import { usePermission } from '../../../../contexts/permission.context';

interface IProps {
  mode: 'view' | 'create' | 'edit' | 'add';
  disabled: boolean;
  onDeviceAppCreate: () => void;
  onDeviceAppCancel: () => void;
  onDeviceAppDelete: () => void;
  onDeviceAppEditStart: () => void;
  onDeviceAppEdit: () => void;
}

export const ActionMenu: FC<IProps> = (props) => {
  const {
    mode,
    disabled,
    onDeviceAppCreate,
    onDeviceAppCancel,
    onDeviceAppDelete,
    onDeviceAppEditStart,
    onDeviceAppEdit
  } = props;
  const { isAllowedTo } = usePermission();

  return (
    <div className={css.ButtonGroup}>
      {mode === 'create' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onDeviceAppCancel}>
            {t('device_apps.app_form.buttons.cancel')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onDeviceAppCreate}>
            {t('device_apps.app_form.buttons.create')}
          </Button>
        </>
      )}
      {mode === 'edit' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onDeviceAppCancel}>
            {t('device_apps.app_form.buttons.cancel')}
          </Button>
          <Button
            view="action"
            onClick={onDeviceAppEdit}
            disabled={disabled || !isAllowedTo(Permission.EditDeviceApplications)}
          >
            {t('device_apps.app_form.buttons.save')}
          </Button>
        </>
      )}
      {mode === 'view' && (
        <>
          <Button
            view="flat"
            onClick={onDeviceAppDelete}
            disabled={disabled || !isAllowedTo(Permission.EditDeviceApplications)}
          >
            {t('device_apps.app_form.buttons.delete')}
          </Button>
          <Button
            view="action"
            onClick={onDeviceAppEditStart}
            disabled={disabled || !isAllowedTo(Permission.EditDeviceApplications)}
          >
            {t('device_apps.app_form.buttons.edit')}
          </Button>
        </>
      )}
    </div>
  );
};
