import { array, object, string } from 'yup';
import i18next from '../../../../../i18n/i18n';
import { notEmpty } from '../../../../../utils/yup.validations';

export type StaticGroupFormValues = {
  display_name: string;
  device_users: string[];
};

export const staticGroupFormSchema = object({
  display_name: string()
    .required(i18next.t('static_groups.page.group_information_tab.errors.no_display_name'))
    .test(notEmpty(i18next.t('static_groups.page.group_information_tab.errors.no_display_name'))),
  device_users: array().default([])
});
