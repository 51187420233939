import { createRequest } from './create-request';
import { CreateRoleDto, GetRolesResponseDto, RoleSimpleDto, UpdateRoleDto } from '../types/api';

export async function getRoles(): Promise<GetRolesResponseDto> {
  return createRequest<GetRolesResponseDto>({ url: 'roles' });
}

export async function getRole(id: string): Promise<RoleSimpleDto> {
  return createRequest<RoleSimpleDto>({ url: `roles/${id}` });
}

export async function createRole(data: CreateRoleDto): Promise<RoleSimpleDto> {
  return createRequest({ method: 'POST', url: `roles`, data });
}

export async function updateRole(id: string, data: UpdateRoleDto): Promise<RoleSimpleDto> {
  return createRequest({ method: 'PATCH', url: `roles/${id}`, data });
}

export async function deleteRole(id: string): Promise<RoleSimpleDto> {
  return createRequest({ method: 'DELETE', url: `roles/${id}` });
}
