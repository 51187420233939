import { FC, MouseEventHandler } from 'react';
import css from './error-state-dropzone.module.scss';
import { Button, Icon, Text } from '@gravity-ui/uikit';
import { FileText, TrashBin } from '@gravity-ui/icons';
import { useTranslation } from 'react-i18next';

interface IProps {
  fileName?: string;
  error?: string;
  onCancel: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
}

export const ErrorStateDropzone: FC<IProps> = (props) => {
  const { fileName, error, onCancel } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={css.FileInfoAndStatus}>
        <div className={css.FileInfoAndIcon}>
          <Icon data={FileText} size={20} />
          <div className={css.FileInfo}>
            {fileName && (
              <Text variant="subheader-1" color="secondary">
                {fileName}
              </Text>
            )}
            <Text color="danger">{error || 'Upload failed, please try again'}</Text>
            <Text variant="subheader-1" color="danger">
              {t('common.file_input.try_again')}
            </Text>
          </div>
        </div>
        {fileName && (
          <Button view="flat" onClick={onCancel}>
            <Icon data={TrashBin} size={20} />
          </Button>
        )}
      </div>
    </>
  );
};
