import { FC, useEffect, useState } from 'react';
import css from './smart-group-device-users.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { SmartGroupDevicesForm } from './components/smart-group-devices-form/smart-group-device-users-form.component';
import {
  CreateSmartGroupDeviceUsersRequestDto,
  Permission,
  SmartGroupDeviceUsersFullDto,
  UpdateSmartGroupDeviceUsersRequestDto
} from '../../../types/api';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import {
  smartGroupDeviceUsersFormSchema,
  SmartGroupDeviceUsersFormValues
} from './components/smart-group-devices-form/smart-group-device-users-form.schema';
import {
  createSmartGroup,
  deleteSmartGroup,
  getSmartGroup,
  updateSmartGroup
} from '../../../api/smart-group-device-users';
import { Dialog, Popover, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { Header } from '../../components/header/header.component';
import { useTranslation } from 'react-i18next';
import { ActionMenu } from './components/action-menu/action-menu.component';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useRequest from '../../../hooks/useRequest';
import { SmartGroupFormSkeleton } from './components/form-skeleton/form-skeleton.component';
import { usePermission } from '../../contexts/permission.context';

export const SmartGroupDeviceUsers: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toaster = useToaster();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const crudRequest = useRequest<SmartGroupDeviceUsersFullDto>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [smartGroup, setSmartGroup] = useState<SmartGroupDeviceUsersFullDto>();
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>(
    id === 'new' ? 'create' : 'view'
  );

  const smartGroupForm = useForm<SmartGroupDeviceUsersFormValues>({
    mode: 'onChange',
    resolver: yupResolver(smartGroupDeviceUsersFormSchema),
    defaultValues: smartGroupDeviceUsersFormSchema.getDefault()
  });
  const {
    register,
    formState: { errors }
  } = smartGroupForm;

  const smartGroupToForm = (
    input: SmartGroupDeviceUsersFullDto
  ): SmartGroupDeviceUsersFormValues => {
    return {
      group: {
        display_name: input.title
      },
      criteria: input.conditions,
      reports: []
    };
  };

  const handleSmartGroupCreate = async () => {
    const isValid = await smartGroupForm.trigger();
    if (!isValid || crudRequest.loading) return;

    const values = smartGroupForm.getValues();
    const request: CreateSmartGroupDeviceUsersRequestDto = {
      title: values.group.display_name,
      conditions: values.criteria
    };
    try {
      const response = await crudRequest.send(createSmartGroup(request), 1000);
      setSmartGroup(response);
      smartGroupForm.reset(smartGroupToForm(response));

      setFormMode('view');
      navigate(`./../${response.id}`);
      toaster.add({
        name: 'create-success',
        content: t('smart_groups.page.successfully_created', { group: response.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'create-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleSmartGroupDelete = async () => {
    if (crudRequest.loading || !smartGroup) return;

    try {
      await crudRequest.send(deleteSmartGroup(smartGroup.id), 1000);

      navigate('./../');
      toaster.add({
        name: 'delete-success',
        content: t('smart_groups.page.successfully_deleted', { group: smartGroup.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'delete-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleSmartGroupEditStart = () => {
    setFormMode('edit');
  };

  const handleSmartGroupCancel = () => {
    if (formMode === 'create') {
      navigate('./../');
      return;
    }
    setFormMode('view');
    smartGroupForm.reset(smartGroup && smartGroupToForm(smartGroup));
  };

  const handleSmartGroupEdit = async () => {
    const isValid = await smartGroupForm.trigger();
    if (!id || !isValid || crudRequest.loading) return;

    const values = smartGroupForm.getValues();
    const request: UpdateSmartGroupDeviceUsersRequestDto = {
      title: values.group.display_name,
      conditions: values.criteria
    };
    try {
      const response = await crudRequest.send(updateSmartGroup(id, request), 1000);
      setFormMode('view');
      setSmartGroup(response);
      smartGroupForm.reset(smartGroupToForm(response));

      navigate(`./../${response.id}`);
      toaster.add({
        name: 'update-success',
        content: t('smart_groups.page.successfully_updated', { group: response.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'update-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const init = async () => {
    if (!id) return;
    if (id !== 'new') {
      const response = await crudRequest.send(getSmartGroup(id), 1000);
      setSmartGroup(response);
      smartGroupForm.reset(smartGroupToForm(response));
    }
  };

  useEffect(() => {
    void init();
  }, [id]);

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={smartGroup?.title}
        rightContent={
          <ActionMenu
            mode={formMode}
            disabled={crudRequest.loading || !isAllowedTo(Permission.EditGroups)}
            onSmartGroupCancel={handleSmartGroupCancel}
            onSmartGroupDelete={handleDeleteModalOpen}
            onSmartGroupCreate={handleSmartGroupCreate}
            onSmartGroupEdit={handleSmartGroupEdit}
            onSmartGroupEditStart={handleSmartGroupEditStart}
          />
        }
      />
      <div className={css.Content}>
        {crudRequest.loading ? (
          <SmartGroupFormSkeleton />
        ) : (
          <>
            <div className={css.Title}>
              {formMode === 'view' && <Text variant="display-2">{smartGroup?.title}</Text>}
              {formMode !== 'view' && (
                <Popover content={t('common.click_to_edit')}>
                  <TextInput
                    placeholder={t('smart_groups.page.group_information_tab.display_name')}
                    size="xl"
                    view="clear"
                    error={errors.group?.display_name?.message}
                    errorPlacement="inside"
                    controlProps={{ className: css.TitleInput }}
                    {...register('group.display_name')}
                  />
                </Popover>
              )}
            </div>
            <FormProvider {...smartGroupForm}>
              <SmartGroupDevicesForm mode={formMode} />
            </FormProvider>
          </>
        )}
      </div>
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalOpen}>
        <Dialog.Header caption={t('smart_groups.page.modal_delete.title')} />
        <Dialog.Body>
          <Text>
            {t('static_groups.page.modal_delete.message', {
              title: smartGroup?.title
            })}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('smart_groups.page.modal_delete.delete_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleSmartGroupDelete}
          onClickButtonCancel={handleDeleteModalClose}
        />
      </Dialog>
    </div>
  );
};
