import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import css from './role.module.scss';
import { CreateRoleDto, Permission, RoleSimpleDto, UpdateRoleDto } from '../../../../types/api';
import useRequest from '../../../../hooks/useRequest';
import { createRole, deleteRole, getRole, updateRole } from '../../../../api/roles';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import {
  IFormPermission,
  resourceNames,
  roleFormSchema,
  RoleFormValues
} from './components/role-form/role-form.schema';
import { RoleFormComponent } from './components/role-form/role-form.component';
import { SettingsPaths } from '../settings.const';
import { Paths } from '../../../constants';
import { Dialog, Popover, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { Header } from '../../../components/header/header.component';
import { ActionMenu } from './components/action-menu/action-menu.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormSkeleton } from './components/form-skeleton/form-skeleton.component';
import { usePermission } from '../../../contexts/permission.context';

export const RoleComponent: FC = () => {
  const { id } = useParams();
  const isNew = id === 'new';

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const navigate = useNavigate();
  const toaster = useToaster();
  const crudRequest = useRequest<RoleSimpleDto>();
  const roleRef = useRef<RoleSimpleDto>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>(isNew ? 'create' : 'view');

  const roleForm = useForm<RoleFormValues>({
    mode: 'onChange',
    resolver: yupResolver(roleFormSchema),
    defaultValues: roleFormSchema.getDefault()
  });
  const {
    register,
    formState: { errors }
  } = roleForm;

  const roleToForm = (input: RoleSimpleDto): RoleFormValues => {
    const formPermissions: IFormPermission[] = [];

    for (const resource of resourceNames) {
      formPermissions.push({
        resource,
        read: input.permissions
          ? input.permissions.includes(Permission[`Read${resource}` as keyof typeof Permission])
          : false,
        edit: input.permissions
          ? input.permissions.includes(Permission[`Edit${resource}` as keyof typeof Permission])
          : false
      });
    }

    return {
      title: input.title,
      permissions: formPermissions,
      superadmin: input.superadmin || false
    };
  };

  const formPermissionsToDto = (formPermissions: IFormPermission[]): Permission[] => {
    const permissions: Permission[] = [];

    for (const formPermission of formPermissions) {
      if (formPermission.read) {
        permissions.push(Permission[`Read${formPermission.resource}` as keyof typeof Permission]);
      }
      if (formPermission.edit) {
        permissions.push(Permission[`Edit${formPermission.resource}` as keyof typeof Permission]);
      }
    }
    return permissions;
  };

  const handleRoleCreate = async () => {
    if (crudRequest.loading) return;

    const isValid = await roleForm.trigger();
    if (!isValid) return;

    const request: CreateRoleDto = {
      title: roleForm.getValues().title,
      ...(roleForm.getValues().permissions && {
        permissions: formPermissionsToDto(roleForm.getValues().permissions)
      }),
      superadmin: roleForm.getValues().superadmin
    };

    try {
      const response = await crudRequest.send(createRole(request), 1000);
      roleRef.current = response;

      setFormMode('view');
      navigate(`/${Paths.SETTINGS}${SettingsPaths.ROLE.replace(':id', response.id)}`);
      toaster.add({
        name: 'success-create-role',
        content: t('settings.tiles.roles.page.role_page.successfully_created', {
          rolename: response.title
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'error-create-role',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleRoleEditStart = () => setFormMode('edit');

  const handleRoleCancel = () => {
    if (formMode === 'create') {
      navigate(`./../`);
      return;
    }
    setFormMode('view');
    if (roleRef.current) roleForm.reset(roleToForm(roleRef.current));
  };

  const handleRoleSave = async () => {
    if (crudRequest.loading || !roleRef.current) return;

    const isValid = await roleForm.trigger();
    if (!isValid) return;

    const request: UpdateRoleDto = {
      ...(roleForm.getValues().title && { title: roleForm.getValues().title }),
      ...(roleForm.getValues().permissions && {
        permissions: formPermissionsToDto(roleForm.getValues().permissions)
      }),
      superadmin: roleForm.getValues().superadmin
    };

    try {
      const response = await crudRequest.send(updateRole(roleRef.current?.id, request), 1000);
      roleRef.current = response;
      roleForm.reset(roleToForm(response));

      setFormMode('view');
      navigate(`/${Paths.SETTINGS}${SettingsPaths.ROLE.replace(':id', response.id)}`);
      toaster.add({
        name: 'success-update-role',
        content: t('settings.tiles.roles.page.role_page.successfully_updated', {
          rolename: response.title
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'error-update-role',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleRoleDelete = async () => {
    if (crudRequest.loading || !roleRef.current) return;
    try {
      const response = await crudRequest.send(deleteRole(roleRef.current?.id), 1000);

      navigate(`/${Paths.SETTINGS}${SettingsPaths.ROLES_LIST}`);
      toaster.add({
        name: 'success-delete-role',
        content: t('settings.tiles.roles.page.role_page.successfully_deleted', {
          rolename: response.title
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (e) {
      const localizedErrorString = getLocalizedErrorString(e as Error);
      toaster.add({
        name: 'error-delete-role',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalSubmit = async () => {
    await handleRoleDelete();
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    const init = async () => {
      if (!id || formMode === 'create') return;
      try {
        const response = await crudRequest.send(getRole(id), 1000);
        roleRef.current = response;
        roleForm.reset(roleToForm(response));
      } catch (error) {
        const localizedErrorString = getLocalizedErrorString(error as Error);
        toaster.add({
          name: 'error-fetch-role',
          content: localizedErrorString,
          theme: 'danger',
          autoHiding: 5000
        });
      }
    };
    void init();
  }, [id]);

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={roleRef.current?.title}
        rightContent={
          <ActionMenu
            mode={formMode}
            disabled={crudRequest.loading || !isAllowedTo(Permission.EditAdministration)}
            onRoleCancel={handleRoleCancel}
            onRoleCreate={handleRoleCreate}
            onRoleDelete={handleDeleteModalOpen}
            onRoleEdit={handleRoleSave}
            onRoleEditStart={handleRoleEditStart}
          />
        }
      />
      <div className={css.Content}>
        {crudRequest.loading ? (
          <FormSkeleton />
        ) : (
          <>
            <div className={css.Title}>
              {formMode === 'view' && <Text variant="display-2">{roleRef.current?.title}</Text>}
              {formMode !== 'view' && (
                <Popover content={t('common.click_to_edit')}>
                  <TextInput
                    placeholder={t('settings.tiles.roles.page.role_page.title')}
                    size="xl"
                    view="clear"
                    error={errors.title?.message}
                    errorPlacement="inside"
                    controlProps={{ className: css.TitleInput }}
                    {...register('title')}
                  />
                </Popover>
              )}
            </div>
            <RoleFormComponent mode={formMode} form={roleForm} />
          </>
        )}
      </div>
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalOpen}>
        <Dialog.Header caption={t('settings.tiles.roles.page.role_page.modal_delete.title')} />
        <Dialog.Body>
          <Text>
            {t('settings.tiles.roles.page.role_page.modal_delete.message', {
              title: roleRef.current?.title
            })}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('settings.tiles.roles.page.role_page.modal_delete.delete_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleDeleteModalSubmit}
          onClickButtonCancel={handleDeleteModalClose}
          loading={crudRequest.loading}
        />
      </Dialog>
    </div>
  );
};
