export function downloadFile(filename: string, file: BlobPart[], options?: BlobPropertyBag) {
  const element = document.createElement('a');
  const blob = new Blob(file, options);
  element.href = URL.createObjectURL(blob);
  element.download = filename;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

// File size in bytes
export function formatFileSize(fileSize: number) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let unitIndex = 0;

  while (fileSize >= 1024 && unitIndex < units.length - 1) {
    fileSize /= 1024;
    unitIndex++;
  }

  return `${parseFloat(fileSize.toFixed(2)).toString()} ${units[unitIndex]}`;
}

export function verifyAccept(fileName: string, accept: string): boolean {
  const regex = new RegExp(accept.replace(/\*/g, '.*').replace(/,/g, '|'));
  const fileExt = fileName.slice((Math.max(0, fileName.lastIndexOf('.') - 1) || Infinity) + 1);
  return regex.test(fileExt);
}
