import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import css from './action-menu.module.scss';
import { t } from 'i18next';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  disabled: boolean;
  onExtensionAttributeCreate: () => void;
  onExtensionAttributeCancel: () => void;
  onExtensionAttributeDelete: () => void;
  onExtensionAttributeEditStart: () => void;
  onExtensionAttributeEdit: () => void;
}

export const ActionMenu: FC<IProps> = (props) => {
  const {
    mode,
    disabled,
    onExtensionAttributeCreate,
    onExtensionAttributeCancel,
    onExtensionAttributeDelete,
    onExtensionAttributeEditStart,
    onExtensionAttributeEdit
  } = props;

  return (
    <div className={css.ButtonGroup}>
      {mode === 'create' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onExtensionAttributeCancel}>
            {t('settings.tiles.extension_attributes.page.extension_attribute_page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onExtensionAttributeCreate}>
            {t('settings.tiles.extension_attributes.page.extension_attribute_page.create_btn')}
          </Button>
        </>
      )}
      {mode === 'view' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onExtensionAttributeDelete}>
            {t('settings.tiles.extension_attributes.page.extension_attribute_page.delete_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onExtensionAttributeEditStart}>
            {t('settings.tiles.extension_attributes.page.extension_attribute_page.edit_btn')}
          </Button>
        </>
      )}
      {mode === 'edit' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onExtensionAttributeCancel}>
            {t('settings.tiles.extension_attributes.page.extension_attribute_page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onExtensionAttributeEdit}>
            {t('settings.tiles.extension_attributes.page.extension_attribute_page.save_btn')}
          </Button>
        </>
      )}
    </div>
  );
};
