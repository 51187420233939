import { object, string } from 'yup';
import { CountryCode } from '../../../../../../../types/api';
import i18next from '../../../../../../../i18n/i18n';

export interface DeviceApplicationSearchFormValues {
  term: string;
  country_code: string;
}

export const deviceApplicationSearchSchema = object({
  term: string().required(i18next.t('device_apps.app_form.errors.field_is_required')),
  country_code: string().default(CountryCode.RussianFederation)
});
