import {
  AddDeviceApplicationDto,
  GetDeviceApplicationListRequestDto,
  GetDeviceApplicationListResponseDto,
  DeviceApplicationDto,
  SearchDeviceApplicationRequestDto,
  SearchDeviceApplicationsResponseDto,
  UpdateDeviceApplicationDto
} from '../types/api';
import { createRequest } from './create-request';

export async function getDeviceApplicationList(
  params?: GetDeviceApplicationListRequestDto
): Promise<GetDeviceApplicationListResponseDto> {
  return createRequest({ url: 'device-applications', params });
}

export async function getAppStoreApplications(
  params?: SearchDeviceApplicationRequestDto
): Promise<SearchDeviceApplicationsResponseDto> {
  return createRequest({ url: 'device-applications/search', params });
}

export async function createDeviceApplication(
  data: AddDeviceApplicationDto
): Promise<DeviceApplicationDto> {
  return createRequest({
    url: 'device-applications',
    method: 'POST',
    data
  });
}

export async function getDeviceApplication(id: string): Promise<DeviceApplicationDto> {
  return createRequest({ url: `device-applications/${id}` });
}

export async function deleteDeviceApplication(id: string): Promise<DeviceApplicationDto> {
  return createRequest({ method: 'DELETE', url: `device-applications/${id}` });
}

export async function updateDeviceApplication(
  id: string,
  data: UpdateDeviceApplicationDto
): Promise<DeviceApplicationDto> {
  return createRequest({ method: 'PATCH', url: `device-applications/${id}`, data });
}
