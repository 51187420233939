import { FC, useEffect, useState } from 'react';
import css from './smart-group-devices.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { SmartGroupDevicesForm } from './components/smart-group-devices-form/smart-group-devices-form.component';
import {
  createSmartGroup,
  deleteSmartGroup,
  getSmartGroup,
  updateSmartGroup
} from '../../../api/smart-group-devices';
import {
  smartGroupDevicesFormSchema,
  SmartGroupFormValues
} from './components/smart-group-devices-form/smart-group-devices-form.schema';
import {
  CreateSmartGroupDevicesRequestDto,
  DeviceOsType,
  Permission,
  SmartGroupDevicesFullDto,
  UpdateSmartGroupDevicesRequestDto
} from '../../../types/api';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';
import { Header } from '../../components/header/header.component';
import { Dialog, Popover, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { ActionMenu } from './components/action-menu/action-menu.component';
import useRequest from '../../../hooks/useRequest';
import { SmartGroupFormSkeleton } from './components/form-skeleton/form-skeleton.component';
import { getSmartGroupsDevicesCSV } from '../../../api/groups';
import { usePermission } from '../../contexts/permission.context';

export const SmartGroupDevices: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toaster = useToaster();
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();
  const { isAllowedTo } = usePermission();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [smartGroup, setSmartGroup] = useState<SmartGroupDevicesFullDto>();
  const [formMode, setFormMode] = useState<'view' | 'create' | 'edit'>(
    id === 'new' ? 'create' : 'view'
  );

  const crudRequest = useRequest();

  const smartGroupForm = useForm<SmartGroupFormValues>({
    mode: 'onChange',
    resolver: yupResolver(smartGroupDevicesFormSchema),
    defaultValues: smartGroupDevicesFormSchema.getDefault()
  });
  const {
    register,
    formState: { errors }
  } = smartGroupForm;

  const smartGroupToForm = (input: SmartGroupDevicesFullDto): SmartGroupFormValues => {
    return {
      group: {
        id: input.id,
        display_name: input.title
      },
      criteria: input.conditions,
      reports: input.devices
    };
  };

  const handleSmartGroupCreate = async () => {
    if (crudRequest.loading) return;

    await smartGroupForm.trigger();
    const errorKeys = Object.keys(smartGroupForm.formState.errors);
    if (errorKeys.length > 0) return;

    const values = smartGroupForm.getValues();
    try {
      const request: CreateSmartGroupDevicesRequestDto = {
        title: values.group.display_name,
        os_type: osType,
        conditions: values.criteria?.map((criteria) => ({
          criteria: criteria.criteria,
          operator: criteria.operator,
          value: criteria.value,
          conjunctive: criteria.conjunctive,
          start_bracket: JSON.parse(criteria.start_bracket.toString()),
          end_bracket: JSON.parse(criteria.end_bracket.toString())
        }))
      };
      const response = (await crudRequest.send(
        createSmartGroup(request),
        1000
      )) as SmartGroupDevicesFullDto;
      setSmartGroup(response);
      smartGroupForm.reset(smartGroupToForm(response));

      setFormMode('view');
      navigate(`./../${response.id}`);
      toaster.add({
        name: 'create-smart-group-success',
        content: t('smart_groups.page.successfully_created', { group: response.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'create-smart-group-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleSmartGroupDelete = async () => {
    if (crudRequest.loading || !smartGroup) return;

    try {
      await crudRequest.send(deleteSmartGroup(smartGroup.id), 1000);
      navigate('./../');
      toaster.add({
        name: 'delete-smart-group-success',
        content: t('smart_groups.page.successfully_deleted', { group: smartGroup.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'delete-smart-group-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };
  const handleSmartGroupEditStart = () => setFormMode('edit');
  const handleSmartGroupEdit = async () => {
    if (crudRequest.loading || !smartGroup) return;

    const isValid = await smartGroupForm.trigger();
    if (!isValid) return;

    const values = smartGroupForm.getValues();
    try {
      const request: UpdateSmartGroupDevicesRequestDto = {
        title: values.group.display_name,
        conditions: values.criteria?.map((criteria) => ({
          criteria: criteria.criteria,
          operator: criteria.operator,
          value: criteria.value,
          conjunctive: criteria.conjunctive,
          start_bracket: JSON.parse(criteria.start_bracket.toString()),
          end_bracket: JSON.parse(criteria.end_bracket.toString())
        }))
      };
      const response = (await crudRequest.send(
        updateSmartGroup(smartGroup.id, request),
        1000
      )) as SmartGroupDevicesFullDto;
      setSmartGroup(response);
      smartGroupForm.reset(smartGroupToForm(response));

      setFormMode('view');
      navigate(`./../${response.id}`);
      toaster.add({
        name: 'update-smart-group-success',
        content: t('smart_groups.page.successfully_updated', { group: response.title }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'update-smart-group-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };
  const handleSmartGroupCancel = () => {
    if (formMode === 'create') {
      navigate('./../');
      return;
    }
    setFormMode('view');
    smartGroupForm.reset(smartGroup && smartGroupToForm(smartGroup));
  };
  const handleClickExportCSV = async () => {
    const data = await getSmartGroupsDevicesCSV({
      group_id: smartGroupForm.getValues().group.id
    });
    const blob = new Blob([data]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'export.csv');
    link.click();
  };

  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleDeleteModalClose = () => setIsDeleteModalOpen(false);
  const handleDeleteModalSubmit = async () => {
    await handleSmartGroupDelete();
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    const init = async () => {
      if (!id) return;
      if (id !== 'new') {
        const response = (await crudRequest.send(
          getSmartGroup(id),
          1000
        )) as SmartGroupDevicesFullDto;
        setSmartGroup(response);
        smartGroupForm.reset(smartGroupToForm(response));
      }
    };

    void init();
  }, []);

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={smartGroup?.title}
        rightContent={
          <ActionMenu
            mode={formMode}
            disabled={crudRequest.loading || !isAllowedTo(Permission.EditGroups)}
            onSmartGroupCreate={handleSmartGroupCreate}
            onSmartGroupCancel={handleSmartGroupCancel}
            onSmartGroupEditStart={handleSmartGroupEditStart}
            onSmartGroupEdit={handleSmartGroupEdit}
            onSmartGroupDelete={handleDeleteModalOpen}
            onSmartGroupExportCSV={handleClickExportCSV}
          />
        }
      />
      <div className={css.Content}>
        {crudRequest.loading ? (
          <SmartGroupFormSkeleton />
        ) : (
          <>
            <div className={css.Title}>
              {formMode === 'view' && <Text variant="display-2">{smartGroup?.title}</Text>}
              {formMode !== 'view' && (
                <Popover content={t('common.click_to_edit')}>
                  <TextInput
                    placeholder={t('smart_groups.page.group_information_tab.display_name')}
                    size="xl"
                    view="clear"
                    error={errors.group?.display_name?.message}
                    errorPlacement="inside"
                    controlProps={{ className: css.TitleInput }}
                    {...register('group.display_name')}
                  />
                </Popover>
              )}
            </div>
            <FormProvider {...smartGroupForm}>
              <SmartGroupDevicesForm mode={formMode} />
            </FormProvider>
          </>
        )}
      </div>
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalOpen}>
        <Dialog.Header caption={t('smart_groups.page.modal_delete.title')} />
        <Dialog.Body>
          <Text>{t('static_groups.page.modal_delete.message', { title: smartGroup?.title })}</Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('smart_groups.page.modal_delete.delete_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleDeleteModalSubmit}
          onClickButtonCancel={handleDeleteModalClose}
        />
      </Dialog>
    </div>
  );
};
