import { FC, FormEvent } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import css from './extension-attribute-form.module.scss';
import {
  ExtensionAttributeDataType,
  ExtensionAttributeInputType,
  ExtensionAttributeInventorySection,
  Permission
} from '../../../../../../types/api';
import { usePermission } from '../../../../../contexts/permission.context';
import {
  dataTypeOptions,
  ExtensionAttributeFormValues,
  inputTypeOptions,
  inventorySectionOptions,
  ISelectOption
} from './extension-attribute-form.schema';
import { Column, Table } from '../../../../../components/table/table.component';
import { Button, Select, Text, TextArea, TextInput } from '@gravity-ui/uikit';
import { DatePicker } from '@gravity-ui/date-components';
import { dateTimeParse } from '@gravity-ui/date-utils';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  form: UseFormReturn<ExtensionAttributeFormValues>;
}

export const ExtensionAttributeFormComponent: FC<IProps> = (props) => {
  const { mode, form } = props;
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors }
  } = form;

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'options'
  });

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleDeleteOption = (index: number) => () => {
    remove(index);
  };

  const handleAddOption = () => {
    append({ value: '' });
  };

  const columns: Column<ISelectOption>[] = [
    {
      id: 'select-options',
      name: t('settings.tiles.extension_attributes.page.extension_attribute_page.select.options'),
      selector: (_, index) => {
        const watchedType = watch().data_type;
        const watchedValue = watch().options?.[index].value;

        switch (watchedType) {
          case ExtensionAttributeDataType.Date:
            return (
              <DatePicker
                value={dateTimeParse(watchedValue, { format: 'DD.MM.YYYY HH.mm' })}
                onUpdate={(value) =>
                  setValue(`options.${index}.value`, value?.format('DD.MM.YYYY HH.mm') || '')
                }
                format="DD.MM.YYYY HH.mm"
                style={{ width: '100%' }}
                autoFocus
                errorMessage={errors.options?.[index]?.value?.message}
                errorPlacement="inside"
                disabled={mode === 'view'}
              />
            );
          default:
            return (
              <TextInput
                type={watchedType === ExtensionAttributeDataType.Number ? 'number' : 'text'}
                autoFocus
                error={errors.options?.[index]?.value?.message}
                errorPlacement="inside"
                disabled={mode === 'view'}
                {...register(`options.${index}.value`)}
              />
            );
        }
      }
    }
  ];
  if (mode !== 'view') {
    columns.push({
      id: 'delete-button',
      name: '',
      selector: (_, index) => (
        <Button view="outlined-danger" onClick={handleDeleteOption(index)}>
          {t('settings.tiles.extension_attributes.page.extension_attribute_page.delete_btn')}
        </Button>
      ),
      align: 'end',
      width: 0
    });
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditDevices)}>
        <div>
          <Text>
            {t('settings.tiles.extension_attributes.page.extension_attribute_page.description')}
          </Text>
          <TextArea
            error={errors.description?.message}
            {...register('description')}
            disabled={mode === 'view'}
            controlProps={{ style: { resize: 'both' } }}
            rows={4}
          />
        </div>
        <div>
          <Text>
            {t('settings.tiles.extension_attributes.page.extension_attribute_page.data_type')}
          </Text>
          <Select
            options={dataTypeOptions}
            disabled={mode === 'view'}
            value={[watch().data_type]}
            onUpdate={(value) => setValue('data_type', value[0] as ExtensionAttributeDataType)}
            {...register('data_type')}
            width="max"
          />
        </div>
        <div>
          <Text>
            {t(
              'settings.tiles.extension_attributes.page.extension_attribute_page.inventory_display'
            )}
          </Text>
          <Select
            options={inventorySectionOptions}
            disabled={mode === 'view'}
            value={[watch().inventory_display]}
            onUpdate={(value) =>
              setValue('inventory_display', value[0] as ExtensionAttributeInventorySection)
            }
            {...register('inventory_display')}
            width="max"
          />
        </div>
        <div>
          <Text>
            {t('settings.tiles.extension_attributes.page.extension_attribute_page.input_type')}
          </Text>
          <Select
            options={inputTypeOptions}
            disabled={mode === 'view'}
            value={[watch().input_type]}
            onUpdate={(value) => setValue('input_type', value[0] as ExtensionAttributeInputType)}
            {...register('input_type')}
            width="max"
          />
        </div>
        {watch().input_type === ExtensionAttributeInputType.Script && (
          <div>
            <Text>
              {t('settings.tiles.extension_attributes.page.extension_attribute_page.script')}
            </Text>
            <TextArea
              error={errors.script?.message}
              {...register('script')}
              disabled={mode === 'view'}
              controlProps={{ className: css.Code }}
              rows={4}
            />
          </div>
        )}
        {watch().input_type === ExtensionAttributeInputType.Select && (
          <div className={css.DataTableContainer}>
            <Table
              columns={columns}
              data={fields}
              leftContent={
                mode !== 'view' && (
                  <Button view="action" onClick={handleAddOption}>
                    {t('settings.tiles.extension_attributes.page.extension_attribute_page.add_btn')}
                  </Button>
                )
              }
            />
          </div>
        )}
      </fieldset>
    </form>
  );
};
