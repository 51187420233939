import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import css from './action-menu.module.scss';
import { t } from 'i18next';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  disabled: boolean;
  onRoleCreate: () => void;
  onRoleCancel: () => void;
  onRoleDelete: () => void;
  onRoleEditStart: () => void;
  onRoleEdit: () => void;
}

export const ActionMenu: FC<IProps> = (props) => {
  const { mode, disabled, onRoleCreate, onRoleCancel, onRoleDelete, onRoleEditStart, onRoleEdit } =
    props;

  return (
    <div className={css.ButtonGroup}>
      {mode === 'create' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onRoleCancel}>
            {t('settings.tiles.roles.page.role_page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onRoleCreate}>
            {t('settings.tiles.roles.page.role_page.create_btn')}
          </Button>
        </>
      )}
      {mode === 'view' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onRoleDelete}>
            {t('settings.tiles.roles.page.role_page.delete_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onRoleEditStart}>
            {t('settings.tiles.roles.page.role_page.edit_btn')}
          </Button>
        </>
      )}
      {mode === 'edit' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onRoleCancel}>
            {t('settings.tiles.roles.page.role_page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onRoleEdit}>
            {t('settings.tiles.roles.page.role_page.save_btn')}
          </Button>
        </>
      )}
    </div>
  );
};
