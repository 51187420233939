import { FC, useEffect, useState } from 'react';
import {
  DeviceFullDto,
  GetPoliciesResponseDto,
  PolicySimpleDto
} from '../../../../../../../../../types/api';
import { getPoliciesForDevice } from '../../../../../../../../../api/policies';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import css from './policies-tab.module.scss';
import useRequest from '../../../../../../../../../hooks/useRequest';
import { Skeleton } from '@gravity-ui/uikit';

enum PolicySortField {
  Title = 'title'
}

interface IProps {
  device?: DeviceFullDto;
}

export const PoliciesTab: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const [policies, setPolicies] = useState<PolicySimpleDto[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getPoliciesRequest = useRequest<GetPoliciesResponseDto>();

  useEffect(() => {
    if (!device) return;
    getPoliciesRequest
      .send(getPoliciesForDevice(device.id), 1000)
      .then((res) => setPolicies(res.policies));
  }, []);

  const columns: Column<PolicySimpleDto>[] = [
    {
      id: PolicySortField.Title,
      name: t('inventory.device_page.management_tab.policies_tab.table.heading.title'),
      selector: (policy) => policy.title
    }
  ];
  const onRowClick = (policy: PolicySimpleDto) => navigate(`/computers/policies/${policy.id}`);

  return (
    <>
      {getPoliciesRequest.loading ? (
        <Skeleton style={{ width: '100%', height: 500 }} />
      ) : (
        <Table columns={columns} data={policies} onRowClick={onRowClick} className={css.Table} />
      )}
    </>
  );
};
