import { FC } from 'react';
import css from './hardware-table-mobile.module.scss';
import { DeviceFullDto } from '../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import { Text } from '@gravity-ui/uikit';

interface IProps {
  device?: DeviceFullDto;
}

interface IKeyValue {
  key: string;
  value: string | null | undefined;
}

export const HardwareTableMobile: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const di = device?.device_information;

  const data: IKeyValue[] = [
    {
      key: t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.capacity'),
      value: di?.device_capacity
        ? `${di.device_capacity} ${t(
            'inventory_mobile.device_page.inventory_tab.hardware_tab.table.gb'
          )}`
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.available_space'
      ),
      value: di?.available_device_capacity
        ? `${di.available_device_capacity} ${t(
            'inventory_mobile.device_page.inventory_tab.hardware_tab.table.gb'
          )} ${
            di?.device_capacity
              ? `(${Math.round(100 * (di?.available_device_capacity / di?.device_capacity))}%)`
              : ''
          }`
        : undefined
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.used_space'),
      value:
        di?.used_space && di?.device_capacity
          ? `${di?.used_space} ${t(
              'inventory_mobile.device_page.inventory_tab.hardware_tab.table.gb'
            )} ${`(${Math.round(100 * (di?.used_space / di?.device_capacity))}%)`}`
          : undefined
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.battery_level'),
      value: di?.battery_level ? `${Math.round(Number(di?.battery_level) * 100)}%` : undefined
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.serial_number'),
      value: device?.serial_number
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.id'),
      value: device?.id
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.udid'),
      value: device?.udid
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.wifi_mac_address'
      ),
      value: di?.wifi_mac
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.bluetooth_mac_address'
      ),
      value: di?.bluetooth_mac
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.modem_firmware_version'
      ),
      value: di?.modem_firmware_version
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.model'),
      value: di?.model
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.model_identifier'
      ),
      value: di?.model_identifier || di?.software_update_device_id
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.model_number'),
      value: di?.model_number
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.hardware_tab.table.heading.bluetooth_low_energy_capability'
      ),
      value: undefined
    }
  ];

  const columns: Column<IKeyValue>[] = [
    {
      id: 'key',
      selector: (i) => <Text variant="subheader-1">{i.key}</Text>,
      width: 250
    },
    {
      id: 'value',
      selector: (i) => i.value
    }
  ];

  return (
    <>
      <Table data={data} columns={columns} className={css.Table} />
    </>
  );
};
