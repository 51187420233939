import { array, boolean, mixed, number, object, string } from 'yup';
import { ConfigProfileLogDto, ConfigProfilePayload } from '../../../../../types/api';
import { ScopeValues } from '../../../../components/scope-form/scope-form.component';
import i18next from '../../../../../i18n/i18n';
import { notEmpty } from '../../../../../utils/yup.validations';

export interface ConfigurationProfileUploadFormValues {
  display_name?: string;
  description?: string;
  organization_name?: string;
  payload?: ConfigProfilePayload;
  scope: ScopeValues;
  imported_payload?: string;
  logs?: ConfigProfileLogDto[];
  config_profile?: File;
}

export interface ConfigurationProfileFormValues {
  display_name: string;
  description?: string;
  organization_name?: string;
  payload: ConfigProfilePayload | null;
  scope: ScopeValues;
  imported_payload?: string;
  logs?: ConfigProfileLogDto[];
}

export const formSchema = object({
  display_name: string()
    .required(i18next.t('profiles.profile_form.errors.display_name_is_required'))
    .test(notEmpty(i18next.t('profiles.profile_form.errors.display_name_is_required')))
    .max(256, i18next.t('profiles.profile_form.errors.display_name_is_too_long')),
  description: string(),
  organization_name: string(),
  payload: object({
    restrictions: object({
      allowActivityContinuation: boolean(),
      allowAddingGameCenterFriends: boolean(),
      allowAirPlayIncomingRequests: boolean(),
      allowAirPrint: boolean(),
      allowAirPrintCredentialsStorage: boolean(),
      allowAirPrintiBeaconDiscovery: boolean(),
      allowAppCellularDataModification: boolean(),
      allowAppClips: boolean(),
      allowAppInstallation: boolean(),
      allowAppRemoval: boolean(),
      allowApplePersonalizedAdvertising: boolean(),
      allowAssistant: boolean(),
      allowAssistantWhileLocked: boolean(),
      allowAutoCorrection: boolean(),
      allowAutoUnlock: boolean(),
      allowAutomaticAppDownloads: boolean(),
      allowBluetoothModification: boolean(),
      allowBookstore: boolean(),
      allowBookstoreErotica: boolean(),
      allowCamera: boolean(),
      allowCellularPlanModification: boolean(),
      allowChat: boolean(),
      allowCloudBackup: boolean(),
      allowCloudDocumentSync: boolean(),
      allowCloudPhotoLibrary: boolean(),
      allowContinuousPathKeyboard: boolean(),
      allowDefinitionLookup: boolean(),
      allowDeviceNameModification: boolean(),
      allowDeviceSleep: boolean(),
      allowDictation: boolean(),
      allowESIMModification: boolean(),
      allowEnablingRestrictions: boolean(),
      allowEnterpriseAppTrust: boolean(),
      allowEnterpriseBookBackup: boolean(),
      allowEnterpriseBookMetadataSync: boolean(),
      allowEraseContentAndSettings: boolean(),
      allowExplicitContent: boolean(),
      allowFilesNetworkDriveAccess: boolean(),
      allowFilesUSBDriveAccess: boolean(),
      allowFindMyDevice: boolean(),
      allowFindMyFriends: boolean(),
      allowFingerprintForUnlock: boolean(),
      allowFingerprintModification: boolean(),
      allowGameCenter: boolean(),
      allowGlobalBackgroundFetchWhenRoaming: boolean(),
      allowInAppPurchases: boolean(),
      allowKeyboardShortcuts: boolean(),
      allowManagedAppsCloudSync: boolean(),
      allowMultiplayerGaming: boolean(),
      allowMusicService: boolean(),
      allowNews: boolean(),
      allowNotificationsModification: boolean(),
      allowOpenFromManagedToUnmanaged: boolean(),
      allowOpenFromUnmanagedToManaged: boolean(),
      allowPairedWatch: boolean(),
      allowPassbookWhileLocked: boolean(),
      allowPasscodeModification: boolean(),
      allowPasswordAutoFill: boolean(),
      allowPasswordProximityRequests: boolean(),
      allowPasswordSharing: boolean(),
      allowPersonalHotspotModification: boolean(),
      allowPhotoStream: boolean(),
      allowPredictiveKeyboard: boolean(),
      allowProximitySetupToNewDevice: boolean(),
      allowRadioService: boolean(),
      allowRemoteAppPairing: boolean(),
      allowRemoteScreenObservation: boolean(),
      allowSafari: boolean(),
      allowScreenShot: boolean(),
      allowSharedStream: boolean(),
      allowSpellCheck: boolean(),
      allowSpotlightInternetResults: boolean(),
      allowSystemAppRemoval: boolean(),
      allowUIAppInstallation: boolean(),
      allowUIConfigurationProfileInstallation: boolean(),
      allowUSBRestrictedMode: boolean(),
      allowUnpairedExternalBootToRecovery: boolean(),
      allowUntrustedTLSPrompt: boolean(),
      allowVPNCreation: boolean(),
      allowVideoConferencing: boolean(),
      allowVoiceDialing: boolean(),
      allowWallpaperModification: boolean(),
      allowiTunes: boolean(),
      blacklistedAppBundleIDs: array().of(string()),
      enforcedSoftwareUpdateDelay: number(),
      forceAirDropUnmanaged: boolean(),
      forceAirPlayIncomingRequestsPairingPassword: boolean(),
      forceAirPlayOutgoingRequestsPairingPassword: boolean(),
      forceAirPrintTrustedTLSRequirement: boolean(),
      forceAssistantProfanityFilter: boolean(),
      forceAuthenticationBeforeAutoFill: boolean(),
      forceAutomaticDateAndTime: boolean(),
      forceClassroomAutomaticallyJoinClasses: boolean(),
      forceClassroomRequestPermissionToLeaveClasses: boolean(),
      forceClassroomUnpromptedAppAndDeviceLock: boolean(),
      forceClassroomUnpromptedScreenObservation: boolean(),
      forceDelayedSoftwareUpdates: boolean(),
      forceEncryptedBackup: boolean(),
      forceITunesStorePasswordEntry: boolean(),
      forceLimitAdTracking: boolean(),
      forceWatchWristDetection: boolean(),
      forceWiFiPowerOn: boolean(),
      forceWiFiWhitelisting: boolean(),
      ratingApps: number(),
      ratingMovies: number(),
      ratingRegion: string(),
      ratingTVShows: number(),
      safariAcceptCookies: number(),
      safariAllowAutoFill: boolean(),
      safariAllowJavaScript: boolean(),
      safariAllowPopups: boolean(),
      safariForceFraudWarning: boolean()
    }),
    passwords: object({
      allowSimple: boolean(),
      forcePIN: boolean(),
      maxFailedAttempts: number(),
      maxGracePeriod: number(),
      maxInactivity: number(),
      maxPINAgeInDays: number(),
      minComplexChars: number(),
      minLength: number(),
      pinHistory: number(),
      requireAlphanumeric: boolean()
    }),
    domains: object({
      EmailDomains: array().of(string()),
      SafariPasswordAutoFillDomains: array().of(string()),
      WebDomains: array().of(string())
    }),
    http_proxy: object({
      ProxyUsername: string(),
      ProxyPassword: string(),
      ProxyServer: string(),
      ProxyServerPort: number(),
      ProxyCaptiveLoginAllowed: boolean(),
      ProxyPACFallbackAllowed: boolean(),
      ProxyPACURL: string(),
      ProxyType: mixed<'Auto' | 'Manual'>().oneOf(['Auto', 'Manual'])
    }),
    dns_proxy: object({
      AppBundleIdentifier: string(),
      ProviderBundleIdentifier: string(),
      ProviderBundleConfiguration: object()
    }),
    web_content_filter: object({
      FilterType: mixed<'BuiltIn' | 'Plugin'>().oneOf(['BuiltIn', 'Plugin']),
      AutoFilterEnabled: boolean(),
      FilterBrowsers: boolean(),
      FilterSockets: boolean(),
      Organization: string(),
      Password: string(),
      PluginBundleID: string(),
      ServerAddress: string(),
      UserDefinedName: string(),
      UserName: string(),
      VendorConfig: object(),
      BlacklistedURLs: array().of(string()),
      PermittedURLs: array().of(string()),
      WhitelistedBookmarks: array().of(
        object({
          Title: string(),
          URL: string()
        })
      )
    })
  }),
  scope: object({
    scoped: boolean().default(true),
    scopeEntriesInclude: array().default([]),
    scopeEntriesExclude: array().default([])
  }).required()
}).typeError(i18next.t('profiles.profile_form.errors.this_field_must_contain_a_valid_json'));

export const uploadFormSchema = object({
  config_profile: mixed()
    .test(
      'required',
      i18next.t('profiles.profile_form.upload_modal.errors.file_needed'),
      (value) => {
        return value;
      }
    )
    .test(
      'fileSize',
      i18next.t('profiles.profile_form.upload_modal.errors.file_too_large'),
      (value) => {
        return value && value.size <= 1000000;
      }
    )
    .test(
      'type',
      i18next.t('profiles.profile_form.upload_modal.errors.must_be_a_profile'),
      function (value) {
        const fileName = value && value.name;
        const fileExtension = fileName.slice(
          (Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1
        );
        return fileExtension === 'mobileconfig';
      }
    ),
  scope: object({
    scoped: boolean().default(true),
    scopeEntriesInclude: array().default([]),
    scopeEntriesExclude: array().default([])
  }).required()
});
