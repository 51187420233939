import { FC } from 'react';
import {
  DeviceApplicationSimpleDto,
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import useDeviceSection, {
  DeviceType
} from '../../../../../../../../contexts/device-section.context';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import css from './applications-table.module.scss';

interface IProps {
  device?: DeviceFullDto;
}

export const ApplicationsTable: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();

  const computerColumns: Column<DeviceApplicationSimpleDto>[] = [
    {
      id: 'name',
      name: t('inventory.device_page.inventory_tab.applications_tab.table.heading.name'),
      selector: (application) => application.name,
      width: 250
    },
    {
      id: 'version',
      name: t('inventory.device_page.inventory_tab.applications_tab.table.heading.version'),
      selector: (application) => application.version,
      width: 250
    },
    {
      id: 'path',
      name: t('inventory.device_page.inventory_tab.applications_tab.table.heading.path'),
      selector: (application) => application.path
    }
  ];
  const mobileColumns: Column<DeviceApplicationSimpleDto>[] = [
    {
      id: 'name',
      name: t('inventory.device_page.inventory_tab.applications_tab.table.heading.name'),
      selector: (application) => application.name,
      width: 250
    },
    {
      id: 'version',
      name: t('inventory.device_page.inventory_tab.applications_tab.table.heading.version'),
      selector: (application) => application.version
    }
  ];
  const actualColumns = deviceType === DeviceType.COMPUTERS ? computerColumns : mobileColumns;

  return (
    <>
      <Table columns={actualColumns} data={device?.applications || []} className={css.Table} />
      {deviceType === DeviceType.COMPUTERS && (
        <DeviceExtensionAttributes
          className={css.ExtensionAttributes}
          device={device}
          inventorySection={ExtensionAttributeInventorySection.Applications}
        />
      )}
    </>
  );
};
