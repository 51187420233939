import i18n from '../i18n/i18n';
import { DeviceUserCriteria } from '../types/api';

export const deviceCriteriaTitles: Record<string, string> = {
  ['ModelName']: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.model_name'),
  ['DaysSinceLastSeen']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.days_since_last_seen'
  ),
  ['General.LocalIP']: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.local_ip'),
  ['General.ComputerName']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.device_name'
  ),
  ['General.UserApprovedMdm']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.mdm_enrollment_status'
  ),
  ['Hardware.CpuType']: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.cpu_type'),
  ['Hardware.IsAppleSilicon']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.is_apple_silicon'
  ),
  ['Hardware.MainNetworkInterfaceMac']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.main_network_interface_mac'
  ),
  ['Hardware.MainNetworkInterface']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.main_network_interface'
  ),
  ['Hardware.SerialNumber']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.serial_number'
  ),
  ['Hardware.TotalRam']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.total_ram_mb'
  ),
  ['Hardware.UDID']: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.udid'),
  ['Hardware.ModelIdentifier']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.model_identifier'
  ),
  ['Hardware.BatteryCapacity']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.battery_capacity'
  ),
  ['OS.ProductVersion']: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.os'),
  ['OS.ProductName']: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.os_name'),
  ['OS.BuildVersion']: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.os_build'),
  ['OS.RapidSecurityResponse']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.os_rapid_security_response'
  ),
  ['OS.ADBind']: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.os_ad_bind'),
  ['Security.FirmwarePasswordEnabled']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.firmware_password_enabled'
  ),
  ['Security.ARDEnabled']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.ard_enabled'
  ),
  ['Security.SIPEnabled']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.sip_enabled'
  ),
  ['Security.ActivationLockEnabled']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.activation_lock_enabled'
  ),
  ['Security.XProtectDefinitionsVersion']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.x_protect_definitions_version'
  ),
  ['Security.FirewallEnabled']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.firewall_enabled'
  ),
  ['Security.AutologinEnabled']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.autologin_enabled'
  ),
  ['Security.GatekeeperEnabled']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.gatekeeper_enabled'
  ),
  ['Storage.PrimaryDriveSerial']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.primary_drive_serial'
  ),
  ['Storage.PrimaryDriveModel']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.primary_drive_model'
  ),
  ['Storage.PrimaryDriveSmartStatus']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.primary_drive_smart_status'
  ),
  ['Storage.PrimaryDriveRevision']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.primary_drive_revision'
  ),
  ['Storage.PrimaryDriveCapacity']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.primary_drive_capacity_mb'
  ),
  ['DiskEncryption.FilevaultEnabled']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.filevault_enabled'
  ),
  ['DiskEncryption.FilevaultHasInstitutionalKey']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.filevault_has_institutional_key'
  ),
  ['Profiles.Identifier']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.profile_identifier'
  ),
  ['Profiles.Name']: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.profile_name'),
  ['Profiles.UUID']: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.profile_uuid'),
  ['LocalUserAccounts.GID']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.local_user_account_gid'
  ),
  ['LocalUserAccounts.UID']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.local_user_account_uid'
  ),
  ['LocalUserAccounts.Dir']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.local_user_account_dir'
  ),
  ['LocalUserAccounts.Name']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.local_user_account_name'
  ),
  ['LocalUserAccounts.IsAdmin']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.local_user_account_is_admin'
  ),
  ['Services.Name']: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.services_name'),
  ['Applications.Name']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.applications_name'
  ),
  ['Applications.Version']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.applications_version'
  ),
  ['Applications.Path']: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.applications_path'
  )
};

export const deviceUserCriteriaTitles: Record<DeviceUserCriteria, string> = {
  [DeviceUserCriteria.Username]: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.username'
  ),
  [DeviceUserCriteria.Email]: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.email'),
  [DeviceUserCriteria.EmployeeName]: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.employee_name'
  ),
  [DeviceUserCriteria.PhoneNumber]: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.phone_number'
  ),
  [DeviceUserCriteria.Position]: i18n.t(
    'smart_groups.page.criteria_tab.new_criteria.criteria.position'
  ),
  [DeviceUserCriteria.Group]: i18n.t('smart_groups.page.criteria_tab.new_criteria.criteria.group')
};
