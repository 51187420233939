import { FC } from 'react';
import { Skeleton } from '@gravity-ui/uikit';
import css from './table-skeleton.module.scss';

export const TableSkeleton: FC = () => {
  return (
    <>
      <div className={css.HeaderContainer}>
        <Skeleton className={css.SearchSkeleton} />
        <Skeleton className={css.PaginationSkeleton} />
      </div>
      <Skeleton className={css.ContentSkeleton} />
    </>
  );
};
