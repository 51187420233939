import { FC, FormEvent, useState } from 'react';
import css from './device-application-form.module.scss';
import { useFormContext } from 'react-hook-form';
import { DeviceApplicationFormValues } from '../../device-application.schema';
import { useTranslation } from 'react-i18next';
import { ScopeForm, ScopeValues } from '../../../../components/scope-form/scope-form.component';
import { Tabs, TabsItemProps } from '@gravity-ui/uikit';
import { GeneralTab } from './components/general-tab/general-tab.component';

export enum DeviceApplicationFormTabs {
  GENERAL = 'General',
  SCOPE = 'Scope'
}

interface IProps {
  disabled: boolean;
}

export const DeviceApplicationForm: FC<IProps> = (props: IProps) => {
  const { disabled } = props;
  const { t } = useTranslation();
  const appForm = useFormContext<DeviceApplicationFormValues>();

  const [activeTab, setActiveTab] = useState(DeviceApplicationFormTabs.GENERAL);
  const [scopeValues, setScopeValues] = useState<ScopeValues>(
    appForm.getValues().scope || { scoped: true, scopeEntriesInclude: [], scopeEntriesExclude: [] }
  );

  const handleChangeTab = (tab: string) => setActiveTab(tab as DeviceApplicationFormTabs);

  const handleScopeChange = (input: Partial<ScopeValues>) => {
    const newScope = { ...scopeValues, ...input };
    setScopeValues(newScope);
    appForm.setValue('scope', newScope);
  };

  const handleFormSubmit = (event: FormEvent) => event.preventDefault();

  const tabs: TabsItemProps[] = [
    {
      id: DeviceApplicationFormTabs.GENERAL,
      title: t('device_apps.app_form.tab.general')
    },
    {
      id: DeviceApplicationFormTabs.SCOPE,
      title: t('device_apps.app_form.tab.scope')
    }
  ];

  return (
    <form onSubmit={handleFormSubmit}>
      <Tabs
        className={css.NavBar}
        items={tabs}
        activeTab={activeTab}
        onSelectTab={handleChangeTab}
      />
      {activeTab === DeviceApplicationFormTabs.GENERAL && <GeneralTab disabled={disabled} />}
      {activeTab === DeviceApplicationFormTabs.SCOPE && (
        <ScopeForm values={scopeValues} disabled={disabled} onChange={handleScopeChange} />
      )}
    </form>
  );
};
