import { FC } from 'react';
import css from './settings-license-form.module.scss';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { UploadLicenseForm } from '../../settings-license.schema';
import { DeviceOsType, LicenseDto, LicenseStatus, Permission } from '../../../../../../types/api';
import { usePermission } from '../../../../../contexts/permission.context';
import { WithClassname } from '../../../../../../types/common';
import { FileInput } from '../../../../../components/file-input-v2/file-input.component';
import { Button, Link, Text, TextArea } from '@gravity-ui/uikit';

interface IProps {
  licenseOSTab: DeviceOsType;
  expirationDate: string;
  activationPeriod: number;
  totalDevicesEnrolled: number;
  license: LicenseDto | null;
  form: UseFormReturn<UploadLicenseForm>;
  onSubmit: () => void;
}

export const LicenseTab: FC<IProps & WithClassname> = (props) => {
  const {
    licenseOSTab,
    expirationDate,
    activationPeriod,
    totalDevicesEnrolled,
    license,
    form,
    onSubmit
  } = props;
  const {
    getValues,
    setValue,
    reset,
    formState: { errors }
  } = form;

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  return (
    <div>
      <div className={css.Title}>
        <Text variant="display-2">
          {licenseOSTab === DeviceOsType.MacOS
            ? t('settings.tiles.license.page.title.macos_license_settings')
            : t('settings.tiles.license.page.title.ios_license_settings')}
        </Text>
      </div>
      <div className={css.Content}>
        {license?.status === LicenseStatus.Active && (
          <>
            <div>
              <Text>{t('settings.tiles.license.page.code')}</Text>
              <TextArea
                className={css.Code}
                value={license.license_code}
                disabled
                controlProps={{ style: { resize: 'both' } }}
                minRows={10}
              />
            </div>
            <Text>
              {t('settings.tiles.license.page.expired_date', {
                date: expirationDate
              })}
              {'. '}
              {t('settings.tiles.license.page.days_left', {
                num_of_days: activationPeriod
              })}
            </Text>
            {license?.max_device && totalDevicesEnrolled <= license.max_device && (
              <Text>
                {t('settings.tiles.license.page.computers_enrolled', {
                  computers_enrolled: String(totalDevicesEnrolled)
                })}
                {'. '}
                {t('settings.tiles.license.page.computers_remainder', {
                  num_of_computers: license?.max_device
                    ? String(license.max_device - totalDevicesEnrolled)
                    : 0
                })}
                {license?.max_device === totalDevicesEnrolled && (
                  <Text color="danger">{t('settings.tiles.license.page.max_num_of_devices')}</Text>
                )}
              </Text>
            )}
            {license?.max_device && totalDevicesEnrolled > license.max_device && (
              <Text>
                {t('settings.tiles.organization_information.page.max_num_of_devices_in_license')}{' '}
                {license?.max_device}
                {'. '}
                {t('settings.tiles.license.page.computers_enrolled', {
                  computers_enrolled: String(totalDevicesEnrolled)
                })}
                <Text color="danger">
                  {t('settings.tiles.license.page.number_of_devices_is_more_than_available', {
                    num: String(totalDevicesEnrolled - license?.max_device)
                  })}
                </Text>
              </Text>
            )}
          </>
        )}
        {license?.status === LicenseStatus.Expired && (
          <>
            <div>
              <Text>{t('settings.tiles.license.page.code')}</Text>
              <TextArea
                className={css.Code}
                value={license.license_code}
                disabled
                controlProps={{ style: { resize: 'both' } }}
                minRows={10}
              />
            </div>
            <Text color="danger">
              {t('settings.tiles.license.page.license_expire')}{' '}
              <Link href="mailto:support@ringomdm.ru?subject=LicenseExpired">
                support@ringomdm.ru
              </Link>
            </Text>
            <Text>
              {t('settings.tiles.license.page.expired_date', {
                date: expirationDate
              })}
            </Text>
            <Text>
              {t('settings.tiles.license.page.computers_enrolled', {
                computers_enrolled: String(totalDevicesEnrolled)
              })}
            </Text>
          </>
        )}
        <form onSubmit={onSubmit}>
          <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditAdministration)}>
            <div className={css.Dropzone}>
              <Text>{t('settings.tiles.license.page.file_input')}</Text>
              <FileInput
                accept=".pem"
                error={errors.license?.message}
                onUpdate={(file) => {
                  reset({ os_type: getValues().os_type });
                  setValue('license', file);
                }}
                onCancel={() => reset({ os_type: getValues().os_type })}
              />
            </div>
            <Button
              view="action"
              type="submit"
              disabled={!isAllowedTo(Permission.EditAdministration)}
            >
              {t('settings.tiles.license.page.activate')}
            </Button>
          </fieldset>
        </form>
      </div>
    </div>
  );
};
