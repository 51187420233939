import css from './policy-run.module.scss';
import { FC, useEffect, useState } from 'react';
import { PolicyRunFullDto, PolicyRunSimpleDto } from '../../../../../../../../../types/api';
import {
  acknowledgePolicyRun,
  cancelPolicyRun,
  getPolicyRun,
  rerunPolicyRun
} from '../../../../../../../../../api/policies';
import { PolicyRunStep } from './components/policy-run-step/policy-run-step.component';
import useRequest from '../../../../../../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Text } from '@gravity-ui/uikit';
import { ArrowLeft } from '@gravity-ui/icons';

interface IProps {
  policyRun: PolicyRunSimpleDto;
  onBack?: () => unknown;
}

export const PolicyRun: FC<IProps> = (props) => {
  const { policyRun, onBack } = props;
  const [policyRunFull, setPolicyRunFull] = useState<PolicyRunFullDto>();
  const [isAcknowledged, setIsAcknowledged] = useState(policyRun.acknowledged);
  const acknowledgeRequest = useRequest<PolicyRunSimpleDto>();
  const cancelRequest = useRequest<PolicyRunSimpleDto>();
  const rerunRequest = useRequest<PolicyRunFullDto>();
  const { t } = useTranslation();

  const handleBack = () => onBack?.();
  const handleAcknowledgeClick = async () => {
    if (acknowledgeRequest.loading) return;
    await acknowledgeRequest.send(acknowledgePolicyRun(policyRun.id));
    setIsAcknowledged(true);
  };

  const handleCancelClick = async () => {
    if (cancelRequest.loading) return;
    const response = await cancelRequest.send(cancelPolicyRun(policyRun.id));
    if (policyRunFull) {
      setPolicyRunFull({
        ...policyRunFull,
        canceled: response.canceled,
        cancel_pending: response.cancel_pending
      });
    }
  };

  const handleRerunClick = async () => {
    if (rerunRequest.loading) return;
    const response = await rerunRequest.send(rerunPolicyRun(policyRun.id));
    if (policyRunFull) {
      setPolicyRunFull(response);
    }
  };

  const getDeviceDisplayName = () => {
    return policyRun.device
      ? `${policyRun.device.model_name} (${policyRun.device.serial_number})`
      : t('policies.policy_run.device_deleted');
  };

  useEffect(() => {
    const init = async () => {
      const response = await getPolicyRun(policyRun.id);
      setPolicyRunFull(response);
    };
    init().then();
  }, []);

  const canBeCanceled = !policyRun.canceled && policyRun.success === null;
  const canBeRerun = !!policyRun.finished_at && policyRun.device;
  return (
    <div className={css.Root}>
      <Text variant="subheader-2">{getDeviceDisplayName()}</Text>
      <div className={css.TopSection}>
        <Button view="flat-info" onClick={handleBack}>
          <Icon data={ArrowLeft} />
          {t('policies.policy_run.button_back')}
        </Button>
        <div className={css.BtnGroup}>
          {!isAcknowledged && policyRun.success === false && (
            <Button
              view="action"
              onClick={handleAcknowledgeClick}
              loading={acknowledgeRequest.loading}
            >
              {t('policies.policy_run.button_acknowledge')}
            </Button>
          )}
          {canBeCanceled && (
            <Button view="action" onClick={handleCancelClick} loading={cancelRequest.loading}>
              {t('policies.policy_run.button_cancel')}
            </Button>
          )}
          {canBeRerun && (
            <Button view="action" onClick={handleRerunClick} loading={rerunRequest.loading}>
              {t('policies.policy_run.button_rerun')}
            </Button>
          )}
        </div>
      </div>
      {policyRunFull &&
        policyRunFull.steps.map((step) => (
          <PolicyRunStep key={step.id} step={step} policyRun={policyRunFull} />
        ))}
    </div>
  );
};
