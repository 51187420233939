import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import { t } from 'i18next';
import css from './action-menu.module.scss';

interface IProps {
  mode: 'create' | 'edit' | 'view';
  disabled: boolean;
  onPackageCreate: () => void;
  onPackageEditStart: () => void;
  onPackageSave: () => void;
  onPackageDelete: () => void;
  onPackageCancel: () => void;
}

export const ActionMenu: FC<IProps> = (props) => {
  const {
    mode,
    disabled,
    onPackageCreate,
    onPackageEditStart,
    onPackageSave,
    onPackageDelete,
    onPackageCancel
  } = props;

  return (
    <div className={css.ButtonGroup}>
      {mode === 'create' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onPackageCancel}>
            {t('settings.tiles.packages.page.package_page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onPackageCreate}>
            {t('settings.tiles.packages.page.package_page.create_btn')}
          </Button>
        </>
      )}
      {mode === 'edit' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onPackageCancel}>
            {t('settings.tiles.packages.page.package_page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onPackageSave}>
            {t('settings.tiles.packages.page.package_page.save_btn')}
          </Button>
        </>
      )}
      {mode === 'view' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onPackageDelete}>
            {t('settings.tiles.packages.page.package_page.delete_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onPackageEditStart}>
            {t('settings.tiles.packages.page.package_page.edit_btn')}
          </Button>
        </>
      )}
    </div>
  );
};
