import { FC } from 'react';
import css from './security-table-mobile.module.scss';
import { DeviceFullDto } from '../../../../../../../../../types/api';
import { isBoolean } from '../../../../../../../../../utils/assert.utils';
import { useTranslation } from 'react-i18next';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import { Text } from '@gravity-ui/uikit';

interface IProps {
  device?: DeviceFullDto;
}

interface IKeyValue {
  key: string;
  value: string | null | undefined;
}

export const SecurityTableMobile: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const di = device?.device_information;

  const data: IKeyValue[] = [
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.device_locator_service'
      ),
      value: isBoolean(di?.is_device_locator_service_enabled)
        ? di?.is_device_locator_service_enabled
          ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.enabled')
          : t('inventory_mobile.device_page.inventory_tab.security_tab.table.disabled')
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.data_protection'
      ),
      value: isBoolean(di?.gatekeeper_enabled)
        ? di?.gatekeeper_enabled
          ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.enabled')
          : t('inventory_mobile.device_page.inventory_tab.security_tab.table.disabled')
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.hardware_encryption'
      ),
      value: di?.hardware_encryption_caps
        ? di?.hardware_encryption_caps === 1 ||
          di?.hardware_encryption_caps === 2 ||
          di?.hardware_encryption_caps === 3
          ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
          : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.passcode_status'
      ),
      value: isBoolean(di?.autologin_enabled)
        ? di?.autologin_enabled
          ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.enabled')
          : t('inventory_mobile.device_page.inventory_tab.security_tab.table.disabled')
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.block_encryption_capability'
      ),
      value: di?.hardware_encryption_caps
        ? di?.hardware_encryption_caps === 1 || di?.hardware_encryption_caps === 3
          ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
          : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.file_encryption_capability'
      ),
      value: di?.hardware_encryption_caps
        ? di?.hardware_encryption_caps === 2 || di?.hardware_encryption_caps === 3
          ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
          : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.passcode_compliance'
      ),
      value: isBoolean(di?.passcode_compliant)
        ? di?.passcode_compliant
          ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
          : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.passcode_compliance_with_config_profile'
      ),
      value: isBoolean(di?.passcode_compliant_with_profiles)
        ? di?.passcode_compliant_with_profiles
          ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
          : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.passcode_lock_grace_period_enforced'
      ),
      value: di?.passcode_lock_grace_period_enforced
        ? di?.passcode_lock_grace_period_enforced
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.activation_lock_enabled'
      ),
      value: isBoolean(di?.firmware_password_enabled)
        ? di?.firmware_password_enabled
          ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
          : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.jailbreak_detected'
      ),
      value: di?.firmware_password
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.security_tab.table.heading.is_mdm_lost_mode_enabled'
      ),
      value: isBoolean(di?.is_mdm_lost_mode_enabled)
        ? di?.is_mdm_lost_mode_enabled
          ? t('inventory_mobile.device_page.inventory_tab.security_tab.table.yes')
          : t('inventory_mobile.device_page.inventory_tab.security_tab.table.no')
        : undefined
    }
  ];

  const columns: Column<IKeyValue>[] = [
    {
      id: 'key',
      selector: (row) => <Text variant="subheader-1">{row.key}</Text>,
      width: 250
    },
    {
      id: 'value',
      selector: (row) => row.value
    }
  ];

  return (
    <>
      <Table data={data} columns={columns} className={css.Table} />
    </>
  );
};
