import { FC, useEffect, useState } from 'react';
import css from './settings-package-list.module.scss';
import { useNavigate } from 'react-router-dom';
import { GetPackagesResponseDto, PackageSimpleDto, Permission } from '../../../../types/api';
import { getPackages } from '../../../../api/packages';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../contexts/permission.context';
import { Header } from '../../../components/header/header.component';
import { Column, Table } from '../../../components/table/table.component';
import { Button, Text } from '@gravity-ui/uikit';
import useRequest from '../../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';
import noDataImage from '../../../../assets/images/no-data.png';

export const SettingsPackageList: FC = () => {
  const [packages, setPackages] = useState<PackageSimpleDto[]>();
  const [packageCount, setPackageCount] = useState<number>(0);
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const navigate = useNavigate();
  const fetchRequest = useRequest<GetPackagesResponseDto>();

  const handleClickCreate = () => {
    navigate('new');
  };

  const handleRowClick = (package_: PackageSimpleDto) => {
    navigate(package_.id);
  };

  useEffect(() => {
    const fetch = async () => {
      const result = await fetchRequest.send(getPackages(), 1000);
      setPackages(result.packages);
      setPackageCount(result.count);
    };
    void fetch();
  }, []);

  const columns: Column<PackageSimpleDto>[] = [
    {
      id: 'display_name',
      name: t('settings.tiles.packages.page.table.heading.title'),
      selector: (row) => row.display_name
    }
  ];

  const headerContent = (
    <Button
      disabled={fetchRequest.loading || !isAllowedTo(Permission.EditPackages)}
      view="action"
      onClick={handleClickCreate}
    >
      {t('settings.tiles.packages.page.new_btn')}
    </Button>
  );

  return (
    <div className={css.Root}>
      <Header rightContent={headerContent} />
      <div className={css.DataTableContainer}>
        {fetchRequest.loading ? (
          <>
            <div className={css.Title}>
              <Text variant="display-2">{t('settings.tiles.packages.page.title')}</Text>
            </div>
            <TableSkeleton />
          </>
        ) : packageCount === 0 ? (
          <>
            <div className={css.Title}>
              <Text variant="display-2">{t('settings.tiles.packages.page.title')}</Text>
            </div>
            <div className={css.NoDataContainer}>
              <img alt="no-data" src={noDataImage} />
              <Text variant="subheader-3">{t('settings.tiles.packages.page.no_data')}</Text>
              {headerContent}
            </div>
          </>
        ) : (
          <Table
            columns={columns}
            data={packages || []}
            onRowClick={handleRowClick}
            leftContent={<Text variant="display-2">{t('settings.tiles.packages.page.title')}</Text>}
          />
        )}
      </div>
    </div>
  );
};
