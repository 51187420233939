import { ChangeEventHandler, DragEventHandler, FC, MouseEventHandler, useState } from 'react';
import { Card, Text, useFileInput } from '@gravity-ui/uikit';
import css from './file-input.module.scss';
import cn from 'classnames';
import { EmptyStateDropzone } from './components/empty-state-dropzone/empty-state-dropzone.component';
import { SuccessStateDropzone } from './components/success-state-dropzone/success-state-dropzone.component';
import { ErrorStateDropzone } from './components/error-state-dropzone/error-state-dropzone.component';
import { useTranslation } from 'react-i18next';
import { verifyAccept } from '../../../utils/file.utils';

interface IProps {
  maxFileSize?: number; // in bytes
  error?: string;
  accept?: string;
  onUpdate?: (file: File) => void;
  onCancel?: () => void;
  disabled?: boolean;
}

export const FileInput: FC<IProps> = (props) => {
  const { accept, maxFileSize, error, onUpdate, onCancel, disabled } = props;

  const { t } = useTranslation();

  const [fileName, setFileName] = useState<string>();
  const [fileSize, setFileSize] = useState<number>();
  const [isDragged, setIsDragged] = useState(false);

  const handleLoadFile = (file: File) => {
    if (accept && !verifyAccept(file.name, accept)) return;

    setFileName(file.name);
    setFileSize(file.size);
    onUpdate?.(file);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (changeEvent) => {
    const uploadedFiles = changeEvent.target.files;
    if (uploadedFiles && uploadedFiles.length > 0) {
      const uploadedFile = uploadedFiles.item(0);
      if (uploadedFile) handleLoadFile(uploadedFile);
    }
  };

  const handleDrop: DragEventHandler<'div'> = (event) => {
    event.preventDefault();
    setIsDragged(false);

    const uploadedFiles = event.dataTransfer?.files;
    if (uploadedFiles && uploadedFiles.length > 0) {
      const uploadedFile = uploadedFiles.item(0);
      if (uploadedFile) handleLoadFile(uploadedFile);
    }
  };

  const handleCancel: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setFileName(undefined);
    setFileSize(undefined);

    onCancel?.();
  };

  const handleDragOver: DragEventHandler<'div'> = (event) => {
    event.preventDefault();
    setIsDragged(true);
  };

  const handleDragEnter: DragEventHandler<'div'> = (event) => {
    event.preventDefault();
    setIsDragged(true);
  };

  const handleDragLeave: DragEventHandler<'div'> = (event) => {
    event.preventDefault();
    setIsDragged(false);
  };

  const { controlProps, triggerProps } = useFileInput({
    onChange: handleChange
  });

  return (
    <Card
      className={cn(
        css.Root,
        fileName ? undefined : css.Empty,
        isDragged ? css.Dragged : undefined
      )}
      size="l"
      type="selection"
      view="outlined"
      disabled={disabled}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      {...triggerProps}
    >
      <input accept={accept} multiple={false} disabled={disabled} {...controlProps} />
      {!isDragged && !fileName && !error && <EmptyStateDropzone maxFileSize={maxFileSize} />}
      {!isDragged && fileName && !error && (
        <SuccessStateDropzone fileName={fileName} fileSize={fileSize} />
      )}
      {!isDragged && error && (
        <ErrorStateDropzone fileName={fileName} error={error} onCancel={handleCancel} />
      )}
      {isDragged && (
        <Text color="light-primary" variant="subheader-3">
          {t('common.file_input.dragged_title')}
        </Text>
      )}
    </Card>
  );
};
