import { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/auth.context';
import useIdle from '../../../hooks/useIdleTimer';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface IProps {
  redirectTo?: string;
  children?: ReactNode;
}

export const PrivateRoute: FC<IProps> = (props: IProps) => {
  const { children, redirectTo = '/' } = props;
  const { authService } = useAuthContext();
  const [isAuthorized, setIsAuthorized] = useState<boolean>();
  const { t } = useTranslation();

  useEffect(() => {
    if (authService.accessToken()) {
      setIsAuthorized(true);
      return;
    }
    authService
      .refreshToken()
      .then((i) => setIsAuthorized(!!i))
      .catch(() => setIsAuthorized(false));
  }, []);

  const onIdle = async () => {
    if (isAuthorized) {
      setIsAuthorized(false);
      await authService.signOut();
      toast.error(t('sign_in.session_expired'), { autoClose: false });
    }
  };
  useIdle({
    onIdle,
    idleMinutes: 30
  });

  if (isAuthorized === undefined) {
    return null;
  }
  return isAuthorized ? <>{children}</> : <Navigate to={redirectTo} replace />;
};
