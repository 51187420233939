import { FC } from 'react';
import css from './general-table-mobile.module.scss';
import { DeviceFullDto } from '../../../../../../../../../types/api';
import { formatDate } from '../../../../../../../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { isBoolean } from '../../../../../../../../../utils/assert.utils';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import { Text } from '@gravity-ui/uikit';

interface IProps {
  device?: DeviceFullDto;
}

interface IKeyValue {
  key: string;
  value: string | null | undefined;
}

export const GeneralTableMobile: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const data: IKeyValue[] = [
    {
      key: t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.device_name'),
      value: device?.device_information?.device_name
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.last_update'),
      value:
        device && device.device_information
          ? new Date(device.updated_at) > new Date(device.device_information.updated_at)
            ? formatDate(device.updated_at)
            : formatDate(device.device_information.updated_at)
          : undefined
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.ip_address'),
      value: device?.device_information?.local_ip
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.is_managed'),
      value: undefined
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.is_supervised'),
      value: isBoolean(device?.device_information?.is_supervised)
        ? device?.device_information?.is_supervised
          ? t('inventory_mobile.device_page.inventory_tab.general_tab.table.yes')
          : t('inventory_mobile.device_page.inventory_tab.general_tab.table.no')
        : undefined
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.ownership_type'),
      value: undefined
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.timezone'),
      value: device?.device_information?.time_zone
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.general_tab.table.heading.tethered_status'
      ),
      value: isBoolean(device?.device_information?.is_network_tethered)
        ? device?.device_information?.is_network_tethered
          ? t('inventory_mobile.device_page.inventory_tab.general_tab.table.enabled')
          : t('inventory_mobile.device_page.inventory_tab.general_tab.table.disabled')
        : undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.general_tab.table.heading.declarative_management'
      ),
      value: undefined
    },
    {
      key: t(
        'inventory_mobile.device_page.inventory_tab.general_tab.table.heading.exchange_device_id'
      ),
      value: device?.device_information?.eas_device_identifier
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.appstore_login'),
      value: undefined
    },
    {
      key: t('inventory_mobile.device_page.inventory_tab.general_tab.table.heading.created_at'),
      value: device ? formatDate(device.created_at) : undefined
    }
  ];

  const columns: Column<IKeyValue>[] = [
    {
      id: 'key',
      selector: (i) => <Text variant="subheader-1">{i.key}</Text>,
      width: 250
    },
    {
      id: 'value',
      selector: (i) => i.value
    }
  ];

  return (
    <>
      <Table columns={columns} data={data} className={css.Table} />
    </>
  );
};
