import css from './policy-list.module.scss';
import { FC, useEffect, useState } from 'react';
import { getPolicies } from '../../../api/policies';
import {
  GetPoliciesResponseDto,
  PaginationSortOrder,
  Permission,
  PoliciesSortField,
  PolicySimpleDto
} from '../../../types/api';
import { policyFrequencyTitles, policyTriggerTitles } from '../../../const/policies.const';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../const/pagination.const';
import { usePermission } from '../../contexts/permission.context';
import { Column, PaginationState, SortState, Table } from '../../components/table/table.component';
import { Button, Icon, Text, useToaster } from '@gravity-ui/uikit';
import { CircleFill } from '@gravity-ui/icons';
import { Header } from '../../components/header/header.component';
import { useNavigate } from 'react-router-dom';
import { DeviceSections, Paths } from '../../constants';
import useRequest from '../../../hooks/useRequest';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import { PolicyTableSkeleton } from './components/policy-table-skeleton/policy-table-skeleton.component';
import noDataImage from '../../../assets/images/no-data.png';

export const PolicyList: FC = () => {
  const [policies, setPolicies] = useState<PolicySimpleDto[]>();
  const [policiesCount, setPoliciesCount] = useState(0);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: 10
  });
  const [sortState, setSortState] = useState<SortState>();
  const getPoliciesRequest = useRequest<GetPoliciesResponseDto>();

  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const navigate = useNavigate();
  const toaster = useToaster();

  const init = async () => {
    try {
      const response = await getPoliciesRequest.send(
        getPolicies({
          page: paginationState.page,
          limit: paginationState.rowsPerPage,
          sort_field: sortState?.column as PoliciesSortField,
          sort_order: sortState?.order as PaginationSortOrder
        }),
        1000
      );
      setPolicies(response.policies);
      setPoliciesCount(response.count);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'fetch-policies-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handlePaginationChange = (state: PaginationState) => setPaginationState(state);

  const handleSortChange = (state?: SortState) => setSortState(state);

  useEffect(() => {
    void init();
  }, [paginationState, sortState]);

  const getPolicyIconClassName = (policy: PolicySimpleDto) => {
    if (!policy.enabled) return css.PolicyIconDisabled;
    if (policy.unacknowledged_failed_policy_run_count > 0) return css.PolicyIconHasErrors;
    return css.PolicyIconEnabled;
  };

  const columns: Column<PolicySimpleDto>[] = [
    {
      id: 'enabled',
      name: '',
      selector: (policy) => <Icon data={CircleFill} className={getPolicyIconClassName(policy)} />,
      width: 20
    },
    {
      id: PoliciesSortField.Title,
      name: t('policy_list.policy_table_name'),
      selector: (policy) => policy.title,
      sortable: true
    },
    {
      id: PoliciesSortField.Frequency,
      name: t('policy_list.policy_table_frequency'),
      selector: (policy) =>
        policy.configuration ? policyFrequencyTitles[policy.configuration.frequency] : undefined
    },
    {
      id: PoliciesSortField.Trigger,
      name: t('policy_list.policy_table_trigger'),
      selector: (policy) => {
        if (!policy.configuration || policy.configuration.triggers.length === 0) return undefined;
        return policy.configuration.triggers.map((i) => policyTriggerTitles[i]).join(', ');
      }
    },
    {
      id: PoliciesSortField.Scope,
      name: t('policy_list.policy_table_scope'),
      selector: (policy) =>
        policy.scoped
          ? t('policy_list.policy_table_specific_computers')
          : t('policy_list.policy_table_all_computers')
    },
    {
      id: PoliciesSortField.ScopedDevicesCount,
      name: t('policy_list.policy_table_devices_in_scope'),
      selector: (policy) => policy.scoped_devices_count
    },
    {
      id: PoliciesSortField.SuccessfulDistinctRunsCount,
      name: t('policy_list.policy_table_successful_runs'),
      selector: (policy) => policy.successful_distinct_runs_count,
      sortable: true
    }
  ];

  const headerContent = (
    <Button
      view="action"
      onClick={() => navigate(`${DeviceSections.COMPUTERS}/${Paths.POLICY_LIST}/new`)}
      disabled={!isAllowedTo(Permission.EditPolicies)}
    >
      {t('policy_list.button_add_policy')}
    </Button>
  );

  const handleRowClick = (policy: PolicySimpleDto) =>
    navigate(`${DeviceSections.COMPUTERS}/${Paths.POLICY_LIST}/${policy.id}`);

  return (
    <div className={css.Root}>
      <Header rightContent={headerContent} />
      <div className={css.DataTableContainer}>
        {getPoliciesRequest.loading ? (
          <PolicyTableSkeleton />
        ) : policiesCount == 0 ? (
          <>
            <Text variant="display-2">{t('policy_list.title')}</Text>
            <div className={css.NoDataContainer}>
              <img alt="no-data" src={noDataImage} />
              <Text variant="subheader-3">{t('policy_list.no_data')}</Text>
              {headerContent}
            </div>
          </>
        ) : (
          <Table
            columns={columns}
            data={policies || []}
            onRowClick={handleRowClick}
            onChangePagination={handlePaginationChange}
            onChangeSort={handleSortChange}
            pagination
            paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
            paginationState={paginationState}
            paginationTotalRows={policiesCount}
            sortState={sortState}
            leftContent={<Text variant="display-2">{t('policy_list.title')}</Text>}
          />
        )}
      </div>
    </div>
  );
};
