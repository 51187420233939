import {
  DeviceApplicationDto,
  DeviceApplicationScopeDto,
  ScopeEntryOperationType,
  ScopeOptionType
} from '../../../types/api';
import { ScopeValues } from '../../components/scope-form/scope-form.component';
import { DeviceApplicationFormValues } from './device-application.schema';

export const appToForm = (input: DeviceApplicationDto): DeviceApplicationFormValues => {
  return {
    display_name: input.display_name,
    bundle_id: input.bundle_id,
    app_view_url: input.app_view_url,
    free: input.free,
    version: input.version,
    ...(input?.management_flags && { management_flags: input?.management_flags }),
    ...(input?.source && { email: input?.source }),
    scope: {
      scoped: input.scoped,
      scopeEntriesExclude:
        input.scope_entries
          ?.filter((i) => i.operation_type === 'Exclude')
          .map((i) => ({
            id: i.target_id,
            display_name: i.target_title || '',
            type: i.target_type as ScopeOptionType
          })) || [],
      scopeEntriesInclude:
        input.scope_entries
          ?.filter((i) => i.operation_type === 'Include')
          .map((i) => ({
            id: i.target_id,
            display_name: i.target_title || '',
            type: i.target_type as ScopeOptionType
          })) || []
    }
  };
};

export function formScopeEntries(scope?: ScopeValues): {
  scope_entries: DeviceApplicationScopeDto[];
} {
  const scopeEntriesInclude: DeviceApplicationScopeDto[] =
    scope?.scopeEntriesInclude.map((entry) => ({
      id: entry.id,
      type: entry.type,
      operation: ScopeEntryOperationType.Include
    })) || [];
  const scopeEntriesExclude: DeviceApplicationScopeDto[] =
    scope?.scopeEntriesExclude.map((entry) => ({
      id: entry.id,
      type: entry.type,
      operation: ScopeEntryOperationType.Exclude
    })) || [];
  const scopeEntries = [...(scope?.scoped ? scopeEntriesInclude : []), ...scopeEntriesExclude];
  return {
    scope_entries: scopeEntries
  };
}
