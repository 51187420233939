import { FC } from 'react';
import css from './device-application-type.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Text } from '@gravity-ui/uikit';

interface IProps {
  onCancel: () => void;
  onNextStep: () => void;
  disabled: boolean;
}

export const DeviceApplicationTypeComponent: FC<IProps> = (props) => {
  const { onCancel, onNextStep, disabled } = props;
  const { t } = useTranslation();

  return (
    <div className={css.Root}>
      <div className={css.Form}>
        <div className={css.FormItem}>
          <Text variant="subheader-1">
            {t('device_apps.installation.type_selection_step.title')}
          </Text>
          <Checkbox
            className={css.Checkbox}
            disabled
            checked
            content={<Text>{t('device_apps.installation.type_selection_step.app_store_app')}</Text>}
          />
        </div>
      </div>
      <div className={css.ActionButton}>
        <Button view="action" onClick={onCancel} disabled={disabled}>
          {t('device_apps.installation.buttons.cancel')}
        </Button>
        <Button view="action" onClick={onNextStep} disabled={disabled}>
          {t('device_apps.installation.buttons.next')}
        </Button>
      </div>
    </div>
  );
};
