import { FC } from 'react';
import { InstallationSteps } from '../../device-application-assistant.component';
import css from './step-sidebar.module.scss';
import { useTranslation } from 'react-i18next';
import { Text } from '@gravity-ui/uikit';

interface IProps {
  step: InstallationSteps;
}

export const StepSidebar: FC<IProps> = (props) => {
  const { step } = props;

  const { t } = useTranslation();

  return (
    <div className={css.Root}>
      <Text variant={step === InstallationSteps.NEW ? 'subheader-1' : 'body-1'}>
        {t('device_apps.installation.type_selection_step.chapter')}
      </Text>
      <Text variant={step === InstallationSteps.SEARCH ? 'subheader-1' : 'body-1'}>
        {t('device_apps.installation.search_step.chapter')}
      </Text>
      <Text variant={step === InstallationSteps.CREATE ? 'subheader-1' : 'body-1'}>
        {t('device_apps.installation.add_app_step.chapter')}
      </Text>
    </div>
  );
};
