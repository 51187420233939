import { useState } from 'react';
import { createContainer } from 'react-tracked';

const useInitialState = () => useState(false);
const container = createContainer(useInitialState);
const SidebarProvider = container.Provider;

const useSidebar = () => {
  const [compact, setCompact] = container.useTracked();
  return { compact, setCompact };
};

export { SidebarProvider, useSidebar };
