import { FC } from 'react';
import css from './storage-table.module.scss';
import {
  DeviceFullDto,
  ExtensionAttributeInventorySection
} from '../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { DeviceExtensionAttributes } from '../device-extension-attributes/device-extension-attributes.component';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import { Text } from '@gravity-ui/uikit';

interface IProps {
  device?: DeviceFullDto;
}

interface IKeyValue {
  key: string;
  value: string | null | undefined;
}

export const StorageTable: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const di = device?.device_information;

  const data: IKeyValue[] = [
    {
      key: t('inventory.device_page.inventory_tab.storage_tab.table.heading.primary_drive_model'),
      value: di?.primary_drive_model
    },
    {
      key: t('inventory.device_page.inventory_tab.storage_tab.table.heading.revision'),
      value: di?.primary_drive_revision
    },
    {
      key: t('inventory.device_page.inventory_tab.storage_tab.table.heading.serial_number'),
      value: di?.primary_drive_serial
    },
    {
      key: t('inventory.device_page.inventory_tab.storage_tab.table.heading.total_capacity'),
      value: di?.primary_drive_capacity_mb
        ? `${(di?.primary_drive_capacity_mb / 1024).toFixed(2)} GB`
        : undefined
    },
    {
      key: t('inventory.device_page.inventory_tab.storage_tab.table.heading.smart_status'),
      value: di?.primary_drive_smart_status
    }
  ];

  const columns: Column<IKeyValue>[] = [
    {
      id: 'key',
      selector: (row) => <Text variant="subheader-1">{row.key}</Text>,
      width: 250
    },
    {
      id: 'value',
      selector: (row) => row.value
    }
  ];

  return (
    <>
      <Table data={data} columns={columns} className={css.Table} />
      <DeviceExtensionAttributes
        className={css.ExtensionAttributes}
        device={device}
        inventorySection={ExtensionAttributeInventorySection.Storage}
      ></DeviceExtensionAttributes>
    </>
  );
};
