import { FC, ReactNode, useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from '../../contexts/auth.context';
import { AxiosRequestConfigExtended } from '../../../api/create-request';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IProps {
  children?: ReactNode;
}

let isInterceptAdded = false;

export const WithAxios: FC<IProps> = ({ children }) => {
  const { authService } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isInterceptAdded) return;
    axios.interceptors.request.use(async (config) => {
      const startsWithHttp = config.url?.search(/^https?:\/\//) === 0;
      if (startsWithHttp) {
        return config;
      }

      const configExtended = config as AxiosRequestConfigExtended;
      const isPublic = configExtended.public;
      if (isPublic) {
        return config;
      }

      if (!authService.isTokenValid()) {
        try {
          await authService.refreshToken();
        } catch (error) {
          toast.error(String(error), { toastId: 'refresh', autoClose: 10_000 });
          navigate('/auth');
          throw error;
        }
      }
      const accessToken = authService.accessToken();
      if (accessToken && config.headers) {
        config.headers['X-Auth-Token'] = accessToken;
      }
      return config;
    });
    isInterceptAdded = true;
  }, []);

  return <>{children}</>;
};
