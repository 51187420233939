import { UploadPushCertificateRequestDto } from '../types/api';
import { createRequest } from './create-request';

export async function uploadCertificate(data: UploadPushCertificateRequestDto): Promise<void> {
  await createRequest({
    url: 'mdm/certificate',
    data,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}
