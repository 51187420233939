import { FC, FormEvent } from 'react';
import css from './settings-upload-certificate.module.scss';
import { useTranslation } from 'react-i18next';
import { FileInput } from '../../../components/file-input-v2/file-input.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Permission, UploadPushCertificateRequestDto } from '../../../../types/api';
import {
  UploadAPNSCertificateForm,
  uploadAPNSCertificateFormSchema
} from './settings-upload-certificate.schema';
import { uploadCertificate } from '../../../../api/mdm';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { usePermission } from '../../../contexts/permission.context';
import { Button, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { Header } from '../../../components/header/header.component';
import useRequest from '../../../../hooks/useRequest';

export const SettingsUploadCertificate: FC = () => {
  const { reset, register, setValue, formState, trigger, getValues } =
    useForm<UploadAPNSCertificateForm>({
      mode: 'onChange',
      resolver: yupResolver(uploadAPNSCertificateFormSchema),
      defaultValues: uploadAPNSCertificateFormSchema.getDefault()
    });
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();
  const uploadRequest = useRequest();

  const handleFormSubmit = async (event?: FormEvent) => {
    event?.preventDefault();
  };

  const handleClick = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    const values = getValues();
    const certificateFile = values.certificate;
    const privateKeyFile = values.private_key;
    try {
      const request: UploadPushCertificateRequestDto = {
        certificate: certificateFile,
        private_key: privateKeyFile,
        password: values.password
      };
      await uploadRequest.send(uploadCertificate(request), 1000);

      toaster.add({
        name: 'success',
        content: t('settings.tiles.upload_certificate.page.upload_success'),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error: unknown) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'danger',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  return (
    <div className={css.Root}>
      <Header
        rightContent={
          <Button
            type="submit"
            view="action"
            loading={uploadRequest.loading}
            onClick={handleClick}
            disabled={!isAllowedTo(Permission.EditAdministration)}
          >
            {t('settings.tiles.upload_certificate.page.upload_btn')}
          </Button>
        }
      />
      <div className={css.Content}>
        <div className={css.Title}>
          <Text variant="display-2">{t('settings.tiles.upload_certificate.page.title')}</Text>
        </div>
        <form onSubmit={handleFormSubmit}>
          <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditAdministration)}>
            <div>
              <Text>{t('settings.tiles.upload_certificate.page.certificate_file')}</Text>
              <FileInput
                accept=".pem"
                error={formState.errors.certificate?.message}
                onUpdate={(file) => {
                  reset({ ...getValues(), certificate: undefined });
                  setValue('certificate', file);
                }}
                onCancel={() => reset({ ...getValues(), certificate: undefined })}
                disabled={uploadRequest.loading}
              />
            </div>
            <div>
              <Text>{t('settings.tiles.upload_certificate.page.private_key_file')}</Text>
              <FileInput
                accept=".key"
                error={formState.errors.private_key?.message}
                onUpdate={(file) => {
                  reset({ ...getValues(), private_key: undefined });
                  setValue('private_key', file);
                }}
                onCancel={() => reset({ ...getValues(), private_key: undefined })}
                disabled={uploadRequest.loading}
              />
            </div>
            <div>
              <Text>{t('settings.tiles.upload_certificate.page.password')}</Text>
              <TextInput
                type="password"
                error={formState.errors.password?.message}
                disabled={uploadRequest.loading}
                {...register('password')}
              />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};
