import { FC } from 'react';
import { PolicyFrequency, PolicyTrigger } from '../../../../../../../../../types/api';
import css from './policy-options-general.module.scss';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  policyFrequencyTitles,
  policyTriggerTitles
} from '../../../../../../../../../const/policies.const';
import { useTranslation } from 'react-i18next';
import { Checkbox, Select, SelectOption, Text, TextInput } from '@gravity-ui/uikit';
import cn from 'classnames';
import { PolicyFormValues } from '../../../../policy-form.schema';

interface IProps {
  form: UseFormReturn<PolicyFormValues>;
  disabled?: boolean;
}

export const PolicyOptionsGeneral: FC<IProps> = (props) => {
  const { form, disabled = false } = props;
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    control
  } = form;
  const { t } = useTranslation();

  const frequencyOptions: SelectOption[] = Object.values(PolicyFrequency).map((i) => ({
    content: policyFrequencyTitles[i],
    value: i
  }));

  const handleUpdateFrequency = (value: string[]) => {
    setValue('general.frequency', value[0] as PolicyFrequency);
  };

  return (
    <div className={css.Root}>
      <Text variant="subheader-2">{t('policies.options_general.general')}</Text>
      <Controller
        control={control}
        name="general.enabled"
        render={({ field: { value, onChange, ref } }) => (
          <Checkbox
            ref={ref}
            className={css.Checkbox}
            content={<Text>{t('policies.options_general.enabled')}</Text>}
            checked={value}
            onChange={onChange}
            disabled={disabled}
          />
        )}
      />
      <Text variant="subheader-2">{t('policies.options_general.triggers')}</Text>
      <Controller
        control={control}
        name="general.triggers.Immediate"
        render={({ field: { value, onChange, ref } }) => (
          <Checkbox
            ref={ref}
            className={css.Checkbox}
            content={<Text>{policyTriggerTitles[PolicyTrigger.Immediate]}</Text>}
            checked={value}
            onChange={onChange}
            disabled={disabled}
          />
        )}
      />
      <Controller
        control={control}
        name="general.triggers.Interval"
        render={({ field: { value, onChange, ref } }) => (
          <Checkbox
            ref={ref}
            className={css.Checkbox}
            content={<Text>{policyTriggerTitles[PolicyTrigger.Interval]}</Text>}
            checked={value}
            onChange={onChange}
            disabled={disabled}
          />
        )}
      />
      {watch(`general.triggers.Interval`) && (
        <div className={cn(css.ExtraFormInput)}>
          <Text className={css.Label}>{t('policies.options_general.interval_amount')}</Text>
          <TextInput
            type="number"
            error={errors.general?.interval_amount?.message}
            disabled={disabled}
            {...register('general.interval_amount')}
          />
        </div>
      )}
      <div>
        <Text className={css.Label}>{t('policies.options_general.frequency')}</Text>
        <Select
          options={frequencyOptions}
          error={errors.general?.frequency?.message}
          disabled={disabled}
          width="max"
          value={[watch().general.frequency]}
          onUpdate={handleUpdateFrequency}
          {...register('general.frequency')}
        />
      </div>
    </div>
  );
};
