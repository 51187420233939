import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import css from './user-list.module.scss';
import { GetUserListResponseDto, Permission, UserSimpleDto } from '../../../../types/api';
import { getUsers } from '../../../../api/users';
import { formatDate } from '../../../../utils/time.utils';
import { usePermission } from '../../../contexts/permission.context';
import { getLocalizedErrorString } from '../../../../utils/localize-error';
import { userOriginTypeTitles } from '../../../../const/users.const';
import { Column, Table } from '../../../components/table/table.component';
import { Button, Text, useToaster } from '@gravity-ui/uikit';
import { Header } from '../../../components/header/header.component';
import useRequest from '../../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.component';
import noDataImage from '../../../../assets/images/no-data.png';

export const UserListComponent: FC = () => {
  const navigate = useNavigate();
  const toaster = useToaster();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const fetchRequest = useRequest<GetUserListResponseDto>();

  const [users, setUsers] = useState<UserSimpleDto[]>([]);
  const [userCount, setUserCount] = useState<number>(0);

  const dateSort = (dateA: string | number | Date, dateB: string | number | Date) => {
    const timestampA = new Date(dateA).getTime();
    const timestampB = new Date(dateB).getTime();
    return timestampA - timestampB;
  };

  const handleRowClick = (row: UserSimpleDto) => {
    navigate(row.id);
  };

  const handleNewBtnClick = () => {
    navigate('new');
  };

  const usersColumns: Column<UserSimpleDto>[] = [
    {
      id: 'username',
      name: t('settings.tiles.users.page.table.heading.username'),
      selector: (row: UserSimpleDto) => row.username
    },
    {
      id: 'display_name',
      name: t('settings.tiles.users.page.table.heading.display_name'),
      selector: (row: UserSimpleDto) => row.display_name
    },
    {
      id: 'origin_type',
      name: t('settings.tiles.users.page.table.heading.origin_type'),
      selector: (row: UserSimpleDto) => userOriginTypeTitles[row.origin_type]
    },
    {
      id: 'role',
      name: t('settings.tiles.users.page.table.heading.role'),
      selector: (row: UserSimpleDto) => row.role.title
    },
    {
      id: 'created_at',
      name: t('settings.tiles.users.page.table.heading.created'),
      selector: (row: UserSimpleDto) => formatDate(row.created_at),
      sortable: (a: UserSimpleDto, b: UserSimpleDto) => dateSort(a.created_at, b.created_at)
    },
    {
      id: 'updated_at',
      name: t('settings.tiles.users.page.table.heading.updated'),
      selector: (row: UserSimpleDto) => formatDate(row.updated_at),
      sortable: (a: UserSimpleDto, b: UserSimpleDto) => dateSort(a.updated_at, b.updated_at)
    }
  ];

  const init = async () => {
    try {
      const response = await fetchRequest.send(getUsers(), 1000);
      setUsers(response.users);
      setUserCount(response.count);
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      toaster.add({
        name: 'danger',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  useEffect(() => {
    void init();
  }, []);

  const headerContent = (
    <Button
      disabled={fetchRequest.loading || !isAllowedTo(Permission.EditAdministration)}
      view="action"
      onClick={handleNewBtnClick}
    >
      {t('settings.tiles.users.page.new_btn')}
    </Button>
  );
  const tableTitle = <Text variant="display-2">{t('settings.tiles.users.page.title')}</Text>;

  return (
    <div className={css.Root}>
      <Header rightContent={headerContent} />
      <div className={css.DataTableContainer}>
        {fetchRequest.loading ? (
          <>
            <div className={css.Title}>{tableTitle}</div>
            <TableSkeleton />
          </>
        ) : userCount === 0 ? (
          <>
            <div className={css.Title}>{tableTitle}</div>
            <div className={css.NoDataContainer}>
              <img alt="no-data" src={noDataImage} />
              <Text variant="subheader-3">{t('settings.tiles.users.page.no_data')}</Text>
              {headerContent}
            </div>
          </>
        ) : (
          <Table
            columns={usersColumns}
            data={users}
            onRowClick={handleRowClick}
            leftContent={tableTitle}
          />
        )}
      </div>
    </div>
  );
};
