import i18next, { t } from 'i18next';
import { number, object, string } from 'yup';
import { notEmpty } from '../../../../../utils/yup.validations';

export interface DeviceUserFormValues {
  username: string;
  email?: string;
  employee_name?: string;
  phone_number?: string;
  position?: string;
  computers_count?: number;
  mobile_devices_count?: number;
}

export const deviceUserFormSchema = object({
  username: string()
    .required(t('device_users.user_form.errors.username_required'))
    .trim()
    .test(notEmpty(i18next.t('static_groups.page.group_information_tab.errors.no_display_name'))),
  email: string().email(i18next.t('common.errors.invalid_email')),
  employee_name: string(),
  phone_number: string(),
  position: string(),
  computers_count: number().default(0),
  mobile_devices_count: number().default(0)
});
