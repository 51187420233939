import { createRequest } from './create-request';
import {
  CreateUserRequestDto,
  GetUserListResponseDto,
  UpdateUserRequestDto,
  UserSimpleDto
} from '../types/api';

export async function getMyUser(): Promise<UserSimpleDto> {
  return createRequest<UserSimpleDto>({ url: 'user' });
}

export async function getUsers(): Promise<GetUserListResponseDto> {
  return createRequest<GetUserListResponseDto>({ url: 'user/list' });
}

export async function getUser(id: string): Promise<UserSimpleDto> {
  return createRequest<UserSimpleDto>({ url: `user/${id}` });
}

export async function createUser(data: CreateUserRequestDto): Promise<UserSimpleDto> {
  return createRequest({ method: 'POST', url: `user`, data });
}

export async function updateUser(id: string, data: UpdateUserRequestDto): Promise<UserSimpleDto> {
  return createRequest({ method: 'PATCH', url: `user/${id}`, data });
}

export async function deleteUser(id: string): Promise<UserSimpleDto> {
  return createRequest({ method: 'DELETE', url: `user/${id}` });
}
