import { FC } from 'react';
import { DeviceFullDto, DeviceServiceSimpleDto } from '../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import css from './services-table.module.scss';

interface IProps {
  device?: DeviceFullDto;
}

export const ServicesTable: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const column: Column<DeviceServiceSimpleDto>[] = [
    {
      id: 'name',
      name: t('inventory.device_page.inventory_tab.services_tab.table.heading.name'),
      selector: (i) => i.name
    }
  ];

  return (
    <>
      <Table columns={column} data={device?.services || []} className={css.Table} />
    </>
  );
};
