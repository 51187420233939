import { createRequest } from './create-request';
import {
  GetExtensionAttributesResponseDto,
  CreateExtensionAttributeDto,
  UpdateExtensionAttributeDto,
  ExtensionAttributeFullDto
} from '../types/api';

export async function getExtensionAttributes(): Promise<GetExtensionAttributesResponseDto> {
  return createRequest<GetExtensionAttributesResponseDto>({ url: 'extension-attributes' });
}

export async function getExtensionAttribute(id: string): Promise<ExtensionAttributeFullDto> {
  return createRequest<ExtensionAttributeFullDto>({ url: `extension-attributes/${id}` });
}

export async function createExtensionAttribute(
  data: CreateExtensionAttributeDto
): Promise<ExtensionAttributeFullDto> {
  return createRequest({ method: 'POST', url: `extension-attributes`, data });
}

export async function updateExtensionAttribute(
  id: string,
  data: UpdateExtensionAttributeDto
): Promise<ExtensionAttributeFullDto> {
  return createRequest({ method: 'PATCH', url: `extension-attributes/${id}`, data });
}

export async function deleteExtensionAttribute(id: string): Promise<ExtensionAttributeFullDto> {
  return createRequest({ method: 'DELETE', url: `extension-attributes/${id}` });
}
