import { FC } from 'react';
import { Button } from '@gravity-ui/uikit';
import css from './action-menu.module.scss';
import { t } from 'i18next';

interface IProps {
  mode: 'view' | 'create' | 'edit';
  disabled: boolean;
  onSmartGroupCreate: () => void;
  onSmartGroupCancel: () => void;
  onSmartGroupDelete: () => void;
  onSmartGroupEditStart: () => void;
  onSmartGroupEdit: () => void;
}

export const ActionMenu: FC<IProps> = (props) => {
  const {
    mode,
    disabled,
    onSmartGroupCreate,
    onSmartGroupCancel,
    onSmartGroupDelete,
    onSmartGroupEditStart,
    onSmartGroupEdit
  } = props;

  return (
    <div className={css.ButtonGroup}>
      {mode === 'create' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onSmartGroupCancel}>
            {t('smart_groups.page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onSmartGroupCreate}>
            {t('smart_groups.page.create_btn')}
          </Button>
        </>
      )}
      {mode === 'view' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onSmartGroupDelete}>
            {t('smart_groups.page.delete_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onSmartGroupEditStart}>
            {t('smart_groups.page.edit_btn')}
          </Button>
        </>
      )}
      {mode === 'edit' && (
        <>
          <Button view="flat" disabled={disabled} onClick={onSmartGroupCancel}>
            {t('smart_groups.page.cancel_btn')}
          </Button>
          <Button view="action" disabled={disabled} onClick={onSmartGroupEdit}>
            {t('smart_groups.page.save_btn')}
          </Button>
        </>
      )}
    </div>
  );
};
