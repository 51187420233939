import { FC } from 'react';
import { DeviceCertificateSimpleDto, DeviceFullDto } from '../../../../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { isBoolean } from '../../../../../../../../../utils/assert.utils';
import { Column, Table } from '../../../../../../../../components/table/table.component';
import css from './certificates-table-mobile.module.scss';

interface IProps {
  device?: DeviceFullDto;
}

export const CertificatesTableMobile: FC<IProps> = (props: IProps) => {
  const { device } = props;
  const { t } = useTranslation();

  const columns: Column<DeviceCertificateSimpleDto>[] = [
    {
      id: 'name',
      name: t('inventory_mobile.device_page.inventory_tab.certificates_tab.table.heading.name'),
      selector: (certificate) => certificate.common_name
    },
    {
      id: 'is_identity',
      name: t(
        'inventory_mobile.device_page.inventory_tab.certificates_tab.table.heading.is_identity'
      ),
      selector: (certificate) =>
        isBoolean(certificate.is_identity)
          ? certificate.is_identity
            ? t('inventory_mobile.device_page.inventory_tab.certificates_tab.table.yes')
            : t('inventory_mobile.device_page.inventory_tab.certificates_tab.table.no')
          : undefined
    }
  ];

  return (
    <>
      <Table columns={columns} data={device?.certificates || []} className={css.Table} />
    </>
  );
};
