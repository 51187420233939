import css from './configuration-profile.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createConfigProfile,
  deleteConfigProfile,
  downloadConfigProfile,
  getConfigProfile,
  importConfigProfile,
  updateConfigProfile
} from '../../../api/config-profiles';
import {
  ConfigProfileFullDto,
  ConfigProfileSimpleDto,
  CreateConfigProfileDto,
  DeviceType as ApiDeviceType,
  ImportConfigProfileDto,
  Permission,
  UpdateConfigProfileDto
} from '../../../types/api';
import { ConfigurationProfileForm } from './components/configuration-profile-form/configuration-profile-form.component';
import {
  ConfigurationProfileFormValues,
  ConfigurationProfileUploadFormValues,
  formSchema,
  uploadFormSchema
} from './components/configuration-profile-form/configuration-profile-form.schema';
import {
  configurationProfileSimpleToForm,
  configurationProfileToForm,
  formScopeEntries,
  partialUpdateConfigurationProfile
} from './configuration-profile.utils';
import { getLocalizedErrorString } from '../../../utils/localize-error';
import useDeviceSection, { DeviceType } from '../../contexts/device-section.context';
import { Dialog, Popover, Text, TextInput, useToaster } from '@gravity-ui/uikit';
import { Header } from '../../components/header/header.component';
import { ActionMenu } from './components/action-menu/action-menu.component';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { UploadModal } from './components/upload-modal/upload-modal.component';
import useRequest from '../../../hooks/useRequest';
import { ProfileSkeleton } from './components/skeleton/skeleton.component';
import { usePermission } from '../../contexts/permission.context';

export const ConfigurationProfile: FC = () => {
  const { id } = useParams();
  const configProfileRef = useRef<ConfigProfileFullDto>();
  const navigate = useNavigate();
  const toaster = useToaster();
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const crudRequest = useRequest();
  const deviceSection = useDeviceSection();
  const deviceType =
    deviceSection.deviceType === DeviceType.COMPUTERS
      ? ApiDeviceType.Computer
      : ApiDeviceType.Mobile;

  const [formMode, setFormMode] = useState<'create' | 'edit' | 'view' | 'upload'>(
    id === 'new' ? 'create' : id === 'import' ? 'upload' : 'view'
  );
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(id === 'import');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const configProfileForm = useForm<ConfigurationProfileFormValues>({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: formSchema.getDefault()
  });
  const {
    register,
    formState: { errors }
  } = configProfileForm;

  const configProfileUploadForm = useForm<ConfigurationProfileUploadFormValues>({
    mode: 'onChange',
    resolver: yupResolver(uploadFormSchema),
    defaultValues: uploadFormSchema.getDefault()
  });

  const isImported = !!configProfileForm.watch().imported_payload;

  const handleProfileDownload = async () => {
    if (!configProfileRef.current || crudRequest.loading) return;
    const data = (await crudRequest.send(
      downloadConfigProfile(configProfileRef.current.id),
      1000
    )) as Blob;
    const blob = new Blob([data]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `${configProfileRef.current?.display_name}.mobileconfig`);
    link.click();
  };

  const handleProfileEditStart = () => setFormMode('edit');

  const handleProfileDelete = async () => {
    if (!configProfileRef.current || crudRequest.loading) return;
    try {
      await crudRequest.send(deleteConfigProfile(configProfileRef.current.id), 1000);

      navigate('./..');
      toaster.add({
        name: 'delete-profile-success',
        content: t('profiles.profile_form.successfully_deleted', {
          profile: configProfileRef.current.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedErrorString = getLocalizedErrorString(error as Error);
      console.log(localizedErrorString);
      toaster.add({
        name: 'delete-profile-error',
        content: localizedErrorString,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleProfileUpload = async () => {
    if (crudRequest.loading) return;

    const isValid = await configProfileUploadForm.trigger();
    if (!isValid) return;

    const configFile = configProfileUploadForm.getValues().config_profile;
    if (!configFile) return;

    const values = configProfileUploadForm.getValues();

    const request: ImportConfigProfileDto = {
      config: configFile,
      scoped: values.scope?.scoped,
      device_type: deviceType,
      ...formScopeEntries(values.scope)
    };
    try {
      const response = (await crudRequest.send(
        importConfigProfile(request),
        1000
      )) as ConfigProfileFullDto;
      configProfileRef.current = response;
      configProfileForm.reset(configurationProfileToForm(response));

      setFormMode('view');
      navigate(`./../${response.id}`);
      toaster.add({
        name: 'import-profile-success',
        content: t('profiles.profile_form.successfully_uploaded', {
          profile: response.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedError = getLocalizedErrorString(error as Error);
      console.log(localizedError);
      toaster.add({
        name: 'import-profile-error',
        content: localizedError,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleProfileCreate = async () => {
    if (crudRequest.loading) return;

    const isValid = await configProfileForm.trigger();
    if (!isValid) return;

    const values = configProfileForm.getValues();

    const request: CreateConfigProfileDto = {
      display_name: values.display_name,
      description: values.description,
      organization_name: values.organization_name,
      payload: values.payload,
      scoped: values.scope?.scoped,
      device_type: deviceType,
      ...formScopeEntries(values.scope)
    };
    try {
      const response = (await crudRequest.send(
        createConfigProfile(request),
        1000
      )) as ConfigProfileSimpleDto;

      setFormMode('view');
      navigate(`./../${response.id}`);
      toaster.add({
        name: 'create-profile-success',
        content: t('profiles.profile_form.successfully_created', {
          profile: response.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedError = getLocalizedErrorString(error as Error);
      console.log(localizedError);
      toaster.add({
        name: 'create-profile-error',
        content: localizedError,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };
  const handleProfileCancel = () => {
    if (formMode === 'create' || formMode === 'upload') {
      navigate(`./../`);
      return;
    }
    if (configProfileRef.current) {
      configProfileForm.reset(configurationProfileToForm(configProfileRef.current));
    }
    setFormMode('view');
  };

  const handleProfileEdit = async () => {
    if (!id || crudRequest.loading) return;

    const isValid = await configProfileForm.trigger();
    if (!isValid) return;

    const values = configProfileForm.getValues();

    let request: UpdateConfigProfileDto;
    if (values.imported_payload) {
      request = {
        scoped: values.scope?.scoped,
        ...formScopeEntries(values.scope)
      };
    } else {
      request = {
        display_name: values?.display_name,
        description: values?.description,
        organization_name: values?.organization_name,
        payload: values?.payload || null,
        scoped: values.scope?.scoped,
        ...formScopeEntries(values.scope)
      };
    }
    try {
      const response = (await crudRequest.send(
        updateConfigProfile(id, request),
        1000
      )) as ConfigProfileSimpleDto;
      configProfileForm.reset(
        configurationProfileSimpleToForm(response, configProfileRef?.current)
      );
      configProfileRef.current = partialUpdateConfigurationProfile(
        response,
        configProfileRef.current
      );

      setFormMode('view');
      toaster.add({
        name: 'update-profile-success',
        content: t('profiles.profile_form.successfully_updated', {
          profile: response.display_name
        }),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedError = String(error);
      console.log(localizedError);
      toaster.add({
        name: 'update-profile-error',
        content: localizedError,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const handleUploadModalSubmit = () => setIsUploadModalOpen(false);
  const handleUploadModalCancel = () => {
    setIsUploadModalOpen(false);
    navigate('./..');
  };

  const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleDeleteModalClose = () => setIsDeleteModalOpen(false);
  const handleDeleteModalSubmit = async () => {
    await handleProfileDelete();
    setIsDeleteModalOpen(false);
  };

  const init = async () => {
    if (!id || crudRequest.loading) return;
    if (id !== 'import' && id !== 'new') {
      const response = (await crudRequest.send(getConfigProfile(id), 1000)) as ConfigProfileFullDto;
      configProfileRef.current = response;
      configProfileForm.reset(configurationProfileToForm(response));
    }
  };

  useEffect(() => {
    void init();
  }, [id]);

  return (
    <div className={css.Root}>
      <Header
        breadcrumbsTitle={configProfileRef.current?.display_name}
        rightContent={
          <ActionMenu
            mode={formMode}
            disabled={crudRequest.loading || !isAllowedTo(Permission.EditProfiles)}
            onProfileCreate={handleProfileCreate}
            onProfileCancel={handleProfileCancel}
            onProfileEdit={handleProfileEdit}
            onProfileEditStart={handleProfileEditStart}
            onProfileDelete={handleDeleteModalOpen}
            onProfileDownload={handleProfileDownload}
            onProfileUpload={handleProfileUpload}
          />
        }
      />
      <div className={css.Content}>
        {crudRequest.loading ? (
          <ProfileSkeleton />
        ) : (
          <>
            <div className={css.Title}>
              {formMode === 'upload' && (
                <Text variant="display-2">{t('profiles.profile_form.create_title')}</Text>
              )}
              {(formMode === 'view' || (formMode === 'edit' && isImported)) && (
                <Text variant="display-2">{configProfileRef.current?.display_name}</Text>
              )}
              {(formMode === 'create' || (formMode === 'edit' && !isImported)) && (
                <Popover content={t('common.click_to_edit')}>
                  <TextInput
                    placeholder={t('profiles.profile_form.options_tab.general.display_name')}
                    size="xl"
                    view="clear"
                    error={errors.display_name?.message}
                    errorPlacement="inside"
                    controlProps={{ className: css.TitleInput }}
                    {...register('display_name')}
                  />
                </Popover>
              )}
            </div>
            <ConfigurationProfileForm
              mode={formMode}
              configProfileForm={configProfileForm}
              configProfileUploadForm={configProfileUploadForm}
            />
          </>
        )}
      </div>
      <UploadModal
        isOpen={isUploadModalOpen}
        onSubmit={handleUploadModalSubmit}
        form={configProfileUploadForm}
        onCancel={handleUploadModalCancel}
      />
      <Dialog onClose={handleDeleteModalClose} open={isDeleteModalOpen}>
        <Dialog.Header caption={t('profiles.profile_form.modal_delete.title')} />
        <Dialog.Body>
          <Text>
            {t('profiles.profile_form.modal_delete.message', {
              title: configProfileRef.current?.display_name
            })}
          </Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('profiles.profile_form.modal_delete.delete_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={handleDeleteModalSubmit}
          onClickButtonCancel={handleDeleteModalClose}
          loading={crudRequest.loading}
        />
      </Dialog>
    </div>
  );
};
